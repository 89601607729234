import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import {
  getcarddetails,
  deleteusercard,
  updatemycard,
} from "../apiserve/ApiComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import Toast from "react-bootstrap/Toast";
// import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import axios from "axios";
import "./dashboard.css";
import { Resizable } from "re-resizable";
import Draggable, { DraggableCore } from "react-draggable";
import { AlignEnd } from "react-bootstrap-icons";
class DashboardMyCardDetailedViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carddetails: [],
      carduserdetails: [],
      carddata: [],
      templdateData: [],
      card_logodata: [],
      card_orientation: "",
      cardbgimage: "",
      show: false,
      saved: false,
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      response: "",
      responsetype: "",
      isLoading: false,
    };
    this.deleteusercard = this.deleteusercard.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleOnChange(e) {
    let updatedata = this.state.carddetails;
    updatedata[e.target.name] = e.target.value;
    this.setState({ carddetails: updatedata });
  }

  handleSave(e) {
    const formData = new FormData();
    formData.append("template_id", this.state.carddetails.template_id);
    formData.append("carddata", this.state.carddetails.card_data);
    formData.append("cardname", this.state.carddetails.cardname);
    formData.append("uid", this.state.carddetails.userid);
    formData.append("id", this.state.carddetails.id);
    updatemycard(formData);
    this.setState({ response: "Card Saved" });
    this.setState({ responsetype: "success" });
    setTimeout(function () {
      window.location.href = "/user/mycards";
    }, 1000);
  }

  getcarddetails(id) {
    getcarddetails(
      id,
      (res) => {
        this.setState({ carddetails: res.data.data[0][0] });
        let cdate = new Date(this.state.carddetails.created_at);
        this.state.carddetails.created_at = cdate.toString().split("GMT")[0];
        this.setState({ carddetails: res.data.data[0][0] });
        this.setState({
          carduserdetails: res.data.data[0][0].userdetails,
        });
        this.setState({
          templdateData: res.data.data[0][0].card_template_data,
        });
        var cardDesignStr = res.data.data[0][0].card_data;
        this.setState({
          carddata: JSON.parse(cardDesignStr),
        });
        this.setState({ cardbgimage: res.data.data[0][0].cardbgimage });
        this.setState({
          card_orientation: res.data.data[0][0].card_orientation,
        });
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading cards data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  deleteusercard() {
    let cardid = "";
    // cardid= localStorage.getItem('currentcard');
    cardid = { id: localStorage.getItem("currentcard") };
    this.setState({ isLoading: true });
    deleteusercard(
      cardid,
      (res) => {
        console.log(res);
        if ((res.status = 200)) {
          this.setState({ isLoading: false });
          this.setState({ show: false });
          this.setState({ response: "Card deleted successfully." });
          this.setState({ responsetype: "success" });
          setTimeout(function () {
            window.location.href = "/user/mycards";
          }, 1000);
        }
      },
      (err) => {
        console.log(err);
        this.setState({ show: false });
        this.setState({ response: "Error in deleting card." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") == 2) {
      let cardid = "";
      let queries = queryString.parse(this.props.location.search);
      if (queries.id != undefined) {
        cardid = queries.id;
      }
      localStorage.setItem("currentcard", cardid);
      this.getcarddetails(cardid);
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure, You want to Delete your card.
            </h5>
            <p className="text-center">
              Once you delete your card, Card data will be deleted permanently.
            </p>
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.deleteusercard}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>

            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <TopBarComponent imagePath={localStorage.getItem("profileimagepath")} />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">My Cards</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <Link
                      to={
                        `../card-design?id=` +
                        this.state.carddetails.template_id +
                        `&action=update&cid=` +
                        this.state.carddetails.id
                      }
                    >
                      <button
                        type="button"
                        className="btn btn-primary btn-sm btn-outline-secondary text-white"
                      >
                        Edit
                      </button>
                    </Link>
                  </div>

                  <button
                    type="button"
                    className="btn btn-danger btn-sm btn-outline-secondary text-white"
                    onClick={this.handleShow}
                  >
                    {/* <span data-feather="calendar"></span> */}
                    Delete
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3"></div>
              <div className="col">
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="row">
                      <div className="col-12">
                        <label>Card Design:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="cardname"
                          value={this.state.carddetails.cardname}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        <button
                          style={{
                            alignItems: "end",
                            display: "flex",
                            justifyContent: "flex-end",
                            float: "right",
                            backgroundColor: "green",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                          }}
                          onClick={(e) => this.handleSave(e)}
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-12">
                        <label>Card Created By:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.carduserdetails}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="clearfix he-1"></div>
                    <div className="row">
                      <div className="col-12">
                        <label>Card Created On:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.carddetails.created_at}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="clearfix he-1"></div>
                    <div className="row">
                      <div className="col-12">
                        <label>Card Template:</label>
                        <input
                          className="form-control"
                          value={this.state.carddetails.cardtemplatename}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Landscape ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                    {this.state.card_orientation != "portrait" ? (
                      <div
                        style={{
                          display: "block",
                          border: "solid 1px blue",
                          width: "87%",
                        }}
                      >
                        <div
                          id="savefromhere"
                          className="col-lg-12"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            className="col-lg-12 p-4 backgroundimage"
                            style={{
                              background: `url(${
                                this.state.baseImgPath + this.state.cardbgimage
                              })`,
                              // background: `url(${this.state.baseImgPath + this.state.carddetails.card_preview})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                          >
                            <div
                              ref={this.inputRef}
                              id={"cardheight"}
                              className="col-lg-12 cardheight justify-content-center"
                              style={{
                                height: "100%",
                                border: "dashed 1px #000",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <div
                                className="col-12"
                                style={{ height: "100%" }}
                              >
                                {this.state.carddata.staffid && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.staffid.position.x,
                                      y: this.state.carddata.staffid.position.y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col py-2 d-block"
                                            id="valuw1"
                                            style={{
                                              color: `${
                                                this.state.carddata.staffid
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.staffid
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.staffid
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.staffid
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.staffid
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.staffid
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.staffid
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.staffid.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.firstname && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.firstname.position
                                        .x,
                                      y: this.state.carddata.firstname.position
                                        .y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col d-block text-center py-2"
                                            id="valuw1"
                                            style={{
                                              color: `${
                                                this.state.carddata.firstname
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.firstname
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.firstname
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.firstname
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.firstname
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.firstname
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.firstname
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.firstname.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.lastname && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.lastname.position
                                        .x,
                                      y: this.state.carddata.lastname.position
                                        .y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col d-block text-center py-2"
                                            style={{
                                              color: `${
                                                this.state.carddata.lastname
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.lastname
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.lastname
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.lastname
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.lastname
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.lastname
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.lastname
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.lastname.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.position && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.position.position
                                        .x,
                                      y: this.state.carddata.position.position
                                        .y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col>
                                          <FormLabel
                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                            style={{
                                              color: `${
                                                this.state.carddata.position
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.position
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.position
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.position
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.position
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.position
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.position
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.position.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.phoneno && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.phoneno.position.x,
                                      y: this.state.carddata.phoneno.position.y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col>
                                          <FormLabel
                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                            id="valuw0"
                                            style={{
                                              color: `${
                                                this.state.carddata.phoneno
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.phoneno
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.phoneno
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.phoneno
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.phoneno
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.phoneno
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.phoneno
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.phoneno.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.extrafields &&
                                  this.state.carddata.extrafields.map(
                                    (item, i) => (
                                      <Draggable
                                        key={i}
                                        position={{
                                          x: item.position.x,
                                          y: item.position.y,
                                        }}
                                      >
                                        <div>
                                          <Row className="handle">
                                            <div
                                              id="extrafields"
                                              className={i}
                                              style={{
                                                fontSize: `${
                                                  item.fontSize || "14px"
                                                }`,
                                                display: "inline-block",
                                                color: `${
                                                  item.color || "#000"
                                                }`,
                                                fontFamily: `${
                                                  item.fontFamily || "arial"
                                                }`,
                                                fontWeight: `${
                                                  item.fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontStyle: `${
                                                  item.fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  item.fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                              }}
                                              key={i}
                                            >
                                              {item.text}
                                            </div>
                                          </Row>
                                        </div>
                                      </Draggable>
                                    )
                                  )}
                                {this.state.carddetails.card_logo && (
                                  <div>
                                    <Draggable
                                      position={{
                                        x: this.state.card_logodata.x,
                                        y: this.state.card_logodata.y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <img
                                            id="myuploadedimage"
                                            src={
                                              this.state.carddetails
                                                ? this.state.baseImgPath +
                                                  this.state.carddetails
                                                    .card_logo
                                                : null || ""
                                            }
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                            }}
                                          />
                                        </Row>
                                      </div>
                                    </Draggable>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // ~~~~~~~~~~~~~~~~~~~~~~~~ Portrait ~~~~~~~~~~~~~~~~~~~~~~~~~
                      <div
                        style={{
                          display: "block",
                          border: "solid 1px blue",
                          width: "478px",
                          height: "763px",
                          borderRadius: "15px",
                        }}
                      >
                        <div
                          id="savefromhere"
                          className="col-lg-12"
                          style={{
                            background: "#ccc",
                            borderRadius: "15px",
                            width: "476px",
                            height: "761px",
                          }}
                        >
                          <div
                            className="col-lg-12 p-4 backgroundimage"
                            style={{
                              background: `url(${
                                this.state.baseImgPath + this.state.cardbgimage
                              })`,
                              // background: `url(${this.state.baseImgPath + this.state.carddetails.card_preview})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              width: "478px",
                              height: "762px",
                              borderRadius: "15px",
                            }}
                          >
                            <div
                              ref={this.inputRef}
                              id={"cardheight"}
                              className="col-lg-12 cardheight justify-content-center"
                              style={{
                                height: "100%",
                                border: "dashed 1px #000",
                                overflow: "hidden",
                                position: "relative",
                                width: "476px",
                                height: "760px",
                                borderRadius: "15px",
                              }}
                            >
                              <div
                                className="col-12"
                                style={{ height: "100%" }}
                              >
                                {this.state.carddata.staffid && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.staffid.position.x,
                                      y: this.state.carddata.staffid.position.y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col py-2 d-block"
                                            id="valuw1"
                                            style={{
                                              color: `${
                                                this.state.carddata.staffid
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.staffid
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.staffid
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.staffid
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.staffid
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.staffid
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.staffid
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.staffid.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.firstname && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.firstname.position
                                        .x,
                                      y: this.state.carddata.firstname.position
                                        .y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col d-block text-center py-2"
                                            id="valuw1"
                                            style={{
                                              color: `${
                                                this.state.carddata.firstname
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.firstname
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.firstname
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.firstname
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.firstname
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.firstname
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.firstname
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.firstname.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.lastname && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.lastname.position
                                        .x,
                                      y: this.state.carddata.lastname.position
                                        .y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col d-block text-center py-2"
                                            style={{
                                              color: `${
                                                this.state.carddata.lastname
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.lastname
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.lastname
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.lastname
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.lastname
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.lastname
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.lastname
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.lastname.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.position && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.position.position
                                        .x,
                                      y: this.state.carddata.position.position
                                        .y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col>
                                          <FormLabel
                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                            style={{
                                              color: `${
                                                this.state.carddata.position
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.position
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.position
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.position
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.position
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.position
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.position
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.position.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.phoneno && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.phoneno.position.x,
                                      y: this.state.carddata.phoneno.position.y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col>
                                          <FormLabel
                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                            id="valuw0"
                                            style={{
                                              color: `${
                                                this.state.carddata.phoneno
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.phoneno
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.phoneno
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.phoneno
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.phoneno
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.phoneno
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.phoneno
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.phoneno.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.extrafields &&
                                  this.state.carddata.extrafields.map(
                                    (item, i) => (
                                      <Draggable
                                        key={i}
                                        position={{
                                          x: item.position.x,
                                          y: item.position.y,
                                        }}
                                      >
                                        <div>
                                          <Row className="handle">
                                            <div
                                              id="extrafields"
                                              className={i}
                                              style={{
                                                fontSize: `${
                                                  item.fontSize || "14px"
                                                }`,
                                                display: "inline-block",
                                                color: `${
                                                  item.color || "#000"
                                                }`,
                                                fontFamily: `${
                                                  item.fontFamily || "arial"
                                                }`,
                                                fontWeight: `${
                                                  item.fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontStyle: `${
                                                  item.fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  item.fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                              }}
                                              key={i}
                                            >
                                              {item.text}
                                            </div>
                                          </Row>
                                        </div>
                                      </Draggable>
                                    )
                                  )}
                                {this.state.carddetails.card_logo && (
                                  <div>
                                    <Draggable
                                      position={{
                                        x: this.state.card_logodata.x,
                                        y: this.state.card_logodata.y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <img
                                            id="myuploadedimage"
                                            src={
                                              this.state.carddetails
                                                ? this.state.baseImgPath +
                                                  this.state.carddetails
                                                    .card_logo
                                                : null || ""
                                            }
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                            }}
                                          />
                                        </Row>
                                      </div>
                                    </Draggable>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardMyCardDetailedViewComponent;
