import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import {
  getallfooterdetails,
  updateingsetting,
} from "../apiserve/ApiComponent";
import TopBarComponent from "./TopBarComponent";
import queryString from "query-string";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { convertToHTML } from "draft-convert";
// import draftToHtml from 'draftjs-to-html';
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import "./dashboard.css";
class DashboardWebsiteSettingComponents extends Component {
  constructor(props) {
    super(props);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.state = {
      allorders: [],
      response: "",
      allsitedata: [],
      catid: "",
      show: false,
      showD: false,
      // editorState: convertToRaw(ContentState.createFromText('Privacy Policy')),
      editorState: EditorState.createEmpty(),
      address: "",
      phone: "",
      mail: "",
      watermark: "",
      privacy_policy: EditorState.createEmpty(),
      terms_conditions: EditorState.createEmpty(),
      facebook: "",
      twitter: "",
      linkedin: "",
      instagram: "",
      formErrors: {
        address: "",
        phone: "",
        mail: "",
        watermark: "",
        privacy_policy: "",
        terms_conditions: "",
        facebook: "",
        twitter: "",
        linkedin: "",
        instagram: "",
      },
      addressValid: false,
      phoneValid: false,
      mailValid: false,
      watermarkValid: false,
      privacy_policyValid: false,
      terms_conditionsValid: false,
      facebookValid: false,
      twitterValid: false,
      linkedinValid: false,
      instagramValid: false,
      formValid: true,
      responsetype: "",
      isLoading: false,
    };
    // this.showuserdetails = this.showuserdetails.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.onPPEditorStateChange = this.onPPEditorStateChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    // this.updateingsetting = this.updateingsetting.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(type) {
    if (type == "delete") {
      this.setState({ showD: true });
    } else if (this.state.categoryname == "" || undefined) {
      this.setState({ response: "Enter category name." });
      this.setState({ responsetype: "danger" });
    } else {
      this.setState({ show: true });
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    // let vpasswordValid = this.state.vpasswordValid;
    let addressValid = this.state.addressValid;
    let phoneValid = this.state.phoneValid;
    let mailValid = this.state.mailValid;
    let watermarkValid = this.state.watermarkValid;
    let privacy_policyValid = this.state.privacy_policyValid;
    let terms_conditionsValid = this.state.terms_conditionsValid;
    let facebookValid = this.state.facebookValid;
    let twitterValid = this.state.twitterValid;
    let linkedinValid = this.state.linkedinValid;
    let instagramValid = this.state.instagramValid;
    switch (fieldName) {
      case "address":
        addressValid = value.length >= 3;
        fieldValidationErrors.address = addressValid ? "" : " is too short";
        break;

      case "phone":
        phoneValid = value.length == 10;

        fieldValidationErrors.phone = phoneValid ? "" : " is too short";
        break;

      case "mail":
        mailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

        fieldValidationErrors.mail = mailValid ? "" : " is invalid";
        break;

      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        addressValid: addressValid,
        facebookValid: facebookValid,
        phoneValid: phoneValid,
        mailValid: mailValid,
        watermarkValid: watermarkValid,
        privacy_policyValid: privacy_policyValid,
        terms_conditionsValid: terms_conditionsValid,
        twitterValid: twitterValid,
        linkedinValid: linkedinValid,
        instagramValid: instagramValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    if (
      this.state.addressValid &&
      this.state.facebookValid &&
      this.state.phoneValid &&
      this.state.mailValid &&
      this.state.watermarkValid &&
      this.state.privacy_policyValid &&
      this.state.terms_conditionsValid &&
      this.state.twitterValid &&
      this.state.linkedinValid &&
      this.state.instagramValid
    ) {
      this.state.formValid = true;
    }
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    let updatedata = this.state.allsitedata;
    updatedata[name] = value;
    this.setState({ allsitedata: updatedata }, () => {
      this.validateField(name, value);
    });
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }
  onPPEditorStateChange(e, dname) {
    console.log(e);
    const blocks = e.blocks;
    const value = blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
    console.log(value);
    const name = dname;
    let updatedata = this.state.allsitedata;
    updatedata[name] = value;
    this.setState({ allsitedata: updatedata }, () => {
      this.validateField(name, value);
    });
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
    //  this.setState({[name]: value})
  }

  onEditorStateChange = (editorState) => {
    console.log(editorState);
    this.setState({
      editorState,
    });
  };
  getallfooterdetails() {
    getallfooterdetails(
      (res) => {
        this.setState({ allsitedata: res.data.data[0][0] });
        this.setState({ address: res.data.data[0][0].address });
        this.setState({ phone: res.data.data[0][0].phone });
        this.setState({ mail: res.data.data[0][0].mail });
        this.setState({ watermark: res.data.data[0][0].watermark });
        this.setState({ facebook: res.data.data[0][0].facebook_url });
        this.setState({ instagram: res.data.data[0][0].instagram_url });
        this.setState({ linkedin: res.data.data[0][0].linkedin_url });
        this.setState({ twitter: res.data.data[0][0].twitter_url });
        this.setState({
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(res.data.data[0][0].privacy_policy)
            )
          ),
        });
        this.setState({
          terms_conditions: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(res.data.data[0][0].privacy_policy)
            )
          ),
        });
      },
      (err) => {
        this.setState({ isLoading: false });
        this.setState({ response: "Error while getting data" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  handleOnSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append("id", 1);
    formData.append("facebook_url", this.state.facebook);
    formData.append("instagram_url", this.state.instagram);
    formData.append("twitter_url", this.state.twitter);
    formData.append("linkedin_url", this.state.linkedin);
    formData.append("phone", this.state.phone);
    formData.append("mail", this.state.mail);
    formData.append("watermark", this.state.watermark);
    formData.append("privacy_policy", this.state.privacy_policy);
    formData.append("terms_conditions", this.state.terms_conditions);
    formData.append("address", this.state.address);
    updateingsetting(
      formData,
      (res) => {
        if (res.success == false) {
          this.setState({ isLoading: false });
          this.setState({ response: "Error while updating settings" });
          this.setState({ responsetype: "error" });
        } else {
          this.setState({ isLoading: false });
          this.setState({ response: "Updated successfully" });
          this.setState({ responsetype: "success" });
        }
      },
      (err) => {
        this.setState({ isLoading: false });
        this.setState({ response: "Error while updating settings" });
        this.setState({ responsetype: "error" });
      }
    );
  }
  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      this.getallfooterdetails();
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure, You want to update Category.
            </h5>
            {/* <p className="text-center">
				  Once you delete your template, template will no longer accessible for user.
				</p> */}
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.updatecategory}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>

            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Website Setting</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  {/* <div className="btn-group me-2">
            <button type="button" onClick={e=>this.handleShow('update')} className="btn btn-sm btn-success text-white btn-outline-secondary">Update</button>
          </div>
          <div className="btn-group me-2">
            <button type="button" onClick={e=>this.handleShow('delete')} className="btn btn-sm btn-danger text-white btn-outline-secondary">Delete</button>
          </div> */}
                </div>
              </div>
              <div className="container rounded bg-white m-1">
                <form onSubmit={this.handleOnSubmit}>
                  <div className="row">
                    <div className="col-lg-12 col-md-6 border-right">
                      <div className="p-1">
                        <div className="row mt-3">
                          <h4>Company Details</h4>
                          <div className="col-lg-12 col-md-12">
                            <label>Address</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.address
                              )} || '' `}
                            >
                              <FormControl
                                name="address"
                                placeholder="Enter URL"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.address || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <h4 className="mt-4">Contact Details</h4>
                          <div className="col-lg-6 col-md-6">
                            <label>Phone No.</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.phone
                              )} || '' `}
                            >
                              <FormControl
                                name="phone"
                                placeholder="Enter URL"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.phone || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>E-Mail</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.mail
                              )} || '' `}
                            >
                              <FormControl
                                name="mail"
                                placeholder="Enter URL"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.mail || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <h4 className="mt-4">Social Links</h4>
                          <div className="col-lg-6 col-md-6">
                            <label>Facebook</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.facebook
                              )} || '' `}
                            >
                              <FormControl
                                name="facebook"
                                placeholder="Enter URL"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.facebook || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>Twitter</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.twitter
                              )} || '' `}
                            >
                              <FormControl
                                name="twitter"
                                placeholder="Enter URL"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.twitter || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>linkedin</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.linkedin
                              )} || '' `}
                            >
                              <FormControl
                                name="linkedin"
                                placeholder="Enter URL"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.linkedin || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>Instagram</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.instagram
                              )} || '' `}
                            >
                              <FormControl
                                name="instagram"
                                placeholder="Enter URL"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.instagram || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <h4 className="mt-4">Watermark</h4>
                          <div className="col-lg-6 col-md-6">
                            <label>Watermark</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.watermark
                              )} || '' `}
                            >
                              <FormControl
                                name="watermark"
                                placeholder="Enter URL"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.watermark || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6"></div>
                          {/* <h4 className="mt-4">Documents</h4> */}
                          {/* <div className="col-lg-6 col-md-6">
                      <label>Privacy Policy</label>
                    <InputGroup className= {`mb-0 form-group ${this.errorClass(this.state.formErrors.privacy_policy)} || '' `}>
                      <FormControl name="privacy_policy" placeholder="Enter URL" required className="form-control wrap-input100 validate-input" value={this.state.privacy_policy  || ''  } onChange={(e) => this.handleUserInput(e)}/>
                    </InputGroup>    
                    </div> */}
                          {/* <div className="col-lg-12 col-md-12 mb-4">
                      <label>Privacy Policy</label>
                      <Editor
                        onChange={(e) => this.onPPEditorStateChange(e, 'privacy_policy')}
                        // editorState={this.state.privacy_policy  || ''}
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                        // onContentStateChange={this.onContentStateChange.bind(this)}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={{ border: "1px solid black", maxHeight:"400px", overflow:"scroll" }}
                        toolbar={{
                          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'],
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: false },
                        }}
                      /> 
                    </div> */}
                          {/* <div className="col-lg-6 col-md-6">
                      <label>Terms & Conditions</label>
                    <InputGroup className= {`mb-0 form-group ${this.errorClass(this.state.formErrors.terms_conditions)} || '' `}>
                      <FormControl name="terms_conditions" placeholder="Enter URL" required className="form-control wrap-input100 validate-input" value={this.state.terms_conditions  || ''  } onChange={(e) => this.handleUserInput(e)}/>
                    </InputGroup>    
                    </div> */}
                          {/* <div className="col-lg-12 col-md-12">
                      <label>Terms & Conditions</label>
                      <Editor
                        onChange={(e) => this.onEditorStateChange(e, 'terms_conditions')}
                        editorState={this.state.terms_conditions  || ''}
                        onEditorStateChange={this.onEditorStateChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={{ border: "1px solid black", maxHeight:"400px", overflow:"scroll" }}
                        toolbar={{
                          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'],
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: false },
                        }}
                      /> 
                    </div> */}
                          <div className="col-lg-3 col-md-3 col-sm-12 mt-3">
                            <button
                              type="submit"
                              className="contact-form-btn btn btn-success text-white"
                            >
                              <span>Update Setting</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardWebsiteSettingComponents;
