import React, { Component, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import { resetpassword } from "./apiserve/ApiComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
class ForgotPasswordComponent extends Component {
  constructor() {
    super();
    this.state = {
      input: { email: "", password: "", confirmpassword: "" },
      errors: {},
      response: "",
      responsetype: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      let input = {};
      input["email"] = "";
      input["password"] = "";
      input["confirmpassword"] = "";
      this.setState({ input: input });
      let mail = this.state.input.email;
      let pass = this.state.input.password;
      let cpass = this.state.input.confirmpassword;
      let token = "";
      // let queries = queryString.parse(this.props.location.search);
      // if (queries.token != undefined) {token=queries.token;}
      // console.log(token);
      let formdata = {};
      formdata = {
        email: mail,
        password: pass,
        password_confirmation: cpass,
        token: token,
      };
      resetpassword(
        formdata,
        (res) => {
          if (res.data.success == true) {
            this.setState({
              response: "New Password Updated successfully please log in again",
            });
            this.setState({ responsetype: "success" });
          } else {
            this.setState({
              response: "Reset Password Failed",
            });
            this.setState({ responsetype: "error" });
          }
        },
        (err) => {
          console.log(err);
          this.setState({
            response: "Error while updating password, try again.",
          });
          this.setState({ responsetype: "error" });
        }
      );
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["password"]) {
      isValid = false;
      this.setState({ response: "Please enter your password." });
      this.setState({ responsetype: "error" });
    } else if (input["password"].length <= 5) {
      isValid = false;
      this.setState({ response: "Please enter minimum 6 character password" });
      this.setState({ responsetype: "error" });
    } else if (!input["confirmpassword"]) {
      isValid = false;
      this.setState({ response: "Please enter your confirm password." });
      this.setState({ responsetype: "error" });
    } else if (input["confirmpassword"].length <= 5) {
      isValid = false;
      this.setState({ response: "Please enter minimum 6 character password" });
      this.setState({ responsetype: "error" });
    } else if (
      typeof input["password"] !== "undefined" &&
      typeof input["confirmpassword"] !== "undefined"
    ) {
      if (input["password"] != input["confirmpassword"]) {
        isValid = false;
        this.setState({ response: "Passwords don't match." });
        this.setState({ responsetype: "error" });
      }
    } else if (!input["email"]) {
      isValid = false;
      this.setState({ response: "Enter your E-mail id" });
      this.setState({ responsetype: "danger" });
    } else if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        this.setState({ response: "Please enter valid E-mail id" });
        this.setState({ responsetype: "danger" });
      }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <HeaderComponent />
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form "
                style={{ paddingTop: "100px" }}
                onSubmit={this.handleSubmit}
              >
                <span className="login100-form-title p-b-43 mb-1">
                  Reset Your Password
                </span>
                <p className="text-muted fw-slim text-center fs-5">
                  Enter your new password to reset
                </p>

                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark pt-1">
                    Your E-Mail
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={this.state.input.email}
                    onChange={this.handleChange}
                    className="form-control form-control-lg form-control-solid"
                  />
                </div>

                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark pt-1">
                    New Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={this.state.input.password}
                    onChange={this.handleChange}
                    className="form-control form-control-lg form-control-solid"
                  />
                </div>

                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark pt-1">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmpassword"
                    value={this.state.input.confirmpassword}
                    onChange={this.handleChange}
                    className="form-control form-control-lg form-control-solid"
                  />
                </div>

                <div className="d-flex flex-wrap pb-lg-0">
                  <button
                    type="submit"
                    className="btn btn-primary fw-bolder fs-6 px-5 py-2 my-3 me-4"
                  >
                    Submit
                  </button>
                  <a href="/login">
                    <button
                      type="button"
                      className="btn btn-light-primary fw-bolder fs-6 px-8 py-1 my-3"
                    >
                      Cancel
                    </button>
                  </a>{" "}
                </div>
              </form>

              <div
                className="login100-more"
                style={{ backgroundImage: "url('../images/bg-02.jpg')" }}
              ></div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}
export default ForgotPasswordComponent;
