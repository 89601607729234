import React, { Component, useState } from 'react';
import SliderComponent from "./SliderComponent";
import HeaderComponent from "./HeaderComponent";
import ServicesComponent from "./ServicesComponent";
import FooterComponent from "./FooterComponent";
import FeaturedComponent from './FeaturedComponent';
class CartComponent extends Component{
 
    render() {

        return ( <div>
              <HeaderComponent/>
              <div className="clearfix">&nbsp;</div>
              <div className="clearfix">&nbsp;</div>
        <div className="container">
        <div className="card" style={{ boxShadow: "0 6px 20px 0 rgb(0 0 0 / 19%)"}}>
    <div className="row">
        <div className="col-md-8 cart">
            <div className="title">
                <div className="row">
                    <div className="col">
                        <h4><b>Shopping Cart</b></h4>
                    </div>
                    <div className="col align-self-center text-right text-muted" style={{textAlign:"right"}}>3 items</div>
                </div>
            </div>
            <div className="row border-top border-bottom">
                <div className="row main align-items-center">
                    <div className="col-2"><img className="img-fluid" src="https://i.imgur.com/1GrakTl.jpg"/></div>
                    <div className="col">
                        <div className="row text-muted" style={{fontWeight:"bold"}}>Shirt</div>
                        <div className="row" style={{fontWeight:"bold"}}>Cotton T-shirt</div>
                    </div>
                    <div className="col"> <a href="#" className="qty">-</a><a href="#" className="qty border">1</a><a href="#" className="qty">+</a> </div>
                    <div className="col" style={{fontWeight:"bold"}}>&euro; 44.00 <span className="close">&#10005;</span></div>
                </div>
            </div>
            <div className="row">
                <div className="row main align-items-center">
                    <div className="col-2"><img className="img-fluid" src="https://i.imgur.com/ba3tvGm.jpg"/></div>
                    <div className="col">
                        <div className="row text-muted" style={{fontWeight:"bold"}}>Shirt</div>
                        <div className="row" style={{fontWeight:"bold"}}>Cotton T-shirt</div>
                    </div>
                    <div className="col"> <a href="#" className="qty">-</a><a href="#"  className="qty border">1</a><a href="#" className="qty">+</a> </div>
                    <div className="col" style={{fontWeight:"bold"}}>&euro; 44.00 <span className="close">&#10005;</span></div>
                </div>
            </div>
            <div className="row border-top border-bottom">
                <div className="row main align-items-center">
                    <div className="col-2"><img className="img-fluid" src="https://i.imgur.com/pHQ3xT3.jpg"/></div>
                    <div className="col">
                        <div className="row text-muted" style={{fontWeight:"bold"}}>Shirt</div>
                        <div className="row" style={{fontWeight:"bold"}}>Cotton T-shirt</div>
                    </div>
                    <div className="col"> <a href="#" className="qty">-</a><a href="#" className="qty border">1</a><a href="#" className="qty">+</a> </div>
                    <div className="col" style={{fontWeight:"bold"}}>&euro; 44.00 <span className="close">&#10005;</span></div>
                </div>
            </div>
            <div className="back-to-shop"><a href="#">←</a><span className="text-muted">Back to shop</span></div>
        </div>
        <div className="col-md-4 summary">
            <div>
                <h5><b>Summary</b></h5>
            </div>
            
            <div className="row m-3"  style={{fontWeight:"bold"}}>
                <div className="col" style={{paddingLeft:"0"}}>ITEMS 3</div>
                <div className="col text-right">&euro; 132.00</div>
            </div>
            <form>
                <p style={{fontWeight:"bold"}}>SHIPPING</p> <select className="select1">
                    <option className="text-muted">Standard-Delivery- &euro;5.00</option>
                </select>
                <p style={{fontWeight:"bold"}}>GIVE CODE</p> <input id="code" placeholder="Enter your code" className="select1" />
            </form>
            <div className="row" style={{borderTop: "1px solid rgba(0,0,0,.1)", padding:"2vh 0"}}>
                <div className="col"  style={{fontWeight:"bold"}}>TOTAL PRICE</div>
                <div className="col text-right">&euro; 137.00</div>
            </div> <button className="btn bgblue text-white">CHECKOUT</button>
        </div>
    </div>
</div>
        </div>
        <div className="clearfix">&nbsp;</div>
        <div className="clearfix">&nbsp;</div>
            <FooterComponent/>
        </div>)
    }
}
export default CartComponent;
