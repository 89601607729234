import React, { Component, useState } from "react";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import {
  getcategorylist,
  getallcardstemplatelist,
} from "./apiserve/ApiComponent";
import { toBeRequired } from "@testing-library/jest-dom/dist/matchers";

class FeaturedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorylist: [],
      allcardstemp: [],
      mainallcardstemp: [],
      baseImgPath: "../public/images/templates/",
      fillter: "",
    };
  }

  getcategorylist() {
    getcategorylist(
      (res) => {
        this.setState({ categorylist: res.data.data[0] });
        // this.setState({orderdata:JSON.parse(this.state.orderhistory.card_data)})
      },
      (err) => {
        console.log(err);
      }
    );
  }

  addfiltter(name) {
    let updatedata = [];
    this.state.allcardstemp = [];
    for (let i = 0; i < this.state.mainallcardstemp.length; i++) {
      if (this.state.mainallcardstemp[i].category_name == name) {
        updatedata.push(this.state.mainallcardstemp[i]);
      } else if (name == "all") {
        updatedata.push(this.state.mainallcardstemp[i]);
      }
    }
    this.setState({ allcardstemp: updatedata });
  }

  createTemplate(e) {
    window.location.href = "/create-template";
  }

  getallcardstemplatelist() {
    getallcardstemplatelist(
      (res) => {
        this.setState({ allcardstemp: res.data.data[0] });
        this.setState({ mainallcardstemp: res.data.data[0] });
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading cards templates." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    //this.getcategorylist();
    this.getallcardstemplatelist();
  }
  render() {
    const { from } = "";
    return (
      <div>
        <HeaderComponent />
        <Container>
          <div className="clearfix">&nbsp;</div>
          <Row>
            <div className="col-lg-8 col-md-8">
              <h2 id="productName" className="bold basic text-shadow">
                Use Our Standard Templates or Create One
              </h2>
            </div>
            <div className="col-lg-4 col-md-4">
              <button
                style={{
                  paddingLeft: "25px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-start",
                  float: "right",
                  backgroundColor: "green",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  height: "40px",
                  width: "200px",
                  fontSize: "18px",
                }}
                onClick={(e) => this.createTemplate(e)}
              >
                Create Your Own ...
              </button>
            </div>
            <Col>
              <div>
                {/* 1 */}
                <div className="d-grid gap-1">
                  {/* <InputGroup className= {`mb-0 form-group `}>
    <FormControl
      placeholder="Search Designs"
      aria-label="Search Designs"
      aria-describedby="basic-addon2" name="searchdesign" className="form-control" placeholder="Search Designs"  />
    <InputGroup.Text id="basic-addon2" className="p-0">
      <Button  style={{borderRadius:'0',background:'#e9ecef',border:'none',color:'#666'}} >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
</Button>
</InputGroup.Text>
  </InputGroup> */}
                </div>
                {/* 1 */}
              </div>
            </Col>
          </Row>

          <div className="clearfix">&nbsp;</div>
          <Row>
            {/* <Col md={2} sm="12">
              <ul className="filter-list">
                <li className="" id="all">
                  <input
                    type="radio"
                    name="fillter"
                    value="all"
                    onClick={(e) => this.addfiltter("all")}
                  />{" "}
                  &nbsp;
                  <a href="#">
                    <label className="category-label">All</label>
                  </a>
                </li>
                {this.state.categorylist.map(function (item, i) {
                  return (
                    <li key={i} className="" id={item.name}>
                      <input
                        type="radio"
                        name="fillter"
                        value={item.name}
                        onClick={(e) => this.addfiltter(item.name)}
                      />{" "}
                      &nbsp;
                      <a href="#">
                        <label className="category-label">{item.name}</label>
                      </a>
                    </li>
                  );
                }, this)}
              </ul>
            </Col> */}
            <Col md={12} sm={12}>
              <Row xs={1} md={3} className="g-4">
                {this.state.allcardstemp.map(function (item, i) {
                  {
                    console.log(item);
                  }
                  return (
                    <Col sm={12} md={4} key={item.id}>
                      <Link
                        to={
                          "/card?template=design" + item.id + "&id=" + item.id
                        }
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <Card style={{ backgroundColor: "#dbdbdb" }}>
                          {item.card_orientation != "portrait" ? (
                            <Card.Img
                              variant="top"
                              src={
                                "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                item.cardbgimage
                              }
                              style={{ height: "222px" }}
                            />
                          ) : (
                            <Card.Img
                              variant="top"
                              src={
                                "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                item.cardbgimage
                              }
                              style={{
                                paddingLeft: "100px",
                                height: "222px",
                                width: "239px",
                              }}
                            />
                          )}

                          <Card.Body>
                            <Card.Title
                              style={{
                                color: "#000000",
                              }}
                            >
                              {item.cardtemplatename}
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  );
                }, this)}
              </Row>
            </Col>
          </Row>
          <div className="clearfix">&nbsp;</div>
        </Container>
        <FooterComponent />
      </div>
    );
  }
}
export default FeaturedComponent;
