import React, { Component, useRef } from "react";
import { Tabs, Tab, Modal, Row, Button, Col, Form, Card, Container, Nav, Image, FormLabel } from "react-bootstrap-v5";
import * as Icon from "react-bootstrap-icons";
import { connect } from 'react-redux';
import DashboardCardsTemplatesComponent from "./DashboardCardsTemplatesComponent";
class DashboardTemplatesComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
        <FormLabel className="col text-center py-2 fw-bold" id="valuw1" style={{color:"red",fontSize:"23px",fontWeight: "bold"}} >
                                            {this.props.XXXXXXXY}    
                                              </FormLabel>
        <DashboardCardsTemplatesComponent onTemplateSelection={this.props.onTemplateSelection} />
      </div>
    );
  }
}


export default DashboardTemplatesComponent
