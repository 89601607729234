import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import "./dashboard.css";
import { getallusers } from "../apiserve/ApiComponent";
import {
  Button,
  InputGroup,
  FormControl,
  FormLabel,
  Row,
  Col,
  Form,
} from "react-bootstrap-v5";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { DeleteUserData } from "../apiserve/ApiComponent";
import axios from "axios";
import DataTable from "react-data-table-component";
class DashboardUserComponents extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.state = {
      show: false,
      delete: false,
      alluserdata: [],
      response: "",
      columnsValue: [
        {
          name: "First Name",
          selector: (row) => row.fname,
          sortable: true,
        },
        {
          name: "Last Name",
          selector: (row) => row.lname,
          sortable: true,
        },
        {
          name: "Email Address",
          selector: (row) => row.email,
          sortable: true,
        },
        {
          name: "Phone",
          selector: (row) => row.phoneno,
          sortable: true,
        },
        {
          name: "Last Login",
          selector: (row) => row.lastlogin,
          sortable: true,
        },
        {
          name: "Active",
          selector: (row) => row.isActive,
          sortable: true,
        },
        {
          name: "Delete",
          selector: (row) => row.isDelete,
          sortable: true,
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <Link to={`/admin/userdetails?id=${row.action}`}>View</Link>
          ),
        },
      ],
      responsetype: "",
      isLoading: false,
    };
    // this.showuserdetails = this.showuserdetails.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  // handleDeleteUser() {
  // this.setState({ saved: false });
  // }

  handleShow() {
    this.setState({ show: true });
  }

  handleDeleteUser() {
    this.setState({ show: false });
    //   check for logged in users
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("vcardurole") == 1) {
        //  this.setState({ saved: true });
        this.DeleteUserData();
      } else {
        this.setState({
          response: "You are not logged in, Please login. Redirecting to login",
        });
        this.setState({ responsetype: "error" });
        setTimeout(function () {
          // localStorage.setItem('designId', this.props.match.params.id);
          window.location.href = "/login?q=savecard";
        }, 1000); //will call the function after 2 secs.
      }
    } else {
      setTimeout(function () {
        // localStorage.setItem('designId', this.props.location.search.id);
        window.location.href = "/login?q=savecard";
      }); //will call the function after 2 secs.
    }

    //   check for logged in users
  }

  DeleteUserData() {
    // const input = document.getElementById('savefromhere');
    let userid;
    // let data=this.props.card_details;
    // html2canvas(input)
    //   .then((canvas) => {
    // 	cardimg = canvas.toDataURL('image/png');
    // 	// saveAs(imgData,'card-design.png');

    // 	data.cardimage = cardimg;
    //   });

    //   console.log(data);
    DeleteUserData(
      userid,
      (res) => {
        //success
        // console.log(res);
        this.setState({ response: "User account deleted successfully." });
        this.setState({ responsetype: "success" });
      },
      (err) => {
        //error
        // alert(err);
        console.log(err);
        this.setState({
          response: "Error deleting user." + JSON.stringify(err),
        });
        this.setState({ responsetype: "error" });
      }
    );
  }

  closetoast() {
    this.setState({ response: "" });
  }

  getallusers() {
    getallusers(
      (res) => {
        this.setState({ alluserdata: res.data.data[0] });
        var emptyobj = [];
        for (var i = 0; i < this.state.alluserdata.length; i++) {
          const obj = {
            id: i + 1,
            fname: this.state.alluserdata[i].fname,
            lname: this.state.alluserdata[i].lname,
            email: this.state.alluserdata[i].email,
            lastlogin: this.state.alluserdata[i].lastlogin,
            phoneno: this.state.alluserdata[i].phoneno,
            isActive:
              this.state.alluserdata[i].isActive == 0 ? "Block User" : "Active",
            isDelete:
              this.state.alluserdata[i].isDelete == 1
                ? "Deleted User"
                : "Active",
            action: this.state.alluserdata[i].id,
          };
          emptyobj.push(obj);
        }
        this.setState({ tableTitle: emptyobj });
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading profile data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      this.getallusers();
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Users</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <Link to={`/admin/createuser`}>
                    <button
                      type="button"
                      className="btn btn-primary text-white btn-sm btn-outline-secondary"
                    >
                      {/* <span data-feather="calendar"></span> */}
                      Create User
                    </button>
                  </Link>
                </div>
              </div>
              <DataTable
                columns={this.state.columnsValue}
                data={this.state.tableTitle}
                pagination
              ></DataTable>
              <div className="table-responsive"></div>
            </main>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure, You want to delete this user.
            </h5>
            <p className="text-center">
              After click yes user data will be lost.
            </p>
            <Row>
              <Col style={{ textAlign: "right" }}>
                <Button
                  className="denger"
                  size="sm"
                  onClick={this.handleDeleteUser}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.saved} onHide={this.handlesavedClose}>
          <Modal.Header closeButton>
            <Modal.Title>User Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <h5 className="text-center">User account deleted successfully.</h5>
            <Row>
              <Col>
                <Button
                  size="sm"
                  className="success"
                  onClick={this.handlesavedClose}
                >
                  OK
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
      </div>
    );
  }
}
export default withRouter(DashboardUserComponents);
