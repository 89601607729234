import React, { Component, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  Navbar,
  NavDropdown,
} from "react-bootstrap-v5";
class SideBarComponent extends Component {
  render() {
    return (
      <div className="p-0">
        {/* <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <nav
          id="sidebarMenu"
          className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
        >
          <div className="position-sticky pt-3">
            <ul className="nav flex-column">
              <li>
                <a>
                  <img
                    src="../images/logo.png"
                    className="img-responsive px-2"
                    id="monitorLogo"
                  />
                </a>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/admin/dashboard"
                >
                  <span data-feather="home"></span>
                  Dashboard
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/admin/users"
                >
                  <span data-feather="file"></span>
                  Users
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/admin/all-cards"
                >
                  <span data-feather="file"></span>
                  User Cards
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/admin/cards-templates"
                >
                  <span data-feather="file"></span>
                  Cards Templates
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/admin/cards-category"
                >
                  <span data-feather="file"></span>
                  Categories
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/admin/card-orders"
                >
                  <span data-feather="shopping-cart"></span>
                  Card Orders
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/admin/slider-setting"
                >
                  <span data-feather="shopping-cart"></span>
                  Slider Setting
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/admin/website-setting"
                >
                  <span data-feather="shopping-cart"></span>
                  CMS Setting
                </Link>
              </li>
              {/* <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/admin/card-orders" >
          <span data-feather="shopping-cart"></span>
              Billing
          </Link>            
          </li> */}

              {/* <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/admin/pending-orders" >
          <span data-feather="users"></span>
              Pending Orders
          </Link>            
          </li>
          <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/admin/completed-orders" >
          <span data-feather="bar-chart-2"></span>
              Completed Orders
          </Link>            
          </li>
          <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/admin/cancelled-orders" >
          <span data-feather="layers"></span>
              Cancelled Orders
          </Link>            
          </li>
         */}
            </ul>

            {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Saved reports</span>
          <a className="link-secondary" href="#" aria-label="Add a new report">
            <span data-feather="plus-circle"></span>
          </a>
        </h6>
       
        <ul className="nav flex-column mb-2">
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span data-feather="file-text"></span>
              Current month
            </a>
          </li>
        </ul> */}
          </div>
        </nav>
      </div>
    );
  }
}
export default SideBarComponent;
