import React, { Component, useState } from "react";
import "../App.css";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import { register_user } from "./apiserve/ApiComponent";
import { FormErrors } from "./FormErrors";
import Toast from "react-bootstrap/Toast";
import queryString from "query-string";
import Spinner from "react-bootstrap/Spinner";
class SignupComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uemail: "",
      fname: "",
      lname: "",
      uemail: "",
      phoneno: "",
      upassword: "",
      vpassword: "",
      showPassword: false,
      showPassword1: false,
      response: "",
      responsetype: "",
      resp: "",
      isLoading: false,
      formErrors: {
        fname: "",
        lname: "",
        uemail: "",
        phoneno: "",
        upassword: "",
        vpassword: "",
        matchpassword: "",
      },
      fnameValid: false,
      lnameValid: false,
      uemailValid: false,
      previousurl: "",
      phonenoValid: false,
      upasswordValid: false,
      vpasswordValid: false,
      matchpasswordValid: false,

      formValid: false,
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleClickShowPassword = (e) => {
    e.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleClickShowPassword1 = (e) => {
    e.preventDefault();
    this.setState({ showPassword1: !this.state.showPassword1 });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    //const { fname, lname, uemail, phoneno, upassword, vpassword } = this.state;
    this.setState({ isLoading: true });
    //   const { history } = this.props;
    //   if(history) history.push('/card-design',this.state);
    if (this.state.formValid == true) {
      register_user(
        this.state.fname,
        this.state.lname,
        this.state.uemail,
        this.state.phoneno,
        this.state.upassword,
        this.state.vpassword,
        (res) => {
          console.log(res.data);
          //success
          if (res.data.success == true || res.success == true) {
            this.setState({
              response: res.data.message || "Registration Successful.",
            });
            this.setState({ responsetype: "success" });
            this.setState({ isLoading: false });
            if (this.state.previousurl == "savecard") {
              localStorage.setItem("savesignup", "save");
            }
          } else {
            this.setState({
              response: "Registration Failed. " + JSON.stringify(res.data.data),
            });
            this.setState({ responsetype: "error" });
            this.setState({ isLoading: false });
          }
        },
        (err) => {
          this.setState({ response: +JSON.stringify(err) });
          this.setState({ isLoading: false });
        }
      );
      // alert(user.data);
      //  console.log("#"+a+"#");
    } else {
      let error = "";
      if (
        this.state.formErrors.fname != "" &&
        this.state.formErrors.lname != "" &&
        this.state.formErrors.uemail != "" &&
        this.state.formErrors.phoneno != "" &&
        this.state.formErrors.upassword != "" &&
        this.state.formErrors.vpassword != ""
      ) {
        this.setState({
          response:
            "Valid first name is required. /n Valid last name is required. /n Valid email address is required. /n password is too short. /n Confirm password is too short. /n Password and Confirm password do not match.",
        });
        this.setState({ responsetype: "error" });
        this.setState({ isLoading: false });
      } else if (this.state.formErrors.fname != "") {
        this.setState({ response: "Valid first name is required." });
        this.setState({ responsetype: "error" });
        this.setState({ isLoading: false });
      } else if (this.state.formErrors.lname != "") {
        this.setState({ response: "Valid last name is required." });
        this.setState({ responsetype: "error" });
        this.setState({ isLoading: false });
      } else if (this.state.formErrors.uemail != "") {
        this.setState({ response: "Valid email address is required." });
        this.setState({ responsetype: "error" });
        this.setState({ isLoading: false });
      } else if (this.state.formErrors.upassword != "") {
        this.setState({ response: "Password is too short." });
        this.setState({ responsetype: "error" });
        this.setState({ isLoading: false });
      } else if (this.state.formErrors.vpassword != "") {
        this.setState({ response: "Confirm password is too short." });
        this.setState({ responsetype: "error" });
        this.setState({ isLoading: false });
      } else if (this.state.formErrors.matchpassword != "") {
        this.setState({
          response: "Password and Confirm password do not match.",
        });
        this.setState({ responsetype: "error" });
        this.setState({ isLoading: false });
      } else {
        this.setState({ response: "Please fill all the required fields." });
        this.setState({ responsetype: "error" });
        this.setState({ isLoading: false });
      }
    }
  };

  closetoast() {
    this.setState({ response: "" });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let fnameValid = this.state.fnameValid;
    let lnameValid = this.state.lnameValid;
    let uemailValid = this.state.uemailValid;
    let phonenoValid = this.state.phonenoValid;
    let upasswordValid = this.state.upasswordValid;
    let vpasswordValid = this.state.vpasswordValid;
    let matchpasswordValid = this.state.matchpasswordValid;
    switch (fieldName) {
      case "fname":
        fnameValid = value.length >= 2;
        fieldValidationErrors.fname = fnameValid ? "" : " is too short";
        break;

      case "lname":
        lnameValid = value.length >= 2;
        fieldValidationErrors.lname = lnameValid ? "" : " is too short";
        break;

      case "phoneno":
        phonenoValid = value.length == 10;
        fieldValidationErrors.phoneno = phonenoValid ? "" : " is too short";
        break;

      case "uemail":
        uemailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.uemail = uemailValid ? "" : " is invalid";
        break;

      case "upassword":
        upasswordValid = value.length >= 6;
        fieldValidationErrors.upassword = upasswordValid ? "" : " is too short";
        break;

      case "vpassword":
        vpasswordValid = value.length >= 6;
        fieldValidationErrors.vpassword = vpasswordValid ? "" : " is too short";
        break;

      default:
        break;
    }
    if (this.state.vpassword.length != this.state.upassword.length) {
      matchpasswordValid = false;
      fieldValidationErrors.matchpassword = matchpasswordValid
        ? ""
        : "password not matched";
    } else {
      matchpasswordValid = true;
      fieldValidationErrors.matchpassword = matchpasswordValid
        ? ""
        : "password not matched";
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        fnameValid: fnameValid,
        lnameValid: lnameValid,
        phonenoValid: phonenoValid,
        uemailValid: uemailValid,
        upasswordValid: upasswordValid,
        matchpasswordValid: matchpasswordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.fnameValid &&
        this.state.lnameValid &&
        this.state.phonenoValid &&
        this.state.uemailValid &&
        this.state.upasswordValid &&
        this.state.matchpasswordValid,
    });
  }

  componentDidMount() {
    let queries = localStorage.getItem("savecard");
    if (queries != undefined) {
      this.setState({ previousurl: queries });
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("vcardurole") == 2
    ) {
      window.location.href = "/user/myprofile";
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("vcardurole") == 1
    ) {
      window.location.href = "/admin/dashboard";
    }
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  render() {
    return (
      <div>
        <HeaderComponent />

        <div className="container">
          <div className="row py-5 mt-4 align-items-center">
            <div className="col-md-5 pr-lg-5 mb-5 mb-md-0">
              <img
                src="https://bootstrapious.com/i/snippets/sn-registeration/illustration.svg"
                alt=""
                className="img-fluid mb-3 d-none d-md-block"
              />
              <h1>Create an Account</h1>
            </div>

            {/* <!-- Registeration Form --> */}
            <div className="col-md-7 col-lg-6 ml-auto">
              <p className="text-white">{this.state.response}</p>
              <form
                className="contact100-form validate-form"
                onSubmit={this.handleOnSubmit}
              >
                {/* <div className="panel panel-default">
             <FormErrors formErrors={this.state.formErrors} />
        </div>
					 */}
                <Toast
                  onClose={() => this.setState({ response: "" })}
                  show={this.state.response != "" ? true : false}
                  className={
                    this.state.responsetype == "success"
                      ? "bg-success"
                      : "bg-danger"
                  }
                  delay={3000}
                  autohide
                >
                  <Toast.Body>{this.state.response}</Toast.Body>
                </Toast>
                <InputGroup
                  className={`mb-0 form-group ${this.errorClass(
                    this.state.formErrors.fname
                  )} || '' `}
                >
                  <h3>
                    <p>Please Note : </p>
                  </h3>
                  <h4>
                    <p>
                      You can't order Smartrider cards from this site, please go
                      to{" "}
                      <a href="https://smartrider.monitorwa.com.au">
                        smartrider.monitorwa.com.au
                      </a>
                    </p>
                  </h4>

                  <FormControl
                    placeholder="First Name"
                    name="fname"
                    required
                    className="form-control wrap-input100 validate-input"
                    value={this.state.fname || ""}
                    onChange={(e) => this.handleUserInput(e)}
                  />
                </InputGroup>

                <InputGroup
                  className={`mb-0 form-group ${this.errorClass(
                    this.state.formErrors.lname
                  )} || '' `}
                >
                  <FormControl
                    placeholder="Last Name"
                    name="lname"
                    required
                    className="form-control wrap-input100 validate-input"
                    value={this.state.lname || ""}
                    onChange={(e) => this.handleUserInput(e)}
                  />
                </InputGroup>

                <InputGroup
                  className={`mb-0 form-group ${this.errorClass(
                    this.state.formErrors.uemail
                  )} || '' `}
                >
                  <FormControl
                    placeholder="Email Address"
                    name="uemail"
                    required
                    className="form-control wrap-input100 validate-input"
                    value={this.state.uemail || ""}
                    onChange={(e) => this.handleUserInput(e)}
                  />
                </InputGroup>

                <InputGroup
                  className={`mb-0 form-group ${this.errorClass(
                    this.state.formErrors.phoneno
                  )} || '' `}
                >
                  <FormControl
                    type="number"
                    placeholder="Phone Number"
                    name="phoneno"
                    required
                    className="form-control wrap-input100 validate-input"
                    pattern="[6-9]{1}[0-9]{9}"
                    value={this.state.phoneno || ""}
                    onChange={(e) => this.handleUserInput(e)}
                  />
                </InputGroup>

                <InputGroup
                  className={`mb-0 form-group ${this.errorClass(
                    this.state.formErrors.upassword
                  )} ${this.errorClass(
                    this.state.formErrors.matchpassword
                  )} || '' `}
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  <FormControl
                    type={this.state.showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="upassword"
                    required
                    className="form-control wrap-input100 validate-input"
                    value={this.state.upassword || ""}
                    onChange={(e) => this.handleUserInput(e)}
                  />
                  <button
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "5px",
                      border: "0",
                      bottom: "0",
                      height: "40px",
                    }}
                  >
                    {this.state.showPassword ? (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                      </div>
                    ) : (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye-slash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                          <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                          <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                        </svg>
                      </div>
                    )}
                  </button>
                </InputGroup>
                <p>Min 6 character password required</p>

                <InputGroup
                  className={`mb-0 form-group ${this.errorClass(
                    this.state.formErrors.vpassword
                  )} ${this.errorClass(
                    this.state.formErrors.matchpassword
                  )} || '' `}
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  <FormControl
                    type={this.state.showPassword1 ? "text" : "password"}
                    placeholder="Confirm Password"
                    name="vpassword"
                    required
                    className="form-control wrap-input100 validate-input"
                    value={this.state.vpassword || ""}
                    onChange={(e) => this.handleUserInput(e)}
                  />
                  <button
                    onClick={this.handleClickShowPassword1}
                    onMouseDown={this.handleMouseDownPassword1}
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "5px",
                      border: "0",
                      bottom: "0",
                      height: "40px",
                    }}
                  >
                    {this.state.showPassword1 ? (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                      </div>
                    ) : (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye-slash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                          <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                          <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                        </svg>
                      </div>
                    )}
                  </button>
                </InputGroup>
                <p>Min 6 character password required</p>

                <div className="container-contact100-form-btn mt-3">
                  {/* disabled={!this.state.formValid?'disabled':""} */}

                  <button
                    type="submit"
                    className="contact-form-btn signup-btn1"
                    style={{
                      display: `${!this.state.isLoading ? "block" : "none"}`,
                    }}
                  >
                    Create your account
                  </button>
                  <span
                    className="contact-form-btn signup-btn1"
                    style={{
                      display: `${this.state.isLoading ? "block" : "none"}`,
                      textAlign: "center",
                      padding: "8px 15px",
                    }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </span>
                </div>

                {/* <!-- Divider Text --> */}
                {/* <div className="form-group col-lg-12 mx-auto d-flex align-items-center my-4">
                        <div className="border-bottom w-100 ml-5"></div>
                        <span className="px-2 small text-muted font-weight-bold text-muted">OR</span>
                        <div className="border-bottom w-100 mr-5"></div>
                    </div> */}

                {/* <!-- Social Login --> */}
                {/* <div className="form-group col-lg-12 mx-auto text-center">
                        <a href="#" className="btn btn-primary btn-block py-2 btn-facebook col-5 m-1">
                            <i className="fa fa-facebook-f mr-2"></i>&nbsp;
                            <span className="font-weight-bold">Continue with Facebook</span>
                        </a>
                        <a href="#" className="btn btn-primary btn-block py-2 btn-twitter col-5 m-1">
                            <i className="fa fa-twitter mr-2"></i>&nbsp;
                            <span className="font-weight-bold">Continue with Twitter</span>
                        </a>
                    </div> */}

                {/* <!-- Already Registered --> */}
                <div className="text-center w-100 mt-3">
                  <p className="text-muted font-weight-bold">
                    Already Registered?{" "}
                    <Link to="/login" className="text-primary ml-2">
                      Login
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}
export default SignupComponent;
