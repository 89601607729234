import React, { Component,useState } from "react";
import {Button,InputGroup,FormControl,FormLabel, Row,Col,Form} from "react-bootstrap-v5";
import * as Icon from 'react-bootstrap-icons';
import { FormErrors } from '../FormErrors';
class ColorComponent extends Component{
  
  constructor(props) {
    super(props);
    this.state = props.state;
    this.state = {
      // email: '',
      // password: '',
      staffid:'',
      studentname: "",
      middlename: "",
      surname: "",
      dob: "",
      phoneno: "",
      email: "",
      parentguardian: "",
      school: "",
      street: "",
      postcode: "",
      suburb: "",
      statename: "",
      stylestudentname:"",
      studentnamecolorpicker:"",
      formErrors: {
        studentname: "",
        middlename: "",
        surname: "",
        dob: "",
        phoneno: "",
        email: "",
        parentguardian: "",
        school: "",
        street: "",
        postcode: "",
        suburb: "",
        statename: "",
      },
      // Valid: false,
      studentnameValid: false,
      middlenameValid: false,
      surnameValid: false,
      surnameValid: false,
      dobValid: false,
      phonenoValid: false,
      emailValid: false,
      parentguardianValid: false,
      schoolValid: false,
      streetValid: false,
      postcodeValid: false,
      suburbValid: false,
      statenameValid: false,
      formValid: false,
      showHidestudentname: false,
      showHidemiddlename: false,
      showHidesurname: false,
      showHidedob: false,
      showHidephoneno: false,
      showHideemail: false,
      showHideparentguardian: false,
      showHideschool: false,
      showHidestreet: false,
      showHidepostcode: false,
      showHidesuburb: false,
      showHidestatename: false,
      values: [],
      // template url
      templateUrl:
        "https://img.freepik.com/free-photo/hand-painted-watercolor-background-with-sky-clouds-shape_24972-1095.jpg",
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleChange(i, event) {
  //   let values = [...this.state.values];
  //   values[i] = event.target.value;
  //   this.setState({ values });
  // }

  addClick() {
    this.setState((prevState) => ({ values: [...prevState.values, ""] }));
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }
  // handleSubmit(event) {
  //   alert("A name was submitted: " + this.state.values.join(", "));
  //   event.preventDefault();
  // }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let studentnamecolorpicker = this.state.studentnamecolorpicker;    
    this.setState(
      {
        studentnamecolorpicker: studentnamecolorpicker,        
      },
    );
  }

  render() {  
    const { staffid,studentname,middlename,surname,phoneno,studentnamecolorpicker } = (this.props.location && this.props.location.state) || {};
        return (
          <div>
            {/* <Row>
              
      <Col className="font-weight-bold"><FormLabel className="label1 py-2">Color:</FormLabel></Col>
      <Col>
      <div className="d-grid gap-1">
<InputGroup className= {`mb-0 form-group`}>
<Form.Control
    type="color"
    id="studentnamecolorpicker" name="studentnamecolorpicker"
    title="Choose your color" value={this.state.studentnamecolorpicker} onChange={this.handleUserInput}
    />    
</InputGroup>
    </div>
      </Col>
    </Row>
    <div className="clearfix he-1">&nbsp;</div> */}
    <Row>
      <Col className="font-weight-bold ">
            <Button variant="outline-dark" size="md" type='button' value='bold' className="col-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-type-bold" viewBox="0 0 16 16">
  <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z"/>
</svg>
            </Button>
      </Col>
      <Col className="font-weight-bold p-0">
            <Button variant="outline-dark" size="md" type='button' value='italic' className="col-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-type-italic" viewBox="0 0 16 16">
  <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z"/>
</svg>
            </Button>
            </Col>
      <Col className="font-weight-bold">
            <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-type-underline" viewBox="0 0 16 16">
  <path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57-1.709 0-2.687-1.08-2.687-2.57V3.136zM12.5 15h-9v-1h9v1z"/>
</svg>
            </Button>

            </Col>
            <div className="clearfix he-1">&nbsp;</div>
      <Col className="font-weight-bold">

      <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-left" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
            </Button>

           
      </Col>


      <Col className="font-weight-bold p-0">

  

            <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-center" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
            </Button>

     
      </Col>


      <Col className="font-weight-bold">


            <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
            </Button>
     
      </Col>

    </Row>
             
          </div>

        )
    }
}
export default ColorComponent;
