import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import DataTable from "react-data-table-component";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import {
  Tabs,
  Tab,
  Table,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Pagination,
} from "react-bootstrap-v5";
import "./dashboard.css";
import { getmycardorders } from "../apiserve/ApiComponent";
class DashboardComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderhistory: [],
      columnsValue: [
        {
          name: "Id",
          selector: (row) => row.id,
          sortable: true,
        },
        {
          name: "Transaction Id",
          selector: (row) => row.txn_id,
          sortable: true,
        },
        {
          name: "Created",
          selector: (row) => row.createdDate,
          sortable: true,
        },
        {
          name: "Order Cards",
          selector: (row) => row.qty,
          sortable: true,
        },
        {
          name: "Total Amount",
          selector: (row) => row.amount,
          sortable: true,
        },
        {
          name: "Tax",
          selector: (row) => row.taxes,
          sortable: true,
        },
        {
          name: "Order Status",
          selector: (row) => row.order_status,
          sortable: true,
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => <Link to={`/user/orders?id=${row.txn_id}`}>View</Link>,
        },
      ],
      orderdata: [],
    };
  }
  getmycardorders() {
    getmycardorders(
      localStorage.getItem("mwauid"),
      (res) => {
        this.setState({ orderhistory: res.data.data[0] });
        var emptyobj = [];

        for (var i = 0; i < this.state.orderhistory.length; i++) {
          console.log(this.state.orderhistory[i]);
          const obj = {
            id: i + 1,
            txn_id: this.state.orderhistory[i].txn_id,
            qty: this.state.orderhistory[i].qty,
            amount: this.state.orderhistory[i].amount,
            taxes: this.state.orderhistory[i].taxes,
            createdDate: this.state.orderhistory[i].createddate,
            order_status:
              this.state.orderhistory[i].order_status == 0
                ? "Created"
                : this.state.orderhistory[i].order_status == 1
                ? "Success"
                : this.state.orderhistory[i].order_status == 2
                ? "Failed"
                : "Delivered",
            action: this.state.orderhistory[i].id,
          };
          emptyobj.push(obj);
        }
        this.setState({ tableTitle: emptyobj });
        //console.log(this.state.orderhistory);
        // this.setState({orderdata:JSON.parse(this.state.orderhistory.card_data)})
      },
      (err) => {
        console.log(err);
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") == 2) {
      this.getmycardorders();
    } else {
      window.location.href = "/";
    }
    // let cardid=''
    // let queries = queryString.parse(this.props.location.search);
    // if (queries.id != undefined) {cardid=queries.id;}
  }

  render() {
    return (
      <div>
        <TopBarComponent imagePath={localStorage.getItem("profileimagepath")} />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="container rounded bg-white m-1 mt-3 p-0">
                <DataTable
                  columns={this.state.columnsValue}
                  data={this.state.tableTitle}
                  pagination
                ></DataTable>
                {/* <Tabs defaultActiveKey="saved" id="uncontrolled-tab-example" className="mb-3">
  <Tab eventKey="saved" title="My Order Cards">
  <Table striped bordered hover>
  <thead>
    <tr>
      <th>No</th>
      <th>Transaction Id</th>
      <th>Order Cards</th>
      <th>Total amount</th>
      <th>Tax</th>
      <th>Order Status</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
  {
   this.state.orderhistory.map(function(item, i){
    return (
    <tr key={i}>
    <td>{i+1}</td>
    <td>{item.txn_id}</td>
    <td>{item.qty}</td>
    <td>{item.amount}</td>
    <td>{item.taxes}</td>
    <td>{item.order_status==0?'Created':item.order_status==1?'Success':item.order_status==2?'Failed':'Delivered'}</td>
    <td><Link to={`/user/orders?id=${item.txn_id}`}>View Order</Link></td>
  </tr>);
  })
}
  </tbody>
</Table>  
  </Tab>
</Tabs> */}
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardComponents;
