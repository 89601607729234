import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import { getallslider } from "../apiserve/ApiComponent";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import "./dashboard.css";
import DataTable from "react-data-table-component";
class DashboardAllSliderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allsliderdata: [],
      columnsValue: [
        {
          name: "Display Order",
          selector: (row) => row.displayOrder,
          sortable: true,
        },
        {
          name: "Slider Preview",
          // selector: row=> row.card_preview,
          // sortable: true,.
          cell: (row) => (
            <img
              src={this.state.baseImgPath + row.image}
              style={{ width: "200px", height: "75px" }}
            />
          ),
        },
        {
          name: "Created Date",
          selector: (row) => row.created_at,
          sortable: true,
        },
        {
          name: "Last Update",
          selector: (row) => row.updated_at,
          sortable: true,
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <Link
              to={`/admin/update-slider?id=${row.action}&name=${row.image}&displayorder=${row.displayOrder}`}
            >
              View
            </Link>
          ),
        },
      ],
      response: "",
      responsetype: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/sliders/",
      isLoading: false,
    };
    // this.deletecategory = this.deletecategory.bind(this);
  }
  getallslider() {
    getallslider(
      (res) => {
        this.setState({ allsliderdata: res.data.data[0] });
        this.setState(this.state.allsliderdata);
        var emptyobj = [];
        for (let i = 0; i < this.state.allsliderdata.length; i++) {
          const obj = {
            id: i + 1,
            image: this.state.allsliderdata[i].image,
            created_at: this.state.allsliderdata[i].createdDate,
            updated_at: this.state.allsliderdata[i].updatedDate,
            action: this.state.allsliderdata[i].id,
            displayOrder: this.state.allsliderdata[i].displayOrder,
          };
          emptyobj.push(obj);
        }
        this.setState({ tableTitle: emptyobj });
        console.log(this.state.allsliderdata);
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading cards categories." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      this.getallslider();
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Website Sliders</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <Link to={`/admin/create-slider`}>
                    <button
                      type="button"
                      className="btn btn-primary text-white btn-sm btn-outline-secondary"
                    >
                      {/* <span data-feather="calendar"></span> */}
                      Create Slider
                    </button>
                  </Link>
                </div>
              </div>
              <DataTable
                columns={this.state.columnsValue}
                data={this.state.tableTitle}
                pagination
              ></DataTable>
              <div className="table-responsive"></div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardAllSliderComponent;
