import React, { Component, useContext, useState } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  FormLabel,
  Row,
  Col,
  Form,
  Select,
} from "react-bootstrap-v5";
import * as Icon from "react-bootstrap-icons";
import { FormErrors } from "../FormErrors";
import DashboardFieldOptionsComponent from "./DashboardFieldOptionsComponent";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
} from "react-router-dom";
import DashboardImageComponent from "./DashboardImageComponent";
import { getcategorylist } from "../apiserve/ApiComponent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  UpdateFieldValue,
  UpdateFieldValueData,
  RemoveExtraField,
  AddExtraField,
  ChangeExtraField,
  ColorChange,
  BoldChange,
  ItalicChange,
  UnderlineChange,
  FontSizeChange,
  ParaChange,
  ChangeFontFamily,
  showHideExtra,
  ChangeExtraFieldColor,
  ChangeExtraFieldFontSize,
  ChangeExtraFieldFontFamily,
  ChangeExtraFieldBold,
  ChangeExtraFieldItalic,
  ChangeExtraFieldUnderline,
  ChangeFrontBackExtraField,
} from "../../state/index";
function tex() {
  const [count, setCount] = useState(0);
  const handle = () => {
    const updatedCount = count + 1;
    console.log(`Clicked ${updatedCount} times`);
    setCount(updatedCount);
  };
}
class DashboardTextFieldComponent extends Component {
  constructor(props) {
    super(props);
    this.state = props.state;
    this.state = {
      values: [],
      staffid: "",
      categorylist: [],
      studentname: "",
      middlename: "",
      surname: "",
      dob: "",
      amount: "",
      Tname: "",
      phoneno: "",
      email: "",
      formErrors: {
        staffid: "",
        firstname: "",
        lastname: "",
        position: "",
        phoneno: "",
        Tname: "",
        amount: "",
        staffidfontsize: "",
        firstnamefontsize: "",
        lastnamefontsize: "",
        positionfontsize: "",
        phonenofontsize: "",
      },
      staffidValid: false,
      firstnameValid: false,
      lastnameValid: false,
      positionValid: false,
      phonenoValid: false,
      formValid: false,
      valuess: [],
      showHidestaffid: false,
      showHidefirstname: false,
      showHidelastname: false,
      showHideposition: false,
      showHidephoneno: false,
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  getcategorylist() {
    getcategorylist(
      (res) => {
        console.log(res.data);
        this.setState({ categorylist: res.data.data[0] });
        console.log(this.state.categorylist);
        // this.setState({orderdata:JSON.parse(this.state.orderhistory.card_data)})
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleChange(i, event) {
    // let valuess = [...this.state.valuess];
    let cvalue = event.target.value;
    this.props.ChangeExtraField({ i, cvalue });
  }

  ChangeExtraFieldColor(i, event) {
    let colorvalue = event.target.value;
    // alert("#");
    this.props.ChangeExtraFieldColor({ i, colorvalue });
  }

  ChangeFrontBackExtraField(i, frontbackvalue) {
    this.props.ChangeFrontBackExtraField({ i, frontbackvalue });
  }

  ChangeExtraFieldFontSize(i, event) {
    let fontsizevalue = event.target.value;
    this.props.ChangeExtraFieldFontSize({ i, fontsizevalue });
  }

  ChangeExtraFieldFontFamily(i, event) {
    let fontfamilyvalue = event.target.value;
    this.props.ChangeExtraFieldFontFamily({ i, fontfamilyvalue });
  }

  addClick() {
    // this.setState(prevState => ({ valuess: [...prevState.valuess, '']}))
    this.props.AddExtraField();
  }

  removeClick(i) {
    let valuess = [...this.state.valuess];
    valuess.splice(i, 1);
    //  this.setState({ valuess });
    this.props.RemoveExtraField(i);
  }

  showHideExtra(i) {
    //  alert(i);
    this.props.showHideExtra(i);
  }

  ChangeExtraFieldBold(i) {
    this.props.ChangeExtraFieldBold(i);
  }

  ChangeExtraFieldItalic(i) {
    this.props.ChangeExtraFieldItalic(i);
  }

  ChangeExtraFieldUnderline(i) {
    this.props.ChangeExtraFieldUnderline(i);
  }

  handleUserInput = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
    this.props.UpdateFieldValue({ name, value });
  };

  handleUserInputData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
    this.props.UpdateFieldValueData({ name, value });
  };

  ColorChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // this.setState({ [name]: value }, () => {
    //   this.validateField(name, value);
    // });
    this.props.ColorChange({ name, value });
  };

  FontSizeChange = (e) => {
    // staffidfontsize:'',
    // firstnamefontsize:'',
    // lastnamefontsize:'',
    // positionfontsize:'',
    // phonenofontsize:'',
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    this.props.FontSizeChange({ name, value });
  };

  ChangeFontFamily = (e) => {
    // staffidfontsize:'',
    // firstnamefontsize:'',
    // lastnamefontsize:'',
    // positionfontsize:'',
    // phonenofontsize:'',
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    this.props.ChangeFontFamily({ name, value });
  };

  ParaChange(name, value) {
    this.props.ParaChange({ name, value });
    // console.log("Name:",name);
    // console.log("Value:",value);
  }

  BoldChange = (value) => {
    // const name = e.target.name;
    // const value = value;
    // this.setState({ [name]: value }, () => {
    //   this.validateField(name, value);
    // });
    this.props.BoldChange(value);
    // console.log("name",name);
    // console.log("bold value",value);
  };

  ItalicChange = (value) => {
    // const name = e.target.name;
    // const value = value;
    // this.setState({ [name]: value }, () => {
    //   this.validateField(name, value);
    // });
    this.props.ItalicChange(value);
    // console.log("name",name);
    // console.log("bold value",value);
  };

  UnderlineChange = (value) => {
    // const name = e.target.name;
    // const value = value;
    // this.setState({ [name]: value }, () => {
    //   this.validateField(name, value);
    // });
    this.props.UnderlineChange(value);
    // console.log("name",name);
    // console.log("bold value",value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let staffidValid = this.state.staffidValid;
    let firstnameValid = this.state.firstnameValid;
    let lastnameValid = this.state.lastnameValid;
    let positionValid = this.state.positionValid;
    let phonenoValid = this.state.phonenoValid;
    switch (fieldName) {
      case "staffid":
        staffidValid = value.length > 2;
        fieldValidationErrors.staffid = staffidValid ? "" : " is invalid";
        break;

      case "firstname":
        firstnameValid = value.length >= 2;
        fieldValidationErrors.firstname = firstnameValid ? "" : " is invalid";
        break;

      case "lastname":
        lastnameValid = value.length >= 2;
        fieldValidationErrors.lastname = lastnameValid ? "" : " is invalid";
        break;

      case "position":
        positionValid = value.length >= 2;
        fieldValidationErrors.position = positionValid ? "" : " is invalid";
        break;

      case "phoneno":
        phonenoValid = value.length == 10;
        fieldValidationErrors.phoneno = phonenoValid ? "" : " is invalid";
        break;

      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        staffidValid: staffidValid,
        firstnameValid: firstnameValid,
        lastnameValid: lastnameValid,
        positionValid: positionValid,
        phonenoValid: phonenoValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.staffidValid &&
        this.state.firstnameValid &&
        this.state.lastnameValid &&
        this.state.positionValid &&
        this.state.phonenoValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  hideComponent(name) {
    switch (name) {
      case "showHidestaffid":
        this.setState({
          showHidelastname: false,
          showHideposition: false,
          showHidephoneno: false,
          showHidefirstname: false,
          showHidestaffid: !this.state.showHidestaffid,
        });
        break;
      case "showHidefirstname":
        this.setState({
          showHidelastname: false,
          showHideposition: false,
          showHidephoneno: false,
          showHidestaffid: false,
          showHidefirstname: !this.state.showHidefirstname,
        });
        break;

      case "showHidelastname":
        this.setState({
          showHidestaffid: false,
          showHideposition: false,
          showHidephoneno: false,
          showHidefirstname: false,
          showHidelastname: !this.state.showHidelastname,
        });
        break;

      case "showHideposition":
        this.setState({
          showHidephoneno: false,
          showHidestaffid: false,
          showHidefirstname: false,
          showHidelastname: false,
          showHideposition: !this.state.showHideposition,
        });
        break;

      case "showHidephoneno":
        this.setState({
          showHidestaffid: false,
          showHidefirstname: false,
          showHidelastname: false,
          showHideposition: false,
          showHidephoneno: !this.state.showHidephoneno,
        });
        break;
    }
  }
  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      this.getcategorylist();
    } else {
      window.location.href = "/";
    }
    // this.setState({valuess:this.props.history.location.state.valuess});
    // this.setState({valuess:this.props.card_details.extrafields});
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.card_details);
  }
  render() {
    const {
      showHidestaffid,
      showHidestudentname,
      showHidemiddlename,
      showHidesurname,
      showHidephoneno,
    } = this.state;
    return (
      <div>
        <div className="clearfix">&nbsp;</div>

        <div className="d-grid gap-2">
          <Row>
            <Col className="col-lg-9">
              <FormLabel className="label1 py-2">Template Details</FormLabel>
            </Col>
          </Row>
        </div>
        {/* <form className="demoForm"> */}
        {/* <div className="panel panel-default">
                 <FormErrors formErrors={this.state.formErrors} />
               </div> */}

        {/* 0 */}
        <div className="d-grid gap-1">
          <div className="clearfix">&nbsp;</div>
          {this.props.card_details.staffid != undefined && (
            <InputGroup className={`mb-0 form-group `}>
              <FormControl
                placeholder="Staff ID"
                aria-label="Staff ID"
                aria-describedby="basic-addon2"
                name="staffid"
                required
                className="form-control"
                // value={this.state.staffid || staffid }
                onChange={this.handleUserInput}
                value={this.props.card_details.staffid.text || ""}
              />
              <InputGroup.Text id="basic-addon2" className="p-0">
                <Button
                  style={{
                    borderRadius: "0",
                    background: "#e9ecef",
                    border: "none",
                    color: "#666",
                  }}
                  onClick={() => this.hideComponent("showHidestaffid")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-grip-horizontal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                </Button>
              </InputGroup.Text>
            </InputGroup>
          )}

          {this.state.showHidestaffid && (
            <div>
              <div style={{ border: "solid 1px #666", padding: "10px" }}>
                {/* color */}
                <Row>
                  <Col className="font-weight-bold">
                    <FormLabel className="label1 py-2">Color:</FormLabel>
                  </Col>
                  <Col>
                    <div className="d-grid gap-1">
                      <InputGroup className={`mb-0 form-group`}>
                        <Form.Control
                          type="color"
                          id="staffidcolorpicker"
                          name="staffidcolorpicker"
                          title="Choose your color"
                          value={this.props.card_details.staffid.color}
                          onChange={this.ColorChange}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                {/* color */}
                <div className="clearfix he-1">&nbsp;</div>
                {/* fontsize */}

                <Row>
                  <Col className="font-weight-bold">
                    <FormLabel className="label1 py-2">Size:</FormLabel>
                  </Col>
                  <Col>
                    <div className="d-grid gap-1">
                      <InputGroup className={`mb-0 form-group`}>
                        <FormControl
                          type="number"
                          min="8"
                          max="100"
                          aria-label="Size"
                          aria-describedby="basic-addon2"
                          name="staffidfontsize"
                          className="form-control"
                          placeholder="Size"
                          value={this.state.staffidfontsize}
                          onChange={this.FontSizeChange}
                        />
                      </InputGroup>
                      <div className="clearfix he-1">&nbsp;</div>
                    </div>
                  </Col>
                </Row>
                {/* fontsize */}
                <div className="clearfix he-1">&nbsp;</div>
                {/* styles */}
                <Row>
                  <Col className="font-weight-bold ">
                    <Button
                      variant="outline-dark"
                      size="md"
                      type="button"
                      name="fboldstaffid"
                      className={`col-12 ${
                        this.props.card_details.staffid.fontBold ? "active" : ""
                      }`}
                      onClick={() => this.BoldChange("fboldstaffid")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-type-bold"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
                      </svg>
                    </Button>
                  </Col>
                  <Col className="font-weight-bold p-0">
                    <Button
                      variant="outline-dark"
                      size="md"
                      type="button"
                      value="italic"
                      name="fitalicstaffid"
                      className={`col-12 ${
                        this.props.card_details.staffid.fontItalic
                          ? "active"
                          : ""
                      }`}
                      onClick={() => this.ItalicChange("fitalicstaffid")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-type-italic"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
                      </svg>
                    </Button>
                  </Col>
                  <Col className="font-weight-bold">
                    <Button
                      variant="outline-dark"
                      size="md"
                      type="button"
                      value="underline"
                      name="funderlinestaffid"
                      className={`col-12 ${
                        this.props.card_details.staffid.fontUnderline
                          ? "active"
                          : ""
                      }`}
                      onClick={() => this.UnderlineChange("funderlinestaffid")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-type-underline"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57-1.709 0-2.687-1.08-2.687-2.57V3.136zM12.5 15h-9v-1h9v1z" />
                      </svg>
                    </Button>
                  </Col>
                  <div className="clearfix he-1">&nbsp;</div>
                  {/* <Col className="font-weight-bold">

      <Button variant="outline-dark" size="md" type='button' value='underline' className={`col-12 ${this.props.card_details.staffid.alignText=='left'?'active':''}`} name="staffidpara" onClick={() => this.ParaChange('staffidpara','left')} >
      
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-left" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
            </Button>

           
      </Col>


      <Col className="font-weight-bold p-0">

  

            <Button variant="outline-dark" size="md" type='button' value='underline' className={`col-12 ${this.props.card_details.staffid.alignText=='center'?'active':''}`} name="staffidparacenter" onClick={() => this.ParaChange('staffidpara','center')} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-center" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
            </Button>

     
      </Col>


      <Col className="font-weight-bold">


            <Button variant="outline-dark" size="md" type='button' value='underline' className={`col-12 ${this.props.card_details.staffid.alignText=='right'?'active':''}`} name="staffidpararight" onClick={() => this.ParaChange('staffidpara','right')} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
            </Button>
     
      </Col> */}
                </Row>
                {/* styles */}
                <div className="clearfix he-1">&nbsp;</div>
                {/* font family */}
                <Row>
                  <Col className="font-weight-bold">
                    <FormLabel className="label1 py-2">Font:</FormLabel>
                  </Col>
                  <Col>
                    <Form.Select
                      id="staffidfontfamily"
                      name="staffidfontfamily"
                      value={this.state.staffidfontfamily}
                      onChange={this.ChangeFontFamily}
                    >
                      <option value="Arial">Arial</option>
                      <option value="Arial Italic">Arial Italic</option>
                      <option value="Arial Bold">Arial Bold</option>
                      <option value="Arial Bold Italic">
                        Arial Bold Italic
                      </option>
                      <option value="Arial Black">Arial Black</option>
                      <option value="Bahnschrift ">Bahnschrift </option>
                      <option value="Calibri Light">Calibri Light</option>
                      <option value="Calibri Light Italic">
                        Calibri Light Italic
                      </option>
                      <option value="Calibri">Calibri</option>
                      <option value="Calibri Italic">Calibri Italic</option>
                      <option value="Calibri Bold">Calibri Bold</option>
                      <option value="Calibri Bold Italic">
                        Calibri Bold Italic
                      </option>
                      <option value="Cambria">Cambria</option>
                      <option value="Cambria Italic">Cambria Italic</option>
                      <option value="Cambria Bold">Cambria Bold</option>
                      <option value="Cambria Bold Italic">
                        Cambria Bold Italic
                      </option>
                      <option value="Cambria Math">Cambria Math</option>
                      <option value="Candara Light ">Candara Light </option>
                      <option value="Candara Light Italic ">
                        Candara Light Italic{" "}
                      </option>
                      <option value="Candara">Candara</option>
                      <option value="Candara Italic">Candara Italic</option>
                      <option value="Candara Bold">Candara Bold</option>
                      <option value="Candara Bold Italic">
                        Candara Bold Italic
                      </option>
                      <option value="Comic Sans MS">Comic Sans MS</option>
                      <option value="Comic Sans MS Italic">
                        Comic Sans MS Italic
                      </option>
                      <option value="Comic Sans MS Bold">
                        Comic Sans MS Bold
                      </option>
                      <option value="Comic Sans MS Bold Italic">
                        Comic Sans MS Bold Italic
                      </option>
                      <option value="Consolas">Consolas</option>
                      <option value="Consolas Italic">Consolas Italic</option>
                      <option value="Consolas Bold">Consolas Bold</option>
                      <option value="Consolas Bold Italic">
                        Consolas Bold Italic
                      </option>
                      <option value="Constantia">Constantia</option>
                      <option value="Constantia Italic">
                        Constantia Italic
                      </option>
                      <option value="Constantia Bold">Constantia Bold</option>
                      <option value="Constantia Bold Italic">
                        Constantia Bold Italic
                      </option>
                      <option value="Corbel Light ">Corbel Light </option>
                      <option value="Corbel Light Italic ">
                        Corbel Light Italic{" "}
                      </option>
                      <option value="Corbel">Corbel</option>
                      <option value="Corbel Italic">Corbel Italic</option>
                      <option value="Corbel Bold">Corbel Bold</option>
                      <option value="Corbel Bold Italic">
                        Corbel Bold Italic
                      </option>
                      <option value="Courier New">Courier New</option>
                      <option value="Courier New Italic">
                        Courier New Italic
                      </option>
                      <option value="Courier New Bold">Courier New Bold</option>
                      <option value="Courier New Bold Italic">
                        Courier New Bold Italic
                      </option>
                      <option value="Ebrima">Ebrima</option>
                      <option value="Ebrima Bold">Ebrima Bold</option>
                      <option value="Franklin Gothic Medium">
                        Franklin Gothic Medium
                      </option>
                      <option value="Franklin Gothic Medium Italic">
                        Franklin Gothic Medium Italic
                      </option>
                      <option value="Gabriola">Gabriola</option>
                      <option value="Gadugi">Gadugi</option>
                      <option value="Gadugi Bold">Gadugi Bold</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Georgia Italic">Georgia Italic</option>
                      <option value="Georgia Bold">Georgia Bold</option>
                      <option value="Georgia Bold Italic">
                        Georgia Bold Italic
                      </option>
                      <option value="HoloLens MDL2 Assets ">
                        HoloLens MDL2 Assets{" "}
                      </option>
                      <option value="Impact">Impact</option>
                      <option value="Ink Free ">Ink Free </option>
                      <option value="Javanese Text">Javanese Text</option>
                      <option value="Leelawadee UI">Leelawadee UI</option>
                      <option value="Leelawadee UI Semilight">
                        Leelawadee UI Semilight
                      </option>
                      <option value="Leelawadee UI Bold">
                        Leelawadee UI Bold
                      </option>
                      <option value="Lucida Console">Lucida Console</option>
                      <option value="Lucida Sans Unicode">
                        Lucida Sans Unicode
                      </option>
                      <option value="Malgun Gothic">Malgun Gothic</option>
                      <option value="Malgun Gothic Bold">
                        Malgun Gothic Bold
                      </option>
                      <option value="Malgun Gothic Semilight ">
                        Malgun Gothic Semilight{" "}
                      </option>
                      <option value="Marlett">Marlett</option>
                      <option value="Microsoft Himalaya">
                        Microsoft Himalaya
                      </option>
                      <option value="Microsoft JhengHei Light">
                        Microsoft JhengHei Light
                      </option>
                      <option value="Microsoft JhengHei">
                        Microsoft JhengHei
                      </option>
                      <option value="Microsoft JhengHei Bold">
                        Microsoft JhengHei Bold
                      </option>
                      <option value="Microsoft JhengHei UI Light">
                        Microsoft JhengHei UI Light
                      </option>
                      <option value="Microsoft JhengHei UI">
                        Microsoft JhengHei UI
                      </option>
                      <option value="Microsoft JhengHei UI Bold">
                        Microsoft JhengHei UI Bold
                      </option>
                      <option value="Microsoft New Tai Lue">
                        Microsoft New Tai Lue
                      </option>
                      <option value="Microsoft New Tai Lue Bold">
                        Microsoft New Tai Lue Bold
                      </option>
                      <option value="Microsoft PhagsPa">
                        Microsoft PhagsPa
                      </option>
                      <option value="Microsoft PhagsPa Bold">
                        Microsoft PhagsPa Bold
                      </option>
                      <option value="Microsoft Sans Serif">
                        Microsoft Sans Serif
                      </option>
                      <option value="Microsoft Tai Le">Microsoft Tai Le</option>
                      <option value="Microsoft Tai Le Bold">
                        Microsoft Tai Le Bold
                      </option>
                      <option value="Microsoft YaHei Light">
                        Microsoft YaHei Light
                      </option>
                      <option value="Microsoft YaHei">Microsoft YaHei</option>
                      <option value="Microsoft YaHei Bold">
                        Microsoft YaHei Bold
                      </option>
                      <option value="Microsoft YaHei UI Light">
                        Microsoft YaHei UI Light
                      </option>
                      <option value="Microsoft YaHei UI">
                        Microsoft YaHei UI
                      </option>
                      <option value="Microsoft YaHei UI Bold">
                        Microsoft YaHei UI Bold
                      </option>
                      <option value="Microsoft Yi Baiti">
                        Microsoft Yi Baiti
                      </option>
                      <option value="MingLiU-ExtB">MingLiU-ExtB</option>
                      <option value="PMingLiU-ExtB">PMingLiU-ExtB</option>
                      <option value="MingLiU_HKSCS-ExtB">
                        MingLiU_HKSCS-ExtB
                      </option>
                      <option value="Mongolian Baiti">Mongolian Baiti</option>
                      <option value="MS Gothic">MS Gothic</option>
                      <option value="MS PGothic">MS PGothic</option>
                      <option value="MS UI Gothic">MS UI Gothic</option>
                      <option value="MV Boli">MV Boli</option>
                      <option value="Myanmar Text">Myanmar Text</option>
                      <option value="Myanmar Text Bold">
                        Myanmar Text Bold
                      </option>
                      <option value="Nirmala UI Semilight">
                        Nirmala UI Semilight
                      </option>
                      <option value="Nirmala UI">Nirmala UI</option>
                      <option value="Nirmala UI Bold">Nirmala UI Bold</option>
                      <option value="Palatino Linotype">
                        Palatino Linotype
                      </option>
                      <option value="Palatino Linotype Italic">
                        Palatino Linotype Italic
                      </option>
                      <option value="Palatino Linotype Bold">
                        Palatino Linotype Bold
                      </option>
                      <option value="Palatino Linotype Bold Italic">
                        Palatino Linotype Bold Italic
                      </option>
                      <option value="Segoe MDL2 Assets ">
                        Segoe MDL2 Assets{" "}
                      </option>
                      <option value="Segoe Print">Segoe Print</option>
                      <option value="Segoe Print Bold">Segoe Print Bold</option>
                      <option value="Segoe Script">Segoe Script</option>
                      <option value="Segoe Script Bold">
                        Segoe Script Bold
                      </option>
                      <option value="Segoe UI Light">Segoe UI Light</option>
                      <option value="Segoe UI Light Italic">
                        Segoe UI Light Italic
                      </option>
                      <option value="Segoe UI Semilight">
                        Segoe UI Semilight
                      </option>
                      <option value="Segoe UI Semilight Italic">
                        Segoe UI Semilight Italic
                      </option>
                      <option value="Segoe UI">Segoe UI</option>
                      <option value="Segoe UI Italic">Segoe UI Italic</option>
                      <option value="Segoe UI Semibold">
                        Segoe UI Semibold
                      </option>
                      <option value="Segoe UI Semibold Italic">
                        Segoe UI Semibold Italic
                      </option>
                      <option value="Segoe UI Bold">Segoe UI Bold</option>
                      <option value="Segoe UI Bold Italic">
                        Segoe UI Bold Italic
                      </option>
                      <option value="Segoe UI Black">Segoe UI Black</option>
                      <option value="Segoe UI Black Italic">
                        Segoe UI Black Italic
                      </option>
                      <option value="Segoe UI Historic ">
                        Segoe UI Historic{" "}
                      </option>
                      <option value="Segoe UI Emoji">Segoe UI Emoji</option>
                      <option value="Segoe UI Symbol">Segoe UI Symbol</option>
                      <option value="SimSun">SimSun</option>
                      <option value="NSimSun">NSimSun</option>
                      <option value="SimSun-ExtB">SimSun-ExtB</option>
                      <option value="Sitka Small">Sitka Small</option>
                      <option value="Sitka Small Italic">
                        Sitka Small Italic
                      </option>
                      <option value="Sitka Small Bold">Sitka Small Bold</option>
                      <option value="Sitka Small Bold Italic">
                        Sitka Small Bold Italic
                      </option>
                      <option value="Sitka Text">Sitka Text</option>
                      <option value="Sitka Text Italic">
                        Sitka Text Italic
                      </option>
                      <option value="Sitka Text Bold">Sitka Text Bold</option>
                      <option value="Sitka Text Bold Italic">
                        Sitka Text Bold Italic
                      </option>
                      <option value="Sitka Subheading">Sitka Subheading</option>
                      <option value="Sitka Subheading Italic">
                        Sitka Subheading Italic
                      </option>
                      <option value="Sitka Subheading Bold">
                        Sitka Subheading Bold
                      </option>
                      <option value="Sitka Subheading Bold Italic">
                        Sitka Subheading Bold Italic
                      </option>
                      <option value="Sitka Heading">Sitka Heading</option>
                      <option value="Sitka Heading Italic">
                        Sitka Heading Italic
                      </option>
                      <option value="Sitka Heading Bold">
                        Sitka Heading Bold
                      </option>
                      <option value="Sitka Heading Bold Italic">
                        Sitka Heading Bold Italic
                      </option>
                      <option value="Sitka Display">Sitka Display</option>
                      <option value="Sitka Display Italic">
                        Sitka Display Italic
                      </option>
                      <option value="Sitka Display Bold">
                        Sitka Display Bold
                      </option>
                      <option value="Sitka Display Bold Italic">
                        Sitka Display Bold Italic
                      </option>
                      <option value="Sitka Banner">Sitka Banner</option>
                      <option value="Sitka Banner Italic">
                        Sitka Banner Italic
                      </option>
                      <option value="Sitka Banner Bold">
                        Sitka Banner Bold
                      </option>
                      <option value="Sitka Banner Bold Italic">
                        Sitka Banner Bold Italic
                      </option>
                      <option value="Sylfaen">Sylfaen</option>
                      <option value="Symbol">Symbol</option>
                      <option value="Tahoma">Tahoma</option>
                      <option value="Tahoma Bold">Tahoma Bold</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Times New Roman Italic">
                        Times New Roman Italic
                      </option>
                      <option value="Times New Roman Bold">
                        Times New Roman Bold
                      </option>
                      <option value="Times New Roman Bold Italic">
                        Times New Roman Bold Italic
                      </option>
                      <option value="Trebuchet MS">Trebuchet MS</option>
                      <option value="Trebuchet MS Italic">
                        Trebuchet MS Italic
                      </option>
                      <option value="Trebuchet MS Bold">
                        Trebuchet MS Bold
                      </option>
                      <option value="Trebuchet MS Bold Italic">
                        Trebuchet MS Bold Italic
                      </option>
                      <option value="Verdana">Verdana</option>
                      <option value="Verdana Italic">Verdana Italic</option>
                      <option value="Verdana Bold">Verdana Bold</option>
                      <option value="Verdana Bold Italic">
                        Verdana Bold Italic
                      </option>
                      <option value="Webdings">Webdings</option>
                      <option value="Wingdings">Wingdings</option>
                      <option value="Yu Gothic Light">Yu Gothic Light</option>
                      <option value="Yu Gothic Regular">
                        Yu Gothic Regular
                      </option>
                      <option value="Yu Gothic Medium ">
                        Yu Gothic Medium{" "}
                      </option>
                      <option value="Yu Gothic Bold">Yu Gothic Bold</option>
                      <option value="Yu Gothic UI Light ">
                        Yu Gothic UI Light{" "}
                      </option>
                      <option value="Yu Gothic UI Semilight ">
                        Yu Gothic UI Semilight{" "}
                      </option>
                      <option value="Yu Gothic UI Regular ">
                        Yu Gothic UI Regular{" "}
                      </option>
                      <option value="Yu Gothic UI Semibold ">
                        Yu Gothic UI Semibold{" "}
                      </option>
                      <option value="Yu Gothic UI Bold ">
                        Yu Gothic UI Bold{" "}
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
                <div className="clearfix he-1">&nbsp;</div>
                {/* font family */}
              </div>
            </div>
          )}
        </div>
        {/* 0 */}

        {/* 1 */}
        <div className="d-grid gap-1">
          <div className="clearfix">&nbsp;</div>
          {this.props.card_details.firstname != undefined && (
            <InputGroup
              className={`mb-0 form-group ${this.errorClass(
                this.state.formErrors.firstname
              )} || '' `}
            >
              <FormControl
                placeholder="First Name"
                aria-label="First Name"
                aria-describedby="basic-addon2"
                name="firstname"
                required
                className="form-control"
                // value={this.state.studentname || studentname}
                onChange={this.handleUserInput}
                value={this.props.card_details.firstname.text || ""}
              />
              <InputGroup.Text id="basic-addon2" className="p-0">
                <Button
                  style={{
                    borderRadius: "0",
                    background: "#e9ecef",
                    border: "none",
                    color: "#666",
                  }}
                  onClick={() => this.hideComponent("showHidefirstname")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-grip-horizontal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                </Button>
              </InputGroup.Text>
            </InputGroup>
          )}
          <div className="clearfix he-1">&nbsp;</div>
          {this.state.showHidefirstname && (
            <div style={{ border: "solid 1px #666", padding: "10px" }}>
              {/* color */}
              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Color:</FormLabel>
                </Col>
                <Col>
                  <div className="d-grid gap-1">
                    <InputGroup className={`mb-0 form-group`}>
                      <Form.Control
                        type="color"
                        id="firstnamecolorpicker"
                        name="firstnamecolorpicker"
                        title="Choose your color"
                        value={this.state.firstnamecolorpicker}
                        onChange={this.ColorChange}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              {/* color */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* fontsize */}

              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Size:</FormLabel>
                </Col>
                <Col>
                  <div className="d-grid gap-1">
                    <InputGroup className={`mb-0 form-group`}>
                      <FormControl
                        type="number"
                        min="8"
                        max="100"
                        aria-label="Size"
                        aria-describedby="basic-addon2"
                        name="firstnamefontsize"
                        className="form-control"
                        placeholder="Size"
                        value={this.state.firstnamefontsize}
                        onChange={this.FontSizeChange}
                      />
                    </InputGroup>
                    <div className="clearfix he-1">&nbsp;</div>
                  </div>
                </Col>
              </Row>
              {/* fontsize */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* styles */}
              <Row>
                <Col className="font-weight-bold ">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    name="fboldfirstname"
                    className={`col-12 ${
                      this.props.card_details.firstname.fontBold ? "active" : ""
                    }`}
                    onClick={() => this.BoldChange("fboldfirstname")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-bold"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
                    </svg>
                  </Button>
                </Col>
                <Col className="font-weight-bold p-0">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    value="italic"
                    name="fitalicfirstname"
                    className={`col-12 ${
                      this.props.card_details.firstname.fontItalic
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.ItalicChange("fitalicfirstname")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-italic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
                    </svg>
                  </Button>
                </Col>
                <Col className="font-weight-bold">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    value="underline"
                    name="funderlinefirstname"
                    className={`col-12 ${
                      this.props.card_details.firstname.fontUnderline
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.UnderlineChange("funderlinefirstname")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-underline"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57-1.709 0-2.687-1.08-2.687-2.57V3.136zM12.5 15h-9v-1h9v1z" />
                    </svg>
                  </Button>
                </Col>
                <div className="clearfix he-1">&nbsp;</div>
                {/* <Col className="font-weight-bold">

      <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12" name="firstnameparaleft" onClick={() => this.ParaChange('firstnamepara','left')} >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-left" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
            </Button>

           
      </Col>


      <Col className="font-weight-bold p-0">

  

            <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12" name="firstnameparacenter" onClick={() => this.ParaChange('firstnamepara','center')} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-center" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
            </Button>

     
      </Col>


      <Col className="font-weight-bold">


            <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="firstnamepararight" onClick={() => this.ParaChange('firstnamepara','right')} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
            </Button>
     
      </Col> */}
              </Row>
              {/* styles */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* font family */}
              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Font:</FormLabel>
                </Col>
                <Col>
                  <Form.Select
                    id="firstnamefontfamily"
                    name="firstnamefontfamily"
                    value={this.state.firstnamefontfamily}
                    onChange={this.ChangeFontFamily}
                  >
                    <option value="Arial">Arial</option>
                    <option value="Arial Italic">Arial Italic</option>
                    <option value="Arial Bold">Arial Bold</option>
                    <option value="Arial Bold Italic">Arial Bold Italic</option>
                    <option value="Arial Black">Arial Black</option>
                    <option value="Bahnschrift ">Bahnschrift </option>
                    <option value="Calibri Light">Calibri Light</option>
                    <option value="Calibri Light Italic">
                      Calibri Light Italic
                    </option>
                    <option value="Calibri">Calibri</option>
                    <option value="Calibri Italic">Calibri Italic</option>
                    <option value="Calibri Bold">Calibri Bold</option>
                    <option value="Calibri Bold Italic">
                      Calibri Bold Italic
                    </option>
                    <option value="Cambria">Cambria</option>
                    <option value="Cambria Italic">Cambria Italic</option>
                    <option value="Cambria Bold">Cambria Bold</option>
                    <option value="Cambria Bold Italic">
                      Cambria Bold Italic
                    </option>
                    <option value="Cambria Math">Cambria Math</option>
                    <option value="Candara Light ">Candara Light </option>
                    <option value="Candara Light Italic ">
                      Candara Light Italic{" "}
                    </option>
                    <option value="Candara">Candara</option>
                    <option value="Candara Italic">Candara Italic</option>
                    <option value="Candara Bold">Candara Bold</option>
                    <option value="Candara Bold Italic">
                      Candara Bold Italic
                    </option>
                    <option value="Comic Sans MS">Comic Sans MS</option>
                    <option value="Comic Sans MS Italic">
                      Comic Sans MS Italic
                    </option>
                    <option value="Comic Sans MS Bold">
                      Comic Sans MS Bold
                    </option>
                    <option value="Comic Sans MS Bold Italic">
                      Comic Sans MS Bold Italic
                    </option>
                    <option value="Consolas">Consolas</option>
                    <option value="Consolas Italic">Consolas Italic</option>
                    <option value="Consolas Bold">Consolas Bold</option>
                    <option value="Consolas Bold Italic">
                      Consolas Bold Italic
                    </option>
                    <option value="Constantia">Constantia</option>
                    <option value="Constantia Italic">Constantia Italic</option>
                    <option value="Constantia Bold">Constantia Bold</option>
                    <option value="Constantia Bold Italic">
                      Constantia Bold Italic
                    </option>
                    <option value="Corbel Light ">Corbel Light </option>
                    <option value="Corbel Light Italic ">
                      Corbel Light Italic{" "}
                    </option>
                    <option value="Corbel">Corbel</option>
                    <option value="Corbel Italic">Corbel Italic</option>
                    <option value="Corbel Bold">Corbel Bold</option>
                    <option value="Corbel Bold Italic">
                      Corbel Bold Italic
                    </option>
                    <option value="Courier New">Courier New</option>
                    <option value="Courier New Italic">
                      Courier New Italic
                    </option>
                    <option value="Courier New Bold">Courier New Bold</option>
                    <option value="Courier New Bold Italic">
                      Courier New Bold Italic
                    </option>
                    <option value="Ebrima">Ebrima</option>
                    <option value="Ebrima Bold">Ebrima Bold</option>
                    <option value="Franklin Gothic Medium">
                      Franklin Gothic Medium
                    </option>
                    <option value="Franklin Gothic Medium Italic">
                      Franklin Gothic Medium Italic
                    </option>
                    <option value="Gabriola">Gabriola</option>
                    <option value="Gadugi">Gadugi</option>
                    <option value="Gadugi Bold">Gadugi Bold</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Georgia Italic">Georgia Italic</option>
                    <option value="Georgia Bold">Georgia Bold</option>
                    <option value="Georgia Bold Italic">
                      Georgia Bold Italic
                    </option>
                    <option value="HoloLens MDL2 Assets ">
                      HoloLens MDL2 Assets{" "}
                    </option>
                    <option value="Impact">Impact</option>
                    <option value="Ink Free ">Ink Free </option>
                    <option value="Javanese Text">Javanese Text</option>
                    <option value="Leelawadee UI">Leelawadee UI</option>
                    <option value="Leelawadee UI Semilight">
                      Leelawadee UI Semilight
                    </option>
                    <option value="Leelawadee UI Bold">
                      Leelawadee UI Bold
                    </option>
                    <option value="Lucida Console">Lucida Console</option>
                    <option value="Lucida Sans Unicode">
                      Lucida Sans Unicode
                    </option>
                    <option value="Malgun Gothic">Malgun Gothic</option>
                    <option value="Malgun Gothic Bold">
                      Malgun Gothic Bold
                    </option>
                    <option value="Malgun Gothic Semilight ">
                      Malgun Gothic Semilight{" "}
                    </option>
                    <option value="Marlett">Marlett</option>
                    <option value="Microsoft Himalaya">
                      Microsoft Himalaya
                    </option>
                    <option value="Microsoft JhengHei Light">
                      Microsoft JhengHei Light
                    </option>
                    <option value="Microsoft JhengHei">
                      Microsoft JhengHei
                    </option>
                    <option value="Microsoft JhengHei Bold">
                      Microsoft JhengHei Bold
                    </option>
                    <option value="Microsoft JhengHei UI Light">
                      Microsoft JhengHei UI Light
                    </option>
                    <option value="Microsoft JhengHei UI">
                      Microsoft JhengHei UI
                    </option>
                    <option value="Microsoft JhengHei UI Bold">
                      Microsoft JhengHei UI Bold
                    </option>
                    <option value="Microsoft New Tai Lue">
                      Microsoft New Tai Lue
                    </option>
                    <option value="Microsoft New Tai Lue Bold">
                      Microsoft New Tai Lue Bold
                    </option>
                    <option value="Microsoft PhagsPa">Microsoft PhagsPa</option>
                    <option value="Microsoft PhagsPa Bold">
                      Microsoft PhagsPa Bold
                    </option>
                    <option value="Microsoft Sans Serif">
                      Microsoft Sans Serif
                    </option>
                    <option value="Microsoft Tai Le">Microsoft Tai Le</option>
                    <option value="Microsoft Tai Le Bold">
                      Microsoft Tai Le Bold
                    </option>
                    <option value="Microsoft YaHei Light">
                      Microsoft YaHei Light
                    </option>
                    <option value="Microsoft YaHei">Microsoft YaHei</option>
                    <option value="Microsoft YaHei Bold">
                      Microsoft YaHei Bold
                    </option>
                    <option value="Microsoft YaHei UI Light">
                      Microsoft YaHei UI Light
                    </option>
                    <option value="Microsoft YaHei UI">
                      Microsoft YaHei UI
                    </option>
                    <option value="Microsoft YaHei UI Bold">
                      Microsoft YaHei UI Bold
                    </option>
                    <option value="Microsoft Yi Baiti">
                      Microsoft Yi Baiti
                    </option>
                    <option value="MingLiU-ExtB">MingLiU-ExtB</option>
                    <option value="PMingLiU-ExtB">PMingLiU-ExtB</option>
                    <option value="MingLiU_HKSCS-ExtB">
                      MingLiU_HKSCS-ExtB
                    </option>
                    <option value="Mongolian Baiti">Mongolian Baiti</option>
                    <option value="MS Gothic">MS Gothic</option>
                    <option value="MS PGothic">MS PGothic</option>
                    <option value="MS UI Gothic">MS UI Gothic</option>
                    <option value="MV Boli">MV Boli</option>
                    <option value="Myanmar Text">Myanmar Text</option>
                    <option value="Myanmar Text Bold">Myanmar Text Bold</option>
                    <option value="Nirmala UI Semilight">
                      Nirmala UI Semilight
                    </option>
                    <option value="Nirmala UI">Nirmala UI</option>
                    <option value="Nirmala UI Bold">Nirmala UI Bold</option>
                    <option value="Palatino Linotype">Palatino Linotype</option>
                    <option value="Palatino Linotype Italic">
                      Palatino Linotype Italic
                    </option>
                    <option value="Palatino Linotype Bold">
                      Palatino Linotype Bold
                    </option>
                    <option value="Palatino Linotype Bold Italic">
                      Palatino Linotype Bold Italic
                    </option>
                    <option value="Segoe MDL2 Assets ">
                      Segoe MDL2 Assets{" "}
                    </option>
                    <option value="Segoe Print">Segoe Print</option>
                    <option value="Segoe Print Bold">Segoe Print Bold</option>
                    <option value="Segoe Script">Segoe Script</option>
                    <option value="Segoe Script Bold">Segoe Script Bold</option>
                    <option value="Segoe UI Light">Segoe UI Light</option>
                    <option value="Segoe UI Light Italic">
                      Segoe UI Light Italic
                    </option>
                    <option value="Segoe UI Semilight">
                      Segoe UI Semilight
                    </option>
                    <option value="Segoe UI Semilight Italic">
                      Segoe UI Semilight Italic
                    </option>
                    <option value="Segoe UI">Segoe UI</option>
                    <option value="Segoe UI Italic">Segoe UI Italic</option>
                    <option value="Segoe UI Semibold">Segoe UI Semibold</option>
                    <option value="Segoe UI Semibold Italic">
                      Segoe UI Semibold Italic
                    </option>
                    <option value="Segoe UI Bold">Segoe UI Bold</option>
                    <option value="Segoe UI Bold Italic">
                      Segoe UI Bold Italic
                    </option>
                    <option value="Segoe UI Black">Segoe UI Black</option>
                    <option value="Segoe UI Black Italic">
                      Segoe UI Black Italic
                    </option>
                    <option value="Segoe UI Historic ">
                      Segoe UI Historic{" "}
                    </option>
                    <option value="Segoe UI Emoji">Segoe UI Emoji</option>
                    <option value="Segoe UI Symbol">Segoe UI Symbol</option>
                    <option value="SimSun">SimSun</option>
                    <option value="NSimSun">NSimSun</option>
                    <option value="SimSun-ExtB">SimSun-ExtB</option>
                    <option value="Sitka Small">Sitka Small</option>
                    <option value="Sitka Small Italic">
                      Sitka Small Italic
                    </option>
                    <option value="Sitka Small Bold">Sitka Small Bold</option>
                    <option value="Sitka Small Bold Italic">
                      Sitka Small Bold Italic
                    </option>
                    <option value="Sitka Text">Sitka Text</option>
                    <option value="Sitka Text Italic">Sitka Text Italic</option>
                    <option value="Sitka Text Bold">Sitka Text Bold</option>
                    <option value="Sitka Text Bold Italic">
                      Sitka Text Bold Italic
                    </option>
                    <option value="Sitka Subheading">Sitka Subheading</option>
                    <option value="Sitka Subheading Italic">
                      Sitka Subheading Italic
                    </option>
                    <option value="Sitka Subheading Bold">
                      Sitka Subheading Bold
                    </option>
                    <option value="Sitka Subheading Bold Italic">
                      Sitka Subheading Bold Italic
                    </option>
                    <option value="Sitka Heading">Sitka Heading</option>
                    <option value="Sitka Heading Italic">
                      Sitka Heading Italic
                    </option>
                    <option value="Sitka Heading Bold">
                      Sitka Heading Bold
                    </option>
                    <option value="Sitka Heading Bold Italic">
                      Sitka Heading Bold Italic
                    </option>
                    <option value="Sitka Display">Sitka Display</option>
                    <option value="Sitka Display Italic">
                      Sitka Display Italic
                    </option>
                    <option value="Sitka Display Bold">
                      Sitka Display Bold
                    </option>
                    <option value="Sitka Display Bold Italic">
                      Sitka Display Bold Italic
                    </option>
                    <option value="Sitka Banner">Sitka Banner</option>
                    <option value="Sitka Banner Italic">
                      Sitka Banner Italic
                    </option>
                    <option value="Sitka Banner Bold">Sitka Banner Bold</option>
                    <option value="Sitka Banner Bold Italic">
                      Sitka Banner Bold Italic
                    </option>
                    <option value="Sylfaen">Sylfaen</option>
                    <option value="Symbol">Symbol</option>
                    <option value="Tahoma">Tahoma</option>
                    <option value="Tahoma Bold">Tahoma Bold</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Times New Roman Italic">
                      Times New Roman Italic
                    </option>
                    <option value="Times New Roman Bold">
                      Times New Roman Bold
                    </option>
                    <option value="Times New Roman Bold Italic">
                      Times New Roman Bold Italic
                    </option>
                    <option value="Trebuchet MS">Trebuchet MS</option>
                    <option value="Trebuchet MS Italic">
                      Trebuchet MS Italic
                    </option>
                    <option value="Trebuchet MS Bold">Trebuchet MS Bold</option>
                    <option value="Trebuchet MS Bold Italic">
                      Trebuchet MS Bold Italic
                    </option>
                    <option value="Verdana">Verdana</option>
                    <option value="Verdana Italic">Verdana Italic</option>
                    <option value="Verdana Bold">Verdana Bold</option>
                    <option value="Verdana Bold Italic">
                      Verdana Bold Italic
                    </option>
                    <option value="Webdings">Webdings</option>
                    <option value="Wingdings">Wingdings</option>
                    <option value="Yu Gothic Light">Yu Gothic Light</option>
                    <option value="Yu Gothic Regular">Yu Gothic Regular</option>
                    <option value="Yu Gothic Medium ">Yu Gothic Medium </option>
                    <option value="Yu Gothic Bold">Yu Gothic Bold</option>
                    <option value="Yu Gothic UI Light ">
                      Yu Gothic UI Light{" "}
                    </option>
                    <option value="Yu Gothic UI Semilight ">
                      Yu Gothic UI Semilight{" "}
                    </option>
                    <option value="Yu Gothic UI Regular ">
                      Yu Gothic UI Regular{" "}
                    </option>
                    <option value="Yu Gothic UI Semibold ">
                      Yu Gothic UI Semibold{" "}
                    </option>
                    <option value="Yu Gothic UI Bold ">
                      Yu Gothic UI Bold{" "}
                    </option>
                  </Form.Select>
                </Col>
              </Row>
              <div className="clearfix he-1">&nbsp;</div>
              {/* font family */}
            </div>
          )}
          <div className="clearfix he-1">&nbsp;</div>
        </div>
        {/* 1 */}

        {/* 2 */}

        <div className="d-grid gap-1">
          {this.props.card_details.lastname != undefined && (
            <InputGroup
              className={`mb-0 form-group ${this.errorClass(
                this.state.formErrors.lastname
              )}`}
            >
              <FormControl
                aria-label="Middle Name"
                aria-describedby="basic-addon2"
                name="lastname"
                required
                className="form-control"
                placeholder="Last Name"
                // value={this.state.middlename || middlename}
                onChange={this.handleUserInput}
                value={this.props.card_details.lastname.text || ""}
              />
              <InputGroup.Text id="basic-addon2" className="p-0">
                <Button
                  onClick={() => this.hideComponent("showHidelastname")}
                  style={{
                    borderRadius: "0",
                    background: "#e9ecef",
                    border: "none",
                    color: "#666",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-grip-horizontal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                </Button>
              </InputGroup.Text>
            </InputGroup>
          )}
          <div className="clearfix he-1">&nbsp;</div>
          {this.state.showHidelastname && (
            <div style={{ border: "solid 1px #666", padding: "10px" }}>
              {/* color */}
              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Color:</FormLabel>
                </Col>
                <Col>
                  <div className="d-grid gap-1">
                    <InputGroup className={`mb-0 form-group`}>
                      <Form.Control
                        type="color"
                        id="lastnamecolorpicker"
                        name="lastnamecolorpicker"
                        title="Choose your color"
                        value={this.state.lastnamecolorpicker}
                        onChange={this.ColorChange}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              {/* color */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* fontsize */}

              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Size:</FormLabel>
                </Col>
                <Col>
                  <div className="d-grid gap-1">
                    <InputGroup className={`mb-0 form-group`}>
                      <FormControl
                        type="number"
                        min="8"
                        max="100"
                        aria-label="Size"
                        aria-describedby="basic-addon2"
                        name="lastnamefontsize"
                        className="form-control"
                        placeholder="Size"
                        value={this.state.lastnamefontsize}
                        onChange={this.FontSizeChange}
                      />
                    </InputGroup>
                    <div className="clearfix he-1">&nbsp;</div>
                  </div>
                </Col>
              </Row>
              {/* fontsize */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* styles */}
              <Row>
                <Col className="font-weight-bold ">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    name="fboldlastname"
                    className={`col-12 ${
                      this.props.card_details.lastname.fontBold ? "active" : ""
                    }`}
                    onClick={() => this.BoldChange("fboldlastname")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-bold"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
                    </svg>
                  </Button>
                </Col>
                <Col className="font-weight-bold p-0">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    value="italic"
                    name="fitaliclastname"
                    className={`col-12 ${
                      this.props.card_details.lastname.fontItalic
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.ItalicChange("fitaliclastname")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-italic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
                    </svg>
                  </Button>
                </Col>
                <Col className="font-weight-bold">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    value="underline"
                    name="funderlinelastname"
                    className={`col-12 ${
                      this.props.card_details.lastname.fontUnderline
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.UnderlineChange("funderlinelastname")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-underline"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57-1.709 0-2.687-1.08-2.687-2.57V3.136zM12.5 15h-9v-1h9v1z" />
                    </svg>
                  </Button>
                </Col>
                <div className="clearfix he-1">&nbsp;</div>
                {/* <Col className="font-weight-bold">

   <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="lastnameparaleft" onClick={() => this.ParaChange('lastnamepara','left')} >
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-left" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
         </Button>        
   </Col>
   <Col className="font-weight-bold p-0">
         <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="lastnameparacenter" onClick={() => this.ParaChange('lastnamepara','center')} >
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-center" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
         </Button>
   </Col>

   <Col className="font-weight-bold">

         <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="lastnamepararight" onClick={() => this.ParaChange('lastnamepara','right')} >
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-right" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
         </Button>
  
   </Col> */}
              </Row>
              {/* styles */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* font family */}
              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Font:</FormLabel>
                </Col>
                <Col>
                  <Form.Select
                    id="lastnamefontfamily"
                    name="lastnamefontfamily"
                    value={this.state.lastnamefontfamily}
                    onChange={this.ChangeFontFamily}
                  >
                    <option value="Arial">Arial</option>
                    <option value="Arial Italic">Arial Italic</option>
                    <option value="Arial Bold">Arial Bold</option>
                    <option value="Arial Bold Italic">Arial Bold Italic</option>
                    <option value="Arial Black">Arial Black</option>
                    <option value="Bahnschrift ">Bahnschrift </option>
                    <option value="Calibri Light">Calibri Light</option>
                    <option value="Calibri Light Italic">
                      Calibri Light Italic
                    </option>
                    <option value="Calibri">Calibri</option>
                    <option value="Calibri Italic">Calibri Italic</option>
                    <option value="Calibri Bold">Calibri Bold</option>
                    <option value="Calibri Bold Italic">
                      Calibri Bold Italic
                    </option>
                    <option value="Cambria">Cambria</option>
                    <option value="Cambria Italic">Cambria Italic</option>
                    <option value="Cambria Bold">Cambria Bold</option>
                    <option value="Cambria Bold Italic">
                      Cambria Bold Italic
                    </option>
                    <option value="Cambria Math">Cambria Math</option>
                    <option value="Candara Light ">Candara Light </option>
                    <option value="Candara Light Italic ">
                      Candara Light Italic{" "}
                    </option>
                    <option value="Candara">Candara</option>
                    <option value="Candara Italic">Candara Italic</option>
                    <option value="Candara Bold">Candara Bold</option>
                    <option value="Candara Bold Italic">
                      Candara Bold Italic
                    </option>
                    <option value="Comic Sans MS">Comic Sans MS</option>
                    <option value="Comic Sans MS Italic">
                      Comic Sans MS Italic
                    </option>
                    <option value="Comic Sans MS Bold">
                      Comic Sans MS Bold
                    </option>
                    <option value="Comic Sans MS Bold Italic">
                      Comic Sans MS Bold Italic
                    </option>
                    <option value="Consolas">Consolas</option>
                    <option value="Consolas Italic">Consolas Italic</option>
                    <option value="Consolas Bold">Consolas Bold</option>
                    <option value="Consolas Bold Italic">
                      Consolas Bold Italic
                    </option>
                    <option value="Constantia">Constantia</option>
                    <option value="Constantia Italic">Constantia Italic</option>
                    <option value="Constantia Bold">Constantia Bold</option>
                    <option value="Constantia Bold Italic">
                      Constantia Bold Italic
                    </option>
                    <option value="Corbel Light ">Corbel Light </option>
                    <option value="Corbel Light Italic ">
                      Corbel Light Italic{" "}
                    </option>
                    <option value="Corbel">Corbel</option>
                    <option value="Corbel Italic">Corbel Italic</option>
                    <option value="Corbel Bold">Corbel Bold</option>
                    <option value="Corbel Bold Italic">
                      Corbel Bold Italic
                    </option>
                    <option value="Courier New">Courier New</option>
                    <option value="Courier New Italic">
                      Courier New Italic
                    </option>
                    <option value="Courier New Bold">Courier New Bold</option>
                    <option value="Courier New Bold Italic">
                      Courier New Bold Italic
                    </option>
                    <option value="Ebrima">Ebrima</option>
                    <option value="Ebrima Bold">Ebrima Bold</option>
                    <option value="Franklin Gothic Medium">
                      Franklin Gothic Medium
                    </option>
                    <option value="Franklin Gothic Medium Italic">
                      Franklin Gothic Medium Italic
                    </option>
                    <option value="Gabriola">Gabriola</option>
                    <option value="Gadugi">Gadugi</option>
                    <option value="Gadugi Bold">Gadugi Bold</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Georgia Italic">Georgia Italic</option>
                    <option value="Georgia Bold">Georgia Bold</option>
                    <option value="Georgia Bold Italic">
                      Georgia Bold Italic
                    </option>
                    <option value="HoloLens MDL2 Assets ">
                      HoloLens MDL2 Assets{" "}
                    </option>
                    <option value="Impact">Impact</option>
                    <option value="Ink Free ">Ink Free </option>
                    <option value="Javanese Text">Javanese Text</option>
                    <option value="Leelawadee UI">Leelawadee UI</option>
                    <option value="Leelawadee UI Semilight">
                      Leelawadee UI Semilight
                    </option>
                    <option value="Leelawadee UI Bold">
                      Leelawadee UI Bold
                    </option>
                    <option value="Lucida Console">Lucida Console</option>
                    <option value="Lucida Sans Unicode">
                      Lucida Sans Unicode
                    </option>
                    <option value="Malgun Gothic">Malgun Gothic</option>
                    <option value="Malgun Gothic Bold">
                      Malgun Gothic Bold
                    </option>
                    <option value="Malgun Gothic Semilight ">
                      Malgun Gothic Semilight{" "}
                    </option>
                    <option value="Marlett">Marlett</option>
                    <option value="Microsoft Himalaya">
                      Microsoft Himalaya
                    </option>
                    <option value="Microsoft JhengHei Light">
                      Microsoft JhengHei Light
                    </option>
                    <option value="Microsoft JhengHei">
                      Microsoft JhengHei
                    </option>
                    <option value="Microsoft JhengHei Bold">
                      Microsoft JhengHei Bold
                    </option>
                    <option value="Microsoft JhengHei UI Light">
                      Microsoft JhengHei UI Light
                    </option>
                    <option value="Microsoft JhengHei UI">
                      Microsoft JhengHei UI
                    </option>
                    <option value="Microsoft JhengHei UI Bold">
                      Microsoft JhengHei UI Bold
                    </option>
                    <option value="Microsoft New Tai Lue">
                      Microsoft New Tai Lue
                    </option>
                    <option value="Microsoft New Tai Lue Bold">
                      Microsoft New Tai Lue Bold
                    </option>
                    <option value="Microsoft PhagsPa">Microsoft PhagsPa</option>
                    <option value="Microsoft PhagsPa Bold">
                      Microsoft PhagsPa Bold
                    </option>
                    <option value="Microsoft Sans Serif">
                      Microsoft Sans Serif
                    </option>
                    <option value="Microsoft Tai Le">Microsoft Tai Le</option>
                    <option value="Microsoft Tai Le Bold">
                      Microsoft Tai Le Bold
                    </option>
                    <option value="Microsoft YaHei Light">
                      Microsoft YaHei Light
                    </option>
                    <option value="Microsoft YaHei">Microsoft YaHei</option>
                    <option value="Microsoft YaHei Bold">
                      Microsoft YaHei Bold
                    </option>
                    <option value="Microsoft YaHei UI Light">
                      Microsoft YaHei UI Light
                    </option>
                    <option value="Microsoft YaHei UI">
                      Microsoft YaHei UI
                    </option>
                    <option value="Microsoft YaHei UI Bold">
                      Microsoft YaHei UI Bold
                    </option>
                    <option value="Microsoft Yi Baiti">
                      Microsoft Yi Baiti
                    </option>
                    <option value="MingLiU-ExtB">MingLiU-ExtB</option>
                    <option value="PMingLiU-ExtB">PMingLiU-ExtB</option>
                    <option value="MingLiU_HKSCS-ExtB">
                      MingLiU_HKSCS-ExtB
                    </option>
                    <option value="Mongolian Baiti">Mongolian Baiti</option>
                    <option value="MS Gothic">MS Gothic</option>
                    <option value="MS PGothic">MS PGothic</option>
                    <option value="MS UI Gothic">MS UI Gothic</option>
                    <option value="MV Boli">MV Boli</option>
                    <option value="Myanmar Text">Myanmar Text</option>
                    <option value="Myanmar Text Bold">Myanmar Text Bold</option>
                    <option value="Nirmala UI Semilight">
                      Nirmala UI Semilight
                    </option>
                    <option value="Nirmala UI">Nirmala UI</option>
                    <option value="Nirmala UI Bold">Nirmala UI Bold</option>
                    <option value="Palatino Linotype">Palatino Linotype</option>
                    <option value="Palatino Linotype Italic">
                      Palatino Linotype Italic
                    </option>
                    <option value="Palatino Linotype Bold">
                      Palatino Linotype Bold
                    </option>
                    <option value="Palatino Linotype Bold Italic">
                      Palatino Linotype Bold Italic
                    </option>
                    <option value="Segoe MDL2 Assets ">
                      Segoe MDL2 Assets{" "}
                    </option>
                    <option value="Segoe Print">Segoe Print</option>
                    <option value="Segoe Print Bold">Segoe Print Bold</option>
                    <option value="Segoe Script">Segoe Script</option>
                    <option value="Segoe Script Bold">Segoe Script Bold</option>
                    <option value="Segoe UI Light">Segoe UI Light</option>
                    <option value="Segoe UI Light Italic">
                      Segoe UI Light Italic
                    </option>
                    <option value="Segoe UI Semilight">
                      Segoe UI Semilight
                    </option>
                    <option value="Segoe UI Semilight Italic">
                      Segoe UI Semilight Italic
                    </option>
                    <option value="Segoe UI">Segoe UI</option>
                    <option value="Segoe UI Italic">Segoe UI Italic</option>
                    <option value="Segoe UI Semibold">Segoe UI Semibold</option>
                    <option value="Segoe UI Semibold Italic">
                      Segoe UI Semibold Italic
                    </option>
                    <option value="Segoe UI Bold">Segoe UI Bold</option>
                    <option value="Segoe UI Bold Italic">
                      Segoe UI Bold Italic
                    </option>
                    <option value="Segoe UI Black">Segoe UI Black</option>
                    <option value="Segoe UI Black Italic">
                      Segoe UI Black Italic
                    </option>
                    <option value="Segoe UI Historic ">
                      Segoe UI Historic{" "}
                    </option>
                    <option value="Segoe UI Emoji">Segoe UI Emoji</option>
                    <option value="Segoe UI Symbol">Segoe UI Symbol</option>
                    <option value="SimSun">SimSun</option>
                    <option value="NSimSun">NSimSun</option>
                    <option value="SimSun-ExtB">SimSun-ExtB</option>
                    <option value="Sitka Small">Sitka Small</option>
                    <option value="Sitka Small Italic">
                      Sitka Small Italic
                    </option>
                    <option value="Sitka Small Bold">Sitka Small Bold</option>
                    <option value="Sitka Small Bold Italic">
                      Sitka Small Bold Italic
                    </option>
                    <option value="Sitka Text">Sitka Text</option>
                    <option value="Sitka Text Italic">Sitka Text Italic</option>
                    <option value="Sitka Text Bold">Sitka Text Bold</option>
                    <option value="Sitka Text Bold Italic">
                      Sitka Text Bold Italic
                    </option>
                    <option value="Sitka Subheading">Sitka Subheading</option>
                    <option value="Sitka Subheading Italic">
                      Sitka Subheading Italic
                    </option>
                    <option value="Sitka Subheading Bold">
                      Sitka Subheading Bold
                    </option>
                    <option value="Sitka Subheading Bold Italic">
                      Sitka Subheading Bold Italic
                    </option>
                    <option value="Sitka Heading">Sitka Heading</option>
                    <option value="Sitka Heading Italic">
                      Sitka Heading Italic
                    </option>
                    <option value="Sitka Heading Bold">
                      Sitka Heading Bold
                    </option>
                    <option value="Sitka Heading Bold Italic">
                      Sitka Heading Bold Italic
                    </option>
                    <option value="Sitka Display">Sitka Display</option>
                    <option value="Sitka Display Italic">
                      Sitka Display Italic
                    </option>
                    <option value="Sitka Display Bold">
                      Sitka Display Bold
                    </option>
                    <option value="Sitka Display Bold Italic">
                      Sitka Display Bold Italic
                    </option>
                    <option value="Sitka Banner">Sitka Banner</option>
                    <option value="Sitka Banner Italic">
                      Sitka Banner Italic
                    </option>
                    <option value="Sitka Banner Bold">Sitka Banner Bold</option>
                    <option value="Sitka Banner Bold Italic">
                      Sitka Banner Bold Italic
                    </option>
                    <option value="Sylfaen">Sylfaen</option>
                    <option value="Symbol">Symbol</option>
                    <option value="Tahoma">Tahoma</option>
                    <option value="Tahoma Bold">Tahoma Bold</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Times New Roman Italic">
                      Times New Roman Italic
                    </option>
                    <option value="Times New Roman Bold">
                      Times New Roman Bold
                    </option>
                    <option value="Times New Roman Bold Italic">
                      Times New Roman Bold Italic
                    </option>
                    <option value="Trebuchet MS">Trebuchet MS</option>
                    <option value="Trebuchet MS Italic">
                      Trebuchet MS Italic
                    </option>
                    <option value="Trebuchet MS Bold">Trebuchet MS Bold</option>
                    <option value="Trebuchet MS Bold Italic">
                      Trebuchet MS Bold Italic
                    </option>
                    <option value="Verdana">Verdana</option>
                    <option value="Verdana Italic">Verdana Italic</option>
                    <option value="Verdana Bold">Verdana Bold</option>
                    <option value="Verdana Bold Italic">
                      Verdana Bold Italic
                    </option>
                    <option value="Webdings">Webdings</option>
                    <option value="Wingdings">Wingdings</option>
                    <option value="Yu Gothic Light">Yu Gothic Light</option>
                    <option value="Yu Gothic Regular">Yu Gothic Regular</option>
                    <option value="Yu Gothic Medium ">Yu Gothic Medium </option>
                    <option value="Yu Gothic Bold">Yu Gothic Bold</option>
                    <option value="Yu Gothic UI Light ">
                      Yu Gothic UI Light{" "}
                    </option>
                    <option value="Yu Gothic UI Semilight ">
                      Yu Gothic UI Semilight{" "}
                    </option>
                    <option value="Yu Gothic UI Regular ">
                      Yu Gothic UI Regular{" "}
                    </option>
                    <option value="Yu Gothic UI Semibold ">
                      Yu Gothic UI Semibold{" "}
                    </option>
                    <option value="Yu Gothic UI Bold ">
                      Yu Gothic UI Bold{" "}
                    </option>
                  </Form.Select>
                </Col>
              </Row>
              <div className="clearfix he-1">&nbsp;</div>
              {/* font family */}
            </div>
          )}
          <div className="clearfix he-1">&nbsp;</div>
        </div>
        {/* 2 */}

        {/* 3 */}
        <div className="d-grid gap-1">
          {this.props.card_details.position != undefined && (
            <InputGroup
              className={`mb-0 form-group ${this.errorClass(
                this.state.formErrors.position
              )}`}
            >
              <FormControl
                // placeholder="Surname"
                aria-label="Surname"
                aria-describedby="basic-addon2"
                name="position"
                required
                className="form-control"
                placeholder="Position"
                // value={this.state.surname || surname}
                onChange={this.handleUserInput}
                value={this.props.card_details.position.text || ""}
              />
              <InputGroup.Text id="basic-addon2" className="p-0">
                <Button
                  onClick={() => this.hideComponent("showHideposition")}
                  style={{
                    borderRadius: "0",
                    background: "#e9ecef",
                    border: "none",
                    color: "#666",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-grip-horizontal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                </Button>
              </InputGroup.Text>
            </InputGroup>
          )}
          <div className="clearfix he-1">&nbsp;</div>
          {this.state.showHideposition && (
            <div style={{ border: "solid 1px #666", padding: "10px" }}>
              {/* color */}
              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Color:</FormLabel>
                </Col>
                <Col>
                  <div className="d-grid gap-1">
                    <InputGroup className={`mb-0 form-group`}>
                      <Form.Control
                        type="color"
                        id="positioncolorpicker"
                        name="positioncolorpicker"
                        title="Choose your color"
                        value={this.state.positioncolorpicker}
                        onChange={this.ColorChange}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              {/* color */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* fontsize */}

              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Size:</FormLabel>
                </Col>
                <Col>
                  <div className="d-grid gap-1">
                    <InputGroup className={`mb-0 form-group`}>
                      <FormControl
                        type="number"
                        min="8"
                        max="100"
                        aria-label="Size"
                        aria-describedby="basic-addon2"
                        name="positionfontsize"
                        className="form-control"
                        placeholder="Size"
                        value={this.state.positionfontsize}
                        onChange={this.FontSizeChange}
                      />
                    </InputGroup>
                    <div className="clearfix he-1">&nbsp;</div>
                  </div>
                </Col>
              </Row>
              {/* fontsize */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* styles */}
              <Row>
                <Col className="font-weight-bold ">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    name="fboldposition"
                    className={`col-12 ${
                      this.props.card_details.position.fontBold ? "active" : ""
                    }`}
                    onClick={() => this.BoldChange("fboldposition")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-bold"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
                    </svg>
                  </Button>
                </Col>
                <Col className="font-weight-bold p-0">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    value="italic"
                    name="fitalicposition"
                    className={`col-12 ${
                      this.props.card_details.position.fontItalic
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.ItalicChange("fitalicposition")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-italic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
                    </svg>
                  </Button>
                </Col>
                <Col className="font-weight-bold">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    value="underline"
                    name="funderlineposition"
                    className={`col-12 ${
                      this.props.card_details.position.fontUnderline
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.UnderlineChange("funderlineposition")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-underline"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57-1.709 0-2.687-1.08-2.687-2.57V3.136zM12.5 15h-9v-1h9v1z" />
                    </svg>
                  </Button>
                </Col>
                <div className="clearfix he-1">&nbsp;</div>
                {/* <Col className="font-weight-bold">

    <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="positionparaleft" onClick={() => this.ParaChange('positionpara','left')} >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-left" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
          </Button>

         
    </Col>


    <Col className="font-weight-bold p-0">



          <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="positionparacenter" onClick={() => this.ParaChange('positionpara','center')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-center" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
          </Button>

   
    </Col>


    <Col className="font-weight-bold">


          <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="positionpararight" onClick={() => this.ParaChange('positionpara','right')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-right" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
          </Button>
   
    </Col> */}
              </Row>
              {/* styles */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* font family */}
              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Font:</FormLabel>
                </Col>
                <Col>
                  <Form.Select
                    id="positionfontfamily"
                    name="positionfontfamily"
                    value={this.state.positionfontfamily}
                    onChange={this.ChangeFontFamily}
                  >
                    <option value="Arial">Arial</option>
                    <option value="Arial Italic">Arial Italic</option>
                    <option value="Arial Bold">Arial Bold</option>
                    <option value="Arial Bold Italic">Arial Bold Italic</option>
                    <option value="Arial Black">Arial Black</option>
                    <option value="Bahnschrift ">Bahnschrift </option>
                    <option value="Calibri Light">Calibri Light</option>
                    <option value="Calibri Light Italic">
                      Calibri Light Italic
                    </option>
                    <option value="Calibri">Calibri</option>
                    <option value="Calibri Italic">Calibri Italic</option>
                    <option value="Calibri Bold">Calibri Bold</option>
                    <option value="Calibri Bold Italic">
                      Calibri Bold Italic
                    </option>
                    <option value="Cambria">Cambria</option>
                    <option value="Cambria Italic">Cambria Italic</option>
                    <option value="Cambria Bold">Cambria Bold</option>
                    <option value="Cambria Bold Italic">
                      Cambria Bold Italic
                    </option>
                    <option value="Cambria Math">Cambria Math</option>
                    <option value="Candara Light ">Candara Light </option>
                    <option value="Candara Light Italic ">
                      Candara Light Italic{" "}
                    </option>
                    <option value="Candara">Candara</option>
                    <option value="Candara Italic">Candara Italic</option>
                    <option value="Candara Bold">Candara Bold</option>
                    <option value="Candara Bold Italic">
                      Candara Bold Italic
                    </option>
                    <option value="Comic Sans MS">Comic Sans MS</option>
                    <option value="Comic Sans MS Italic">
                      Comic Sans MS Italic
                    </option>
                    <option value="Comic Sans MS Bold">
                      Comic Sans MS Bold
                    </option>
                    <option value="Comic Sans MS Bold Italic">
                      Comic Sans MS Bold Italic
                    </option>
                    <option value="Consolas">Consolas</option>
                    <option value="Consolas Italic">Consolas Italic</option>
                    <option value="Consolas Bold">Consolas Bold</option>
                    <option value="Consolas Bold Italic">
                      Consolas Bold Italic
                    </option>
                    <option value="Constantia">Constantia</option>
                    <option value="Constantia Italic">Constantia Italic</option>
                    <option value="Constantia Bold">Constantia Bold</option>
                    <option value="Constantia Bold Italic">
                      Constantia Bold Italic
                    </option>
                    <option value="Corbel Light ">Corbel Light </option>
                    <option value="Corbel Light Italic ">
                      Corbel Light Italic{" "}
                    </option>
                    <option value="Corbel">Corbel</option>
                    <option value="Corbel Italic">Corbel Italic</option>
                    <option value="Corbel Bold">Corbel Bold</option>
                    <option value="Corbel Bold Italic">
                      Corbel Bold Italic
                    </option>
                    <option value="Courier New">Courier New</option>
                    <option value="Courier New Italic">
                      Courier New Italic
                    </option>
                    <option value="Courier New Bold">Courier New Bold</option>
                    <option value="Courier New Bold Italic">
                      Courier New Bold Italic
                    </option>
                    <option value="Ebrima">Ebrima</option>
                    <option value="Ebrima Bold">Ebrima Bold</option>
                    <option value="Franklin Gothic Medium">
                      Franklin Gothic Medium
                    </option>
                    <option value="Franklin Gothic Medium Italic">
                      Franklin Gothic Medium Italic
                    </option>
                    <option value="Gabriola">Gabriola</option>
                    <option value="Gadugi">Gadugi</option>
                    <option value="Gadugi Bold">Gadugi Bold</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Georgia Italic">Georgia Italic</option>
                    <option value="Georgia Bold">Georgia Bold</option>
                    <option value="Georgia Bold Italic">
                      Georgia Bold Italic
                    </option>
                    <option value="HoloLens MDL2 Assets ">
                      HoloLens MDL2 Assets{" "}
                    </option>
                    <option value="Impact">Impact</option>
                    <option value="Ink Free ">Ink Free </option>
                    <option value="Javanese Text">Javanese Text</option>
                    <option value="Leelawadee UI">Leelawadee UI</option>
                    <option value="Leelawadee UI Semilight">
                      Leelawadee UI Semilight
                    </option>
                    <option value="Leelawadee UI Bold">
                      Leelawadee UI Bold
                    </option>
                    <option value="Lucida Console">Lucida Console</option>
                    <option value="Lucida Sans Unicode">
                      Lucida Sans Unicode
                    </option>
                    <option value="Malgun Gothic">Malgun Gothic</option>
                    <option value="Malgun Gothic Bold">
                      Malgun Gothic Bold
                    </option>
                    <option value="Malgun Gothic Semilight ">
                      Malgun Gothic Semilight{" "}
                    </option>
                    <option value="Marlett">Marlett</option>
                    <option value="Microsoft Himalaya">
                      Microsoft Himalaya
                    </option>
                    <option value="Microsoft JhengHei Light">
                      Microsoft JhengHei Light
                    </option>
                    <option value="Microsoft JhengHei">
                      Microsoft JhengHei
                    </option>
                    <option value="Microsoft JhengHei Bold">
                      Microsoft JhengHei Bold
                    </option>
                    <option value="Microsoft JhengHei UI Light">
                      Microsoft JhengHei UI Light
                    </option>
                    <option value="Microsoft JhengHei UI">
                      Microsoft JhengHei UI
                    </option>
                    <option value="Microsoft JhengHei UI Bold">
                      Microsoft JhengHei UI Bold
                    </option>
                    <option value="Microsoft New Tai Lue">
                      Microsoft New Tai Lue
                    </option>
                    <option value="Microsoft New Tai Lue Bold">
                      Microsoft New Tai Lue Bold
                    </option>
                    <option value="Microsoft PhagsPa">Microsoft PhagsPa</option>
                    <option value="Microsoft PhagsPa Bold">
                      Microsoft PhagsPa Bold
                    </option>
                    <option value="Microsoft Sans Serif">
                      Microsoft Sans Serif
                    </option>
                    <option value="Microsoft Tai Le">Microsoft Tai Le</option>
                    <option value="Microsoft Tai Le Bold">
                      Microsoft Tai Le Bold
                    </option>
                    <option value="Microsoft YaHei Light">
                      Microsoft YaHei Light
                    </option>
                    <option value="Microsoft YaHei">Microsoft YaHei</option>
                    <option value="Microsoft YaHei Bold">
                      Microsoft YaHei Bold
                    </option>
                    <option value="Microsoft YaHei UI Light">
                      Microsoft YaHei UI Light
                    </option>
                    <option value="Microsoft YaHei UI">
                      Microsoft YaHei UI
                    </option>
                    <option value="Microsoft YaHei UI Bold">
                      Microsoft YaHei UI Bold
                    </option>
                    <option value="Microsoft Yi Baiti">
                      Microsoft Yi Baiti
                    </option>
                    <option value="MingLiU-ExtB">MingLiU-ExtB</option>
                    <option value="PMingLiU-ExtB">PMingLiU-ExtB</option>
                    <option value="MingLiU_HKSCS-ExtB">
                      MingLiU_HKSCS-ExtB
                    </option>
                    <option value="Mongolian Baiti">Mongolian Baiti</option>
                    <option value="MS Gothic">MS Gothic</option>
                    <option value="MS PGothic">MS PGothic</option>
                    <option value="MS UI Gothic">MS UI Gothic</option>
                    <option value="MV Boli">MV Boli</option>
                    <option value="Myanmar Text">Myanmar Text</option>
                    <option value="Myanmar Text Bold">Myanmar Text Bold</option>
                    <option value="Nirmala UI Semilight">
                      Nirmala UI Semilight
                    </option>
                    <option value="Nirmala UI">Nirmala UI</option>
                    <option value="Nirmala UI Bold">Nirmala UI Bold</option>
                    <option value="Palatino Linotype">Palatino Linotype</option>
                    <option value="Palatino Linotype Italic">
                      Palatino Linotype Italic
                    </option>
                    <option value="Palatino Linotype Bold">
                      Palatino Linotype Bold
                    </option>
                    <option value="Palatino Linotype Bold Italic">
                      Palatino Linotype Bold Italic
                    </option>
                    <option value="Segoe MDL2 Assets ">
                      Segoe MDL2 Assets{" "}
                    </option>
                    <option value="Segoe Print">Segoe Print</option>
                    <option value="Segoe Print Bold">Segoe Print Bold</option>
                    <option value="Segoe Script">Segoe Script</option>
                    <option value="Segoe Script Bold">Segoe Script Bold</option>
                    <option value="Segoe UI Light">Segoe UI Light</option>
                    <option value="Segoe UI Light Italic">
                      Segoe UI Light Italic
                    </option>
                    <option value="Segoe UI Semilight">
                      Segoe UI Semilight
                    </option>
                    <option value="Segoe UI Semilight Italic">
                      Segoe UI Semilight Italic
                    </option>
                    <option value="Segoe UI">Segoe UI</option>
                    <option value="Segoe UI Italic">Segoe UI Italic</option>
                    <option value="Segoe UI Semibold">Segoe UI Semibold</option>
                    <option value="Segoe UI Semibold Italic">
                      Segoe UI Semibold Italic
                    </option>
                    <option value="Segoe UI Bold">Segoe UI Bold</option>
                    <option value="Segoe UI Bold Italic">
                      Segoe UI Bold Italic
                    </option>
                    <option value="Segoe UI Black">Segoe UI Black</option>
                    <option value="Segoe UI Black Italic">
                      Segoe UI Black Italic
                    </option>
                    <option value="Segoe UI Historic ">
                      Segoe UI Historic{" "}
                    </option>
                    <option value="Segoe UI Emoji">Segoe UI Emoji</option>
                    <option value="Segoe UI Symbol">Segoe UI Symbol</option>
                    <option value="SimSun">SimSun</option>
                    <option value="NSimSun">NSimSun</option>
                    <option value="SimSun-ExtB">SimSun-ExtB</option>
                    <option value="Sitka Small">Sitka Small</option>
                    <option value="Sitka Small Italic">
                      Sitka Small Italic
                    </option>
                    <option value="Sitka Small Bold">Sitka Small Bold</option>
                    <option value="Sitka Small Bold Italic">
                      Sitka Small Bold Italic
                    </option>
                    <option value="Sitka Text">Sitka Text</option>
                    <option value="Sitka Text Italic">Sitka Text Italic</option>
                    <option value="Sitka Text Bold">Sitka Text Bold</option>
                    <option value="Sitka Text Bold Italic">
                      Sitka Text Bold Italic
                    </option>
                    <option value="Sitka Subheading">Sitka Subheading</option>
                    <option value="Sitka Subheading Italic">
                      Sitka Subheading Italic
                    </option>
                    <option value="Sitka Subheading Bold">
                      Sitka Subheading Bold
                    </option>
                    <option value="Sitka Subheading Bold Italic">
                      Sitka Subheading Bold Italic
                    </option>
                    <option value="Sitka Heading">Sitka Heading</option>
                    <option value="Sitka Heading Italic">
                      Sitka Heading Italic
                    </option>
                    <option value="Sitka Heading Bold">
                      Sitka Heading Bold
                    </option>
                    <option value="Sitka Heading Bold Italic">
                      Sitka Heading Bold Italic
                    </option>
                    <option value="Sitka Display">Sitka Display</option>
                    <option value="Sitka Display Italic">
                      Sitka Display Italic
                    </option>
                    <option value="Sitka Display Bold">
                      Sitka Display Bold
                    </option>
                    <option value="Sitka Display Bold Italic">
                      Sitka Display Bold Italic
                    </option>
                    <option value="Sitka Banner">Sitka Banner</option>
                    <option value="Sitka Banner Italic">
                      Sitka Banner Italic
                    </option>
                    <option value="Sitka Banner Bold">Sitka Banner Bold</option>
                    <option value="Sitka Banner Bold Italic">
                      Sitka Banner Bold Italic
                    </option>
                    <option value="Sylfaen">Sylfaen</option>
                    <option value="Symbol">Symbol</option>
                    <option value="Tahoma">Tahoma</option>
                    <option value="Tahoma Bold">Tahoma Bold</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Times New Roman Italic">
                      Times New Roman Italic
                    </option>
                    <option value="Times New Roman Bold">
                      Times New Roman Bold
                    </option>
                    <option value="Times New Roman Bold Italic">
                      Times New Roman Bold Italic
                    </option>
                    <option value="Trebuchet MS">Trebuchet MS</option>
                    <option value="Trebuchet MS Italic">
                      Trebuchet MS Italic
                    </option>
                    <option value="Trebuchet MS Bold">Trebuchet MS Bold</option>
                    <option value="Trebuchet MS Bold Italic">
                      Trebuchet MS Bold Italic
                    </option>
                    <option value="Verdana">Verdana</option>
                    <option value="Verdana Italic">Verdana Italic</option>
                    <option value="Verdana Bold">Verdana Bold</option>
                    <option value="Verdana Bold Italic">
                      Verdana Bold Italic
                    </option>
                    <option value="Webdings">Webdings</option>
                    <option value="Wingdings">Wingdings</option>
                    <option value="Yu Gothic Light">Yu Gothic Light</option>
                    <option value="Yu Gothic Regular">Yu Gothic Regular</option>
                    <option value="Yu Gothic Medium ">Yu Gothic Medium </option>
                    <option value="Yu Gothic Bold">Yu Gothic Bold</option>
                    <option value="Yu Gothic UI Light ">
                      Yu Gothic UI Light{" "}
                    </option>
                    <option value="Yu Gothic UI Semilight ">
                      Yu Gothic UI Semilight{" "}
                    </option>
                    <option value="Yu Gothic UI Regular ">
                      Yu Gothic UI Regular{" "}
                    </option>
                    <option value="Yu Gothic UI Semibold ">
                      Yu Gothic UI Semibold{" "}
                    </option>
                    <option value="Yu Gothic UI Bold ">
                      Yu Gothic UI Bold{" "}
                    </option>
                  </Form.Select>
                </Col>
              </Row>
              <div className="clearfix he-1">&nbsp;</div>
              {/* font family */}
            </div>
          )}
          <div className="clearfix he-1">&nbsp;</div>
        </div>
        {/* 3 */}

        {/* 4 */}

        {/* <div className="d-grid gap-2">
            <InputGroup className={`mb-0 form-group ${this.errorClass(this.state.formErrors.dob)}`}>
              <Form.Control
                type="date"
                placeholder="Date of Birth"
                aria-label="Date Of Birth"
                aria-describedby="basic-addon2"
                name="dob"
                required
                className="form-control"
                placeholder="DOB"
                value={this.state.dob}
                onChange={this.handleUserInput}
              />
              <InputGroup.Text id="basic-addon2" className="p-0">
                <Button
                  onClick={() => this.hideComponent("showHidedob")}
                  style={{ borderRadius: "0", background: "#e9ecef", border: "none", color: "#666" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-grip-horizontal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                </Button>
              </InputGroup.Text>
            </InputGroup>
            <div className="clearfix he-1">&nbsp;</div>
            {showHidedob && (
              <div>
                <DashboardFieldOptionsComponent />
              </div>
            )}
            <div className="clearfix he-1">&nbsp;</div>
          </div> */}
        {/* 4 */}

        {/* <div className="clearfix">&nbsp;</div> */}

        {/* <div className="d-grid gap-2">
            <Row>
              <Col className="col-lg-9">
                <FormLabel className="label1 py-2">Personal Details</FormLabel>
              </Col>
              <Col>
                <Button
                  variant="outline-dark"
                  size="md"
                  type="button"
                  value="add more"
                  onClick={this.addClick.bind(this)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z" />
                  </svg>
                </Button>
              </Col>
            </Row>

            <div className="clearfix he-1">&nbsp;</div>
          </div> */}

        {/* 5 */}
        {/* <div className="clearfix">&nbsp;</div> */}
        <div className="d-grid gap-2">
          {this.props.card_details.phoneno != undefined && (
            <InputGroup
              className={`mb-0 form-group ${this.errorClass(
                this.state.formErrors.phoneno
              )}`}
            >
              <FormControl
                placeholder="Phone"
                aria-label="Phone"
                aria-describedby="basic-addon2"
                name="phoneno"
                required
                className="form-control"
                // value={this.state.phoneno || phoneno}
                onChange={this.handleUserInput}
                value={this.props.card_details.phoneno.text || ""}
              />
              <InputGroup.Text id="basic-addon2" className="p-0">
                <Button
                  onClick={() => this.hideComponent("showHidephoneno")}
                  style={{
                    borderRadius: "0",
                    background: "#e9ecef",
                    border: "none",
                    color: "#666",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-grip-horizontal"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                </Button>
              </InputGroup.Text>
            </InputGroup>
          )}
          <div className="clearfix he-1">&nbsp;</div>
          {this.state.showHidephoneno && (
            <div style={{ border: "solid 1px #666", padding: "10px" }}>
              {/* color */}
              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Color:</FormLabel>
                </Col>
                <Col>
                  <div className="d-grid gap-1">
                    <InputGroup className={`mb-0 form-group`}>
                      <Form.Control
                        type="color"
                        id="phonenocolorpicker"
                        name="phonenocolorpicker"
                        title="Choose your color"
                        value={this.state.phonenocolorpicker}
                        onChange={this.ColorChange}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              {/* color */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* fontsize */}

              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Size:</FormLabel>
                </Col>
                <Col>
                  <div className="d-grid gap-1">
                    <InputGroup className={`mb-0 form-group`}>
                      <FormControl
                        type="number"
                        min="8"
                        max="100"
                        aria-label="Size"
                        aria-describedby="basic-addon2"
                        name="phonenofontsize"
                        className="form-control"
                        placeholder="Size"
                        value={this.state.phonenofontsize}
                        onChange={this.FontSizeChange}
                      />
                    </InputGroup>
                    <div className="clearfix he-1">&nbsp;</div>
                  </div>
                </Col>
              </Row>
              {/* fontsize */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* styles */}
              <Row>
                <Col className="font-weight-bold ">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    name="fboldphoneno"
                    className={`col-12 ${
                      this.props.card_details.phoneno.fontBold ? "active" : ""
                    }`}
                    onClick={() => this.BoldChange("fboldphoneno")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-bold"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
                    </svg>
                  </Button>
                </Col>
                <Col className="font-weight-bold p-0">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    value="italic"
                    name="fitalicphoneno"
                    className={`col-12 ${
                      this.props.card_details.phoneno.fontItalic ? "active" : ""
                    }`}
                    onClick={() => this.ItalicChange("fitalicphoneno")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-italic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
                    </svg>
                  </Button>
                </Col>
                <Col className="font-weight-bold">
                  <Button
                    variant="outline-dark"
                    size="md"
                    type="button"
                    value="underline"
                    name="funderlinephoneno"
                    className={`col-12 ${
                      this.props.card_details.phoneno.fontUnderline
                        ? "active"
                        : ""
                    }`}
                    onClick={() => this.UnderlineChange("funderlinephoneno")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-type-underline"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57-1.709 0-2.687-1.08-2.687-2.57V3.136zM12.5 15h-9v-1h9v1z" />
                    </svg>
                  </Button>
                </Col>
                <div className="clearfix he-1">&nbsp;</div>
                {/* <Col className="font-weight-bold">

    <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="phonenoparaleft" onClick={() => this.ParaChange('phonenopara','left')} >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-left" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
          </Button>

         
    </Col>


    <Col className="font-weight-bold p-0">



          <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="phonenoparacenter" onClick={() => this.ParaChange('phonenopara','center')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-center" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
          </Button>

   
    </Col>


    <Col className="font-weight-bold">


          <Button variant="outline-dark" size="md" type='button' value='underline' className="col-12"  name="phonenopararight" onClick={() => this.ParaChange('phonenopara','right')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-text-right" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
</svg>
          </Button>
   
    </Col> */}
              </Row>
              {/* styles */}
              <div className="clearfix he-1">&nbsp;</div>
              {/* font family */}
              <Row>
                <Col className="font-weight-bold">
                  <FormLabel className="label1 py-2">Font:</FormLabel>
                </Col>
                <Col>
                  <Form.Select
                    id="phonenofontfamily"
                    name="phonenofontfamily"
                    value={this.state.phonenofontfamily}
                    onChange={this.ChangeFontFamily}
                  >
                    <option value="Arial">Arial</option>
                    <option value="Arial Italic">Arial Italic</option>
                    <option value="Arial Bold">Arial Bold</option>
                    <option value="Arial Bold Italic">Arial Bold Italic</option>
                    <option value="Arial Black">Arial Black</option>
                    <option value="Bahnschrift ">Bahnschrift </option>
                    <option value="Calibri Light">Calibri Light</option>
                    <option value="Calibri Light Italic">
                      Calibri Light Italic
                    </option>
                    <option value="Calibri">Calibri</option>
                    <option value="Calibri Italic">Calibri Italic</option>
                    <option value="Calibri Bold">Calibri Bold</option>
                    <option value="Calibri Bold Italic">
                      Calibri Bold Italic
                    </option>
                    <option value="Cambria">Cambria</option>
                    <option value="Cambria Italic">Cambria Italic</option>
                    <option value="Cambria Bold">Cambria Bold</option>
                    <option value="Cambria Bold Italic">
                      Cambria Bold Italic
                    </option>
                    <option value="Cambria Math">Cambria Math</option>
                    <option value="Candara Light ">Candara Light </option>
                    <option value="Candara Light Italic ">
                      Candara Light Italic{" "}
                    </option>
                    <option value="Candara">Candara</option>
                    <option value="Candara Italic">Candara Italic</option>
                    <option value="Candara Bold">Candara Bold</option>
                    <option value="Candara Bold Italic">
                      Candara Bold Italic
                    </option>
                    <option value="Comic Sans MS">Comic Sans MS</option>
                    <option value="Comic Sans MS Italic">
                      Comic Sans MS Italic
                    </option>
                    <option value="Comic Sans MS Bold">
                      Comic Sans MS Bold
                    </option>
                    <option value="Comic Sans MS Bold Italic">
                      Comic Sans MS Bold Italic
                    </option>
                    <option value="Consolas">Consolas</option>
                    <option value="Consolas Italic">Consolas Italic</option>
                    <option value="Consolas Bold">Consolas Bold</option>
                    <option value="Consolas Bold Italic">
                      Consolas Bold Italic
                    </option>
                    <option value="Constantia">Constantia</option>
                    <option value="Constantia Italic">Constantia Italic</option>
                    <option value="Constantia Bold">Constantia Bold</option>
                    <option value="Constantia Bold Italic">
                      Constantia Bold Italic
                    </option>
                    <option value="Corbel Light ">Corbel Light </option>
                    <option value="Corbel Light Italic ">
                      Corbel Light Italic{" "}
                    </option>
                    <option value="Corbel">Corbel</option>
                    <option value="Corbel Italic">Corbel Italic</option>
                    <option value="Corbel Bold">Corbel Bold</option>
                    <option value="Corbel Bold Italic">
                      Corbel Bold Italic
                    </option>
                    <option value="Courier New">Courier New</option>
                    <option value="Courier New Italic">
                      Courier New Italic
                    </option>
                    <option value="Courier New Bold">Courier New Bold</option>
                    <option value="Courier New Bold Italic">
                      Courier New Bold Italic
                    </option>
                    <option value="Ebrima">Ebrima</option>
                    <option value="Ebrima Bold">Ebrima Bold</option>
                    <option value="Franklin Gothic Medium">
                      Franklin Gothic Medium
                    </option>
                    <option value="Franklin Gothic Medium Italic">
                      Franklin Gothic Medium Italic
                    </option>
                    <option value="Gabriola">Gabriola</option>
                    <option value="Gadugi">Gadugi</option>
                    <option value="Gadugi Bold">Gadugi Bold</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Georgia Italic">Georgia Italic</option>
                    <option value="Georgia Bold">Georgia Bold</option>
                    <option value="Georgia Bold Italic">
                      Georgia Bold Italic
                    </option>
                    <option value="HoloLens MDL2 Assets ">
                      HoloLens MDL2 Assets{" "}
                    </option>
                    <option value="Impact">Impact</option>
                    <option value="Ink Free ">Ink Free </option>
                    <option value="Javanese Text">Javanese Text</option>
                    <option value="Leelawadee UI">Leelawadee UI</option>
                    <option value="Leelawadee UI Semilight">
                      Leelawadee UI Semilight
                    </option>
                    <option value="Leelawadee UI Bold">
                      Leelawadee UI Bold
                    </option>
                    <option value="Lucida Console">Lucida Console</option>
                    <option value="Lucida Sans Unicode">
                      Lucida Sans Unicode
                    </option>
                    <option value="Malgun Gothic">Malgun Gothic</option>
                    <option value="Malgun Gothic Bold">
                      Malgun Gothic Bold
                    </option>
                    <option value="Malgun Gothic Semilight ">
                      Malgun Gothic Semilight{" "}
                    </option>
                    <option value="Marlett">Marlett</option>
                    <option value="Microsoft Himalaya">
                      Microsoft Himalaya
                    </option>
                    <option value="Microsoft JhengHei Light">
                      Microsoft JhengHei Light
                    </option>
                    <option value="Microsoft JhengHei">
                      Microsoft JhengHei
                    </option>
                    <option value="Microsoft JhengHei Bold">
                      Microsoft JhengHei Bold
                    </option>
                    <option value="Microsoft JhengHei UI Light">
                      Microsoft JhengHei UI Light
                    </option>
                    <option value="Microsoft JhengHei UI">
                      Microsoft JhengHei UI
                    </option>
                    <option value="Microsoft JhengHei UI Bold">
                      Microsoft JhengHei UI Bold
                    </option>
                    <option value="Microsoft New Tai Lue">
                      Microsoft New Tai Lue
                    </option>
                    <option value="Microsoft New Tai Lue Bold">
                      Microsoft New Tai Lue Bold
                    </option>
                    <option value="Microsoft PhagsPa">Microsoft PhagsPa</option>
                    <option value="Microsoft PhagsPa Bold">
                      Microsoft PhagsPa Bold
                    </option>
                    <option value="Microsoft Sans Serif">
                      Microsoft Sans Serif
                    </option>
                    <option value="Microsoft Tai Le">Microsoft Tai Le</option>
                    <option value="Microsoft Tai Le Bold">
                      Microsoft Tai Le Bold
                    </option>
                    <option value="Microsoft YaHei Light">
                      Microsoft YaHei Light
                    </option>
                    <option value="Microsoft YaHei">Microsoft YaHei</option>
                    <option value="Microsoft YaHei Bold">
                      Microsoft YaHei Bold
                    </option>
                    <option value="Microsoft YaHei UI Light">
                      Microsoft YaHei UI Light
                    </option>
                    <option value="Microsoft YaHei UI">
                      Microsoft YaHei UI
                    </option>
                    <option value="Microsoft YaHei UI Bold">
                      Microsoft YaHei UI Bold
                    </option>
                    <option value="Microsoft Yi Baiti">
                      Microsoft Yi Baiti
                    </option>
                    <option value="MingLiU-ExtB">MingLiU-ExtB</option>
                    <option value="PMingLiU-ExtB">PMingLiU-ExtB</option>
                    <option value="MingLiU_HKSCS-ExtB">
                      MingLiU_HKSCS-ExtB
                    </option>
                    <option value="Mongolian Baiti">Mongolian Baiti</option>
                    <option value="MS Gothic">MS Gothic</option>
                    <option value="MS PGothic">MS PGothic</option>
                    <option value="MS UI Gothic">MS UI Gothic</option>
                    <option value="MV Boli">MV Boli</option>
                    <option value="Myanmar Text">Myanmar Text</option>
                    <option value="Myanmar Text Bold">Myanmar Text Bold</option>
                    <option value="Nirmala UI Semilight">
                      Nirmala UI Semilight
                    </option>
                    <option value="Nirmala UI">Nirmala UI</option>
                    <option value="Nirmala UI Bold">Nirmala UI Bold</option>
                    <option value="Palatino Linotype">Palatino Linotype</option>
                    <option value="Palatino Linotype Italic">
                      Palatino Linotype Italic
                    </option>
                    <option value="Palatino Linotype Bold">
                      Palatino Linotype Bold
                    </option>
                    <option value="Palatino Linotype Bold Italic">
                      Palatino Linotype Bold Italic
                    </option>
                    <option value="Segoe MDL2 Assets ">
                      Segoe MDL2 Assets{" "}
                    </option>
                    <option value="Segoe Print">Segoe Print</option>
                    <option value="Segoe Print Bold">Segoe Print Bold</option>
                    <option value="Segoe Script">Segoe Script</option>
                    <option value="Segoe Script Bold">Segoe Script Bold</option>
                    <option value="Segoe UI Light">Segoe UI Light</option>
                    <option value="Segoe UI Light Italic">
                      Segoe UI Light Italic
                    </option>
                    <option value="Segoe UI Semilight">
                      Segoe UI Semilight
                    </option>
                    <option value="Segoe UI Semilight Italic">
                      Segoe UI Semilight Italic
                    </option>
                    <option value="Segoe UI">Segoe UI</option>
                    <option value="Segoe UI Italic">Segoe UI Italic</option>
                    <option value="Segoe UI Semibold">Segoe UI Semibold</option>
                    <option value="Segoe UI Semibold Italic">
                      Segoe UI Semibold Italic
                    </option>
                    <option value="Segoe UI Bold">Segoe UI Bold</option>
                    <option value="Segoe UI Bold Italic">
                      Segoe UI Bold Italic
                    </option>
                    <option value="Segoe UI Black">Segoe UI Black</option>
                    <option value="Segoe UI Black Italic">
                      Segoe UI Black Italic
                    </option>
                    <option value="Segoe UI Historic ">
                      Segoe UI Historic{" "}
                    </option>
                    <option value="Segoe UI Emoji">Segoe UI Emoji</option>
                    <option value="Segoe UI Symbol">Segoe UI Symbol</option>
                    <option value="SimSun">SimSun</option>
                    <option value="NSimSun">NSimSun</option>
                    <option value="SimSun-ExtB">SimSun-ExtB</option>
                    <option value="Sitka Small">Sitka Small</option>
                    <option value="Sitka Small Italic">
                      Sitka Small Italic
                    </option>
                    <option value="Sitka Small Bold">Sitka Small Bold</option>
                    <option value="Sitka Small Bold Italic">
                      Sitka Small Bold Italic
                    </option>
                    <option value="Sitka Text">Sitka Text</option>
                    <option value="Sitka Text Italic">Sitka Text Italic</option>
                    <option value="Sitka Text Bold">Sitka Text Bold</option>
                    <option value="Sitka Text Bold Italic">
                      Sitka Text Bold Italic
                    </option>
                    <option value="Sitka Subheading">Sitka Subheading</option>
                    <option value="Sitka Subheading Italic">
                      Sitka Subheading Italic
                    </option>
                    <option value="Sitka Subheading Bold">
                      Sitka Subheading Bold
                    </option>
                    <option value="Sitka Subheading Bold Italic">
                      Sitka Subheading Bold Italic
                    </option>
                    <option value="Sitka Heading">Sitka Heading</option>
                    <option value="Sitka Heading Italic">
                      Sitka Heading Italic
                    </option>
                    <option value="Sitka Heading Bold">
                      Sitka Heading Bold
                    </option>
                    <option value="Sitka Heading Bold Italic">
                      Sitka Heading Bold Italic
                    </option>
                    <option value="Sitka Display">Sitka Display</option>
                    <option value="Sitka Display Italic">
                      Sitka Display Italic
                    </option>
                    <option value="Sitka Display Bold">
                      Sitka Display Bold
                    </option>
                    <option value="Sitka Display Bold Italic">
                      Sitka Display Bold Italic
                    </option>
                    <option value="Sitka Banner">Sitka Banner</option>
                    <option value="Sitka Banner Italic">
                      Sitka Banner Italic
                    </option>
                    <option value="Sitka Banner Bold">Sitka Banner Bold</option>
                    <option value="Sitka Banner Bold Italic">
                      Sitka Banner Bold Italic
                    </option>
                    <option value="Sylfaen">Sylfaen</option>
                    <option value="Symbol">Symbol</option>
                    <option value="Tahoma">Tahoma</option>
                    <option value="Tahoma Bold">Tahoma Bold</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Times New Roman Italic">
                      Times New Roman Italic
                    </option>
                    <option value="Times New Roman Bold">
                      Times New Roman Bold
                    </option>
                    <option value="Times New Roman Bold Italic">
                      Times New Roman Bold Italic
                    </option>
                    <option value="Trebuchet MS">Trebuchet MS</option>
                    <option value="Trebuchet MS Italic">
                      Trebuchet MS Italic
                    </option>
                    <option value="Trebuchet MS Bold">Trebuchet MS Bold</option>
                    <option value="Trebuchet MS Bold Italic">
                      Trebuchet MS Bold Italic
                    </option>
                    <option value="Verdana">Verdana</option>
                    <option value="Verdana Italic">Verdana Italic</option>
                    <option value="Verdana Bold">Verdana Bold</option>
                    <option value="Verdana Bold Italic">
                      Verdana Bold Italic
                    </option>
                    <option value="Webdings">Webdings</option>
                    <option value="Wingdings">Wingdings</option>
                    <option value="Yu Gothic Light">Yu Gothic Light</option>
                    <option value="Yu Gothic Regular">Yu Gothic Regular</option>
                    <option value="Yu Gothic Medium ">Yu Gothic Medium </option>
                    <option value="Yu Gothic Bold">Yu Gothic Bold</option>
                    <option value="Yu Gothic UI Light ">
                      Yu Gothic UI Light{" "}
                    </option>
                    <option value="Yu Gothic UI Semilight ">
                      Yu Gothic UI Semilight{" "}
                    </option>
                    <option value="Yu Gothic UI Regular ">
                      Yu Gothic UI Regular{" "}
                    </option>
                    <option value="Yu Gothic UI Semibold ">
                      Yu Gothic UI Semibold{" "}
                    </option>
                    <option value="Yu Gothic UI Bold ">
                      Yu Gothic UI Bold{" "}
                    </option>
                  </Form.Select>
                </Col>
              </Row>
              <div className="clearfix he-1">&nbsp;</div>
              {/* font family */}
            </div>
          )}
          <div className="clearfix he-1">&nbsp;</div>
        </div>
        {/* <div className="clearfix">&nbsp;</div> */}
        <div className="d-grid gap-2">
          {/* <FormLabel className="label1">Template Name</FormLabel> */}
          <InputGroup className={`mb-0 form-group}`}>
            <select
              className="form-control"
              id="orientation"
              name="orientation"
              defaultValue="default"
              value={this.props.card_details.orientation}
              onChange={this.handleUserInputData}
              required
            >
              <option disabled value="default">
                {" "}
                Orientation{" "}
              </option>
              <option value="landscape">Landscape</option>
              <option value="portrait">Portrait</option>
            </select>
          </InputGroup>
        </div>
        <div className="clearfix">&nbsp;</div>
        <div className="d-grid gap-2">
          <div className="col-12 d-grid gap-2">
            {/* <FormLabel className="label1">Template Name</FormLabel> */}
            <InputGroup className={`mb-0 form-group}`}>
              <FormControl
                // placeholder="Template Name"
                aria-label="Tname"
                name="Tname"
                required
                className="form-control"
                placeholder="Enter Template Name"
                value={this.props.card_details.Tname}
                onChange={this.handleUserInputData}
              />
            </InputGroup>
          </div>
        </div>
        <div className="clearfix">&nbsp;</div>
        <div className="row">
          <div className="col-6 d-grid gap-2">
            {/* <FormLabel className="label1">Template Name</FormLabel> */}
            <InputGroup className={`mb-0 form-group}`}>
              <FormControl
                // placeholder="Template Amount"
                aria-label="amount"
                name="amount"
                required
                className="form-control"
                placeholder="Enter Price"
                value={this.props.card_details.amount}
                onChange={this.handleUserInputData}
              />
            </InputGroup>
          </div>
          <div className="col-6 d-grid gap-2">
            {/* <FormLabel className="label1">Template Name</FormLabel> */}
            <InputGroup className={`mb-0 form-group}`}>
              <select
                className="form-control"
                id="status"
                name="status"
                defaultValue="default"
                value={this.props.card_details.status}
                onChange={this.handleUserInputData}
                required
              >
                <option disabled value="default">
                  {" "}
                  Status{" "}
                </option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </InputGroup>
          </div>
          <div className="clearfix">&nbsp;</div>
          <div className="col-12 d-grid gap-2">
            {/* <FormLabel className="label1">Template Name</FormLabel> */}
            <InputGroup className={`mb-0 form-group}`}>
              <select
                className="form-control"
                id="category_id"
                name="category_id"
                defaultValue="default"
                value={this.props.card_details.category_id}
                onChange={this.handleUserInputData}
                required
              >
                <option disabled value="default">
                  {" "}
                  select Category{" "}
                </option>
                {this.state.categorylist.map(function (item, i) {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                }, this)}
              </select>
            </InputGroup>
          </div>
        </div>
        {/* 5 */}
        <div className="variablebox" style={{ maxHeight: "100%" }}>
          {/* {this.createUI()} */}
        </div>
        {/* </form> */}
        {this.props.children}
        <div className="clearfix">&nbsp;</div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    card_details: state.card_data,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      UpdateFieldValue,
      UpdateFieldValueData,
      RemoveExtraField,
      AddExtraField,
      ChangeExtraField,
      ColorChange,
      BoldChange,
      FontSizeChange,
      ItalicChange,
      UnderlineChange,
      ParaChange,
      ChangeFontFamily,
      showHideExtra,
      ChangeExtraFieldColor,
      ChangeExtraFieldFontSize,
      ChangeExtraFieldFontFamily,
      ChangeExtraFieldBold,
      ChangeExtraFieldItalic,
      ChangeExtraFieldUnderline,
      ChangeFrontBackExtraField,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardTextFieldComponent);
