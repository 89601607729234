import React, { Component, useState } from 'react';
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import './dashboard.css';
class DashboardComponents extends Component{
 
    render() {

        return (
          <div>
              <TopBarComponent/>
              <div className="container-fluid">
  <div className="row">
    
  <SideBarComponent/>              
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div className="container rounded bg-white m-1 mt-3 p-0">
    
    <div className="row">
        
        <div className="col-lg-3 col-md-3">
        <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm border-primary text-center">
          
          <div className="card-body">
              <img src="/images/shop.png" style={{width:"80%",margin:"0 auto"}} className="mb-3" />
            <h1 className="card-title pricing-card-title"><sup>$</sup>29<small className="text-muted fw-light" style={{fontSize:"18px"}}>/mo</small></h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>30 users included</li>
              <li>15 GB of storage</li>
              <li>Phone and email support</li>
              <li>Help center access</li>
            </ul>
            <button type="button" className="w-100 btn btn-lg btn-primary">Buy Addon Services</button>
          </div>
        </div>
      </div>
        </div>

        <div className="col-lg-9 col-md-5 border-right">
        <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm border-primary">
          
          <div className="card-body">
          <h2 className="card-title pricing-card-title">Yearly Subscription</h2>  
          <p className="card-title pricing-card-title">Renew Date: 28 March 2022</p>  
        <div className="col p-2" style={{fontWeight:"bold",border:"solid 1px #ccc",borderRadius:"3px"}}>Main License 1 Year Subscription  
        <span className="float-right" style={{display:"inline-block",float:"right"}}>$ 1,400 + VAT</span>
        </div>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm border-primary">
          
          <div className="card-body">
          
        <div className="col p-2" style={{fontWeight:"bold",borderRadius:"3px"}}>
        <div className="row">
            <div className="col">
              <h5 className="card-title pricing-card-title" style={{fontSize:"18px"}}>Last Payment</h5>  
                <p className="card-title pricing-card-title" style={{fontSize:"14px",color:"#ccc"}}>Payment failed</p>  
            </div>

            <div className="col">
            <div className="card p-3">
                    <div className="row">
                        <div className="col"><div className="number"> <label className="fw-bold" for="">**** **** **** 1060</label> </div></div>
                        <div className="col-3"><div className="img-box" > <img src="https://www.freepnglogos.com/uploads/visa-logo-download-png-21.png" alt="" style={{width:"100%"}} /> </div></div>
                    </div>
            </div>
            </div>

            <div className="col">
            <span className="float-right" style={{display:"inline-block",float:"right"}}>$ 1,000 + VAT</span>
            </div>
        </div>

        <div className="row mt-2 pt-3" style={{borderTop:"solid 1px #ccc"}}>
            <div className="col">
              <h5 className="card-title pricing-card-title" style={{fontSize:"18px"}}>Next Payment</h5>  
                <p className="card-title pricing-card-title" style={{fontSize:"14px",color:"#ccc"}}>28 March 2022</p>  
            </div>

            <div className="col">
            <div className="card p-3" style={{display:"none"}}>
                    <div className="row">
                        <div className="col"><div className="number"> <label className="fw-bold" for="">**** **** **** 1060</label> </div></div>
                        <div className="col-3"><div className="img-box" > <img src="https://www.freepnglogos.com/uploads/visa-logo-download-png-21.png" alt="" style={{width:"100%"}} /> </div></div>
                    </div>
            </div>
            </div>

            <div className="col">
            <span className="float-right" style={{display:"inline-block",float:"right"}}>$ 13,00</span>
            </div>
        </div>

        <div className="row mt-2 pt-3" style={{borderTop:"solid 1px #ccc"}}>
            <div className="col">
              <h5 className="card-title pricing-card-title" style={{fontSize:"18px"}}>Change Payment Method</h5>  
            </div>

            <div className="col">
            <div className="card p-3" style={{display:"none"}}>
                    <div className="row">
                        <div className="col"><div className="number"> <label className="fw-bold" for="">**** **** **** 1060</label> </div></div>
                        <div className="col-3"><div className="img-box" > <img src="https://www.freepnglogos.com/uploads/visa-logo-download-png-21.png" alt="" style={{width:"100%"}} /> </div></div>
                    </div>
            </div>
            </div>

            <div className="col">
            <span className="float-right" style={{display:"inline-block",float:"right"}}>Payment History</span>
            </div>
        </div>
        
        </div>
          </div>
        </div>
      </div>
        </div>


    </div>    

    <div className="row">
        
        <div className="col-lg-12 col-md-5 p-3 border-right card">
            <div className="p-1">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="text-right">Billing</h5>
                    <span className="float-right" style={{display:"inline-block",float:"right"}}>Change Billing</span>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <h5>Corporate Name</h5>
                        <p>ABC Corp.</p>
                    </div>
                    <div className="col">
                        <h5>Address</h5>
                        <p>123 Street, Block 1, North Star.</p>
                    </div>
                    
                    <div className="col">
                        <h5>Tax Department</h5>
                        <p>ABC.</p>
                    </div>

                    <div className="col">
                        <h5>Tax ID</h5>
                        <p>326999</p>
                    </div>
                </div>

            </div>
        </div>
             
        <div className="clearfix">&nbsp;</div>
                <div className="clearfix">&nbsp;</div>
                <div className="clearfix">&nbsp;</div>        
    </div>
</div>

      

      
      
    </main>
  </div>
</div>
      
          </div>
       

        )
    }
}
export default DashboardComponents;
