import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  DeleteUserData,
  getprofiledetailsbyid,
  userAccessControl,
  userDelete,
  emailaddresscheck,
  updateuserprofileAdmin,
  passwordChange,
} from "../apiserve/ApiComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import axios from "axios";
import "./dashboard.css";
import { Box } from "react-bootstrap-icons";
class DashboardComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myprofiledata: [],
      response: "",
      responsetype: "",
      isLoading: false,
      fname: "",
      lname: "",
      userid: "",
      // userfid:{id:''},
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/profile/",
      email: "",
      dUserId: "",
      phoneno: "",
      show: false,
      showD: false,
      showU: false,
      address1: "",
      address2: "",
      postcode: "",
      state: "",
      // area:'',
      // education:'',
      country: "",
      password: "",
      confirmpassword: "",
      uprofileimg: "",
      response: "",
      responsetype: "",
      resp: "",
      isLoading: false,
      formErrors: {
        fname: "",
        lname: "",
        email: "",
        phoneno: "",
        address1: "",
        address2: "",
        postcode: "",
        state: "",
        // area:'',
        // education:'',
        country: "",
      },
      fnameValid: false,
      lnameValid: false,
      emailValid: false,
      phonenoValid: false,
      address1Valid: false,
      address2Valid: false,
      postcodeValid: false,
      stateValid: false,
      // areaValid:false,
      // educationValid:false,
      countryValid: false,
      formValid: false,
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.checkvaliddata = this.checkvaliddata.bind(this);
    // this.validateForm  = this.validateForm.bind(this);
    // this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.userAccessControl = this.userAccessControl.bind(this);
    this.userDelete = this.userDelete.bind(this);
    this.userUpdate = this.userUpdate.bind(this);
    this.DeleteUserData = this.DeleteUserData.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleShowDelete = this.handleShowDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
    this.setState({ showD: false });
    this.setState({ showU: false });
  }

  handleShow(type) {
    if (type == "active") {
      this.setState({ show: true });
    } else if (type == "update") {
      this.setState({ showU: true });
    } else if (this.state.isDelete == 1) {
      this.setState({ response: "User already deleted can't delete again." });
      this.setState({ responsetype: "error" });
    } else {
      this.setState({ showD: true });
    }
  }

  handleShowDelete() {
    console.log(this.state.isDelete);
    if (this.state.isDelete == 1) {
      this.setState({ response: "User already deleted can't delete again." });
      this.setState({ responsetype: "error" });
    } else {
      this.setState({ showD: true });
    }
  }

  getprofiledetailsbyid(userid) {
    getprofiledetailsbyid(
      userid,
      (res) => {
        let profiledata = res.data.data[0][0];
        this.setState({ myprofiledata: profiledata });
        this.setState({ fname: profiledata.fname });
        this.setState({ dUserId: profiledata.id });
        this.setState({ lname: profiledata.lname });
        this.setState({ email: profiledata.email });
        this.setState({ isActive: profiledata.isActive });
        this.setState({ isDelete: profiledata.isDelete });
        this.setState({ phoneno: profiledata.phoneno });
        this.setState({ address1: profiledata.address1 });
        this.setState({ address2: profiledata.address2 });
        this.setState({ state: profiledata.state });
        this.setState({ area: profiledata.area });
        this.setState({ postcode: profiledata.postcode });
        // this.setState({education:profiledata.education});
        this.setState({ country: profiledata.country });
        this.setState({ uprofileimg: profiledata.uprofileimg });

        console.log(profiledata);

        this.validateField("fname", this.state.fname);
        this.validateField("lname", this.state.lname);
        this.validateField("email", this.state.email);
        this.validateField("phoneno", this.state.phoneno);
        this.validateField("address1", this.state.address1);
        this.validateField("state", this.state.state);
        this.validateField("postcode", this.state.postcode);
        this.validateField("country", this.state.country);
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading profile data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    let updatedata = this.state.myprofiledata;
    updatedata[name] = value;
    this.setState({ myprofiledata: updatedata }, () => {
      this.validateField(name, value);
    });
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  updatePassword(e) {
    if (
      this.state.password == this.state.confirmpassword &&
      this.state.password != ""
    ) {
      let pass = { password: "", user: "" };
      pass.password = this.state.password;
      pass.user = this.state.myprofiledata.id;

      passwordChange(pass, (res) => {
        if (res.data.success == true) {
          this.setState({ response: "Password updated successfully" });
          this.setState({ responsetype: "success" });
        }
      });
    } else {
      this.setState({ response: "Password & Confirm Password must match" });
      this.setState({ responsetype: "error" });
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let vpasswordValid = this.state.vpasswordValid;
    let fnameValid = this.state.fnameValid;
    let lnameValid = this.state.lnameValid;
    let phonenoValid = this.state.phonenoValid;
    let emailValid = this.state.emailValid;
    let address1Valid = this.state.address1Valid;
    let postcodeValid = this.state.postcodeValid;
    let stateValid = this.state.stateValid;
    // let areaValid=this.state.areaValid;
    // let educationValid=this.state.educationValid;
    let countryValid = this.state.countryValid;
    switch (fieldName) {
      case "fname":
        fnameValid = value.length >= 3;
        fieldValidationErrors.fname = fnameValid ? "" : " is too short";
        break;

      case "lname":
        lnameValid = value.length >= 3;
        fieldValidationErrors.lname = lnameValid ? "" : " is too short";
        break;

      case "phoneno":
        phonenoValid = value.length >= 8;
        fieldValidationErrors.phoneno = phonenoValid ? "" : " is too short";
        break;

      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;

      case "address1":
        if (value != null) address1Valid = value.length >= 3;
        fieldValidationErrors.address1 = address1Valid ? "" : " is too short";
        break;

      case "postcode":
        if (value != null) postcodeValid = value.length >= 3;
        fieldValidationErrors.postcode = postcodeValid ? "" : " is too short";
        break;

      case "state":
        if (value != null) stateValid = value.length >= 2;
        fieldValidationErrors.state = stateValid ? "" : " is too short";
        break;

      case "country":
        if (value != null) countryValid = value.length >= 3;
        fieldValidationErrors.country = countryValid ? "" : " is too short";
        break;

      case "password":
        if (value == null) fieldValidationErrors.password = " can't be empty";
        break;

      case "confirmpassword":
        if (value == null)
          fieldValidationErrors.confirmpassword = " can't be empty";
        break;

      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        fnameValid: fnameValid,
        lnameValid: lnameValid,
        phonenoValid: phonenoValid,
        emailValid: emailValid,
        address1Valid: address1Valid,
        postcodeValid: postcodeValid,
        stateValid: stateValid,
        // areaValid: areaValid,
        // educationValid: educationValid,
        countryValid: countryValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    if (
      this.state.fnameValid &&
      this.state.lnameValid &&
      this.state.phonenoValid &&
      this.state.emailValid &&
      this.state.address1Valid &&
      this.state.postcodeValid &&
      this.state.stateValid &&
      // this.state.areaValid &&
      // this.state.educationValid &&
      this.state.countryValid
    ) {
      this.state.formValid = true;
    }
  }

  handleOnSubmit = (e) => {
    this.validateForm();
    e.preventDefault();
    let updatedata = this.state.myprofiledata;
    updatedata["token"] = localStorage.getItem("token");
    this.setState({ myprofiledata: updatedata });
    const { myprofiledata } = this.state;
    this.setState({ isLoading: true });

    if (this.state.formValid == true) {
      updateuserprofileAdmin(
        myprofiledata,
        (res) => {
          if (res.data.success == true || res.success == true) {
            this.setState({
              response: res.data.message || "Profile updated successfully.",
            });
            this.setState({ responsetype: "success" });
            this.setState({ isLoading: false });
          } else {
            this.setState({
              response:
                "Profile update failed due to" + JSON.stringify(res.data.error),
            });
            this.setState({ responsetype: "error" });
            this.setState({ isLoading: false });
          }
        },
        (err) => {
          this.setState({ response: +JSON.stringify(err) });
          this.setState({ isLoading: false });
        }
      );
    } else {
      this.setState({
        response:
          "All fields are mandatory. Please fill all the required fields.",
      });
      this.setState({ responsetype: "error" });
      this.setState({ isLoading: false });
    }
  };

  emailaddresscheck() {
    emailaddresscheck(
      this.state.email,
      this.state.myprofiledata.id,
      (res) => {
        console.log(res);
        if (res.data.success == false) {
          this.setState({ response: res.data.message });
          this.setState({ responsetype: "error" });
        } else {
          this.setState({ response: "Email Address Valid" });
          this.setState({ responsetype: "success" });
        }
        this.setState({ emailValid: false }, this.validateForm);
      },
      (err) => {
        // console.log(err);
        this.setState({ response: "Error in loading profile data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  // Delete user profile;
  DeleteUserData() {
    // else{
    let userfid;
    userfid = this.state.dUserId;
    DeleteUserData(
      userfid,
      (res) => {
        console.log(res);
        this.setState({ response: "User profile deleted successfully." });
        this.setState({ responsetype: "success" });
        setTimeout(function () {
          window.location.href = "/users";
        }, 1000);
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading profile data." });
        this.setState({ responsetype: "error" });
      }
    );
    // }
  }
  // Accerss control user profile;
  userAccessControl() {
    const { myprofiledata } = this.state;

    let userObj = {};
    userObj["id"] = myprofiledata.id;
    userObj["fname"] = myprofiledata.fname;
    userObj["lname"] = myprofiledata.lname;
    userObj["address1"] = myprofiledata.address1;
    userObj["address2"] = myprofiledata.address2;
    userObj["area"] = myprofiledata.area;
    userObj["country"] = myprofiledata.country;
    userObj["email"] = myprofiledata.email;
    userObj["phoneno"] = myprofiledata.phoneno;
    userObj["postcode"] = myprofiledata.postcode;
    userObj["state"] = myprofiledata.state;
    userObj["email_verified_at"] = myprofiledata.email_verified_at;
    userObj["remember_token"] = myprofiledata.remember_token;
    userObj["user_role"] = myprofiledata.user_role;
    userObj["education"] = myprofiledata.education;
    userObj["billingaddress1"] = myprofiledata.billingaddress1;
    userObj["billingaddress2"] = myprofiledata.billingaddress2;
    userObj["uprofileimg"] = myprofiledata.uprofileimg;
    userObj["created_at"] = myprofiledata.created_at;
    userObj["updated_at"] = myprofiledata.updated_at;
    userObj["isVerified"] = myprofiledata.isVerified;
    userObj["verification_token"] = myprofiledata.verification_token;
    userObj["isActive"] = 1;
    userObj["isDelete"] = 0;
    userObj["token"] = myprofiledata.token;
    userObj["password"] = "";
    userObj["confirmpassword"] = "";

    const userData = [];
    userData.push(userObj);

    this.setState({ isLoading: true });
    updateuserprofileAdmin(
      userData,
      (res) => {
        this.setState({ show: false });
        console.log(res);
        this.setState({ response: "User data updated successfully." });
        this.setState({ responsetype: "success" });
        setTimeout(function () {
          window.location.href = "?id=" + myprofiledata.id;
        }, 1000);
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading profile data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  // Delete user profile;
  userDelete() {
    const { myprofiledata } = this.state;

    let userObj = {};
    userObj["id"] = myprofiledata.id;
    userObj["fname"] = myprofiledata.fname;
    userObj["lname"] = myprofiledata.lname;
    userObj["address1"] = myprofiledata.address1;
    userObj["address2"] = myprofiledata.address2;
    userObj["area"] = myprofiledata.area;
    userObj["country"] = myprofiledata.country;
    userObj["email"] = myprofiledata.email;
    userObj["phoneno"] = myprofiledata.phoneno;
    userObj["postcode"] = myprofiledata.postcode;
    userObj["state"] = myprofiledata.state;
    userObj["email_verified_at"] = myprofiledata.email_verified_at;
    userObj["remember_token"] = myprofiledata.remember_token;
    userObj["user_role"] = myprofiledata.user_role;
    userObj["education"] = myprofiledata.education;
    userObj["billingaddress1"] = myprofiledata.billingaddress1;
    userObj["billingaddress2"] = myprofiledata.billingaddress2;
    userObj["uprofileimg"] = myprofiledata.uprofileimg;
    userObj["created_at"] = myprofiledata.created_at;
    userObj["updated_at"] = myprofiledata.updated_at;
    userObj["isVerified"] = myprofiledata.isVerified;
    userObj["verification_token"] = myprofiledata.verification_token;
    userObj["isActive"] = myprofiledata.isActive;
    userObj["isDelete"] = 1;
    userObj["token"] = myprofiledata.token;
    userObj["password"] = "";

    const userData = [];
    userData.push(userObj);
    console.log(userObj);

    this.setState({ isLoading: true });
    updateuserprofileAdmin(
      userData,
      (res) => {
        this.setState({ showD: false });
        console.log(res);
        this.setState({ response: "User deleted successfully." });
        this.setState({ responsetype: "success" });
        setTimeout(function () {
          window.location.href = "/admin/users";
        }, 1000);
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading profile data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  checkvaliddata() {
    if (this.state.fname) {
      this.state.fnameValid = true;
    }
    if (this.state.lname) {
      this.state.lnameValid = true;
    }
    if (this.state.email) {
      this.state.emailValid = true;
    }
    if (this.state.phoneno) {
      this.state.phonenoValid = true;
    }
  }

  // Update user profile;
  userUpdate() {
    this.checkvaliddata();
    this.validateForm();
    let updatedata = this.state.myprofiledata;
    updatedata["token"] = localStorage.getItem("token");
    this.setState({ myprofiledata: updatedata });
    const { myprofiledata } = this.state;
    this.setState({ isLoading: true });

    let userObj = {};
    userObj["id"] = myprofiledata.id;
    userObj["fname"] = myprofiledata.fname;
    userObj["lname"] = myprofiledata.lname;
    userObj["address1"] = myprofiledata.address1;
    userObj["address2"] = myprofiledata.address2;
    userObj["area"] = myprofiledata.area;
    userObj["country"] = myprofiledata.country;
    userObj["email"] = myprofiledata.email;
    userObj["phoneno"] = myprofiledata.phoneno;
    userObj["postcode"] = myprofiledata.postcode;
    userObj["state"] = myprofiledata.state;
    userObj["email_verified_at"] = myprofiledata.email_verified_at;
    userObj["remember_token"] = myprofiledata.remember_token;
    userObj["user_role"] = myprofiledata.user_role;
    userObj["education"] = myprofiledata.education;
    userObj["billingaddress1"] = myprofiledata.billingaddress1;
    userObj["billingaddress2"] = myprofiledata.billingaddress2;
    userObj["uprofileimg"] = myprofiledata.uprofileimg;
    userObj["created_at"] = myprofiledata.created_at;
    userObj["updated_at"] = myprofiledata.updated_at;
    userObj["isVerified"] = myprofiledata.isVerified;
    userObj["verification_token"] = myprofiledata.verification_token;
    userObj["isActive"] = myprofiledata.isActive;
    userObj["isDelete"] = myprofiledata.isDelete;
    userObj["token"] = myprofiledata.token;
    userObj["password"] = "";

    const userData = [];
    userData.push(userObj);

    if (this.state.formValid == true) {
      updateuserprofileAdmin(
        userData,
        (res) => {
          console.log(res);
          if (res.data.success == true || res.success == true) {
            this.setState({
              response: res.data.message || "Profile updated successfully.",
            });
            this.setState({ responsetype: "success" });
            this.handleClose();
            this.setState({ isLoading: false });
            setTimeout(function () {
              window.location.href = "/admin/users";
            }, 500);
          } else {
            this.setState({
              response:
                "Profile update failed due to" + JSON.stringify(res.data.error),
            });
            this.setState({ responsetype: "error" });
            this.setState({ isLoading: false });
          }
        },
        (err) => {
          this.setState({ response: +JSON.stringify(err) });
          this.setState({ isLoading: false });
        }
      );
    } else {
      this.setState({
        response:
          "All fields are mandatory. Please fill all the required fields.",
      });
      this.setState({ responsetype: "error" });
      this.setState({ isLoading: false });
    }
  }

  closetoast() {
    this.setState({ response: "" });
  }
  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      let queries = queryString.parse(this.props.location.search);
      if (queries.id != undefined) {
        this.state.userid = queries.id;
      }
      this.getprofiledetailsbyid(this.state.userid);
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <Modal show={this.state.showD} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center mb-4">
              Are you sure, You want to delete this user.
            </h5>
            {/* <p className="text-center">
				  Once you delete your card, Card data will be deleted permanently.
				</p> */}
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.userDelete}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showU} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center mb-4">
              Are you sure, You want to Update this users data.
            </h5>
            {/* <p className="text-center">
				  Once you delete your card, Card data will be deleted permanently.
				</p> */}
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.userUpdate}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Access Control</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center mb-4">
              Are you sure, You want change user permission.
            </h5>
            {/* <p className="text-center">
				  Once you delete your card, Card data will be deleted permanently.
				</p> */}
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.userAccessControl}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>

        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">View User Profile</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      onClick={(e) => this.handleShow("active")}
                      className="btn btn-sm btn-success btn-outline-secondary text-white"
                    >
                      {parseInt(this.state.isActive) == 0
                        ? "Unblock"
                        : "Active"}
                    </button>
                  </div>
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      onClick={(e) => this.handleShow("delete")}
                      className="btn btn-sm btn-danger btn-outline-secondary text-white"
                    >
                      {parseInt(this.state.isDelete) == 1
                        ? "Deleted User"
                        : "Delete"}
                    </button>
                  </div>
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      onClick={(e) => this.handleShow("update")}
                      className="btn btn-sm btn-primary btn-outline-secondary text-white"
                    >
                      Update
                    </button>
                  </div>
                  {/* <button type="button" onClick={this.DeleteUserData} className="btn btn-danger btn-sm btn-outline-secondary text-white">            
            Delete User
          </button> */}
                </div>
              </div>
              <div className="container rounded bg-white m-1">
                {/* <form onSubmit={this.handleOnSubmit}>					 */}
                <div className="row">
                  <div className="col-lg-3 col-md-3 border-right">
                    <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                      {/* <img src={this.state.selectedFile? URL.createObjectURL(this.state.selectedFile) : null || this.state.myprofiledetails.s3ImagePath || 'http://localhost:3000/images/logo.png' } alt={this.state.selectedFile? this.state.selectedFile.name : null} style={{maxHeight:"100px",width:"100px",border:"solid 1px #ccc",borderRadius:"100px",minHeight:"100px"}} /> */}
                      {this.state.uprofileimg == null && (
                        <img
                          className="rounded-circle mt-5"
                          style={{ width: "150px", height: "150px" }}
                          src={
                            this.state.uprofileimg
                              ? this.state.baseImgPath + this.state.uprofileimg
                              : "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                          }
                        />
                      )}
                      {this.state.uprofileimg != null && (
                        <img
                          className="rounded-circle mt-5"
                          style={{ width: "150px", height: "150px" }}
                          src={
                            this.state.uprofileimg
                              ? this.state.baseImgPath + this.state.uprofileimg
                              : this.state.baseImgPath +
                                "loading-slow-internet.gif"
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-5 border-right">
                    <form onSubmit={this.handleOnSubmit}>
                      <div className="p-1">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h4 className="text-right">View User Profile</h4>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-6 col-md-6">
                            <label>First Name</label>
                            <InputGroup>
                              <FormControl
                                name="fname"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.fname || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Last Name</label>
                            <InputGroup>
                              <FormControl
                                placeholder="Last Name"
                                name="lname"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.lname || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Email Address</label>
                            <InputGroup>
                              <FormControl
                                placeholder="Email Address"
                                name="email"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.email || ""}
                                onChange={(e) => this.handleUserInput(e)}
                                onBlur={(e) => this.emailaddresscheck(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Phone Number</label>
                            <InputGroup>
                              <FormControl
                                placeholder="Phone Number"
                                name="phoneno"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.phoneno || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Address 1</label>
                            <InputGroup>
                              <FormControl
                                placeholder="Address 1"
                                name="address1"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.address1 || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Address 2</label>
                            <InputGroup>
                              <FormControl
                                placeholder="Address 2"
                                name="address2"
                                className="form-control wrap-input100 validate-input"
                                value={this.state.address2 || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>Suburb</label>
                            <InputGroup>
                              <FormControl
                                placeholder="Suburb"
                                name="suburb"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.area || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>State</label>
                            <InputGroup>
                              <FormControl
                                placeholder="State"
                                name="state"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.state || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Post Code</label>
                            <InputGroup>
                              <FormControl
                                placeholder="Post Code"
                                name="postcode"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.postcode || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Country</label>
                            <InputGroup>
                              <FormControl
                                placeholder="Country"
                                name="country"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.country || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                        </div>
                      </div>
                    </form>

                    <br />
                    <div
                      style={{
                        padding: "2%",
                        width: "100%",
                        height: "30%",
                        display: "inline-block",
                        backgroundColor: "#68B5DF",
                        border: "1px solid black",
                        //borderstyle: "solid",
                        //bordercolor: "black",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-lg-12 col-md-12 h4">
                        <label>Password</label>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <Row>
                          <div className="col-lg-6 col-md-6">
                            <label style={{ paddingBottom: "6px" }}>
                              New Password
                            </label>
                            <InputGroup>
                              <FormControl
                                placeholder="New Password"
                                name="password"
                                type="password"
                                className="form-control wrap-input100 validate-input"
                                value={this.state.password || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label style={{ paddingBottom: "6px" }}>
                              Confirm Password
                            </label>
                            <InputGroup>
                              <FormControl
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmpassword"
                                className="form-control wrap-input100 validate-input"
                                value={this.state.confirmpassword || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-lg-6 col-md-6"></div>
                          <div className="col-lg-6 col-md-6">
                            <button
                              style={{
                                alignItems: "end",
                                display: "flex",
                                justifyContent: "flex-end",
                                float: "right",
                                backgroundColor: "green",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                              }}
                              className="btn btn-sm btn-success btn-outline-success text-white"
                              onClick={(e) => this.updatePassword(e)}
                            >
                              Update Password
                            </button>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
      </div>
    );
  }
}
export default DashboardComponents;
