import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import { get_templatedata, queueCard } from "../apiserve/ApiComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import { onChangePosition } from "../../state/index";
import Toast from "react-bootstrap/Toast";
// import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import axios from "axios";
import "./dashboard.css";
import { Resizable } from "re-resizable";
import Draggable, { DraggableCore } from "react-draggable";
import { toBeRequired } from "@testing-library/jest-dom/dist/matchers";
class DashboardSavedTemplateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carddetails: [],
      carduserdetails: [],
      carddata: [],
      cardDesignData: [],
      defaultarray: [],
      resizing: false,
      show: false,
      saved: false,
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      response: "",
      responsetype: "",
      isLoading: false,
      staffid: "",
      firstname: "",
      lastname: "",
      position: "",
      phoneno: "",
      formErrors: {
        staffid: "",
        firstname: "",
        lastname: "",
        position: "",
      },
      firstnameUpdate: false,
      activeDrags: 0,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.queueCard = this.queueCard.bind(this);
    this.onChangePosition = this.onChangePosition.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  onChangePosition(e, position) {
    if (this.state.resizing == true) {
      let newx = position.x;
      let newy = position.y;
      let targetname = "myuploadedimage";
      this.props.onChangePosition({ targetname, newy, newx });
      this.setState({ resizing: false });
    } else {
      let newx = position.x;
      let newy = position.y;
      let targetname = e.target.id;

      this.state.cardDesignData[targetname]["position"].x = newx;
      this.state.cardDesignData[targetname]["position"].y = newy;

      this.setState({ resizing: this.state.resizing });
    }
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });

    switch (name) {
      case "firstname":
        this.setState({ firstname: value });
        return value;
      case "lastname":
        this.setState({ lastname: value });
        return value;
      case "position":
        this.setState({ position: value });
        return value;
      case "phoneno":
        this.setState({ phoneno: value });
        return value;
      default:
        this.setState({ staffid: value });
        return value;
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let staffidValid = this.state.staffidValid;
    let firstnameValid = this.state.firstnameValid;
    let lastnameValid = this.state.lastnameValid;
    let positionValid = this.state.positionValid;

    switch (fieldName) {
      case "firstname":
        firstnameValid = value.length >= 2;
        fieldValidationErrors.firstname = firstnameValid ? "" : " is invalid";
        break;

      case "lastname":
        lastnameValid = value.length >= 2;
        fieldValidationErrors.lastname = lastnameValid ? "" : " is invalid";
        break;

      case "position":
        positionValid = value.length >= 2;
        fieldValidationErrors.position = positionValid ? "" : " is invalid";
        break;

      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        //staffidValid: staffidValid,
        firstnameValid: firstnameValid,
        lastnameValid: lastnameValid,
        positionValid: positionValid,
      },
      this.validateForm
    );
  }

  gettempcarddetails(id) {
    get_templatedata(
      id,
      (res) => {
        this.setState({ carddetails: res.data.data[0][0] });
        this.setState({
          cardDesignData: JSON.parse(res.data.data[0][0].card_data),
        });

        // this.setState({
        //   firstnamex: JSON.parse(res.data.data[0][0].card_data)["firstname"][
        //     "position"
        //   ].x,
        // });
        var s = res.data.data[0][0].cardtemplatedata;
        s = s.replace(/\\/g, "");
        this.setState({
          carddata: JSON.parse(s),
        });

        // reset User Entered Data
        this.state.cardDesignData.staffid.text = "";
        this.state.cardDesignData.firstname.text = "";
        this.state.cardDesignData.lastname.text = "";
        this.state.cardDesignData.position.text = "";
        this.state.cardDesignData.phoneno.text = "";
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading Template data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  queueCard() {
    var queueCardData = [];
    const templatedesign = {
      templatedesign: {
        frontimage: this.state.carddetails.cardbgimage,
        backimage: this.state.carddetails.card_back_image,
      },
    };

    const staffid = {
      staffid: {
        text: this.state.staffid,
        color: this.state.cardDesignData.staffid.color,
        fontBold: this.state.cardDesignData.staffid.fontBold,
        fontItalic: this.state.cardDesignData.staffid.fontItalic,
        fontUnderline: this.state.cardDesignData.staffid.fontUnderline,
        alignText: this.state.cardDesignData.staffid.alignText,
        fontSize: this.state.cardDesignData.staffid.fontSize,
        fontFamily: this.state.cardDesignData.staffid.fontFamily,
        position: {
          x: this.state.cardDesignData.staffid.position.x,
          y: this.state.cardDesignData.staffid.position.y,
        },
      },
    };
    const firstname = {
      firstname: {
        text: this.state.firstname,
        color: this.state.cardDesignData.firstname.color,
        fontBold: this.state.cardDesignData.firstname.fontBold,
        fontItalic: this.state.cardDesignData.firstname.fontItalic,
        fontUnderline: this.state.cardDesignData.firstname.fontUnderline,
        alignText: this.state.cardDesignData.firstname.alignText,
        fontSize: this.state.cardDesignData.firstname.fontSize,
        fontFamily: this.state.cardDesignData.firstname.fontFamily,
        position: {
          x: this.state.cardDesignData.firstname.position.x,
          y: this.state.cardDesignData.firstname.position.y,
        },
      },
    };

    const lastname = {
      lastname: {
        text: this.state.lastname,
        color: this.state.cardDesignData.lastname.color,
        fontBold: this.state.cardDesignData.lastname.fontBold,
        fontItalic: this.state.cardDesignData.lastname.fontItalic,
        fontUnderline: this.state.cardDesignData.lastname.fontUnderline,
        alignText: this.state.cardDesignData.lastname.alignText,
        fontSize: this.state.cardDesignData.lastname.fontSize,
        fontFamily: this.state.cardDesignData.lastname.fontFamily,
        position: {
          x: this.state.cardDesignData.lastname.position.x,
          y: this.state.cardDesignData.lastname.position.y,
        },
      },
    };

    const position = {
      position: {
        text: this.state.position,
        color: this.state.cardDesignData.position.color,
        fontBold: this.state.cardDesignData.position.fontBold,
        fontItalic: this.state.cardDesignData.position.fontItalic,
        fontUnderline: this.state.cardDesignData.position.fontUnderline,
        alignText: this.state.cardDesignData.position.alignText,
        fontSize: this.state.cardDesignData.position.fontSize,
        fontFamily: this.state.cardDesignData.position.fontFamily,
        position: {
          x: this.state.cardDesignData.position.position.x,
          y: this.state.cardDesignData.position.position.y,
        },
      },
    };

    const phoneno = {
      phoneno: {
        text: this.state.phoneno,
        color: this.state.carddata.phoneno.color,
        fontBold: this.state.carddata.phoneno.fontBold,
        fontItalic: this.state.carddata.phoneno.fontItalic,
        fontUnderline: this.state.carddata.phoneno.fontUnderline,
        alignText: this.state.carddata.phoneno.alignText,
        fontSize: this.state.carddata.phoneno.fontSize,
        fontFamily: this.state.carddata.phoneno.fontFamily,
        position: {
          x: this.state.cardDesignData.phoneno.position.x,
          y: this.state.cardDesignData.phoneno.position.y,
        },
      },
    };

    const extrafields = [];
    var efield;

    for (var i = 0; i < this.state.cardDesignData.extrafields.length; i++) {
      efield = {
        text: this.state.cardDesignData.extrafields[i].text,
        color: this.state.cardDesignData.extrafields[i].color,
        fontBold: this.state.cardDesignData.extrafields[i].fontBold,
        fontItalic: this.state.cardDesignData.extrafields[i].fontItalic,
        fontUnderline: this.state.cardDesignData.extrafields[i].fontUnderline,
        alignText: this.state.cardDesignData.extrafields[i].alignText,
        fontSize: this.state.cardDesignData.extrafields[i].fontSize,
        fontFamily: this.state.cardDesignData.extrafields[i].fontFamily,
        position: {
          x: this.state.cardDesignData.extrafields[i].position.x,
          y: this.state.cardDesignData.extrafields[i].position.y,
        },
      };
      extrafields.push(efield);
    }

    console.log(firstname);

    queueCardData.push(templatedesign);
    queueCardData.push(staffid);
    queueCardData.push(firstname);
    queueCardData.push(lastname);
    queueCardData.push(position);
    queueCardData.push(extrafields);

    queueCard(localStorage.getItem("currenttemplate"), queueCardData, (res) => {
      if (res.data.success == true) {
        this.setState({ response: "Card Added to Order Queue" });
        this.setState({ responsetype: "success" });
      }
    });
  }

  onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
  };

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  };

  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    // console.log(this.state.activeDrags)
    this.setState({ activeDrags: --this.state.activeDrags });
  };

  componentDidMount() {
    if (localStorage.getItem("vcardurole") == 2) {
      let cardid = "";
      let queries = queryString.parse(this.props.location.search);
      if (queries.id != undefined) {
        cardid = queries.id;
      }
      localStorage.setItem("currenttemplate", cardid);
      this.gettempcarddetails(cardid);
    } else {
      window.location.href = "/";
    }
  }

  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure, You want to Delete your template.
            </h5>
            <p className="text-center">
              Once you delete your template, template will no longer accessible
              for user.
            </p>
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.deletetempcard}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>

            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <TopBarComponent imagePath={localStorage.getItem("profileimagepath")} />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  {this.state.carddetails.cardtemplatename}
                </h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2"></div>
                  <button
                    type="button"
                    className="btn btn-success btn-sm btn-outline-secondary text-white"
                    onClick={this.queueCard}
                  >
                    Queue Card
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3"></div>

              <div className="col">
                <div className="row">
                  <div className="col-md-4 col-sm-12 mb-4">
                    <div className="row">
                      <div className="col-12">
                        <label>Card Design Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.carddetails.cardtemplatename}
                        />
                      </div>
                    </div>
                    <div className="clearfix he-1"></div>
                    <div className="clearfix he-1">&nbsp;</div>
                    <div className="d-grid gap-1">
                      <InputGroup
                        className={`mb-0 form-group ${this.errorClass(
                          this.state.formErrors.staffid
                        )} || '' `}
                      >
                        <FormControl
                          placeholder="Staff ID"
                          aria-label="Staff ID"
                          aria-describedby="basic-addon2"
                          name="staffid"
                          //required
                          className="form-control"
                          value={this.state.staffid || ""}
                          onChange={this.handleUserInput}
                        />
                        <InputGroup.Text
                          id="basic-addon2"
                          className="p-0"
                        ></InputGroup.Text>
                      </InputGroup>
                      <div className="clearfix he-1">&nbsp;</div>
                    </div>
                    {/* firstname */}
                    <div className="d-grid gap-1">
                      <InputGroup
                        className={`mb-0 form-group ${this.errorClass(
                          this.state.formErrors.firstname
                        )} || '' `}
                      >
                        <FormControl
                          placeholder="First Name"
                          aria-label="First Name"
                          aria-describedby="basic-addon2"
                          name="firstname"
                          //required
                          className="form-control"
                          value={this.state.firstname || ""}
                          onChange={this.handleUserInput}
                        />
                        <InputGroup.Text
                          id="basic-addon2"
                          className="p-0"
                        ></InputGroup.Text>
                      </InputGroup>
                      <div className="clearfix he-1">&nbsp;</div>
                    </div>

                    {/* lastname */}
                    <div className="d-grid gap-1">
                      <InputGroup
                        className={`mb-0 form-group ${this.errorClass(
                          this.state.formErrors.lastname
                        )} || '' `}
                      >
                        <FormControl
                          placeholder="Last Name"
                          aria-label="Last Name"
                          aria-describedby="basic-addon2"
                          name="lastname"
                          //required
                          className="form-control"
                          value={this.state.lastname || ""}
                          onChange={this.handleUserInput}
                        />
                        <InputGroup.Text
                          id="basic-addon2"
                          className="p-0"
                        ></InputGroup.Text>
                      </InputGroup>
                      <div className="clearfix he-1">&nbsp;</div>
                    </div>
                    {/* position */}
                    <div className="d-grid gap-1">
                      <InputGroup
                        className={`mb-0 form-group ${this.errorClass(
                          this.state.formErrors.position
                        )} || '' `}
                      >
                        <FormControl
                          placeholder="Position"
                          aria-label="Position"
                          aria-describedby="basic-addon2"
                          name="position"
                          //required
                          className="form-control"
                          value={this.state.position || ""}
                          onChange={this.handleUserInput}
                        />
                        <InputGroup.Text
                          id="basic-addon2"
                          className="p-0"
                        ></InputGroup.Text>
                      </InputGroup>
                      <div className="clearfix he-1">&nbsp;</div>
                    </div>
                    <div className="clearfix he-1"></div>

                    {/* phone */}
                    <div className="d-grid gap-1">
                      <InputGroup
                      // className={`mb-0 form-group ${this.errorClass(
                      //   this.state.formErrors.phoneno
                      // )} || '' `}
                      >
                        <FormControl
                          placeholder="Phone"
                          aria-label="Phone"
                          aria-describedby="basic-addon2"
                          name="phoneno"
                          //required
                          className="form-control"
                          value={this.state.phoneno || ""}
                          onChange={this.handleUserInput}
                        />
                        <InputGroup.Text
                          id="basic-addon2"
                          className="p-0"
                        ></InputGroup.Text>
                      </InputGroup>
                      <div className="clearfix he-1">&nbsp;</div>
                    </div>
                    <div className="clearfix he-1"></div>
                  </div>
                  <div className="col">
                    {this.state.carddetails.card_orientation != "portrait" ? (
                      <div
                        style={{
                          display: "block",
                          border: "solid 1px blue",
                          width: "87%",
                        }}
                      >
                        <div
                          id="savefromhere"
                          className="col-lg-12"
                          style={{ background: "#ccc" }}
                        >
                          <div
                            className="col-lg-12 p-4 backgroundimage"
                            style={{
                              background: `url("https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.carddetails.cardbgimage}")`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                          >
                            <div
                              ref={this.inputRef}
                              id={"cardheight"}
                              className="col-lg-12 cardheight justify-content-center"
                              style={{
                                height: "100%",
                                border: "dashed 1px #000",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <div
                                className="col-12"
                                style={{ height: "100%" }}
                              >
                                {this.state.carddata.staffid && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.staffid.position.x,
                                      y: this.state.carddata.staffid.position.y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col py-2 d-block"
                                            id="valuw1"
                                            style={{
                                              color: `${
                                                this.state.carddata.staffid
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.staffid
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.staffid
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.staffid
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.staffid
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.staffid
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.staffid
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.staffid.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.firstname && (
                                  <Draggable
                                    bounds="parent"
                                    axis="both"
                                    handle=".handle"
                                    position={{
                                      x: this.state.cardDesignData.lastname
                                        .position.x,
                                      y: this.state.cardDesignData.lastname
                                        .position.y,
                                    }}
                                    {...dragHandlers}
                                    onDrag={this.onControlledDrag}
                                    onStop={this.onChangePosition}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col d-block text-center py-2"
                                            id="firstname"
                                            style={{
                                              color: `${
                                                this.state.cardDesignData
                                                  .firstname.color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.cardDesignData
                                                  .firstname.fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.cardDesignData
                                                  .firstname.fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.cardDesignData
                                                  .firstname.fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.cardDesignData
                                                  .firstname.fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.cardDesignData
                                                  .firstname.alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.cardDesignData
                                                  .firstname.fontFamily ||
                                                "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.firstname}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.lastname && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.lastname.position
                                        .x,
                                      y: this.state.carddata.lastname.position
                                        .y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col d-block text-center py-2"
                                            style={{
                                              color: `${
                                                this.state.carddata.lastname
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.lastname
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.lastname
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.lastname
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.lastname
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.lastname
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.lastname
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.lastname.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.position && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.position.position
                                        .x,
                                      y: this.state.carddata.position.position
                                        .y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col>
                                          <FormLabel
                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                            style={{
                                              color: `${
                                                this.state.carddata.position
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.position
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.position
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.position
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.position
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.position
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.position
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.position.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.carddata.phoneno && (
                                  <Draggable
                                    position={{
                                      x: this.state.carddata.phoneno.position.x,
                                      y: this.state.carddata.phoneno.position.y,
                                    }}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col>
                                          <FormLabel
                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                            id="valuw0"
                                            style={{
                                              color: `${
                                                this.state.carddata.phoneno
                                                  .color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.carddata.phoneno
                                                  .fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.carddata.phoneno
                                                  .fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.carddata.phoneno
                                                  .fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.carddata.phoneno
                                                  .fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.carddata.phoneno
                                                  .alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.carddata.phoneno
                                                  .fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.carddata.phoneno.text}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "block",
                          border: "solid 1px blue",
                          width: "478px",
                          height: "763px",
                          borderRadius: "15px",
                        }}
                      >
                        <div
                          id="savefromhere"
                          className="col-lg-12"
                          style={{
                            background: "#ccc",
                            width: "478px",
                            height: "763px",
                            borderRadius: "15px",
                          }}
                        >
                          <div
                            className="col-lg-12 p-4 backgroundimage"
                            style={{
                              background: `url("https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.carddetails.cardbgimage}")`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              width: "478px",
                              height: "763px",
                              borderRadius: "15px",
                            }}
                          >
                            <div
                              ref={this.inputRef}
                              id={"cardheight"}
                              className="col-lg-12 cardheight justify-content-center"
                              style={{
                                height: "100%",
                                border: "dashed 1px #000",
                                overflow: "hidden",
                                position: "relative",
                                width: "478px",
                                height: "763px",
                                borderRadius: "15px",
                              }}
                            >
                              <div
                                className="col-12"
                                style={{ height: "100%" }}
                              >
                                {this.state.staffid && (
                                  <Draggable
                                    bounds="parent"
                                    axis="both"
                                    handle=".handle"
                                    position={{
                                      x: this.state.cardDesignData.staffid
                                        .position.x,
                                      y: this.state.cardDesignData.staffid
                                        .position.y,
                                    }}
                                    onStop={this.onChangePosition}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col py-2 d-block"
                                            id="staffid"
                                            style={{
                                              color: `${
                                                this.state.cardDesignData
                                                  .staffid.color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.cardDesignData
                                                  .staffid.fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.cardDesignData
                                                  .staffid.fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.cardDesignData
                                                  .staffid.fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.cardDesignData
                                                  .staffid.fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.cardDesignData
                                                  .staffid.alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.cardDesignData
                                                  .staffid.fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.staffid}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.firstname && (
                                  <Draggable
                                    bounds="parent"
                                    axis="both"
                                    handle=".handle"
                                    position={{
                                      x: this.state.cardDesignData.firstname
                                        .position.x,
                                      y: this.state.cardDesignData.firstname
                                        .position.y,
                                    }}
                                    onStop={this.onChangePosition}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col d-block text-center py-2"
                                            id="firstname"
                                            style={{
                                              color: `${
                                                this.state.cardDesignData
                                                  .firstname.color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.cardDesignData
                                                  .firstname.fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.cardDesignData
                                                  .firstname.fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.cardDesignData
                                                  .firstname.fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.cardDesignData
                                                  .firstname.fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.cardDesignData
                                                  .firstname.alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.cardDesignData
                                                  .firstname.fontFamily ||
                                                "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.firstname}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.lastname && (
                                  <Draggable
                                    bounds="parent"
                                    axis="both"
                                    handle=".handle"
                                    position={{
                                      x: this.state.cardDesignData.lastname
                                        .position.x,
                                      y: this.state.cardDesignData.lastname
                                        .position.y,
                                    }}
                                    onStop={this.onChangePosition}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col className="text-center">
                                          <FormLabel
                                            className="m-0 p-0 col d-block text-center py-2"
                                            id="lastname"
                                            style={{
                                              color: `${
                                                this.state.cardDesignData
                                                  .lastname.color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.cardDesignData
                                                  .lastname.fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.cardDesignData
                                                  .lastname.fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.cardDesignData
                                                  .lastname.fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.cardDesignData
                                                  .lastname.fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.cardDesignData
                                                  .lastname.alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.cardDesignData
                                                  .lastname.fontFamily ||
                                                "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.lastname}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.position && (
                                  <Draggable
                                    bounds="parent"
                                    axis="both"
                                    handle=".handle"
                                    position={{
                                      x: this.state.cardDesignData.position
                                        .position.x,
                                      y: this.state.cardDesignData.position
                                        .position.y,
                                    }}
                                    onStop={this.onChangePosition}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col>
                                          <FormLabel
                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                            id="position"
                                            style={{
                                              color: `${
                                                this.state.cardDesignData
                                                  .position.color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.cardDesignData
                                                  .position.fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.cardDesignData
                                                  .position.fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.cardDesignData
                                                  .position.fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.cardDesignData
                                                  .position.fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.cardDesignData
                                                  .position.alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.cardDesignData
                                                  .position.fontFamily ||
                                                "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.position}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}

                                {this.state.phoneno && (
                                  <Draggable
                                    bounds="parent"
                                    axis="both"
                                    handle=".handle"
                                    position={{
                                      x: this.state.cardDesignData.phoneno
                                        .position.x,
                                      y: this.state.cardDesignData.phoneno
                                        .position.y,
                                    }}
                                    onStop={this.onChangePosition}
                                  >
                                    <div>
                                      <Row className="handle">
                                        <Col>
                                          <FormLabel
                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                            id="phoneno"
                                            style={{
                                              color: `${
                                                this.state.cardDesignData
                                                  .phoneno.color || "#000"
                                              }`,
                                              fontWeight: `${
                                                this.state.cardDesignData
                                                  .phoneno.fontBold
                                                  ? "bold"
                                                  : "normal"
                                              }`,
                                              fontSize: `${
                                                this.state.cardDesignData
                                                  .phoneno.fontSize || "14px"
                                              }`,
                                              fontStyle: `${
                                                this.state.cardDesignData
                                                  .phoneno.fontItalic
                                                  ? "italic"
                                                  : "normal"
                                              }`,
                                              textDecoration: `${
                                                this.state.cardDesignData
                                                  .phoneno.fontUnderline
                                                  ? "underline"
                                                  : "none"
                                              }`,
                                              textAlign: `${
                                                this.state.cardDesignData
                                                  .phoneno.alignText || "left"
                                              }`,
                                              fontFamily: `${
                                                this.state.cardDesignData
                                                  .phoneno.fontFamily || "arial"
                                              }`,
                                            }}
                                          >
                                            {this.state.phoneno}
                                          </FormLabel>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Draggable>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardSavedTemplateDetails;
