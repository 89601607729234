import React, { Component, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import { forgotpassword } from "./apiserve/ApiComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
class ForgotPasswordComponent extends Component {
  constructor() {
    super();
    this.state = {
      input: { email: "" },
      errors: {},
      response: "",
      responsetype: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      let input = {};
      input["email"] = "";
      // input["confirm_password"] = "";
      this.setState({ input: input });
      let mail = this.state.input.email;
      forgotpassword(
        mail,
        (res) => {
          if (res.data.success == true) {
            this.setState({
              response:
                "Reset mail send will be sent to your ID Cards Platform email, please check your mail",
            });
            this.setState({ responsetype: "success" });
          }
        },
        (err) => {
          console.log(err);
          this.setState({ response: "Error while sending mail, try again." });
          this.setState({ responsetype: "error" });
        }
      );
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["email"]) {
      isValid = false;
      this.setState({ response: "Enter your E-mail id" });
      this.setState({ responsetype: "danger" });
    } else if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        this.setState({ response: "Please enter valid E-mail id" });
        this.setState({ responsetype: "danger" });
      }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <HeaderComponent />
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form"
                style={{ paddingTop: "100px" }}
                onSubmit={this.handleSubmit}
              >
                <span className="login100-form-title p-b-43 mb-1">
                  Forgotten Password ?
                </span>
                <p className="text-muted fw-slim text-center fs-5">
                  Enter your account email to reset your password <br />
                  (reset link will be sent to this email address)
                </p>

                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark pt-1">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={this.state.input.email}
                    onChange={this.handleChange}
                    placeholder="Enter Your E-Mail"
                    id="email"
                    className="form-control form-control-lg form-control-solid"
                  />
                </div>

                <div className="d-flex flex-wrap pb-lg-0">
                  <button
                    type="submit"
                    className="btn btn-primary fw-bolder fs-6 px-5 py-2 my-3 me-4"
                  >
                    Submit
                  </button>
                  <a href="/login">
                    <button
                      type="button"
                      className="btn btn-light-primary fw-bolder fs-6 px-8 py-1 my-3"
                    >
                      Sign-in
                    </button>
                  </a>{" "}
                </div>
              </form>

              <div
                className="login100-more"
                style={{ backgroundImage: "url('images/bg-02.jpg')" }}
              ></div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}
export default ForgotPasswordComponent;
