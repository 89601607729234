import React, { Component, useRef } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  FormLabel,
  Row,
  Col,
  Form,
} from "react-bootstrap-v5";
import * as Icon from "react-bootstrap-icons";
// import PreviousUploads from './PreviousUploads';
// import QrCodeComponents from './QrCodeComponents';
// import FileUploadComponent from './FileUploadComponent';
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  UploadMyImage,
  UploadMyFTemp,
  UploadMyBTemp,
  RemoveImage,
  ChangeFrontBackMyImage,
} from "../../state/index";
class DashboardImageComponent extends Component {
  fileObj = [];
  fileArray = [];
  constructor(props) {
    super(props);
    this.state = props.state;
    // this.inputRef = useRef<HTMLInputElement>(null);
    this.inputRef = "";
    this.state = {
      showHideqrcode: false,
      showHidepreviousupload: false,
      studentnameValid: false,
      // selectedFile:[null]
      selectedFile: "",
      selectedFTemp: "",
      selectedBTemp: "",
      frontbaseimg: "",
    };
    this.inputReference = React.createRef();
    this.inputReferencefront = React.createRef();
    this.inputReferenceback = React.createRef();
  }

  fileUploadAction = () => this.inputReference.current.click();
  fileFUploadAction = () => this.inputReferencefront.current.click();
  fileBUploadAction = () => this.inputReferenceback.current.click();

  fileUploadInputChange = (e) => {
    console.log(e.target.files[0]);
    let image = URL.createObjectURL(e.target.files[0]);
    this.props.UploadMyImage(image);
    this.setState({ selectedFile: e.target.files[0] }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state);
      }
    });
  };

  onFileChange = (event) => {
    // Update the state
    console.log(event.target.files[0]);
    this.setState({ selectedFile: event.target.files[0] }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state);
      }
    });
    let image = event.target.files[0];
    this.props.UploadMyImage(image);
  };

  onFTempChange = (event) => {
    console.log(event.target.files[0]);
    // Update the state
    this.setState({ selectedFTemp: event.target.files[0] }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state);
      }
    });
    var reader = new FileReader();
    reader.onloadend = function () {
      console.log(reader.result);
    };
    if (image) {
      reader.readAsDataURL(image);
    } else {
    }
    let image = URL.createObjectURL(event.target.files[0]);

    let imagebg = event.target.files[0];
    console.log(image);
    this.props.UploadMyFTemp({ image, imagebg });
  };

  onBTempChange = (event) => {
    console.log(event.target.files[0]);
    // Update the state
    this.setState({ selectedBTemp: event.target.files[0] }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state);
      }
    });
    let image = URL.createObjectURL(event.target.files[0]);
    let imageback = event.target.files[0];
    console.log(image);
    this.props.UploadMyBTemp({ image, imageback });
  };
  // On file upload (click the upload button)
  onFileUpload = (e) => {
    let errors = {};
    const { userID, FileUpload } = this.state;
    e.preventDefault();
    // Create an object of formData
    const formData = new FormData();
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {};

  RemoveImage() {
    this.props.RemoveImage();
  }

  ChangeFrontBackMyImage(frontback) {
    // console.log(frontback);
    this.props.ChangeFrontBackMyImage(frontback);
  }

  render() {
    const {
      showHideqrcode,
      showHidepreviousupload,
      studentname,
      selectedFile,
    } = this.state;

    return (
      <div>
        {/* {this.props.message}
                <button onClick={()=> alert(this.props.rowData)}>test me</button> */}
        {/* <div className="form-group multi-preview">
                    {(this.fileArray || []).map(url => (
                        <img src={url} alt="..." />
                    ))}
                </div> */}
        {/* <FormControl
                placeholder="First Name"
                aria-label="First Name"
                aria-describedby="basic-addon2"
                name="studentname"
                required
                className="form-control"
                placeholder="Employee Name"
                value={this.state.studentname}
                onChange={this.handleUserInput}
              /> */}
        <div className="clearfix">&nbsp;</div>
        <FormLabel
          className="col text-center py-2 fw-bold"
          id="valuw1"
          style={{ color: "red", fontSize: "23px", fontWeight: "bold" }}
        >
          {this.props.GGGHHHH}
        </FormLabel>
        <FormLabel className="label1">Upload</FormLabel>
        <div className="clearfix"></div>
        {/* <a href="#" style={{fontSize:"12px"}} className="cblue">View upload specs and templates</a> */}
        <div className="clearfix">&nbsp;</div>
        <Row>
          <Col>
            <div className="d-grid gap-2">
              <FormLabel style={{ fontSize: "10px" }}>Upload Front</FormLabel>
              <input
                type="file"
                name="files[]"
                hidden
                ref={this.inputReferencefront}
                onChange={this.onFTempChange}
                multiple
              />
              <Button
                variant="light"
                size="md"
                className="p-4"
                style={{ border: "1px solid #c1c4c5" }}
                onClick={this.fileFUploadAction}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-upload"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                </svg>
              </Button>
            </div>
          </Col>
          <Col>
            <div className="d-grid gap-2">
              <FormLabel style={{ fontSize: "10px" }}>Upload Back</FormLabel>
              <input
                type="file"
                name="files[]"
                hidden
                ref={this.inputReferenceback}
                onChange={this.onBTempChange}
                multiple
              />
              <Button
                variant="light"
                size="md"
                className="p-4"
                style={{ border: "1px solid #c1c4c5" }}
                onClick={this.fileBUploadAction}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-upload"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                </svg>
              </Button>
            </div>
          </Col>
          {/* <Col className="mt-4">
          <div className="d-grid gap-2">
          
            <input type="file" name="files[]" hidden ref={this.inputReference} onChange={this.onFileChange} multiple />
              <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}} onClick={this.fileUploadAction}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                </svg>
              </Button>
              <FormLabel style={{fontSize:"10px"}}>Upload Sample Image</FormLabel>
            </div>            
          </Col> */}

          {/* <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}} onClick={() => this.showhideComponent("showHideqrcode")}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
  <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z"/>
  <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>Previous Uploads</FormLabel>
</div>
          </Col> */}

          {/* <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}} onClick={() => this.showhideComponent("showHidepreviousupload")}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upc-scan" viewBox="0 0 16 16">
  <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>QR Code</FormLabel>
</div>
          </Col> */}
          {/* {showHideqrcode && (
    
    <div>
      <PreviousUploads/>               
    </div>
  )} */}

          {/* {showHidepreviousupload && (
    
    <div>
      <QrCodeComponents/>               
    </div>
  )} */}
          <div>
            <div className="form-group multi-preview">
              {this.props.card_details.myuploadedimage != undefined &&
                this.props.card_details.myuploadedimage.file && (
                  <div>
                    <span
                      class="position-relative"
                      style={{ display: "inline-block" }}
                    >
                      <img
                        src={
                          this.props.card_details.myuploadedimage.file
                            ? this.props.card_details.myuploadedimage.file
                            : null || ""
                        }
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "5px",
                        }}
                      />

                      <Button
                        className="py-4 px-2 m-1 btn btn-light btn-md"
                        style={{
                          border: "1px solid rgb(193, 196, 197)",
                          background: "#e9ecef",
                          color: "#666",
                        }}
                        onClick={this.RemoveImage.bind(this)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="red"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fillRule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                      </Button>

                      <Button
                        className={`col-3 py-2 px-2 m-1 btn btn-light btn-md ${
                          this.props.card_details.myuploadedimage.frontback ==
                          "front"
                            ? "active"
                            : ""
                        }`}
                        style={{
                          border: "1px solid rgb(193, 196, 197)",
                          background: "#e9ecef",
                          color: "#666",
                        }}
                        onClick={this.ChangeFrontBackMyImage.bind(
                          this,
                          "front"
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-front"
                          viewBox="0 0 16 16"
                          style={{ display: "block" }}
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm5 10v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2v5a2 2 0 0 1-2 2H5z" />
                        </svg>
                        <FormLabel style={{ fontSize: "11px", margin: 0 }}>
                          Bring to Front
                        </FormLabel>
                      </Button>

                      <Button
                        className={`col-3 py-2 px-2 m-1 btn btn-light btn-md ${
                          this.props.card_details.myuploadedimage.frontback ==
                          "back"
                            ? "active"
                            : ""
                        }`}
                        style={{
                          border: "1px solid rgb(193, 196, 197)",
                          background: "#e9ecef",
                          color: "#666",
                        }}
                        onClick={this.ChangeFrontBackMyImage.bind(this, "back")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-back"
                          viewBox="0 0 16 16"
                          style={{ display: "block" }}
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z" />
                        </svg>
                        <FormLabel style={{ fontSize: "11px", margin: 0 }}>
                          Send to Back
                        </FormLabel>
                      </Button>
                    </span>
                  </div>
                )}
            </div>
            {/* <img src={this.state.fileUploadState} alt={this.state.fileUploadState? this.state.fileUploadState : null} style={{maxHeight:"100px",width:"100px",border:"solid 1px #ccc",borderRadius:"100px",minHeight:"100px"}} /> */}
            {/* <img src={this.state.selectedFile? URL.createObjectURL(this.state.selectedFile) : null ||'' } alt={this.state.selectedFile? this.state.selectedFile.name : null} style={{maxHeight:"100px",width:"100px",border:"solid 1px #ccc",borderRadius:"100px",minHeight:"100px"}} /> */}
          </div>
        </Row>

        <div className="clearfix">&nbsp;</div>
        <FormLabel style={{ fontSize: "12px" }}>
          Please ensure you have the right to use any image you upload
        </FormLabel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    card_details: state.card_data,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      UploadMyImage,
      UploadMyFTemp,
      UploadMyBTemp,
      RemoveImage,
      ChangeFrontBackMyImage,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardImageComponent);
