import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import {
  Table,
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import axios from "axios";
import "./dashboard.css";
import { Resizable } from "re-resizable";
import Draggable, { DraggableCore } from "react-draggable";
import { AlignEnd } from "react-bootstrap-icons";

import {
  getQueuedCards,
  deleteQueuedCard,
  buyusercards,
  savemycard,
} from "../apiserve/ApiComponent";

class DashboardQueuedCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carddetails: [],
      carduserdetails: [],
      carddata: [],
      templateData: [],
      card_logodata: [],
      cardbgimage: "",
      total_amount: 0,
      show: false,
      saved: false,
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      response: "",
      responsetype: "",
      isLoading: false,
    };
    this.loadTemplate = this.loadTemplate.bind(this);
    this.buyqueuedcards = this.buyqueuedcards.bind(this);
  }

  buyqueuedcards() {
    //Add cards to Card Orders Table
    const formCardData = new FormData();
    for (let i = 0; i < this.state.templateData.length; i++) {
      formCardData.delete("template_id");
      formCardData.delete("carddata");
      formCardData.delete("cardname");
      formCardData.delete("uid");

      formCardData.append("template_id", this.state.templateData[i].templateID);
      formCardData.append("carddata", this.state.templateData[i].cardData);
      formCardData.append(
        "cardname",
        this.state.templateData[i].cardtemplatename
      );
      formCardData.append("uid", localStorage.getItem("mwauid"));
      formCardData.append("queueid", this.state.templateData[i].queueID);

      savemycard(formCardData, (res) => {
        if (res.status == 200) {
          this.state.templateData[i].cardID = res.data.data[0][0].ResultStr;
          //          console.log(this.state.templateData[i].cardID);
          //          deleteQueuedCard(this.state.templateData[i].queueID);
        }
      });
    }

    let items = [];
    for (let i = 0; i < this.state.templateData.length; i++) {
      items.push({
        id: this.state.templateData[i].cardID,
        qty: "1",
        userid: localStorage.getItem("mwauid"),
        carddescription: this.state.templateData[i].cardtemplatename,
        itemcost: this.state.templateData[i].amount,
        total: this.state.templateData[i].amount,
        queueID: this.state.templateData[i].queueID,
      });
    }
    if (items.length == 0) {
      this.setState({ response: "Please select card(s) to purchase." });
      this.setState({ responsetype: "error" });
    } else {
      //Add Postage if Cards Ordered
      items.push({
        id: "0",
        qty: 1,
        userid: "",
        carddescription: "Flat Postage Fee",
        itemcost: "10",
        total: "10",
      });

      const formData = new FormData();
      formData.append("items", JSON.stringify(items));
      formData.append("userid", localStorage.getItem("mwauid"));

      //Buy the cards
      buyusercards(
        formData,
        (res) => {
          if (res.status == 200) {
            this.setState({
              response:
                "Payment link is generated, Redirecting to payment page.",
            });
            this.setState({ responsetype: "success" });
            let paymentlink = res.data.data.PaymentURL;
            //Payment Link
            localStorage.setItem("accesscode", res.data.data.AccessCode);
            setTimeout(function () {
              window.location.href = paymentlink;
              //window.open(paymentlink);
            }, 1000);
          }
        },
        (err) => {
          console.log(err);
          this.setState({
            response: "Error while generating payment link, Try again.",
          });
          this.setState({ responsetype: "error" });
        }
      );
    }
  }

  removeFromQueue(queueID) {
    deleteQueuedCard(queueID, (res) => {
      if (res.status == 200) {
        this.setState({
          response: "Queued Card removed from Queue.",
        });
        this.setState({ responsetype: "success" });
        this.setState({ total_amount: "0" });
        this.loadMyQueuedCards();
      } else {
        this.setState({
          response: "Failed to Remove Nominated Card From Queue.",
        });
      }
    });
  }

  loadMyQueuedCards() {
    getQueuedCards(localStorage.getItem("mwauid"), (res) => {
      let amount = "";
      this.setState({ templateData: res.data.data[0] });
      for (let i = 0; i < this.state.templateData.length; i++) {
        amount = parseInt(
          this.state.total_amount + this.state.templateData[i].amount
        );
        this.setState({ total_amount: amount });
      }
    });
  }

  loadTemplate(templateid) {
    window.location.href = `/user/saved_templatedetails?id=${templateid}`;
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") == 2) {
      this.loadMyQueuedCards();
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <TopBarComponent imagePath={localStorage.getItem("profileimagepath")} />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="container rounded bg-white m-1 mt-3 p-0">
                <Tabs
                  defaultActiveKey="saved"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="saved" title="Cards Queued Awaiting Ordering">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Template Name</th>
                          <th>Template Front</th>
                          <th>Template Back</th>
                          <th>Created Date/Time</th>
                          <th>Status</th>
                          <th>Cost</th>
                          <th>Queued By</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.templateData.map(function (item, i) {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.cardtemplatename}</td>
                              <td>
                                {item.card_orientation != "portrait" ? (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.cardbgimage
                                    }
                                    style={{ width: "100px", height: "60px" }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.cardbgimage
                                    }
                                    style={{ width: "60px", height: "100px" }}
                                  />
                                )}
                              </td>
                              <td>
                                {item.card_orientation != "portrait" ? (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.card_back_image
                                    }
                                    style={{ width: "100px", height: "60px" }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.card_back_image
                                    }
                                    style={{ width: "60px", height: "100px" }}
                                  />
                                )}
                              </td>
                              <td>{item.dateCreated.toString()}</td>
                              <td>
                                {item.queueStatus == 1 ? "Queued" : "Ordered"}
                              </td>
                              <td>
                                {item.amount == 0 ? "Free" : "$" + item.amount}
                              </td>
                              <td>{item.username}</td>
                              <td>
                                <Button
                                  className="btn btn-danger"
                                  onClick={(e) =>
                                    this.removeFromQueue(item.queueID)
                                  }
                                >
                                  Remove From Queue
                                </Button>
                              </td>
                            </tr>
                          );
                        }, this)}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </div>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  Billing Amount: $
                  {this.state.total_amount == ""
                    ? "0"
                    : this.state.total_amount}
                </h1>
                <div className="btn-toolbar mb-2 mb-md-0 text-right">
                  <button
                    type="button"
                    onClick={this.buyqueuedcards}
                    className="btn btn-sm btn-success btn-outline-secondary text-white"
                  >
                    Buy Queued Cards
                  </button>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardQueuedCards;
