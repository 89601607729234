import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import { createcategory } from "../apiserve/ApiComponent";
import TopBarComponent from "./TopBarComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import "./dashboard.css";
class DashboardCardCategoryCreateComponents extends Component {
  constructor(props) {
    super(props);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.state = {
      allorders: [],
      response: "",
      categoryname: [],
      responsetype: "",
      isLoading: false,
    };
    // this.showuserdetails = this.showuserdetails.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    // this.createcategory = this.createcategory.bind(this);
  }

  handleUserInput(e) {
    const value = e.target.value;
    let updatedata = value;
    this.setState({ categoryname: updatedata });
    // console.log(this.state.categoryname);
  }

  handleOnSubmit(e) {
    e.preventDefault();
    let catname = { name: "" };
    console.log(catname);
    catname.name = this.state.categoryname;
    console.log(catname);
    if (catname == "" || undefined) {
      this.setState({ response: "Please enter category name" });
      this.setState({ responsetype: "error" });
    } else {
      this.setState({ isLoading: true });
      createcategory(
        catname,
        (res) => {
          if (res.success == false) {
            this.setState({ isLoading: false });
            this.setState({ response: "Category already exists" });
            this.setState({ responsetype: "error" });
          } else {
            this.setState({ isLoading: false });
            this.setState({ response: "Category Created successfully" });
            this.setState({ responsetype: "success" });
          }
        },
        (err) => {
          this.setState({ isLoading: false });
          this.setState({ response: "Error while creating category" });
          this.setState({ responsetype: "error" });
        }
      );
    }
  }
  componentDidMount() {
    // this.getdashboarddata();
    //   this.getallorders();
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Create Category</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    {/* <button type="button" className="btn btn-sm btn-outline-secondary">Export</button> */}
                  </div>
                </div>
              </div>
              <div className="container rounded bg-white m-1">
                <form onSubmit={this.handleOnSubmit}>
                  <div className="row">
                    <div className="col-lg-12 col-md-6 border-right">
                      <div className="p-1">
                        <div className="row mt-3">
                          <div className="col-lg-6 col-md-6">
                            <label>Category</label>
                            <InputGroup>
                              <FormControl
                                name="fname"
                                placeholder="Enter Category Name"
                                required
                                className="form-control wrap-input100 validate-input"
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-3 col-md-3 mt-3">
                            <button
                              type="submit"
                              className="contact-form-btn btn btn-success text-white"
                            >
                              <span
                                style={{
                                  display: `${
                                    !this.state.isLoading ? "block" : "none"
                                  }`,
                                }}
                              >
                                Create Category
                              </span>
                              <span
                                className=""
                                style={{
                                  display: `${
                                    this.state.isLoading ? "block" : "none"
                                  }`,
                                  textAlign: "center",
                                  padding: "8px 15px",
                                }}
                              >
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </Spinner>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardCardCategoryCreateComponents;
