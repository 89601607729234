import React, { createContext, useState, useContext } from "react";

export const DatabaseContext = createContext({});

export const DatabaseProvider = ({ children }) => {
  // Axios.defaults.headers.common = {
  //   Authorization:
  //     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNTkwMDM1MTc0LCJleHAiOjE1OTI2MjcxNzR9.84RgftakT93wSLoT3HKoRf6SR8-kJ82ZtL5vc3Dihr4',
  // };

  const [state] = useState({
    //apiURL: "http://localhost:3006/api",
    apiURL: "https://office.monitorwa.com.au/tccardorderapi/api",
  });
  return (
    <DatabaseContext.Provider value={state}>
      {children}
    </DatabaseContext.Provider>
  );
};
