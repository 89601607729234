import React, { Component, useState, useRef, useContext } from "react";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  FormLabel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import TextFieldComponent from "./TextFieldComponent";
import ImageComponent from "./ImageComponent";
import TemplatesComponent from "./TemplatesComponent";
import queryString, { stringify } from "query-string";
import HeaderComponent from "./HeaderComponent";
// import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import FooterComponent from "./FooterComponent";
import SaveModalComponents from "./SaveModalComponents";
import { Image } from "react";
import { Resizable } from "re-resizable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  UpdateCardState,
  onChangePosition,
  onChangePositionExtra,
  ColorChange,
  FontSizeChange,
  undoSingleValueE,
  ChangeExtraFieldColor,
  checkUndoStatusE,
  echeckRedoPoistoin,
  checkUndoPoistoinExtra,
  ChangeExtraFieldFontFamily,
  ChangeExtraFieldFontSize,
  ChangeExtraFieldBold,
  ChangeExtraFieldUnderline,
  ChangeExtraFieldItalic,
  updateUndoStatus,
  ChangeFontFamily,
  BoldChange,
  undoSingleValue,
  redoSingleValue,
  redoSingleValueE,
  ItalicChange,
  UnderlineChange,
  checkUndoStatus,
  updateRedoStatus,
  checkRedoStatus,
  checkRedoStatusE,
  checkUndoPoistoin,
  checkRedoPoistoin,
} from "../state/index";

import Draggable, { DraggableCore } from "react-draggable";
import {
  get_templatedata,
  getcarddetails,
  getallfooterdetails,
  savemycard,
} from "./apiserve/ApiComponent";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// import {onChangePosition} from '../state/index'

import { saveAs } from "file-saver";

class DesignerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.adjustXPos.state = {
      activeDrags: 0,
      deltaPosition: {
        x: 0,
        y: 0,
      },
      controlledPosition: {
        x: -400,
        y: 200,
      },
    };
    this.onChangePosition = this.onChangePosition.bind(this);
    this.onChangePositionExtra = this.onChangePositionExtra.bind(this);
    this.checkSide = this.checkSide.bind(this);
    this.savetopdf = this.savetopdf.bind(this);
    this.savetojpg = this.savetojpg.bind(this);
    this.handleShowID = this.handleShowID.bind(this);
    this.getcarddetails = this.getcarddetails.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.checkResize = this.checkResize.bind(this);
    this.checkundo = this.checkundo.bind(this);
    this.checkredo = this.checkredo.bind(this);
    this.inputRef = React.createRef();
    this.state = {
      response: "",
      responsetype: "",
      showID: false,
      downloadType: "",
      previewImg: "",
      watermarkdata: [],
      defaultarray: [],
      redo: [],
      undo: [],
      defaultside: "front",
      resizing: false,
      logo: "",
      templatedata: [],
      cardFrontImage: "",
      cardBackImage: "",
      card_orientation: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
    };
  }
  getcarddetails(id) {
    getcarddetails(
      id,
      (res) => {
        this.setState({
          card_orientation: res.data.data[0][0].card_orientation,
        });
        this.setState({
          cardFrontImage: res.data.data[0][0].cardbgimage,
        });

        this.setState({
          cardBackImage: res.data.data[0][0].card_back_image,
        });
        let card_templatedata = JSON.parse(res.data.data[0][0].card_data);
        this.setState({
          templatedata: res.data.data[0][0].card_template_data,
        });
        this.props.UpdateCardState(card_templatedata);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  get_templatedata(id) {
    let checksignup = "";
    checksignup = localStorage.getItem("action");

    get_templatedata(
      id,
      (res) => {
        //success
        var s = res.data.data[0][0].cardtemplatedata;
        s = s.replace(/\\"/g, '"');
        let card_templatedata = JSON.parse(s);
        if (checksignup != "signup" || checksignup != "save") {
          this.setState({ templatedata: res.data.data[0][0] });
        } else {
          this.setState({ templatedata: res.data.data[0][0] });
        }
        this.setState({
          cardFrontImage: res.data.data[0][0].cardbgimage,
        });

        this.setState({
          cardBackImage: res.data.data[0][0].card_back_image,
        });
        this.setState({
          card_orientation: res.data.data[0][0].card_orientation,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleCustomDrag = (e, ui) => {
    console.log(e);
  };

  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    // console.log(this.state.activeDrags)
    this.setState({ activeDrags: --this.state.activeDrags });
  };

  handleClose() {
    this.setState({ showID: false });
  }

  handleShowID(e) {
    var type = e.target.getAttribute("data-v");
    this.setState({ downloadType: type });
    const inputf = document.getElementById("savefromhere");
    const inputb = document.getElementById("savefromhereback");
    const formData = new FormData();
    let card_preview_back;
    let card_preview;

    if (this.state.defaultside == "front") {
      html2canvas(inputb, {
        watermark:
          "https://office.monitorwa.com.au/tccardorderapi/images/logo.png",
        useCORS: true,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById(
            "bottom-tabs-example-tabpane-second"
          ).style.display = "block";
        },
      }).then((canvas) => {
        var file;
        file = canvas.toDataURL("image/png");
        card_preview_back = file;
        this.setState({ previewImgBack: card_preview_back });
      });

      const input = document.getElementById("savefromhere");
      html2canvas(input, {
        useCORS: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpg");
        this.setState({ previewImg: imgData });
      });
    } else {
      html2canvas(inputf, {
        useCORS: true,
        onclone: function (clonedDoc) {
          console.log("2");
          clonedDoc.getElementById(
            "bottom-tabs-example-tabpane-first"
          ).style.display = "block";
        },
      }).then((canvas) => {
        var file;
        file = canvas.toDataURL("image/png");
        card_preview = file;
        this.setState({ previewImg: card_preview });
      });
      const input = document.getElementById("savefromhereback");
      html2canvas(input, {
        useCORS: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpg");
        this.setState({ previewImgBack: imgData });
      });
    }

    this.setState({ showID: true });
  }

  onChangePosition(e, position) {
    if (this.state.resizing == true) {
      let newx = position.x;
      let newy = position.y;
      let targetname = "myuploadedimage";
      this.props.onChangePosition({ targetname, newy, newx });
      this.setState({ resizing: false });
    } else {
      let newx = position.x;
      let newy = position.y;
      let targetname = e.target.id;
      let dataup = this.state.undo;
      this.state.defaultarray = {
        [targetname]: {
          position: {
            x: this.props.card_details[targetname]["position"].x,
            y: this.props.card_details[targetname]["position"].y,
          },
          position_new: { x: newx, y: newy },
        },
      };
      this.state.undo.unshift(this.state.defaultarray);
      let value = {
        position: {
          x: this.props.card_details[targetname]["position"].x,
          y: this.props.card_details[targetname]["position"].y,
        },
        position_new: { x: newx, y: newy },
      };
      dataup = this.state.undo;
      this.setState({ undo: dataup });
      this.props.onChangePosition({ targetname, newy, newx });
      //this.props.checkUndoPosition({ value, targetname });
    }
  }

  checkundo() {
    let targetname = "";
    let target = "";
    let name = "";
    let newx = "";
    let newy = "";
    let value = "";
    let mainvalue = "";
    let i = "";
    if (this.props.card_details.undo.length > 0) {
      let undoaction = this.props.card_details.undo[0];
      name = Object.keys(undoaction)[0];
      if (name == "extrafields") {
        i = Object.keys(undoaction[name])[0];
        console.log(Object.values(undoaction[name])[0]);
        let data = Object.keys(Object.values(undoaction[name])[0])[0];
        if (data == "color") {
          let colorvalue = Object.values(Object.values(undoaction[name])[0])[0];
          value = colorvalue;
          this.props.ChangeExtraFieldColor({ i, colorvalue });
          targetname = data;
          this.props.checkRedoStatusE({ i, value, targetname });
          this.props.updateUndoStatus();
        } else if (data == "fontSize") {
          let fontsizevalue = Object.values(
            Object.values(undoaction[name])[0]
          )[0];
          value = fontsizevalue;
          this.props.ChangeExtraFieldFontSize({ i, fontsizevalue });
          targetname = data;
          this.props.checkRedoStatusE({ i, value, targetname });
          this.props.updateUndoStatus();
        } else if (data == "position") {
          value = Object.values(Object.values(undoaction[name]));
          console.log(value);
          targetname = name;
          newx = value[0].position.x;
          newy = value[0].position.y;
          let index = i;
          this.props.echeckRedoPoistoin({ value, targetname, index });
          this.props.onChangePositionExtra({ targetname, newy, newx, index });
          this.props.updateUndoStatus();
        } else if (data == "fontBold") {
          target = data;
          this.props.ChangeExtraFieldBold(i);
          this.props.redoSingleValueE({ i, target });
          this.props.updateUndoStatus();
        } else if (data == "alignText") {
          this.props.updateUndoStatus();
        } else if (data == "fontFamily") {
          let fontfamilyvalue = Object.values(
            Object.values(undoaction[name])[0]
          )[0];
          value = fontfamilyvalue;
          this.props.ChangeExtraFieldFontFamily({ i, fontfamilyvalue });
          targetname = data;
          this.props.checkRedoStatusE({ i, value, targetname });
          this.props.updateUndoStatus();
        } else if (data == "fontItalic") {
          target = data;
          this.props.ChangeExtraFieldItalic(i);
          this.props.redoSingleValueE({ i, target });
          this.props.updateUndoStatus();
        } else if (data == "fontUnderline") {
          target = data;
          this.props.ChangeExtraFieldUnderline(i);
          this.props.redoSingleValueE({ i, target });
          this.props.updateUndoStatus();
        } else if (data == "text") {
          this.props.updateUndoStatus();
        }
      } else {
        let data = Object.keys(undoaction[name])[0];
        console.log(data);
        mainvalue = Object.values(undoaction[name])[0];
        console.log(mainvalue);
        if (data == "color") {
          value = mainvalue;
          targetname = data;
          this.props.ColorChange({ name, value });
          this.props.checkRedoStatus({ name, value, targetname });
          this.props.updateUndoStatus();
        } else if (data == "fontSize") {
          value = mainvalue;
          targetname = data;
          this.props.FontSizeChange({ name, value });
          this.props.checkRedoStatus({ name, value, targetname });
          this.props.updateUndoStatus();
        } else if (data == "position") {
          value = Object.values(undoaction)[0];
          console.log(value.position.x);
          targetname = name;
          newx = value.position.x;
          newy = value.position.y;
          console.log(newx);
          console.log(newy);
          this.props.checkRedoPoistoin({ value, targetname });
          this.props.onChangePosition({ targetname, newy, newx });
          this.props.updateUndoStatus();
        } else if (data == "fontBold") {
          // fbold
          value = "fbold" + name;
          console.log(value);
          this.props.BoldChange(value);
          target = data;
          this.props.redoSingleValue({ name, target });
          this.props.updateUndoStatus();
        } else if (data == "alignText") {
          this.props.updateUndoStatus();
        } else if (data == "fontFamily") {
          value = mainvalue;
          this.props.ChangeFontFamily({ name, value });
          targetname = data;
          this.props.checkRedoStatus({ name, value, targetname });
          this.props.updateUndoStatus();
        } else if (data == "fontItalic") {
          // fitalic
          value = "fitalic" + name;
          console.log(value);
          this.props.ItalicChange(value);
          target = data;
          this.props.redoSingleValue({ name, target });
          this.props.updateUndoStatus();
        } else if (data == "fontUnderline") {
          // funderline
          value = "funderline" + name;
          console.log(value);
          this.props.UnderlineChange(value);
          target = data;
          this.props.redoSingleValue({ name, target });
          this.props.updateUndoStatus();
        } else if (data == "text") {
          this.props.updateUndoStatus();
        }
      }
    } else {
      this.setState({ response: "Make some changes first" });
      this.setState({ responsetype: "error" });
    }
  }

  checkredo() {
    let targetname = "";
    let target = "";
    let name = "";
    let newx = "";
    let newy = "";
    let mainvalue = "";
    let value = "";
    if (this.props.card_details.redo.length > 0) {
      let redoaction = this.props.card_details.redo[0];
      name = Object.keys(redoaction)[0];
      let data = Object.keys(redoaction[name])[0];
      console.log(data);
      mainvalue = Object.values(redoaction[name])[0];
      console.log(mainvalue);
      if (name == "extrafields") {
        let i = Object.keys(redoaction[name])[0];
        console.log(Object.values(redoaction[name])[0]);
        let data = Object.keys(Object.values(redoaction[name])[0])[0];
        if (data == "color") {
          let colorvalue = Object.values(Object.values(redoaction[name])[0])[0];
          targetname = data;
          let index = i;
          this.props.ChangeExtraFieldColor({ i, colorvalue });
          this.props.checkUndoStatusE({ index, value, targetname });
          this.props.updateRedoStatus();
        } else if (data == "fontSize") {
          let fontsizevalue = Object.values(
            Object.values(redoaction[name])[0]
          )[0];
          value = fontsizevalue;
          this.props.ChangeExtraFieldFontSize({ i, fontsizevalue });
          targetname = data;
          let index = i;
          this.props.checkUndoStatusE({ index, value, targetname });
          this.props.updateRedoStatus();
        } else if (data == "position") {
          value = Object.values(redoaction)[0];
          console.log(value);
          targetname = name;
          newx = value[0].position_new.x;
          newy = value[0].position_new.y;
          let index = i;
          this.props.checkUndoPoistoinExtra({ value, targetname });
          this.props.onChangePositionExtra({ targetname, newy, newx, index });
          this.props.updateRedoStatus();
        } else if (data == "fontBold") {
          target = data;
          this.props.ChangeExtraFieldBold(i);
          this.props.undoSingleValueE({ i, target });
          this.props.updateRedoStatus();
        } else if (data == "alignText") {
          this.props.updateRedoStatus();
        } else if (data == "fontFamily") {
          this.props.updateRedoStatus();
        } else if (data == "fontItalic") {
          target = data;
          this.props.ChangeExtraFieldItalic(i);
          this.props.undoSingleValueE({ i, target });
          this.props.updateRedoStatus();
        } else if (data == "fontUnderline") {
          target = data;
          this.props.ChangeExtraFieldUnderline(i);
          this.props.undoSingleValueE({ i, target });
          this.props.updateRedoStatus();
        } else if (data == "text") {
          this.props.updateRedoStatus();
        }
      } else {
        if (data == "color") {
          value = mainvalue;
          targetname = data;
          this.props.ColorChange({ name, value });
          this.props.checkUndoStatus({ name, value, targetname });
          this.props.updateRedoStatus();
        } else if (data == "fontSize") {
          value = mainvalue;
          targetname = data;
          this.props.FontSizeChange({ name, value });
          this.props.checkUndoStatus({ name, value, targetname });
          this.props.updateRedoStatus();
        } else if (data == "position") {
          value = Object.values(redoaction)[0];
          targetname = name;
          newx = value.position_new.x;
          newy = value.position_new.y;
          this.props.checkUndoPoistoin({ value, targetname });
          this.props.onChangePosition({ targetname, newy, newx });
          this.props.updateRedoStatus();
        } else if (data == "fontBold") {
          // fbold
          value = "fbold" + name;
          console.log(value);
          this.props.BoldChange(value);
          target = data;
          this.props.undoSingleValue({ name, target });
          this.props.updateRedoStatus();
        } else if (data == "alignText") {
        } else if (data == "fontFamily") {
        } else if (data == "fontItalic") {
          // fitalic
          value = "fitalic" + name;
          console.log(value);
          target = data;
          this.props.ItalicChange(value);
          this.props.undoSingleValue({ name, target });
          this.props.updateRedoStatus();
        } else if (data == "fontUnderline") {
          // funderline
          value = "funderline" + name;
          console.log(value);
          target = data;
          this.props.UnderlineChange(value);
          this.props.undoSingleValue({ name, target });
          this.props.updateRedoStatus();
        } else if (data == "text") {
        }
      }
    }
  }

  onChangePositionExtra(e, position) {
    let newx = "";
    let newy = "";
    if (position.lastX == null || undefined) {
      newx = 0;
      newy = 0;
    } else {
      newx = position.x;
      newy = position.y;
    }

    let targetname = e.target.id;
    let index = parseInt(e.target.className);
    let value = {
      [index]: {
        position: {
          x: this.props.card_details[targetname][index]["position"].x,
          y: this.props.card_details[targetname][index]["position"].y,
        },
        position_new: { x: newx, y: newy },
      },
    };
    this.props.checkUndoPoistoinExtra({ value, targetname });
    this.props.onChangePositionExtra({ targetname, newy, newx, index });
  }

  onDrop = (e) => {
    this.setState({ activeDrags: --this.state.activeDrags });
    if (e.target.classList.contains("drop-target")) {
      alert("Dropped!");
      e.target.classList.remove("hovered");
    }
  };
  onTouchEnd(ev) {
    var rect = ev.target.getBoundingClientRect();
    console.log(rect.top, rect.right, rect.bottom, rect.left);
  }
  checkupdateposition(e) {
    var rect = e.target.getBoundingClientRect();
    console.log(rect.top, rect.right, rect.bottom, rect.left);
  }
  onDropAreaMouseEnter = (e) => {
    if (this.state.activeDrags) {
      e.target.classList.add("hovered");
    }
  };
  onDropAreaMouseLeave = (e) => {
    e.target.classList.remove("hovered");
  };

  // For controlled component
  adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = this.state.controlledPosition;
    this.setState({ controlledPosition: { x: x - 10, y } });
  };

  adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { controlledPosition } = this.state;
    const { x, y } = controlledPosition;
    this.setState({ controlledPosition: { x, y: y - 10 } });
  };

  onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
  };

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  };

  getallfooterdetails() {
    getallfooterdetails(
      (res) => {
        let data = res.data.data;
        this.setState({ watermarkdata: data });
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading watermark" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  checkforactionsaveandloggedinuser() {
    let queries = queryString.parse(this.props.location.search);
    if (queries.action != undefined) {
      if (
        queries.action == "save" &&
        localStorage.getItem("token") &&
        localStorage.getItem("vcardurole") == 2
      ) {
        setTimeout(
          function () {
            this.setState({ response: "Please save your card." });
            this.setState({ responsetype: "success" });
          }.bind(this),
          5000
        );
      }
    }
  }

  componentDidMount() {
    let id = "";
    let actioncheck = "";
    let updatecardid = "";
    // let signupcheck=''
    let queries = queryString.parse(this.props.location.search);
    localStorage.setItem("cardDesignId", queries.cid);
    updatecardid = queries.cid;
    // signupcheck = localStorage.getItem('savecard');
    localStorage.setItem("action", queries.action);
    actioncheck = queries.action;
    if (queries.id != undefined) {
      id = queries.id;
    }
    localStorage.setItem("designId", id);
    if (actioncheck == "update") {
      this.getcarddetails(updatecardid);
      console.log(this.state.card_orientation);
    } else {
      this.get_templatedata(id);
    }

    this.checkforactionsaveandloggedinuser();
    this.getallfooterdetails();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.card_details);
    // if(nextProps.card_details.undo[0]!=undefined||null){
    // (this.state.undo).unshift(nextProps.card_details.undo[0]);
    // console.log(this.state.undo);
    // let dataup=this.state.undo;
    // console.log(dataup);
    // this.setState({undo:dataup});
    // }
  }
  handleDelete(index) {
    this.props.delEmp(index);
  }
  handleEdit(index) {
    console.log("Edit:", index);
  }
  localStorageUpdated() {
    if (!localStorage.getItem("selectedFile")) {
      alert("not found");
    } else if (!this.state.status) {
      alert("found");
    }
  }

  checkSide(side) {
    if (side == "front") {
      this.setState({ defaultside: side });
    } else {
      this.setState({ defaultside: side });
    }
  }

  checkResize() {
    console.log("resizing");
    this.setState({ resizing: true });
  }

  savetopng() {
    const input = document.getElementById("savefromhere");
    html2canvas(input, {
      watermark: "Monitor WA",
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      saveAs(imgData, "card-design-front.png");
    });
  }

  savetojpg() {
    const input = document.getElementById("imgpreviewmodelBody");
    html2canvas(input, {
      watermark: `Monitor WA`,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpg");
      saveAs(imgData, "card-design.jpg");
      this.setState({ showID: false });
    });
  }

  savetopdf() {
    console.log(this.state.card_orientation);
    const inputpdf = document.getElementById("imgpreviewmodelBody");
    html2canvas(inputpdf, {
      watermark: "Monitor WA",
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      console.log(imgData);
      const pdf = new jsPDF("p", "mm", "A4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      if (this.state.card_orientation != "portrait") {
        pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      } else {
        pdf.addImage(imgData, "JPEG", 0, 0, width / 2, height);
      }
      pdf.save("card-design-front.pdf");
    });
  }

  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    const { ResizableBox } = require("react-resizable");
    const htmlToImage = require("html-to-image");
    const FileSaver = require("file-saver");
    const { deltaPosition, controlledPosition } = this.state;

    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <Modal
          id="imgpreviewmodel"
          show={this.state.showID}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <h5 className="text-center">Are you sure, You want to save your card.</h5> */}
            <p id="imgpreviewmodelBody">
              <p className="text-center" id="previewImg">
                <span id="imgwatermark" className="frontwatermark">
                  {this.state.watermarkdata.watermark}
                </span>
                <img src={this.state.previewImg} />
                {/* You need to login to save your card and it will be saved in your account. If you are not logged in, You will be redirected to login page. */}
              </p>
              <p className="text-center" id="previewImgBack">
                <span id="imgwatermark" className="backwatermark">
                  {this.state.watermarkdata.watermark}
                </span>
                <img src={this.state.previewImgBack} />
                {/* You need to login to save your card and it will be saved in your account. If you are not logged in, You will be redirected to login page. */}
              </p>
            </p>
            <Row>
              <Col style={{ textAlign: "right" }}>
                <Button
                  size="sm"
                  onClick={
                    this.state.downloadType == "pdf"
                      ? this.savetopdf
                      : this.savetojpg
                  }
                >
                  Download
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <HeaderComponent />
        <div className="clearfix">&nbsp;</div>
        <div className="col">
          <Container>
            <Row>
              <Col>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row
                    style={{ border: "solid 1px #1f2744", borderRadius: "3px" }}
                    className="mobiletabs"
                  >
                    <Col className="col-lg col-6">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-fonts"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.258 3h-8.51l-.083 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062 2.434.301 2.693 1.846h.479L12.258 3z" />
                            </svg>
                            <span className="hidemobile">Text</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-file-image"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                              <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z" />
                            </svg>{" "}
                            <span className="hidemobile">Image</span>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link eventKey="third">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-three-dots-vertical"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>{" "}
                            <span className="hidemobile">Templates</span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col className="col-lg col-6">
                      <Nav className=" justify-content-end">
                        <Nav.Item>
                          <Nav.Link className="cred" onClick={this.checkundo}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-counterclockwise"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                              />
                              <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                            </svg>{" "}
                            <span className="hidemobile">Undo</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="cred" onClick={this.checkredo}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-clockwise"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                              />
                              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                            </svg>{" "}
                            <span className="hidemobile">Redo</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <SaveModalComponents />
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <div className="clearfix">&nbsp;</div>
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col sm={3} className="tabsidebar">
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <TextFieldComponent
                                state={this.state}
                                onChange={this.handleTextChange}
                                handleClick={this.changebold}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              {/* {this.state.data} */}
                              <ImageComponent />
                            </Tab.Pane>

                            <Tab.Pane eventKey="third">
                              <TemplatesComponent />
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                        <Col sm={9} className="cardcontainer">
                          <Tab.Container
                            id="bottom-tabs-example"
                            defaultActiveKey="first"
                          >
                            <Row>
                              <Col sm={12}>
                                <Row>
                                  <Col>
                                    <Nav
                                      variant="pills"
                                      className="text-center justify-content-center"
                                    >
                                      <Nav.Item>
                                        <Nav.Link
                                          eventKey="first"
                                          onClick={(e) =>
                                            this.checkSide("front")
                                          }
                                        >
                                          Front Side
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link
                                          eventKey="second"
                                          onClick={(e) =>
                                            this.checkSide("back")
                                          }
                                        >
                                          Back Side
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                  </Col>
                                </Row>
                                <div className="clearfix he-1">&nbsp;</div>
                              </Col>
                              <Col sm={12} className="px-5">
                                <Tab.Content>
                                  <Tab.Pane eventKey="first">
                                    {this.state.card_orientation !=
                                    "portrait" ? (
                                      /****************************************************************************Landscape********************************************/
                                      <div
                                        style={{
                                          display: "block",
                                          border: "solid 1px blue",
                                          borderRadius: "15px",
                                        }}
                                      >
                                        <div
                                          id="savefromhere"
                                          className="col-lg-12"
                                          style={{
                                            background: "#ccc",
                                            borderRadius: "15px",
                                          }}
                                        >
                                          <div
                                            className="col-lg-12 p-4 backgroundimage"
                                            style={{
                                              // background: `url(images/design/${this.props.card_details.templatedesign || 'default.jpg'})`,
                                              background: `url("https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.cardFrontImage}")`,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "cover",
                                              borderRadius: "15px",
                                            }}
                                          >
                                            <div
                                              ref={this.inputRef}
                                              id={"cardheight"}
                                              className="col-lg-12 cardheight justify-content-center"
                                              style={{
                                                height: "100%",
                                                border: "dashed 1px #000",
                                                overflow: "hidden",
                                                position: "relative",
                                                borderRadius: "15px",
                                              }}
                                            >
                                              <div
                                                className="col-12"
                                                style={{ height: "100%" }}
                                              >
                                                <Draggable
                                                  bounds="parent"
                                                  axis="both"
                                                  handle=".handle"
                                                  position={{
                                                    x: this.props.card_details
                                                      .staffid.position.x,
                                                    y: this.props.card_details
                                                      .staffid.position.y,
                                                  }}
                                                  onDrag={this.onControlledDrag}
                                                  onStop={this.onChangePosition}
                                                >
                                                  <div>
                                                    <Row className="handle">
                                                      <Col className="text-center">
                                                        <FormLabel
                                                          className="m-0 p-0 col py-2 d-block"
                                                          id="staffid"
                                                          style={{
                                                            color: `${
                                                              this.props
                                                                .card_details
                                                                .staffid
                                                                .color || "#000"
                                                            }`,
                                                            fontWeight: `${
                                                              this.props
                                                                .card_details
                                                                .staffid
                                                                .fontBold
                                                                ? "bold"
                                                                : "normal"
                                                            }`,
                                                            fontSize: `${
                                                              this.props
                                                                .card_details
                                                                .staffid
                                                                .fontSize ||
                                                              "50px"
                                                            }`,
                                                            fontStyle: `${
                                                              this.props
                                                                .card_details
                                                                .staffid
                                                                .fontItalic
                                                                ? "italic"
                                                                : "normal"
                                                            }`,
                                                            textDecoration: `${
                                                              this.props
                                                                .card_details
                                                                .staffid
                                                                .fontUnderline
                                                                ? "underline"
                                                                : "none"
                                                            }`,
                                                            textAlign: `${
                                                              this.props
                                                                .card_details
                                                                .staffid
                                                                .alignText ||
                                                              "left"
                                                            }`,
                                                            fontFamily: `${
                                                              this.props
                                                                .card_details
                                                                .staffid
                                                                .fontFamily ||
                                                              "arial"
                                                            }`,
                                                          }}
                                                        >
                                                          {
                                                            this.props
                                                              .card_details
                                                              .staffid.text
                                                          }
                                                        </FormLabel>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Draggable>

                                                <Draggable
                                                  bounds="parent"
                                                  axis="both"
                                                  handle=".handle"
                                                  position={{
                                                    x: this.props.card_details
                                                      .firstname.position.x,
                                                    y: this.props.card_details
                                                      .firstname.position.y,
                                                  }}
                                                  {...dragHandlers}
                                                  onDrag={this.onControlledDrag}
                                                  onStop={this.onChangePosition}
                                                >
                                                  <div>
                                                    <Row className="handle">
                                                      <Col className="text-center">
                                                        <FormLabel
                                                          className="m-0 p-0 col d-block text-center py-2"
                                                          id="firstname"
                                                          style={{
                                                            color: `${
                                                              this.props
                                                                .card_details
                                                                .firstname
                                                                .color || "#000"
                                                            }`,
                                                            fontWeight: `${
                                                              this.props
                                                                .card_details
                                                                .firstname
                                                                .fontBold
                                                                ? "bold"
                                                                : "normal"
                                                            }`,
                                                            fontSize: `${
                                                              this.props
                                                                .card_details
                                                                .firstname
                                                                .fontSize ||
                                                              "50px"
                                                            }`,
                                                            fontStyle: `${
                                                              this.props
                                                                .card_details
                                                                .firstname
                                                                .fontItalic
                                                                ? "italic"
                                                                : "normal"
                                                            }`,
                                                            textDecoration: `${
                                                              this.props
                                                                .card_details
                                                                .firstname
                                                                .fontUnderline
                                                                ? "underline"
                                                                : "none"
                                                            }`,
                                                            textAlign: `${
                                                              this.props
                                                                .card_details
                                                                .firstname
                                                                .alignText ||
                                                              "left"
                                                            }`,
                                                            fontFamily: `${
                                                              this.props
                                                                .card_details
                                                                .firstname
                                                                .fontFamily ||
                                                              "arial"
                                                            }`,
                                                          }}
                                                        >
                                                          {
                                                            this.props
                                                              .card_details
                                                              .firstname.text
                                                          }
                                                        </FormLabel>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Draggable>

                                                <Draggable
                                                  bounds="parent"
                                                  axis="both"
                                                  handle=".handle"
                                                  position={{
                                                    x: this.props.card_details
                                                      .lastname.position.x,
                                                    y: this.props.card_details
                                                      .lastname.position.y,
                                                  }}
                                                  {...dragHandlers}
                                                  onDrag={this.onControlledDrag}
                                                  onStop={this.onChangePosition}
                                                >
                                                  <div>
                                                    <Row className="handle">
                                                      <Col className="text-center">
                                                        <FormLabel
                                                          className="m-0 p-0 col d-block text-center py-2"
                                                          id="lastname"
                                                          style={{
                                                            color: `${
                                                              this.props
                                                                .card_details
                                                                .lastname
                                                                .color || "#000"
                                                            }`,
                                                            fontWeight: `${
                                                              this.props
                                                                .card_details
                                                                .lastname
                                                                .fontBold
                                                                ? "bold"
                                                                : "normal"
                                                            }`,
                                                            fontSize: `${
                                                              this.props
                                                                .card_details
                                                                .lastname
                                                                .fontSize ||
                                                              "50px"
                                                            }`,
                                                            fontStyle: `${
                                                              this.props
                                                                .card_details
                                                                .lastname
                                                                .fontItalic
                                                                ? "italic"
                                                                : "normal"
                                                            }`,
                                                            textDecoration: `${
                                                              this.props
                                                                .card_details
                                                                .lastname
                                                                .fontUnderline
                                                                ? "underline"
                                                                : "none"
                                                            }`,
                                                            textAlign: `${
                                                              this.props
                                                                .card_details
                                                                .lastname
                                                                .alignText ||
                                                              "left"
                                                            }`,
                                                            fontFamily: `${
                                                              this.props
                                                                .card_details
                                                                .lastname
                                                                .fontFamily ||
                                                              "arial"
                                                            }`,
                                                          }}
                                                        >
                                                          {
                                                            this.props
                                                              .card_details
                                                              .lastname.text
                                                          }
                                                        </FormLabel>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Draggable>
                                                <Draggable
                                                  bounds="parent"
                                                  axis="both"
                                                  handle=".handle"
                                                  position={{
                                                    x: this.props.card_details
                                                      .position.position.x,
                                                    y: this.props.card_details
                                                      .position.position.y,
                                                  }}
                                                  {...dragHandlers}
                                                  onDrag={this.onControlledDrag}
                                                  onStop={this.onChangePosition}
                                                >
                                                  <div>
                                                    <Row className="handle">
                                                      <Col>
                                                        <FormLabel
                                                          className="m-0 p-0 col-12 d-block text-center py-2"
                                                          id="position"
                                                          style={{
                                                            color: `${
                                                              this.props
                                                                .card_details
                                                                .position
                                                                .color || "#000"
                                                            }`,
                                                            fontWeight: `${
                                                              this.props
                                                                .card_details
                                                                .position
                                                                .fontBold
                                                                ? "bold"
                                                                : "normal"
                                                            }`,
                                                            fontSize: `${
                                                              this.props
                                                                .card_details
                                                                .position
                                                                .fontSize ||
                                                              "50px"
                                                            }`,
                                                            fontStyle: `${
                                                              this.props
                                                                .card_details
                                                                .position
                                                                .fontItalic
                                                                ? "italic"
                                                                : "normal"
                                                            }`,
                                                            textDecoration: `${
                                                              this.props
                                                                .card_details
                                                                .position
                                                                .fontUnderline
                                                                ? "underline"
                                                                : "none"
                                                            }`,
                                                            textAlign: `${
                                                              this.props
                                                                .card_details
                                                                .position
                                                                .alignText ||
                                                              "left"
                                                            }`,
                                                            fontFamily: `${
                                                              this.props
                                                                .card_details
                                                                .position
                                                                .fontFamily ||
                                                              "arial"
                                                            }`,
                                                          }}
                                                        >
                                                          {
                                                            this.props
                                                              .card_details
                                                              .position.text
                                                          }
                                                        </FormLabel>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Draggable>
                                                <Draggable
                                                  bounds="parent"
                                                  axis="both"
                                                  handle=".handle"
                                                  position={{
                                                    x: this.props.card_details
                                                      .phoneno.position.x,
                                                    y: this.props.card_details
                                                      .phoneno.position.y,
                                                  }}
                                                  {...dragHandlers}
                                                  onDrag={this.onControlledDrag}
                                                  onStop={this.onChangePosition}
                                                >
                                                  <div>
                                                    <Row className="handle">
                                                      <Col>
                                                        <FormLabel
                                                          className="m-0 p-0 col-12 d-block text-center py-2"
                                                          id="phoneno"
                                                          style={{
                                                            color: `${
                                                              this.props
                                                                .card_details
                                                                .phoneno
                                                                .color || "#000"
                                                            }`,
                                                            fontWeight: `${
                                                              this.props
                                                                .card_details
                                                                .phoneno
                                                                .fontBold
                                                                ? "bold"
                                                                : "normal"
                                                            }`,
                                                            fontSize: `${
                                                              this.props
                                                                .card_details
                                                                .phoneno
                                                                .fontSize ||
                                                              "14px"
                                                            }`,
                                                            fontStyle: `${
                                                              this.props
                                                                .card_details
                                                                .phoneno
                                                                .fontItalic
                                                                ? "italic"
                                                                : "normal"
                                                            }`,
                                                            textDecoration: `${
                                                              this.props
                                                                .card_details
                                                                .phoneno
                                                                .fontUnderline
                                                                ? "underline"
                                                                : "none"
                                                            }`,
                                                            textAlign: `${
                                                              this.props
                                                                .card_details
                                                                .phoneno
                                                                .alignText ||
                                                              "left"
                                                            }`,
                                                            fontFamily: `${
                                                              this.props
                                                                .card_details
                                                                .phoneno
                                                                .fontFamily ||
                                                              "arial"
                                                            }`,
                                                          }}
                                                        >
                                                          {
                                                            this.props
                                                              .card_details
                                                              .phoneno.text
                                                          }
                                                        </FormLabel>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Draggable>

                                                {this.props.card_details
                                                  .extrafields &&
                                                  this.props.card_details.extrafields.map(
                                                    (item, i) =>
                                                      item.frontback ==
                                                        "front" && (
                                                        <Draggable
                                                          key={i}
                                                          bounds="parent"
                                                          axis="both"
                                                          handle=".handle"
                                                          position={{
                                                            x: item.position.x,
                                                            y: item.position.y,
                                                          }}
                                                          {...dragHandlers}
                                                          onDrag={
                                                            this
                                                              .onControlledDrag
                                                          }
                                                          onStop={
                                                            this
                                                              .onChangePositionExtra
                                                          }
                                                        >
                                                          <div>
                                                            <Row className="handle">
                                                              <div
                                                                id="extrafields"
                                                                className={i}
                                                                style={{
                                                                  fontSize: `${
                                                                    item.fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  display:
                                                                    "inline-block",
                                                                  color: `${
                                                                    item.color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    item.fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    item.fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    item.fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    item.fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                }}
                                                                key={i}
                                                              >
                                                                {item.text}
                                                              </div>
                                                            </Row>
                                                          </div>
                                                        </Draggable>
                                                      )
                                                  )}
                                                {this.props.card_details
                                                  .myuploadedimage.frontback ==
                                                  "front" &&
                                                  this.props.card_details
                                                    .myuploadedimage.file && (
                                                    // <div>
                                                    <Draggable
                                                      bounds="parent"
                                                      axis="both"
                                                      handle=".handle"
                                                      {...dragHandlers}
                                                      position={{
                                                        x: this.props
                                                          .card_details
                                                          .myuploadedimage
                                                          .position.x,
                                                        y: this.props
                                                          .card_details
                                                          .myuploadedimage
                                                          .position.y,
                                                      }}
                                                      onDrag={
                                                        this.onControlledDrag
                                                      }
                                                      onStop={
                                                        this.onChangePosition
                                                      }
                                                    >
                                                      <div>
                                                        <Row className="handle">
                                                          {/* {this.state.selectedFile}  */}
                                                          <Resizable
                                                            id="myuploadedimage"
                                                            className="myuploadedimage"
                                                            defaultSize={{
                                                              width: "auto",
                                                              height: "auto",
                                                            }}
                                                            onResize={
                                                              this.checkResize
                                                            }
                                                          >
                                                            <img
                                                              id="myuploadedimage"
                                                              src={
                                                                this.props
                                                                  .card_details
                                                                  .myuploadedimage
                                                                  .file
                                                                  ? this.props
                                                                      .card_details
                                                                      .myuploadedimage
                                                                      .file
                                                                  : null || ""
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                height: "100%",
                                                              }}
                                                            />
                                                          </Resizable>
                                                        </Row>
                                                      </div>
                                                    </Draggable>
                                                    // </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      //********************************************************** Portrait *****************************************************************************/
                                      <div>
                                        <div
                                          style={{
                                            display: "block",
                                            border: "solid 1px blue",
                                            borderRadius: "15px",
                                            width: "478px",
                                            height: "762px",
                                          }}
                                        >
                                          <div
                                            id="savefromhere"
                                            className="col-lg-12"
                                            style={{
                                              background: "#ccc",
                                              borderRadius: "15px",
                                              width: "478px",
                                              height: "762px",
                                            }}
                                          >
                                            <div
                                              className="col-lg-12 p-4 backgroundimage"
                                              style={{
                                                // background: `url(images/design/${this.props.card_details.templatedesign || 'default.jpg'})`,
                                                background: `url("https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.cardFrontImage}")`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover",
                                                borderRadius: "15px",
                                                width: "478px",
                                                height: "762px",
                                              }}
                                            >
                                              <div
                                                ref={this.inputRef}
                                                id={"cardheight"}
                                                className="col-lg-12 cardheight justify-content-center"
                                                style={{
                                                  height: "100%",
                                                  border: "dashed 1px #000",
                                                  overflow: "hidden",
                                                  position: "relative",
                                                  width: "478px",
                                                  height: "762px",
                                                  borderRadius: "15px",
                                                }}
                                              >
                                                <div
                                                  className="col-12"
                                                  style={{
                                                    width: "478px",
                                                    height: "762px",
                                                  }}
                                                >
                                                  <Draggable
                                                    bounds="parent"
                                                    axis="both"
                                                    handle=".handle"
                                                    position={{
                                                      x: this.props.card_details
                                                        .staffid.position.x,
                                                      y: this.props.card_details
                                                        .staffid.position.y,
                                                    }}
                                                    onDrag={
                                                      this.onControlledDrag
                                                    }
                                                    onStop={
                                                      this.onChangePosition
                                                    }
                                                  >
                                                    <div>
                                                      <Row className="handle">
                                                        <Col className="text-center">
                                                          <FormLabel
                                                            className="m-0 p-0 col py-2 d-block"
                                                            id="staffid"
                                                            style={{
                                                              color: `${
                                                                this.props
                                                                  .card_details
                                                                  .staffid
                                                                  .color ||
                                                                "#000"
                                                              }`,
                                                              fontWeight: `${
                                                                this.props
                                                                  .card_details
                                                                  .staffid
                                                                  .fontBold
                                                                  ? "bold"
                                                                  : "normal"
                                                              }`,
                                                              fontSize: `${
                                                                this.props
                                                                  .card_details
                                                                  .staffid
                                                                  .fontSize ||
                                                                "50px"
                                                              }`,
                                                              fontStyle: `${
                                                                this.props
                                                                  .card_details
                                                                  .staffid
                                                                  .fontItalic
                                                                  ? "italic"
                                                                  : "normal"
                                                              }`,
                                                              textDecoration: `${
                                                                this.props
                                                                  .card_details
                                                                  .staffid
                                                                  .fontUnderline
                                                                  ? "underline"
                                                                  : "none"
                                                              }`,
                                                              textAlign: `${
                                                                this.props
                                                                  .card_details
                                                                  .staffid
                                                                  .alignText ||
                                                                "left"
                                                              }`,
                                                              fontFamily: `${
                                                                this.props
                                                                  .card_details
                                                                  .staffid
                                                                  .fontFamily ||
                                                                "arial"
                                                              }`,
                                                            }}
                                                          >
                                                            {
                                                              this.props
                                                                .card_details
                                                                .staffid.text
                                                            }
                                                          </FormLabel>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Draggable>

                                                  <Draggable
                                                    bounds="parent"
                                                    axis="both"
                                                    handle=".handle"
                                                    position={{
                                                      x: this.props.card_details
                                                        .firstname.position.x,
                                                      y: this.props.card_details
                                                        .firstname.position.y,
                                                    }}
                                                    {...dragHandlers}
                                                    onDrag={
                                                      this.onControlledDrag
                                                    }
                                                    onStop={
                                                      this.onChangePosition
                                                    }
                                                  >
                                                    <div>
                                                      <Row className="handle">
                                                        <Col className="text-center">
                                                          <FormLabel
                                                            className="m-0 p-0 col d-block text-center py-2"
                                                            id="firstname"
                                                            style={{
                                                              color: `${
                                                                this.props
                                                                  .card_details
                                                                  .firstname
                                                                  .color ||
                                                                "#000"
                                                              }`,
                                                              fontWeight: `${
                                                                this.props
                                                                  .card_details
                                                                  .firstname
                                                                  .fontBold
                                                                  ? "bold"
                                                                  : "normal"
                                                              }`,
                                                              fontSize: `${
                                                                this.props
                                                                  .card_details
                                                                  .firstname
                                                                  .fontSize ||
                                                                "50px"
                                                              }`,
                                                              fontStyle: `${
                                                                this.props
                                                                  .card_details
                                                                  .firstname
                                                                  .fontItalic
                                                                  ? "italic"
                                                                  : "normal"
                                                              }`,
                                                              textDecoration: `${
                                                                this.props
                                                                  .card_details
                                                                  .firstname
                                                                  .fontUnderline
                                                                  ? "underline"
                                                                  : "none"
                                                              }`,
                                                              textAlign: `${
                                                                this.props
                                                                  .card_details
                                                                  .firstname
                                                                  .alignText ||
                                                                "left"
                                                              }`,
                                                              fontFamily: `${
                                                                this.props
                                                                  .card_details
                                                                  .firstname
                                                                  .fontFamily ||
                                                                "arial"
                                                              }`,
                                                            }}
                                                          >
                                                            {
                                                              this.props
                                                                .card_details
                                                                .firstname.text
                                                            }
                                                          </FormLabel>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Draggable>

                                                  <Draggable
                                                    bounds="parent"
                                                    axis="both"
                                                    handle=".handle"
                                                    position={{
                                                      x: this.props.card_details
                                                        .lastname.position.x,
                                                      y: this.props.card_details
                                                        .lastname.position.y,
                                                    }}
                                                    {...dragHandlers}
                                                    onDrag={
                                                      this.onControlledDrag
                                                    }
                                                    onStop={
                                                      this.onChangePosition
                                                    }
                                                  >
                                                    <div>
                                                      <Row className="handle">
                                                        <Col className="text-center">
                                                          <FormLabel
                                                            className="m-0 p-0 col d-block text-center py-2"
                                                            id="lastname"
                                                            style={{
                                                              color: `${
                                                                this.props
                                                                  .card_details
                                                                  .lastname
                                                                  .color ||
                                                                "#000"
                                                              }`,
                                                              fontWeight: `${
                                                                this.props
                                                                  .card_details
                                                                  .lastname
                                                                  .fontBold
                                                                  ? "bold"
                                                                  : "normal"
                                                              }`,
                                                              fontSize: `${
                                                                this.props
                                                                  .card_details
                                                                  .lastname
                                                                  .fontSize ||
                                                                "50px"
                                                              }`,
                                                              fontStyle: `${
                                                                this.props
                                                                  .card_details
                                                                  .lastname
                                                                  .fontItalic
                                                                  ? "italic"
                                                                  : "normal"
                                                              }`,
                                                              textDecoration: `${
                                                                this.props
                                                                  .card_details
                                                                  .lastname
                                                                  .fontUnderline
                                                                  ? "underline"
                                                                  : "none"
                                                              }`,
                                                              textAlign: `${
                                                                this.props
                                                                  .card_details
                                                                  .lastname
                                                                  .alignText ||
                                                                "left"
                                                              }`,
                                                              fontFamily: `${
                                                                this.props
                                                                  .card_details
                                                                  .lastname
                                                                  .fontFamily ||
                                                                "arial"
                                                              }`,
                                                            }}
                                                          >
                                                            {
                                                              this.props
                                                                .card_details
                                                                .lastname.text
                                                            }
                                                          </FormLabel>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Draggable>
                                                  <Draggable
                                                    bounds="parent"
                                                    axis="both"
                                                    handle=".handle"
                                                    position={{
                                                      x: this.props.card_details
                                                        .position.position.x,
                                                      y: this.props.card_details
                                                        .position.position.y,
                                                    }}
                                                    {...dragHandlers}
                                                    onDrag={
                                                      this.onControlledDrag
                                                    }
                                                    onStop={
                                                      this.onChangePosition
                                                    }
                                                  >
                                                    <div>
                                                      <Row className="handle">
                                                        <Col>
                                                          <FormLabel
                                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                                            id="position"
                                                            style={{
                                                              color: `${
                                                                this.props
                                                                  .card_details
                                                                  .position
                                                                  .color ||
                                                                "#000"
                                                              }`,
                                                              fontWeight: `${
                                                                this.props
                                                                  .card_details
                                                                  .position
                                                                  .fontBold
                                                                  ? "bold"
                                                                  : "normal"
                                                              }`,
                                                              fontSize: `${
                                                                this.props
                                                                  .card_details
                                                                  .position
                                                                  .fontSize ||
                                                                "50px"
                                                              }`,
                                                              fontStyle: `${
                                                                this.props
                                                                  .card_details
                                                                  .position
                                                                  .fontItalic
                                                                  ? "italic"
                                                                  : "normal"
                                                              }`,
                                                              textDecoration: `${
                                                                this.props
                                                                  .card_details
                                                                  .position
                                                                  .fontUnderline
                                                                  ? "underline"
                                                                  : "none"
                                                              }`,
                                                              textAlign: `${
                                                                this.props
                                                                  .card_details
                                                                  .position
                                                                  .alignText ||
                                                                "left"
                                                              }`,
                                                              fontFamily: `${
                                                                this.props
                                                                  .card_details
                                                                  .position
                                                                  .fontFamily ||
                                                                "arial"
                                                              }`,
                                                            }}
                                                          >
                                                            {
                                                              this.props
                                                                .card_details
                                                                .position.text
                                                            }
                                                          </FormLabel>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Draggable>
                                                  <Draggable
                                                    bounds="parent"
                                                    axis="both"
                                                    handle=".handle"
                                                    position={{
                                                      x: this.props.card_details
                                                        .phoneno.position.x,
                                                      y: this.props.card_details
                                                        .phoneno.position.y,
                                                    }}
                                                    {...dragHandlers}
                                                    onDrag={
                                                      this.onControlledDrag
                                                    }
                                                    onStop={
                                                      this.onChangePosition
                                                    }
                                                  >
                                                    <div>
                                                      <Row className="handle">
                                                        <Col>
                                                          <FormLabel
                                                            className="m-0 p-0 col-12 d-block text-center py-2"
                                                            id="phoneno"
                                                            style={{
                                                              color: `${
                                                                this.props
                                                                  .card_details
                                                                  .phoneno
                                                                  .color ||
                                                                "#000"
                                                              }`,
                                                              fontWeight: `${
                                                                this.props
                                                                  .card_details
                                                                  .phoneno
                                                                  .fontBold
                                                                  ? "bold"
                                                                  : "normal"
                                                              }`,
                                                              fontSize: `${
                                                                this.props
                                                                  .card_details
                                                                  .phoneno
                                                                  .fontSize ||
                                                                "14px"
                                                              }`,
                                                              fontStyle: `${
                                                                this.props
                                                                  .card_details
                                                                  .phoneno
                                                                  .fontItalic
                                                                  ? "italic"
                                                                  : "normal"
                                                              }`,
                                                              textDecoration: `${
                                                                this.props
                                                                  .card_details
                                                                  .phoneno
                                                                  .fontUnderline
                                                                  ? "underline"
                                                                  : "none"
                                                              }`,
                                                              textAlign: `${
                                                                this.props
                                                                  .card_details
                                                                  .phoneno
                                                                  .alignText ||
                                                                "left"
                                                              }`,
                                                              fontFamily: `${
                                                                this.props
                                                                  .card_details
                                                                  .phoneno
                                                                  .fontFamily ||
                                                                "arial"
                                                              }`,
                                                            }}
                                                          >
                                                            {
                                                              this.props
                                                                .card_details
                                                                .phoneno.text
                                                            }
                                                          </FormLabel>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Draggable>

                                                  {this.props.card_details
                                                    .extrafields &&
                                                    this.props.card_details.extrafields.map(
                                                      (item, i) =>
                                                        item.frontback ==
                                                          "front" && (
                                                          <Draggable
                                                            key={i}
                                                            bounds="parent"
                                                            axis="both"
                                                            handle=".handle"
                                                            position={{
                                                              x: item.position
                                                                .x,
                                                              y: item.position
                                                                .y,
                                                            }}
                                                            {...dragHandlers}
                                                            onDrag={
                                                              this
                                                                .onControlledDrag
                                                            }
                                                            onStop={
                                                              this
                                                                .onChangePositionExtra
                                                            }
                                                          >
                                                            <div>
                                                              <Row className="handle">
                                                                <div
                                                                  id="extrafields"
                                                                  className={i}
                                                                  style={{
                                                                    fontSize: `${
                                                                      item.fontSize ||
                                                                      "14px"
                                                                    }`,
                                                                    display:
                                                                      "inline-block",
                                                                    color: `${
                                                                      item.color ||
                                                                      "#000"
                                                                    }`,
                                                                    fontFamily: `${
                                                                      item.fontFamily ||
                                                                      "arial"
                                                                    }`,
                                                                    fontWeight: `${
                                                                      item.fontBold
                                                                        ? "bold"
                                                                        : "normal"
                                                                    }`,
                                                                    fontStyle: `${
                                                                      item.fontItalic
                                                                        ? "italic"
                                                                        : "normal"
                                                                    }`,
                                                                    textDecoration: `${
                                                                      item.fontUnderline
                                                                        ? "underline"
                                                                        : "none"
                                                                    }`,
                                                                  }}
                                                                  key={i}
                                                                >
                                                                  {item.text}
                                                                </div>
                                                              </Row>
                                                            </div>
                                                          </Draggable>
                                                        )
                                                    )}
                                                  {this.props.card_details
                                                    .myuploadedimage
                                                    .frontback == "front" &&
                                                    this.props.card_details
                                                      .myuploadedimage.file && (
                                                      // <div>
                                                      <Draggable
                                                        bounds="parent"
                                                        axis="both"
                                                        handle=".handle"
                                                        {...dragHandlers}
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .myuploadedimage
                                                            .position.x,
                                                          y: this.props
                                                            .card_details
                                                            .myuploadedimage
                                                            .position.y,
                                                        }}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            {/* {this.state.selectedFile}  */}
                                                            <Resizable
                                                              id="myuploadedimage"
                                                              className="myuploadedimage"
                                                              defaultSize={{
                                                                width: "auto",
                                                                height: "auto",
                                                              }}
                                                              onResize={
                                                                this.checkResize
                                                              }
                                                            >
                                                              {this.state
                                                                .card_orientation !=
                                                              "portrait" ? (
                                                                <img
                                                                  id="myuploadedimage"
                                                                  src={
                                                                    this.props
                                                                      .card_details
                                                                      .myuploadedimage
                                                                      .file
                                                                      ? this
                                                                          .props
                                                                          .card_details
                                                                          .myuploadedimage
                                                                          .file
                                                                      : null ||
                                                                        ""
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    height:
                                                                      "100%",
                                                                  }}
                                                                />
                                                              ) : (
                                                                <img
                                                                  id="myuploadedimage"
                                                                  src={
                                                                    this.props
                                                                      .card_details
                                                                      .myuploadedimage
                                                                      .file
                                                                      ? this
                                                                          .props
                                                                          .card_details
                                                                          .myuploadedimage
                                                                          .file
                                                                      : null ||
                                                                        ""
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "478px",
                                                                    height:
                                                                      "762px",
                                                                    borderRadius:
                                                                      "15px",
                                                                  }}
                                                                />
                                                              )}
                                                            </Resizable>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                      // </div>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="second">
                                    {this.state.card_orientation !=
                                    "portrait" ? (
                                      /****************************************************************************Landscape********************************************/
                                      <div
                                        style={{
                                          display: "block",
                                          border: "solid 1px blue",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <div
                                          className="col-lg-12"
                                          style={{ background: "#ccc" }}
                                        >
                                          <div
                                            className="col-lg-12 p-4 backgroundimage"
                                            style={{
                                              background: `url("https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.cardBackImage}")`,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "cover",
                                            }}
                                            id="savefromhereback"
                                          >
                                            <div
                                              className="col-lg-12 cardheight justify-content-center"
                                              style={{
                                                height: "100%",
                                                border: "dashed 1px #000",
                                                overflow: "hidden",
                                                position: "relative",
                                              }}
                                            >
                                              <div
                                                className="col-12 align-middle justify-content-center"
                                                style={{ height: "100%" }}
                                              >
                                                {this.props.card_details
                                                  .extrafields &&
                                                  this.props.card_details.extrafields.map(
                                                    (item, i) =>
                                                      item.frontback ==
                                                        "back" && (
                                                        <Draggable
                                                          key={i}
                                                          bounds="parent"
                                                          axis="both"
                                                          handle=".handle"
                                                          position={{
                                                            x: item.position.x,
                                                            y: item.position.y,
                                                          }}
                                                          {...dragHandlers}
                                                          onDrag={
                                                            this
                                                              .onControlledDrag
                                                          }
                                                          onStop={
                                                            this
                                                              .onChangePositionExtra
                                                          }
                                                        >
                                                          <div>
                                                            <Row className="handle">
                                                              <div
                                                                id="extrafields"
                                                                className={i}
                                                                style={{
                                                                  fontSize: `${
                                                                    item.fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  display:
                                                                    "inline-block",
                                                                  color: `${
                                                                    item.color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    item.fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    item.fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    item.fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    item.fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                }}
                                                                key={i}
                                                              >
                                                                {item.text}
                                                              </div>
                                                            </Row>
                                                          </div>
                                                        </Draggable>
                                                      )
                                                  )}

                                                {this.props.card_details
                                                  .myuploadedimage.frontback ==
                                                  "back" &&
                                                  this.props.card_details
                                                    .myuploadedimage.file && (
                                                    // <div>
                                                    <Draggable
                                                      bounds="parent"
                                                      axis="both"
                                                      handle=".handle"
                                                      {...dragHandlers}
                                                      position={{
                                                        x: this.props
                                                          .card_details
                                                          .myuploadedimage
                                                          .position.x,
                                                        y: this.props
                                                          .card_details
                                                          .myuploadedimage
                                                          .position.y,
                                                      }}
                                                      onDrag={
                                                        this.onControlledDrag
                                                      }
                                                      onStop={
                                                        this.onChangePosition
                                                      }
                                                    >
                                                      <div>
                                                        <Row className="handle">
                                                          <Resizable
                                                            id="myuploadedimage"
                                                            className="myuploadedimage"
                                                            defaultSize={{
                                                              width: "auto",
                                                              height: "auto",
                                                            }}
                                                            onResize={
                                                              this.checkResize
                                                            }
                                                          >
                                                            <img
                                                              id="myuploadedimage"
                                                              src={
                                                                this.props
                                                                  .card_details
                                                                  .myuploadedimage
                                                                  .file
                                                                  ? this.props
                                                                      .card_details
                                                                      .myuploadedimage
                                                                      .file
                                                                  : null || ""
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                height: "100%",
                                                              }}
                                                            />
                                                          </Resizable>
                                                        </Row>
                                                      </div>
                                                    </Draggable>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "block",
                                          border: "solid 1px blue",
                                          borderRadius: "15px",
                                          width: "478px",
                                          height: "762px",
                                        }}
                                      >
                                        <div
                                          className="col-lg-12"
                                          style={{
                                            background: "#ccc",
                                            borderRadius: "15px",
                                            width: "478px",
                                            height: "762px",
                                          }}
                                        >
                                          <div
                                            className="col-lg-12 p-4 backgroundimage"
                                            style={{
                                              background: `url("https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.cardBackImage}")`,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "cover",
                                              borderRadius: "15px",
                                              width: "478px",
                                              height: "762px",
                                            }}
                                            id="savefromhereback"
                                          >
                                            <div
                                              className="col-lg-12 cardheight justify-content-center"
                                              style={{
                                                height: "100%",
                                                border: "dashed 1px #000",
                                                overflow: "hidden",
                                                position: "relative",
                                                borderRadius: "15px",
                                                width: "478px",
                                                height: "762px",
                                              }}
                                            >
                                              <div
                                                className="col-12 align-middle justify-content-center"
                                                style={{ height: "100%" }}
                                              >
                                                {this.props.card_details
                                                  .extrafields &&
                                                  this.props.card_details.extrafields.map(
                                                    (item, i) =>
                                                      item.frontback ==
                                                        "back" && (
                                                        <Draggable
                                                          key={i}
                                                          bounds="parent"
                                                          axis="both"
                                                          handle=".handle"
                                                          position={{
                                                            x: item.position.x,
                                                            y: item.position.y,
                                                          }}
                                                          {...dragHandlers}
                                                          onDrag={
                                                            this
                                                              .onControlledDrag
                                                          }
                                                          onStop={
                                                            this
                                                              .onChangePositionExtra
                                                          }
                                                        >
                                                          <div>
                                                            <Row className="handle">
                                                              <div
                                                                id="extrafields"
                                                                className={i}
                                                                style={{
                                                                  fontSize: `${
                                                                    item.fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  display:
                                                                    "inline-block",
                                                                  color: `${
                                                                    item.color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    item.fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    item.fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    item.fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    item.fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                }}
                                                                key={i}
                                                              >
                                                                {item.text}
                                                              </div>
                                                            </Row>
                                                          </div>
                                                        </Draggable>
                                                      )
                                                  )}

                                                {this.props.card_details
                                                  .myuploadedimage.frontback ==
                                                  "back" &&
                                                  this.props.card_details
                                                    .myuploadedimage.file && (
                                                    // <div>
                                                    <Draggable
                                                      bounds="parent"
                                                      axis="both"
                                                      handle=".handle"
                                                      {...dragHandlers}
                                                      position={{
                                                        x: this.props
                                                          .card_details
                                                          .myuploadedimage
                                                          .position.x,
                                                        y: this.props
                                                          .card_details
                                                          .myuploadedimage
                                                          .position.y,
                                                      }}
                                                      onDrag={
                                                        this.onControlledDrag
                                                      }
                                                      onStop={
                                                        this.onChangePosition
                                                      }
                                                    >
                                                      <div>
                                                        <Row className="handle">
                                                          <Resizable
                                                            id="myuploadedimage"
                                                            className="myuploadedimage"
                                                            defaultSize={{
                                                              width: "auto",
                                                              height: "auto",
                                                            }}
                                                            onResize={
                                                              this.checkResize
                                                            }
                                                          >
                                                            <img
                                                              id="myuploadedimage"
                                                              src={
                                                                this.props
                                                                  .card_details
                                                                  .myuploadedimage
                                                                  .file
                                                                  ? this.props
                                                                      .card_details
                                                                      .myuploadedimage
                                                                      .file
                                                                  : null || ""
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                height: "100%",
                                                              }}
                                                            />
                                                          </Resizable>
                                                        </Row>
                                                      </div>
                                                    </Draggable>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Tab.Pane>
                                </Tab.Content>
                              </Col>
                              <Col sm={12}>
                                <Row>
                                  <div className="clearfix he-1">&nbsp;</div>
                                  <Col className="justify-content-center text-center">
                                    <button
                                      onClick={this.handleShowID}
                                      data-v="img"
                                      type="button"
                                      class="m-1 btn-sm btn btn-primary"
                                    >
                                      Save as Image
                                    </button>
                                    <button
                                      onClick={this.handleShowID}
                                      data-v="pdf"
                                      type="button"
                                      className="m-1 btn-sm btn btn-primary"
                                    >
                                      Save to PDF
                                    </button>
                                  </Col>
                                </Row>
                                <div className="clearfix he-1">&nbsp;</div>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>{" "}
            </Row>
          </Container>
        </div>
        <FooterComponent />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    card_details: state.card_data,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      UpdateCardState,
      onChangePosition,
      ColorChange,
      FontSizeChange,
      updateUndoStatus,
      undoSingleValueE,
      checkUndoStatusE,
      echeckRedoPoistoin,
      ChangeExtraFieldColor,
      checkUndoPoistoinExtra,
      ChangeExtraFieldFontFamily,
      ChangeExtraFieldFontSize,
      ChangeExtraFieldBold,
      ChangeExtraFieldItalic,
      ChangeExtraFieldUnderline,
      BoldChange,
      ChangeFontFamily,
      undoSingleValue,
      redoSingleValue,
      redoSingleValueE,
      checkUndoStatus,
      UnderlineChange,
      ItalicChange,
      updateRedoStatus,
      checkRedoStatus,
      checkRedoStatusE,
      checkUndoPoistoin,
      checkRedoPoistoin,
      onChangePositionExtra,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(DesignerComponent);
