import React, { Component, useState } from 'react';
import {Button,InputGroup,FormControl,FormLabel, Row,Col,Form} from "react-bootstrap-v5";
import * as Icon from 'react-bootstrap-icons';
import { FormErrors } from '../FormErrors';
import FieldFontsDropdownComponent from './FieldFontsDropdownComponent';
import ColorComponent from './ColorComponent';


class QrCodeComponents extends Component{
  constructor (props) {
    super(props);
    }
   

    render() {
      
        return (
          <div>
            <div style={{border:'solid 1px #666',padding:'10px'}}>
                <p className="fw-bold">Set up your QR Code:</p>
                <Row>
      
      <Col>
      <div className="d-grid gap-1">
<InputGroup className= {`mb-0 form-group`}>
    <FormControl
      
      aria-label="Size"
      aria-describedby="basic-addon2"
      name="size" className="form-control" placeholder="http://www.url.com" />
  </InputGroup>
  <div className="clearfix he-1">&nbsp;</div>
</div>
      </Col>
     
    </Row>
    
    <Row>
    <Col>
      <h6>Preview</h6>
     <img src="images/qrcode.png"/>
      </Col>
      <Col className="p-4">
    <Button variant="light" size="sm" className="my-4" style={{border:"1px solid #c1c4c5"}} >Add QR Code</Button>
      
      </Col>
    </Row>
            </div>            
          </div>

        )
    }
}
export default QrCodeComponents;
