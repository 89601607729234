import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DatabaseContext } from "../../contexts/DatabaseContext.js";

export function register_user(
  fname,
  lname,
  uemail,
  phoneno,
  upassword,
  vpassword,
  callback,
  errorcallback
) {
  axios
    .post(`https://office.monitorwa.com.au/tccardorderapi/api/register`, {
      fname: fname,
      lname: lname,
      email: uemail,
      phoneno: phoneno,
      password: upassword,
      password_confirmation: vpassword,
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function login_user(uemail, upassword, callback, errorcallback) {
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/register/login`, {
      params: {
        email: uemail,
        password: upassword,
      },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getmy_profiledetails(callback, errorcallback) {
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/register/getUser`,
      {
        params: { userID: localStorage.getItem("mwauid") },
      }
    )
    .then((res) => {
      // console.log(res);
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getprofiledetailsbyid(userid, callback, errorcallback) {
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/register/getUser`,
      {
        params: { userID: userid },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function emailaddresscheck(email, userid, callback, errorcallback) {
  axios
    .get(
      `http://vcard.nirmalvermicompost.com/API/vcardapi/public/api/emailaddresscheck`,
      {
        params: { email: email, userid: userid },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getallusers(callback, errorcallback) {
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/admin/getallusers`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getdashboarddata(callback, errorcallback) {
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/admin/dashboard`, {
      // params: { token: localStorage.getItem("token") },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updateuserprofile(myprofiledata, callback, errorcallback) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/register/updateprofile`,
      {
        myprofiledata,
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updateuserprofileAdmin(myprofiledata, callback, errorcallback) {
  var data = myprofiledata[0];
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/register/updateprofile`,
      {
        myprofiledata: data,
      }
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function get_templatedata(templateid, callback, errorcallback) {
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/card_templates/gettemplatedata/`,
      {
        params: { id: templateid },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getQueuedCards(id, callback, errorcallback) {
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/queue`, {
      //.get(`http://localhost:3006/api/queue`, {
      params: { userid: id },
    })
    .then((res) => {
      console.log(res);
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function deleteQueuedCard(id, callback, errorcallback) {
  axios
    .delete(`https://office.monitorwa.com.au/tccardorderapi/api/queue`, {
      //.delete(`http://localhost:3006/api/queue`, {
      params: { queueid: id },
    })
    .then((res) => {
      console.log(res);
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function queueCard(templateid, data, callback, errorcallback) {
  let uid = localStorage.getItem("mwauid");
  axios
    .post(`https://office.monitorwa.com.au/tccardorderapi/api/queue`, {
      uid,
      templateid,
      data,
    })
    //.post(`http://localhost:3006/api/queue`, { uid, templateid, data })
    .then((res) => {
      console.log(res);
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function savemycard(formData, callback, errorcallback) {
  var templateID = "";
  var cardData = "";
  var cardName = "";
  var patronID = "";
  var queueID = "";

  for (var key of formData.entries()) {
    if (key[0] == "template_id") {
      templateID = key[1];
    }
    if (key[0] == "carddata") {
      cardData = key[1];
    }
    if (key[0] == "cardname") {
      cardName = key[1];
    }
    if (key[0] == "uid") {
      patronID = key[1];
    }
    if (key[0] == "queueid") {
      queueID = key[1];
    }
  }

  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/cards/savemycard`,
      {
        templateID: templateID,
        patronID: patronID,
        cardName: cardName,
        cardData: cardData,
        queueID: queueID,
      }
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //     "Content-type": "multipart/form-data",
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updatemycard(formData, callback, errorcallback) {
  var templateID = "";
  var cardData = "";
  var cardName = "";
  var patronID = "";
  var id = "";

  for (var key of formData.entries()) {
    if (key[0] == "template_id") {
      templateID = key[1];
    }
    if (key[0] == "carddata") {
      cardData = key[1];
    }
    if (key[0] == "cardname") {
      cardName = key[1];
    }
    if (key[0] == "uid") {
      patronID = key[1];
    }
    if (key[0] == "id") {
      id = key[1];
    }
  }
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/cards/updatemycard`,
      {
        cardID: id,
        templateID: templateID,
        patronID: patronID,
        cardName: cardName,
        cardData: cardData,
      }
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //     "Content-type": "multipart/form-data",
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function movetempTemplateFiles(
  frontFileName,
  rearFileName,
  callback,
  errorcallback
) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/card_templates/moveTempTemplates`,
      //`http://localhost:3006/api/card_templates/moveTempTemplates`,
      {
        frontfile: frontFileName,
        rearfile: rearFileName,
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function savetempdesign(formData, callback, errorcallback) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/card_templates/create`,
      //`http://localhost:3006/api/card_templates/create`,
      formData
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //     "Content-type": "multipart/form-data",
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updatetempdesign(formData, callback, errorcallback) {
  console.log(formData);
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/card_templates/update`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getmycard(callback, errorcallback) {
  let uid = localStorage.getItem("mwauid");
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/cards/`, {
      params: { id: uid },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function DeleteUserData(userid, callback, errorcallback) {
  axios
    .post(
      `http://vcard.nirmalvermicompost.com/API/vcardapi/public/api/admin/users/delete/` +
        userid,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function create_user(
  fname,
  lname,
  uemail,
  phoneno,
  upassword,
  callback,
  errorcallback
) {
  axios
    .post(`https://office.monitorwa.com.au/tccardorderapi/api/register`, {
      fname: fname,
      lname: lname,
      email: uemail,
      phoneno: phoneno,
      password: upassword,
      password_confirmation: upassword,
    })

    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function deleteusercard(cardid, callback, errorcallback) {
  console.log(cardid);
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/cards/deletemycard`,
      cardid

      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function deletetempcard(templateID, callback, errorcallback) {
  //http://localhost:3006/api/
  axios
    .delete(
      `https://office.monitorwa.com.au/tccardorderapi/api/card_templates/?id=${templateID}`
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function deletecategory(catid, callback, errorcallback) {
  axios
    .post(
      `http://vcard.nirmalvermicompost.com/API/vcardapi/public/api/admin/category/delete`,
      catid,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updatecategory(catid, callback, errorcallback) {
  axios
    .put(`https://office.monitorwa.com.au/tccardorderapi/api/category`, catid, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function userAccessControl(userid, callback, errorcallback) {
  axios
    .post(
      `http://vcard.nirmalvermicompost.com/API/vcardapi/public/api/admin/users/access`,
      userid,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function userDelete(userid, callback, errorcallback) {
  axios
    .post(
      `http://vcard.nirmalvermicompost.com/API/vcardapi/public/api/admin/users/delete`,
      userid,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function deleteslider(id, callback, errorcallback) {
  axios
    .delete(
      `https://office.monitorwa.com.au/tccardorderapi/api/admin/cms/slider//?id=${id}`
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function usercardAccessControl(userid, callback, errorcallback) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/cards/setactive`,
      userid
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updateTransaction(data, callback, errorcallback) {
  //http://localhost:3006/api/
  //https://office.monitorwa.com.au/tccardorderapi/api/order/create
  axios
    .put(
      `https://office.monitorwa.com.au/tccardorderapi/api/order/transaction`,
      data
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function buyusercards(itemsdata, callback, errorcallback) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/order/create`,
      //`http://localhost:3006/api/order/create`,
      itemsdata
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getallcards(callback, errorcallback) {
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/cards/allcards`,
      {}
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getallorders(callback, errorcallback) {
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/order/allorders`, {
      // headers: {
      //   Authorization: "Bearer " + localStorage.getItem("token"),
      // },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getmycardorders(userID, callback, errorcallback) {
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/order`, {
      params: { id: userID },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getallcardsteamplate(callback, errorcallback) {
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/card_templates`, {
      // headers: {
      //   Authorization: "Bearer " + localStorage.getItem("token"),
      // },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getallcardcategory(callback, errorcallback) {
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/category`
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function createcategory(formData, callback, errorcallback) {
  console.log(formData);
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/category`,
      formData
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getallcardstemplatelist(callback, errorcallback) {
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/card_templates`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export async function uploadtemporarytemplatefile(
  uploadfile,
  callback,
  errorcallback
) {
  await axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/card_templates/uploadtempfile`,
      //`http://localhost:3006/api/card_templates/uploadtempfile`,
      uploadfile
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function uploadtemplatefile(uploadfile, callback, errorcallback) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/card_templates/uploadfile`,
      //`http://localhost:3006/api/order/create`,
      uploadfile
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getallfooterdetails(callback, errorcallback) {
  //const { apiURL } = useContext(DatabaseContext);
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/admin/cms/settings`
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function verifymailfunction(token, email, callback, errorcallback) {
  axios
    .post(
      `http://vcard.nirmalvermicompost.com/API/vcardapi/public/api/email-verify`,
      { body: { token: token, email: email } }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getallslider(callback, errorcallback) {
  //http://localhost:3006/api/admin/cms/settings
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/admin/cms/slider`)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getcategorylist(callback, errorcallback) {
  axios
    .get(`https://office.monitorwa.com.au/tccardorderapi/api/category`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export async function getcarddetails(id, callback, errorcallback) {
  await axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/cards/getcarddetails`,
      { params: { id: id } }
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function get_Utemplatedata(id, callback, errorcallback) {
  axios
    .get(
      `http://vcard.nirmalvermicompost.com/API/vcardapi/public/api/admin/card_templates/` +
        id,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getcategorydata(id, callback, errorcallback) {
  axios
    .get(
      `http://vcard.nirmalvermicompost.com/API/vcardapi/public/api/admin/getcategorydetails`,
      { params: { id: id } },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function gettempcarddetails(id, callback, errorcallback) {
  //Here
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/admin/card_templates/` +
        id
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getordersdetails(id, callback, errorcallback) {
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/order/transaction`,
      {
        params: { transID: id },
      }
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updateorderstatus(id, callback, errorcallback) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/order/update_status`,
      //`http://localhost:3006/api/order/update_status`,
      id,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function cardprinted(id, transactionID, callback, errorcallback) {
  axios
    .put(
      `https://office.monitorwa.com.au/tccardorderapi/api/order/cardPrinted/?cardID=${id}&transactionID=${transactionID}`
      //`http://localhost:3006/api/order/cardPrinted/?cardID=${id}&transactionID=${transactionID}`
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getorderlines(id, callback, errorcallback) {
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/order/orderLines`,
      {
        params: { transID: id },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getuserordersdetails(id, callback, errorcallback) {
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/order/transaction`,
      {
        params: { transID: id },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updateuserorderstatus(id, callback, errorcallback) {
  axios
    .post(
      `http://vcard.nirmalvermicompost.com/API/vcardapi/public/api/order`,
      id,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updateslider(data, callback, errorcallback) {
  axios
    .put(
      `https://office.monitorwa.com.au/tccardorderapi/api/admin/cms/slider/`,
      data
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function passwordChange(password, callback, errorcallback) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/register/updatePassword`,
      password
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function forgotpassword(mail, callback, errorcallback) {
  let formData = { email: mail };
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/admin/forgot_password`,
      formData
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function resetpassword(formData, callback, errorcallback) {
  // let formData = {email:mail}
  console.log(formData);
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/register/updatePassword`,
      formData
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function onFileUpload(formData, callback, errorcallback) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/register/uploadProfileImage`,
      formData
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //     "Content-type": "multipart/form-data",
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function onSliderUpload(formData, callback, errorcallback) {
  axios
    .post(
      `https://office.monitorwa.com.au/tccardorderapi/api/admin/cms/slider`,
      //`http://localhost:3006/api/admin/cms/slider`,
      formData
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //     "Content-type": "multipart/form-data",
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function updateingsetting(formData, callback, errorcallback) {
  for (var key of formData.entries()) {
    console.log(key[0] + ", " + key[1]);
  }
  axios
    .put(
      `https://office.monitorwa.com.au/tccardorderapi/api/admin/cms/settings`,
      formData
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //     "Content-type": "multipart/form-data",
      //   },
      // }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export function getmytemplates(userid, callback, errorcallback) {
  axios
    .get(
      `https://office.monitorwa.com.au/tccardorderapi/api/card_templates/mytemplates`,
      {
        params: { id: userid },
      }
    )
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
