import React, { Component, useState } from "react";
import "../App.css";
import HeaderComponent from "./HeaderComponent";
import { getallfooterdetails } from "./apiserve/ApiComponent";
import FooterComponent from "./FooterComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
} from "react-bootstrap-v5";
class TermsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsdetails: [],
    };
  }

  getallfooterdetails() {
    getallfooterdetails(
      (res) => {
        let data = res.data.data[0][0];
        this.setState({ termsdetails: data });
        console.log(this.state.termsdetails);
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading Terms and Conditions" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    this.getallfooterdetails();
  }

  render() {
    return (
      <div>
        <HeaderComponent />
        <div className="clearfix">&nbsp;</div>
        <div className="container">
          <div className="row">
            <div className="col citem1 text-left">
              <div className="col text-center">
                <h1>Terms & Conditions</h1>
              </div>
              <div className="col mt-4">
                <p className="contactp text-wrapp">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.termsdetails.terms_conditions,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div clasName="clearfix">&nbsp;</div>
        <FooterComponent />
      </div>
    );
  }
}
export default TermsComponent;
