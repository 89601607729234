import React, { Component, useState } from 'react';
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import DashboardUserComponents from "./DashboardUserComponents";
import { BrowserRouter as Router, Route, Link, NavLink, Switch } from 'react-router-dom'  
import './dashboard.css';
class DashboardComponents extends Component{
 
    render() {

        return (
          <div>
              <TopBarComponent/>
              <div className="container-fluid">
  <div className="row">
    
  <SideBarComponent/>              
  <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Dashboard</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
            <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
          </div>
          <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
            <span data-feather="calendar"></span>
            This week
          </button>
        </div>
      </div>

    {/* card */}
    <div className="row">

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Recent</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                Saved</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
                                            </div>
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-auto">
                                                    <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
                                                </div>
                                                <div className="col">
                                                    <div className="progress progress-sm mr-2">
                                                        <div className="progress-bar bg-info" role="progressbar" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                Pending </div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    {/* card */}


{/* recent customers */}
<div className="row">
              <div className="col-xl-12 col-sm-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body px-0 overflow-auto">
                    <h4 className="card-title pl-4 m-3">Purchase History</h4>
                    <div className="table-responsive">
                      <table className="table">
                        <thead className="bg-light">
                          <tr>
                            <th>Customer</th>
                            <th>Order</th>
                            <th>Invoice</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img src="../images/face1.jpg" alt="image" className="face" />
                                <div className="table-user-name ml-3">
                                  <p className="mb-0 font-weight-medium"> Cecelia Cooper </p>
                                  <small> Payment on hold</small>
                                </div>
                              </div>
                            </td>
                            <td>Business Cards</td>
                            <td>
                              <div className="badge badge-inverse-success green"> Completed </div>
                            </td>
                            <td>$ 77.99</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img src="../images/face10.jpg" alt="image" className="face" />
                                <div className="table-user-name ml-3">
                                  <p className="mb-0 font-weight-medium"> Victor Watkins </p>
                                  <small>Payment under processing</small>
                                </div>
                              </div>
                            </td>
                            <td>Business Cards</td>
                            <td>
                              <div className="badge badge-inverse-success green"> Completed </div>
                            </td>
                            <td>$245.30</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img src="../images/face11.jpg" alt="image" className="face" />
                                <div className="table-user-name ml-3">
                                  <p className="mb-0 font-weight-medium"> Ada Burgess </p>
                                  <small>Payment under processing</small>
                                </div>
                              </div>
                            </td>
                            <td>Employee Cards</td>
                            <td>
                              <div className="badge badge-inverse-danger red"> Completed </div>
                            </td>
                            <td>$ 160.25</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img src="../images/face13.jpg" alt="image" className="face" />
                                <div className="table-user-name ml-3">
                                  <p className="mb-0 font-weight-medium"> Dollie Lynch </p>
                                  <small>Payment under processing</small>
                                </div>
                              </div>
                            </td>
                            <td>College Cards</td>
                            <td>
                              <div className="badge badge-inverse-success green"> Declined </div>
                            </td>
                            <td>$ 123.21</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <img src="../images/face16.jpg" alt="image" className="face"  />
                                <div className="table-user-name ml-3">
                                  <p className="mb-0 font-weight-medium"> Harry Holloway </p>
                                  <small>Payment on process</small>
                                </div>
                              </div>
                            </td>
                            <td>Student Cards</td>
                            <td>
                              <div className="badge badge-inverse-danger red"> Declined </div>
                            </td>
                            <td>$ 150.00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Link to="/admin/card-orders" className="text-black m-3 d-block pl-4">
                    <span className="font-weight-medium h6">View all order history</span>
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    
                  </div>
                </div>
              </div>
           
            </div>
{/* recent customers */}
      
      {/* <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">User Name</th>
              <th scope="col">Email Address</th>
              <th scope="col">Company</th>
              <th scope="col">Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>William Riley</td>
              <td>WilliamRiley@email.com</td>
              <td>placeholder</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Julian Harris</td>
              <td>JulianHarris@email.com</td>
              <td>visual</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Fiona Rogers</td>
              <td>Fiona.Rogers@emaila.com</td>
              <td>dashboard</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Haris Parker</td>
              <td>HarisLParker@test.com</td>
              <td>illustrative</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>4</td>
              <td>George Hunt</td>
              <td>George@cardbuilder.com</td>
              <td>layout</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Adam Ross</td>
              <td>rossadams@gmail.com</td>
              <td>text</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Maddie Evans</td>
              <td>madevans@company.com</td>
              <td>rich</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Savana Parker</td>
              <td>sparker@gmail.com</td>
              <td>information</td>
              <td>238-3234-25</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Daisy Wells</td>
              <td>daisywells@email.com</td>
              <td>placeholder</td>
              <td>342-8777-95</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Owen Dixon</td>
              <td>Owendixon@gmail.com</td>
              <td>visual</td>
              <td>333-8777-95</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Adelaide Bailey</td>
              <td>Adelaide@cardbuilder.com</td>
              <td>dashboard</td>
              <td>228-3457-95</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Nicholas Elliott</td>
              <td>Nicholas9333@email.com</td>
              <td>illustrative</td>
              <td>444-8777-95</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Aida Ryan</td>
              <td>Ryan.a@email.com</td>
              <td>layout</td>
              <td>228-8777-88</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Henry Cole</td>
              <td>Henrycolehenry@email.com</td>
              <td>text</td>
              <td>228-6666-95</td>
            </tr>
            <tr>
              <td>14</td>
              <td>Spike Wright</td>
              <td>Spikewright@gmaill.com</td>
              <td>rich</td>
              <td>328-8777-95</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Spike Wright</td>
              <td>Spik1ewright@gmaill.com</td>
              <td>information</td>
              <td>228-8777-95</td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </main>
  </div>
</div>
      
          </div>
       

        )
    }
}
export default DashboardComponents;
