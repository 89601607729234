const reducer = (state = "", action) => {
  switch (action.type) {
    case "ADD_CARD_DETAILS":
      return (state = action.payload);

    case "UPDATE_CARD_STATE":
      let newstate = action.payload;
      console.log(newstate);
      //   return state=newstate;
      state["templatedesign"] = newstate["templatedesign"];
      let afield = state["templatedesign"];
      afield = newstate["templatedesign"];
      state = {
        ...state,
        templatedesign: afield,
      };
      let bfield = state.staffid;

      bfield["text"] = newstate["staffid"]["text"];
      bfield["color"] = newstate["staffid"]["color"];
      bfield["fontSize"] = newstate["staffid"]["fontSize"];
      bfield["fontBold"] = newstate["staffid"]["fontBold"];
      bfield["fontFamily"] = newstate["staffid"]["fontFamily"];
      bfield["fontItalic"] = newstate["staffid"]["fontItalic"];
      bfield["fontUnderline"] = newstate["staffid"]["fontUnderline"];
      bfield["position"] = newstate["staffid"]["position"];
      state = {
        ...state,
        staffid: bfield,
      };

      let cfield = state.firstname;
      cfield["text"] = newstate["firstname"]["text"];
      cfield["color"] = newstate["firstname"]["color"];
      cfield["fontSize"] = newstate["firstname"]["fontSize"];
      cfield["fontBold"] = newstate["firstname"]["fontBold"];
      cfield["fontFamily"] = newstate["firstname"]["fontFamily"];
      cfield["fontItalic"] = newstate["firstname"]["fontItalic"];
      cfield["fontUnderline"] = newstate["firstname"]["fontUnderline"];
      cfield["position"]["x"] = newstate["firstname"]["position"]["x"];
      cfield["position"]["y"] = newstate["firstname"]["position"]["y"];
      state = {
        ...state,
        firstname: cfield,
      };

      let dfield = state.lastname;
      dfield["text"] = newstate["lastname"]["text"];
      dfield["color"] = newstate["lastname"]["color"];
      dfield["fontSize"] = newstate["lastname"]["fontSize"];
      dfield["fontBold"] = newstate["lastname"]["fontBold"];
      dfield["fontFamily"] = newstate["lastname"]["fontFamily"];
      dfield["fontItalic"] = newstate["lastname"]["fontItalic"];
      dfield["fontUnderline"] = newstate["lastname"]["fontUnderline"];
      dfield["position"] = newstate["lastname"]["position"];
      state = {
        ...state,
        lastname: dfield,
      };

      let efield = state.position;
      efield["text"] = newstate["position"]["text"];
      efield["color"] = newstate["position"]["color"];
      efield["fontSize"] = newstate["position"]["fontSize"];
      efield["fontBold"] = newstate["position"]["fontBold"];
      efield["fontFamily"] = newstate["position"]["fontFamily"];
      efield["fontItalic"] = newstate["position"]["fontItalic"];
      efield["fontUnderline"] = newstate["position"]["fontUnderline"];
      efield["position"] = newstate["position"]["position"];
      state = {
        ...state,
        position: efield,
      };

      let ffield = state.phoneno;
      ffield["text"] = newstate["phoneno"]["text"];
      ffield["color"] = newstate["phoneno"]["color"];
      ffield["fontSize"] = newstate["phoneno"]["fontSize"];
      ffield["fontBold"] = newstate["phoneno"]["fontBold"];
      ffield["fontFamily"] = newstate["phoneno"]["fontFamily"];
      ffield["fontItalic"] = newstate["phoneno"]["fontItalic"];
      ffield["fontUnderline"] = newstate["phoneno"]["fontUnderline"];
      ffield["position"] = newstate["phoneno"]["position"];
      state = {
        ...state,
        phoneno: ffield,
      };

      let uploadimage = state.myuploadedimage;
      //  uploadimage['file']=newstate['myuploadedimage']['file'];
      uploadimage["frontback"] = newstate["myuploadedimage"]["frontback"];
      uploadimage["position"]["x"] =
        newstate["myuploadedimage"]["position"]["x"];
      uploadimage["position"]["y"] =
        newstate["myuploadedimage"]["position"]["y"];
      state = {
        ...state,
        myuploadedimage: uploadimage,
      };

      let ufield = [];
      state = {
        ...state,
        undo: ufield,
      };
      let rfield = [];
      state = {
        ...state,
        redo: rfield,
      };
      return state;

    case "UPDATE_FIELD_VALUE":
      let name = action.payload.name;
      let value = action.payload.value;
      let myfield = state[name];
      myfield["text"] = value;
      let newState = {
        ...state,
        [action.payload.name]: myfield,
      };

      return newState;

    case "UPDATE_FIELD_VALUE_DATA":
      let dataname = action.payload.name;
      let datavalue = action.payload.value;
      let myfielddata = state[dataname];
      myfielddata = datavalue;
      let newStatedata = {
        ...state,
        [action.payload.name]: myfielddata,
      };

      console.log(myfielddata);

      return newStatedata;

    case "CHANGE_COLOR_VALUE":
      let colorname = action.payload.name;
      colorname = colorname.replace(/colorpicker/g, "");
      let colorvalue = action.payload.value;
      let colormyfield = state[colorname];
      colormyfield["color"] = colorvalue;
      // let myfield = state[name]['text']=value;
      // let fieldtext = [...state.name];
      let colorState = {
        ...state,
        [colorname]: colormyfield,
      };
      // console.log(colorname);
      // getextrafield[changefieldindex]=changefieldvalue;
      // /console.log(colormyfield);
      return colorState;

    case "CHANGE_FONT_VALUE":
      let fontSizename = action.payload.name;
      fontSizename = fontSizename.replace(/fontsize/g, "");
      let fontSizevalue = action.payload.value;
      console.log(fontSizevalue);
      console.log(typeof fontSizevalue);
      let fontSizemyfield = state[fontSizename];
      fontSizemyfield["fontSize"] = fontSizevalue + "px";
      // let myfield = state[name]['text']=value;
      // let fieldtext = [...state.name];
      let fontSizeState = {
        ...state,
        [fontSizename]: fontSizemyfield,
      };
      // console.log(colorname);
      // getextrafield[changefieldindex]=changefieldvalue;
      // /console.log(colormyfield);
      return fontSizeState;

    case "CHANGE_FONT_FAMILY":
      let fontfamiliyname = action.payload.name;
      fontfamiliyname = fontfamiliyname.replace(/fontfamily/g, "");
      let fontfamiliyvalue = action.payload.value;
      let fontfamiliymyfield = state[fontfamiliyname];
      let undofontfamiliymyfield = state["undo"];
      if (undofontfamiliymyfield)
        undofontfamiliymyfield.unshift({
          [fontfamiliyname]: { fontFamily: fontfamiliyvalue },
        });
      fontfamiliymyfield["fontFamily"] = fontfamiliyvalue;
      let fontfamiliyState = {
        ...state,
        [fontfamiliyname]: fontfamiliymyfield,
        ["undo"]: undofontfamiliymyfield,
      };
      return fontfamiliyState;

    case "CHANGE_BOLD_VALUE":
      let boldname = action.payload;
      console.log(boldname);
      boldname = boldname.replace(/fbold/g, "");
      let boldnamemyfield = state[boldname];
      boldnamemyfield["fontBold"] = boldnamemyfield["fontBold"] ? false : true;
      let boldnameState = {
        ...state,
        [boldname]: boldnamemyfield,
      };
      //  console.log(boldnameState);
      return boldnameState;

    case "CHANGE_ALIGN_VALUE":
      let alignname = action.payload;
      console.log(alignname);
      alignname = alignname.replace(/falign/g, "");
      let alignnamemyfield = state[alignname];
      alignnamemyfield["fontAlign"] = alignnamemyfield["fontAlign"]
        ? false
        : true;
      let alignnameState = {
        ...state,
        [alignname]: alignnamemyfield,
      };
      //  console.log(boldnameState);
      return alignnameState;

    case "CHANGE_ITALIC_VALUE":
      let italicname = action.payload;
      italicname = italicname.replace(/fitalic/g, "");
      let italicnamemyfield = state[italicname];
      italicnamemyfield["fontItalic"] = italicnamemyfield["fontItalic"]
        ? false
        : true;
      let italicnameState = {
        ...state,
        [italicname]: italicnamemyfield,
      };
      //  console.log(italicnameState);
      return italicnameState;

    case "CHANGE_UNDERLINE_VALUE":
      let underlinename = action.payload;
      underlinename = underlinename.replace(/funderline/g, "");
      let underlinenamemyfield = state[underlinename];
      underlinenamemyfield["fontUnderline"] = underlinenamemyfield[
        "fontUnderline"
      ]
        ? false
        : true;
      let underlinenameState = {
        ...state,
        [underlinename]: underlinenamemyfield,
      };
      //  console.log(underlinenameState);
      return underlinenameState;

    case "CHANGE_PARA_VALUE":
      let aligntextname = action.payload.name;
      aligntextname = aligntextname.replace(/para/g, "");
      let aligntextvalue = action.payload.value;
      let aligntextmyfield = state[aligntextname];
      aligntextmyfield["alignText"] = aligntextvalue;
      // let myfield = state[name]['text']=value;
      // let fieldtext = [...state.name];
      let aligntextState = {
        ...state,
        [aligntextname]: aligntextmyfield,
      };
      // console.log(colorname);
      // getextrafield[changefieldindex]=changefieldvalue;
      // /console.log(colormyfield);
      return aligntextState;

    case "REMOVE_EXTRA_FIELD":
      let fieldindex = action.payload;
      // let newState = {
      //     ...state,
      //     [action.payload.name]: value
      //  }
      // console.log('Field Index:', fieldindex);
      let storedata = [...state.extrafields];
      // console.log("index before splice", fieldindex);
      storedata.splice(fieldindex, 1);
      // console.log("after splice",storedata);
      return {
        ...state,
        extrafields: storedata,
      };

    case "ADD_EXTRA_FIELD":
      let extrafield = [...state.extrafields];
      return {
        ...state,
        extrafields: [
          ...extrafield,
          {
            text: "",
            showHide: false,
            color: "#000",
            fontBold: false,
            fontItalic: false,
            fontUnderline: false,
            alignText: "left",
            fontSize: "14px",
            fontFamily: "arial",
            position: { x: 0, y: 0 },
            frontback: "front",
          },
        ],
      };
    // return [...state, ''];

    case "CHANGE_EXTRA_FIELD":
      let changefieldindex = action.payload.i;
      let changefieldvalue = action.payload.cvalue;
      //  console.log("FIndeX:",changefieldindex);
      // console.log("Value:",changefieldvalue);
      let getextrafield = [...state.extrafields];
      getextrafield[changefieldindex]["text"] = changefieldvalue;
      console.log(getextrafield);
      return {
        ...state,
        extrafields: getextrafield,
      };

    case "SHOWHIDE_EXTRA_FIELD":
      let showhidefieldindex = action.payload;
      let showhideextrafield = [...state.extrafields];
      showhideextrafield[showhidefieldindex]["showHide"] = showhideextrafield[
        showhidefieldindex
      ]["showHide"]
        ? false
        : true;
      // console.log(showhideextrafield[showhidefieldindex]['showHide']);
      return {
        ...state,
        extrafields: showhideextrafield,
      };

    case "CHANGE_EXTRA_FIELD_COLOR":
      let changefieldcolorindex = action.payload.i;
      let changefieldcolorvalue = action.payload.colorvalue;
      //   console.log("FIndeX:",changefieldcolorindex);
      //  console.log("Value:",changefieldcolorvalue);
      let getextrafieldcolor = [...state.extrafields];
      getextrafieldcolor[changefieldcolorindex]["color"] =
        changefieldcolorvalue;
      // console.log(getextrafieldcolor);
      return {
        ...state,
        extrafields: getextrafieldcolor,
      };

    case "CHANGE_EXTRA_FIELD_FONTSIZE":
      let changefieldfontsizeindex = action.payload.i;
      let changefieldfontsizevalue = action.payload.fontsizevalue;
      //   console.log("FIndeX:",changefieldfontsizeindex);
      //  console.log("Value:",changefieldfontsizevalue);
      let getextrafieldfontsize = [...state.extrafields];
      getextrafieldfontsize[changefieldfontsizeindex]["fontSize"] =
        changefieldfontsizevalue + "px";
      // console.log(getextrafieldcolor);
      return {
        ...state,
        extrafields: getextrafieldfontsize,
      };

    case "CHANGE_EXTRA_FIELD_FONTFAMILY":
      let changefieldfontfamilyindex = action.payload.i;
      let changefieldfontfamilyvalue = action.payload.fontfamilyvalue;
      let getextrafieldfontfamily = [...state.extrafields];
      let eundofontfamiliymyfield = state["undo"];
      if (eundofontfamiliymyfield)
        eundofontfamiliymyfield.unshift({
          extrafields: {
            [changefieldfontfamilyindex]: {
              fontFamily: changefieldfontfamilyvalue,
            },
          },
        });
      getextrafieldfontfamily[changefieldfontfamilyindex]["fontFamily"] =
        changefieldfontfamilyvalue;
      return {
        ...state,
        extrafields: getextrafieldfontfamily,
        ["undo"]: eundofontfamiliymyfield,
      };

    case "CHANGE_EXTRA_FIELD_BOLD":
      let changefieldboldindex = action.payload;
      //   console.log("FIndeX:",changefieldboldindex);
      //  console.log("Value:",changefieldboldvalue);
      let getextrafieldbold = [...state.extrafields];
      getextrafieldbold[changefieldboldindex]["fontBold"] = getextrafieldbold[
        changefieldboldindex
      ]["fontBold"]
        ? false
        : true;
      // console.log(getextrafieldcolor);
      return {
        ...state,
        extrafields: getextrafieldbold,
      };

    case "CHANGE_EXTRA_FIELD_ITALIC":
      let changefielditalicindex = action.payload;
      //   console.log("FIndeX:",changefielditalicindex);
      //  console.log("Value:",changefielditalicvalue);
      let getextrafielditalic = [...state.extrafields];
      getextrafielditalic[changefielditalicindex]["fontItalic"] =
        getextrafielditalic[changefielditalicindex]["fontItalic"]
          ? false
          : true;
      // console.log(getextrafieldcolor);
      return {
        ...state,
        extrafields: getextrafielditalic,
      };

    case "CHANGE_EXTRA_FIELD_UNDERLINE":
      let changefieldunderlineindex = action.payload;
      //   console.log("FIndeX:",changefieldunderlineindex);
      //  console.log("Value:",changefieldunderlinevalue);
      let getextrafieldunderline = [...state.extrafields];
      getextrafieldunderline[changefieldunderlineindex]["fontUnderline"] =
        getextrafieldunderline[changefieldunderlineindex]["fontUnderline"]
          ? false
          : true;
      // console.log(getextrafieldcolor);
      return {
        ...state,
        extrafields: getextrafieldunderline,
      };

    case "CHANGE_FRONTBACK_EXTRAFIELD":
      let changefieldfrontbackindex = action.payload.i;
      let changefieldfrontbackvalue = action.payload.frontbackvalue;
      //    console.log("FIndeX:",changefieldfrontbackindex);
      //   console.log("Value:",changefieldfrontbackvalue);
      let getextrafieldfrontback = [...state.extrafields];
      getextrafieldfrontback[changefieldfrontbackindex]["frontback"] =
        changefieldfrontbackvalue;
      // console.log(getextrafieldcolor);
      return {
        ...state,
        extrafields: getextrafieldfrontback,
      };

    case "UPLOAD_MY_IMAGE":
      let image = action.payload;
      let imagemyfield = state.myuploadedimage;
      imagemyfield["file"] = image;
      return {
        ...state,
        myuploadedimage: imagemyfield,
      };

    case "UPLOAD_MY_IMAGE_LOGO":
      // let Ltemplate = action.payload.image;
      let Ltemplatebg = action.payload.imagebg;
      // let Limagemytempfield = '';
      let Limagebgcard = "";
      // Limagemytempfield=Ltemplate;
      Limagebgcard = Ltemplatebg;
      return {
        ...state,
        // Ltemplatedesign: Limagemytempfield,
        Lcardbgimage: Limagebgcard,
      };

    case "UPLOAD_MY_F_TEMPLATE":
      let template = action.payload.image;
      let templatebg = action.payload.imagebg;
      let imagemytempfield = "";
      let imagebgcard = "";
      imagemytempfield = template;
      imagebgcard = templatebg;
      return {
        ...state,
        templatedesign: imagemytempfield,
        cardbgimage: imagebgcard,
      };

    case "UPLOAD_MY_B_TEMPLATE":
      let templateB = action.payload.image;
      let templatebgback = action.payload.imageback;
      let imagemytempfieldB = "";
      let imagebgcardback = "";
      imagemytempfieldB = templateB;
      imagebgcardback = templatebgback;
      return {
        ...state,
        templatedesignB: imagemytempfieldB,
        cardbgimageback: imagebgcardback,
      };

    case "REMOVE_IMAGE":
      let removeimagemyfield = state.myuploadedimage;
      removeimagemyfield["file"] = "";
      return {
        ...state,
        myuploadedimage: removeimagemyfield,
      };

    case "CHANGE_FRONTBACK_IMAGE":
      let frontbackimage = action.payload;
      // console.log(frontbackimage);
      let changefrontbackimagemyfield = state.myuploadedimage;
      changefrontbackimagemyfield["frontback"] = frontbackimage;
      return {
        ...state,
        myuploadedimage: changefrontbackimagemyfield,
      };

    case "UPDATE_NEW_POSITION":
      // alert("check");
      let newx = action.payload.newx;
      let newy = action.payload.newy;
      let targetname = action.payload.targetname;
      let positionvalue = { x: newx, y: newy };
      // let redoarray=[];
      // console.log(targetname);
      let myfieldposition = state[targetname];
      // redoarray.push({[targetname]:state[targetname]});
      myfieldposition["position"] = positionvalue;
      // redoarray[targetname]['position']=positionvalue;
      // console.log(redoarray);
      console.log(myfieldposition);
      let positionState = {
        ...state,
        [targetname]: myfieldposition,
      };
      return positionState;
    case "UPDATE_NEW_EXTRA_POSITION":
      // alert("check");
      let newEx = action.payload.newx;
      let newEy = action.payload.newy;
      let targetEname = action.payload.targetname;
      let index = action.payload.index;
      let positionEvalue = { x: newEx, y: newEy };
      let myEfieldposition = state[targetEname];
      myEfieldposition[index]["position"] = positionEvalue;
      let positionEState = {
        ...state,
        [targetEname]: myEfieldposition,
      };
      return positionEState;

    case "UPDATE_UNDO_STATUS_EXTRA":
      let eundoindex = action.payload.index;
      let eundovalue = action.payload.value;
      let eundotarget = action.payload.targetname;
      let eundostatus = state["undo"];
      if (eundostatus)
        eundostatus.unshift({
          extrafields: { [eundoindex]: { [eundotarget]: eundovalue } },
        });
      let eundoStatus = {
        ...state,
        undo: eundostatus,
      };
      return eundoStatus;

    case "UPDATE_UNDO_STATUS":
      let undoname = action.payload.name;
      let undovalue = action.payload.value;
      let undotarget = action.payload.targetname;
      let undostatus = state["undo"];
      if (undostatus)
        undostatus.unshift({ [undoname]: { [undotarget]: undovalue } });
      let undoStatus = {
        ...state,
        undo: undostatus,
      };
      console.log(undoStatus);
      return undoStatus;

    case "UPDATE_REDO_STATUS":
      let redoname = action.payload.name;
      let redovalue = action.payload.value;
      let redotarget = action.payload.targetname;
      let redostatus = state["redo"];
      redostatus.unshift({ [redoname]: { [redotarget]: redovalue } });
      let redoStatus = {
        ...state,
        redo: redostatus,
      };
      return redoStatus;
    case "UPDATE_REDO_EXTRA_STATUS":
      let eredoname = action.payload.i;
      let eredovalue = action.payload.value;
      let eredotarget = action.payload.targetname;
      let eredostatus = state["redo"];
      eredostatus.unshift({
        extrafields: { [eredoname]: { [eredotarget]: eredovalue } },
      });
      let eredoStatus = {
        ...state,
        redo: eredostatus,
      };
      return eredoStatus;
    case "UPDATE_NEW_UNDO_STATUS":
      let undonewstatus = state["undo"];
      undonewstatus.shift();
      let undoNewStatus = {
        ...state,
        undo: undonewstatus,
      };
      return undoNewStatus;
    case "UPDATE_NEW_REDO_STATUS":
      let redonewstatus = state["redo"];
      redonewstatus.shift();
      let redoNewStatus = {
        ...state,
        redo: redonewstatus,
      };
      return redoNewStatus;
    case "UPDATE_NEW_UNDO_POSITION":
      let undoposition = action.payload.targetname;
      console.log(undoposition);
      let undopositionvalue = action.payload.value;
      console.log(undopositionvalue);
      let undonewposition = state["undo"];
      undonewposition.unshift({ [undoposition]: undopositionvalue });
      let undoNewStatusPos = {
        ...state,
        undo: undonewposition,
      };
      return undoNewStatusPos;

    case "UPDATE_NEW_UNDO_EXTRA_POSITION":
      let eundoposition = action.payload.targetname;
      let eundopositionvalue = action.payload.value;
      let eundopositionindex = action.payload.index;
      let eundonewposition = state["undo"];
      if (eundonewposition)
        eundonewposition.unshift({ [eundoposition]: eundopositionvalue });
      let eundoNewStatusPos = {
        ...state,
        undo: eundonewposition,
      };
      return eundoNewStatusPos;
    case "UPDATE_NEW_REDO_POSITION":
      let redoposition = action.payload.targetname;
      console.log(redoposition);
      let redopositionvalue = action.payload.value;
      console.log(redopositionvalue);
      let redonewposition = state["redo"];
      redonewposition.unshift({ [redoposition]: redopositionvalue });
      let redoNewStatusPos = {
        ...state,
        redo: redonewposition,
      };
      return redoNewStatusPos;

    case "UPDATE_NEW_REDO_EXTRA_POSITION":
      let eredoposition = action.payload.targetname;
      let eredopositionvalue = action.payload.value;
      let eredonewposition = state["redo"];
      eredonewposition.unshift({ [eredoposition]: eredopositionvalue });
      let eredoNewStatusPos = {
        ...state,
        redo: eredonewposition,
      };
      return eredoNewStatusPos;
    case "UPDATE_NEW_SINGLE_VALUE":
      let signlevalue = action.payload.name;
      let signletarget = action.payload.target;
      let signlevaluefield = state["undo"];
      let checksignlevalue = state[signlevalue];

      if (signlevaluefield)
        signlevaluefield.unshift({
          [signlevalue]: { [signletarget]: checksignlevalue[signletarget] },
        });
      let signleValueState = {
        ...state,
        ["undo"]: signlevaluefield,
      };
      return signleValueState;

    case "UPDATE_NEW_SINGLE_EXTRA_VALUE":
      let esignleindex = action.payload.i;
      let esignletarget = action.payload.target;
      let esignlevaluefield = state["undo"];
      let echecksignlevalue = state["extrafields"][esignleindex];
      if (esignlevaluefield)
        esignlevaluefield.unshift({
          extrafields: {
            [esignleindex]: {
              [esignletarget]: echecksignlevalue[esignletarget],
            },
          },
        });
      // eundostatus.unshift({'extrafields':{[eundoindex]:{[eundotarget]:eundovalue,}}});
      let esignleValueState = {
        ...state,
        ["undo"]: esignlevaluefield,
      };
      return esignleValueState;
    case "UPDATE_NEW_SINGLE_VALUE_REDO":
      let signlevaluerd = action.payload.name;
      let signletargetrd = action.payload.target;
      let signlevaluefieldrd = state["redo"];
      let checksignlevaluerd = state[signlevaluerd];
      signlevaluefieldrd.unshift({
        [signlevaluerd]: {
          [signletargetrd]: checksignlevaluerd[signletargetrd],
        },
      });
      let signleValueStaterd = {
        ...state,
        ["redo"]: signlevaluefieldrd,
      };
      return signleValueStaterd;
    case "UPDATE_NEW_SINGLE_VALUE_EXTRA_REDO":
      let esignlevaluerd = action.payload.i;
      let esignletargetrd = action.payload.target;
      let esignlevaluefieldrd = state["redo"];
      let echecksignlevaluerd = state["extrafields"][esignlevaluerd];
      esignlevaluefieldrd.unshift({
        extrafields: {
          [esignlevaluerd]: {
            [esignletargetrd]: echecksignlevaluerd[esignletargetrd],
          },
        },
      });
      let esignleValueStaterd = {
        ...state,
        ["redo"]: esignlevaluefieldrd,
      };
      return esignleValueStaterd;
    default:
      return state;
  }
};
export default reducer;
