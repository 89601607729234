import React, { Component, useState } from 'react';
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import './dashboard.css';
class DashboardComponents extends Component{
 
    render() {

        return (
          <div>
              <TopBarComponent/>
              <div className="container-fluid">
  <div className="row">
    
  <SideBarComponent/>              
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Users</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
            <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
          </div>
          <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
            <span data-feather="calendar"></span>
            This week
          </button>
        </div>
      </div>

      

      
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">User Name</th>
              <th scope="col">Email Address</th>
              <th scope="col">Company</th>
              <th scope="col">Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>William Riley</td>
              <td>WilliamRiley@email.com</td>
              <td>placeholder</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Julian Harris</td>
              <td>JulianHarris@email.com</td>
              <td>visual</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Fiona Rogers</td>
              <td>Fiona.Rogers@emaila.com</td>
              <td>dashboard</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Haris Parker</td>
              <td>HarisLParker@test.com</td>
              <td>illustrative</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>4</td>
              <td>George Hunt</td>
              <td>George@cardbuilder.com</td>
              <td>layout</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Adam Ross</td>
              <td>rossadams@gmail.com</td>
              <td>text</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Maddie Evans</td>
              <td>madevans@company.com</td>
              <td>rich</td>
              <td>228-8777-95</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Savana Parker</td>
              <td>sparker@gmail.com</td>
              <td>information</td>
              <td>238-3234-25</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Daisy Wells</td>
              <td>daisywells@email.com</td>
              <td>placeholder</td>
              <td>342-8777-95</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Owen Dixon</td>
              <td>Owendixon@gmail.com</td>
              <td>visual</td>
              <td>333-8777-95</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Adelaide Bailey</td>
              <td>Adelaide@cardbuilder.com</td>
              <td>dashboard</td>
              <td>228-3457-95</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Nicholas Elliott</td>
              <td>Nicholas9333@email.com</td>
              <td>illustrative</td>
              <td>444-8777-95</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Aida Ryan</td>
              <td>Ryan.a@email.com</td>
              <td>layout</td>
              <td>228-8777-88</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Henry Cole</td>
              <td>Henrycolehenry@email.com</td>
              <td>text</td>
              <td>228-6666-95</td>
            </tr>
            <tr>
              <td>14</td>
              <td>Spike Wright</td>
              <td>Spikewright@gmaill.com</td>
              <td>rich</td>
              <td>328-8777-95</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Spike Wright</td>
              <td>Spik1ewright@gmaill.com</td>
              <td>information</td>
              <td>228-8777-95</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</div>
      
          </div>
       

        )
    }
}
export default DashboardComponents;
