import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import { create_user } from "../apiserve/ApiComponent";
import TopBarComponent from "./TopBarComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import "./dashboard.css";
class DashboardCreateUserComponents extends Component {
  constructor(props) {
    super(props);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.state = {
      allorders: [],
      response: "",
      fname: "",
      lname: "",
      phone: "",
      mail: "",
      password: "",
      formErrors: { fname: "", lname: "", mail: "", phone: "", password: "" },
      fnameValid: false,
      lnameValid: false,
      mailValid: false,
      phoneValid: false,
      passwordValid: false,

      formValid: false,
      categoryname: [],
      responsetype: "",
      isLoading: false,
    };
    // this.showuserdetails = this.showuserdetails.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    // this.createcategory = this.createcategory.bind(this);
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let fnameValid = this.state.fnameValid;
    let lnameValid = this.state.lnameValid;
    let mailValid = this.state.mailValid;
    let phoneValid = this.state.phoneValid;
    let passwordValid = this.state.passwordValid;
    switch (fieldName) {
      case "fname":
        fnameValid = value.length >= 2;
        fieldValidationErrors.fname = fnameValid ? "" : " is too short";
        break;

      case "lname":
        lnameValid = value.length >= 2;
        fieldValidationErrors.lname = lnameValid ? "" : " is too short";
        break;

      case "phone":
        phoneValid = value.length >= 8;
        fieldValidationErrors.phone = phoneValid ? "" : " is too short";
        break;

      case "mail":
        mailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.mail = mailValid ? "" : " is invalid";
        break;

      case "password":
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? "" : " is too short";
        break;

      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        fnameValid: fnameValid,
        lnameValid: lnameValid,
        phoneValid: phoneValid,
        mailValid: mailValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.fnameValid &&
        this.state.lnameValid &&
        this.state.phoneValid &&
        this.state.mailValid &&
        this.state.passwordValid,
    });
  }

  handleOnSubmit(e) {
    e.preventDefault();
    const { fname, lname, mail, phone, password } = this.state;
    if (this.state.formValid == true) {
      this.setState({ isLoading: true });
      create_user(
        fname,
        lname,
        mail,
        phone,
        password,
        (res) => {
          //success
          console.log(res);
          if (res.data.success == true || res.success == true) {
            this.setState({
              response: res.data.message || "User Created Successfully.",
            });
            this.setState({ responsetype: "success" });
            this.setState({ isLoading: false });
            setTimeout(function () {
              window.location.href = "/admin/users";
            }, 2000);
          } else {
            this.setState({
              response: "Failed. " + JSON.stringify(res.data.error),
            });
            this.setState({ responsetype: "error" });
            this.setState({ isLoading: false });
          }
        },
        (err) => {
          this.setState({ response: +JSON.stringify(err) });
          this.setState({ isLoading: false });
        }
      );
    } else {
      this.setState({ isLoading: false });
      this.setState({ response: "Enter valid data" });
      this.setState({ responsetype: "error" });
    }
    //   }
  }
  componentDidMount() {
    // this.getdashboarddata();
    //   this.getallorders();
  }
  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Create User</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    {/* <button type="button" className="btn btn-sm btn-outline-secondary">Export</button> */}
                  </div>
                </div>
              </div>
              <div className="container rounded bg-white m-1">
                <form onSubmit={this.handleOnSubmit}>
                  <div className="row">
                    <div className="col-lg-12 col-md-6 border-right">
                      <div className="p-1">
                        <div className="row mt-3">
                          <div className="col-lg-6 col-md-6">
                            <label>First Name</label>
                            <InputGroup
                              className={`${this.errorClass(
                                this.state.formErrors.fname
                              )} || '' `}
                            >
                              <FormControl
                                name="fname"
                                placeholder="Enter First Name"
                                required
                                className="form-control wrap-input100 validate-input"
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>Last Name</label>
                            <InputGroup
                              className={`${this.errorClass(
                                this.state.formErrors.lname
                              )} || '' `}
                            >
                              <FormControl
                                name="lname"
                                placeholder="Enter Last Name"
                                required
                                className="form-control wrap-input100 validate-input"
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>Phone Number</label>
                            <InputGroup
                              className={`${this.errorClass(
                                this.state.formErrors.phone
                              )} || '' `}
                            >
                              <FormControl
                                name="phone"
                                placeholder="Enter Phone Number"
                                required
                                className="form-control wrap-input100 validate-input"
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>Email</label>
                            <InputGroup
                              className={`${this.errorClass(
                                this.state.formErrors.mail
                              )} || '' `}
                            >
                              <FormControl
                                name="mail"
                                placeholder="Enter Email"
                                required
                                className="form-control wrap-input100 validate-input"
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>Password</label>
                            <InputGroup
                              className={`${this.errorClass(
                                this.state.formErrors.password
                              )} || '' `}
                            >
                              <FormControl
                                name="password"
                                placeholder="Enter Password"
                                required
                                className="form-control wrap-input100 validate-input"
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-lg-6 col-md-6"></div>
                          <div className="col-lg-3 col-md-3 mt-3">
                            <button
                              type="submit"
                              className="contact-form-btn btn btn-success text-white"
                            >
                              <span
                                style={{
                                  display: `${
                                    !this.state.isLoading ? "block" : "none"
                                  }`,
                                }}
                              >
                                Create User
                              </span>
                              <span
                                className=""
                                style={{
                                  display: `${
                                    this.state.isLoading ? "block" : "none"
                                  }`,
                                  textAlign: "center",
                                  padding: "8px 15px",
                                }}
                              >
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </Spinner>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardCreateUserComponents;
