import { combineReducers } from 'redux';
import amountReducer from './amountReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['card_data']
}
const reducers = combineReducers({
    card_data: amountReducer,
}
);

export default persistReducer(persistConfig, reducers);
// export default reducers;