import React, { Component, useState } from 'react';
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import './dashboard.css';
class DashboardCancelledOrdersComponents extends Component{
 
    render() {

        return (
          <div>
              <TopBarComponent/>
              <div className="container-fluid">
  <div className="row">
    
  <SideBarComponent/>              
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Cancelled Orders</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
            <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
          </div>
          <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
            <span data-feather="calendar"></span>
            This week
          </button>
        </div>
      </div>

      

      
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Customer Name</th>
              <th scope="col">Card Ordered</th>
              <th scope="col">Numbers of Cards Required</th>
              <th scope="col">Total Billing Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>William Riley</td>
              <td>Card Matte</td>
              <td>100</td>
              <td>40,000/-</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Julian Harris</td>
              <td>Visiting Card</td>
              <td>1000</td>
              <td>80,000/-</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Fiona Rogers</td>
              <td>Business Card</td>
              <td>300</td>
              <td>10,000/-</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Haris Parker</td>
              <td>Student Card</td>
              <td>500</td>
              <td>50,000/-</td>
            </tr>
            <tr>
              <td>4</td>
              <td>George Hunt</td>
              <td>Employee Card</td>
              <td>800</td>
              <td>10,000/-</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Adam Ross</td>
              <td>Business Card</td>
              <td>50</td>
              <td>50,000/-</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Maddie Evans</td>
              <td>Meta Card</td>
              <td>50</td>
              <td>50,000/-</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Savana Parker</td>
              <td>Student Card</td>
              <td>506</td>
              <td>90,000/-</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Daisy Wells</td>
              <td>Employee Card</td>
              <td>450</td>
              <td>45,000/-</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Owen Dixon</td>
              <td>Business Card</td>
              <td>507</td>
              <td>40,000/-</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Adelaide Bailey</td>
              <td>Business Card</td>
              <td>5089</td>
              <td>40,000/-</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Nicholas Elliott</td>
              <td>Employee Card</td>
              <td>5000</td>
              <td>40,000/-</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Aida Ryan</td>
              <td>Employee Card</td>
              <td>504</td>
              <td>40,000/-</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Henry Cole</td>
              <td>Employee Card</td>
              <td>500</td>
              <td>40,000/-</td>
            </tr>
            <tr>
              <td>14</td>
              <td>Spike Wright</td>
              <td>Visiting Card</td>
              <td>500</td>
              <td>40,000/-</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Spike Wright</td>
              <td>Employee Card</td>
              <td>500</td>
              <td>40,000/-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</div>
      
          </div>
       

        )
    }
}
export default DashboardCancelledOrdersComponents;
