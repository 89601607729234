import { createStore } from 'redux';
import thunk from 'redux-thunk';
import { applyMiddleware } from 'redux';
import reducers from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const store = createStore(reducers, {}, applyMiddleware(thunk))
export const persistor = persistStore(store);

export default { store, persistor };
// export default store;
