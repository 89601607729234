import React, { Component, useState, useRef } from "react";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import DashboardTextFieldComponent from "./DashboardTextFieldComponent";
import DashboardImageComponent from "./DashboardImageComponent";
import DashboardTemplatesComponent from "./DashboardTemplatesComponent";
import queryString from "query-string";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import { useEffect } from "react";
// import HeaderComponent from "./HeaderComponent";
// import FooterComponent from "./FooterComponent";
import DashboardSaveModalComponents from "./DashboardSaveModalComponents";

import { Resizable } from "re-resizable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  UpdateCardState,
  onChangePosition,
  addCardDetails,
} from "../../state/index";

import Draggable, { DraggableCore } from "react-draggable";
import {
  get_templatedata,
  savemycard,
  getcategorylist,
} from "../apiserve/ApiComponent";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// import {onChangePosition} from '../state/index'

import { saveAs } from "file-saver";

class DashboardCardTemplateCreateComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card_data: [],
    };
    this.adjustXPos.state = {
      activeDrags: 0,
      categorylist: [],

      deltaPosition: {
        x: 0,
        y: 0,
      },
      controlledPosition: {
        x: -400,
        y: 200,
      },
    };
    this.onChangePosition = this.onChangePosition.bind(this);
    this.inputRef = React.createRef();
    this.state = {
      response: "",
      responsetype: "",
      loading: false,
      setLoading: false,
      basicImagedata: "",
      baseImgPath: "https://4ca0-183-87-100-41.ngrok.io/images/",
      frontbaseimg: "",
    };
  }
  get_templatedata(id) {
    let formdata = {
      templatedesign: this.state.fromtemplate,
      staffid: {
        text: this.state.staffid,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 0, y: 0 },
      },
      firstname: {
        text: this.state.firstname,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 0, y: 0 },
      },
      lastname: {
        text: this.state.lastname,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 0, y: 0 },
      },
      position: {
        text: this.state.position,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 0, y: 0 },
      },
      phoneno: {
        text: this.state.phoneno,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 0, y: 0 },
      },
      extrafields: this.state.valuess,
      myuploadedimage: {
        file: "",
        position: { x: 0, y: 0 },
        frontback: "front",
      },
    };
    console.log(formdata);
    this.setState({ card_data: formdata });
    this.props.addCardDetails(formdata);
  }

  handleCustomDrag = (e, ui) => {
    console.log(e);
  };

  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
  };

  onChangePosition(e, position) {
    let newx = position.x;
    let newy = position.y;
    let targetname = e.target.id;
    console.log(newx);
    console.log(newy);
    console.log(targetname);
    this.props.onChangePosition({ targetname, newy, newx });
  }
  onDrop = (e) => {
    this.setState({ activeDrags: --this.state.activeDrags });
    if (e.target.classList.contains("drop-target")) {
      alert("Dropped!");
      e.target.classList.remove("hovered");
    }
  };
  onTouchEnd(ev) {
    var rect = ev.target.getBoundingClientRect();
    console.log(rect.top, rect.right, rect.bottom, rect.left);
  }
  checkupdateposition(e) {
    var rect = e.target.getBoundingClientRect();
    console.log(rect.top, rect.right, rect.bottom, rect.left);
  }
  onDropAreaMouseEnter = (e) => {
    if (this.state.activeDrags) {
      e.target.classList.add("hovered");
    }
  };
  onDropAreaMouseLeave = (e) => {
    e.target.classList.remove("hovered");
  };

  // For controlled component
  adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = this.state.controlledPosition;
    this.setState({ controlledPosition: { x: x - 10, y } });
  };

  adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { controlledPosition } = this.state;
    const { x, y } = controlledPosition;
    this.setState({ controlledPosition: { x, y: y - 10 } });
  };

  onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
  };

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  };

  componentDidMount() {
    let id = "";
    localStorage.removeItem("updatetempid");
    let formdata = {
      templatedesign: "default.jpg",
      templatedesignB: "default.jpg",
      staffid: {
        text: "ID",
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 300, y: 50 },
      },
      firstname: {
        text: "First Name",
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 300, y: 100 },
      },
      lastname: {
        text: "Last Name",
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 300, y: 150 },
      },
      position: {
        text: "Position",
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 300, y: 200 },
      },
      phoneno: {
        text: "Phone",
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "14px",
        fontFamily: "arial",
        position: { x: 300, y: 250 },
      },
      extrafields: [],
      myuploadedimage: {
        file: "",
        position: { x: 0, y: 0 },
        frontback: "front",
      },
    };
    this.setState({ card_data: formdata });
    this.props.addCardDetails(formdata);
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps.card_details);
  }
  handleDelete(index) {
    this.props.delEmp(index);
  }
  handleEdit(index) {
    console.log("Edit:", index);
  }
  localStorageUpdated() {
    if (!localStorage.getItem("selectedFile")) {
      alert("not found");
    } else if (!this.state.status) {
      alert("found");
    }
  }

  savetopng() {
    const input = document.getElementById("savefromhere");
    console.log(input);
    html2canvas(input, {
      allowTaint: false,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      saveAs(imgData, "card-design.png");
    });
  }
  savetojpg() {
    const input = document.getElementById("savefromhere");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpg");
      saveAs(imgData, "card-design.jpg");
    });
  }
  savetopdf() {
    const input = document.getElementById("savefromhere");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("card-design.pdf");
    });
  }
  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    const { ResizableBox } = require("react-resizable");
    const htmlToImage = require("html-to-image");
    const FileSaver = require("file-saver");
    const { deltaPosition, controlledPosition } = this.state;

    return (
      <div>
        <TopBarComponent />

        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <Container>
                <Row>
                  <Col>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Row
                        style={{
                          border: "solid 1px #1f2744",
                          borderRadius: "3px",
                        }}
                        className="mobiletabs mt-4"
                      >
                        <Col className="col-lg col-6">
                          <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link eventKey="first">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-fonts"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.258 3h-8.51l-.083 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062 2.434.301 2.693 1.846h.479L12.258 3z" />
                                </svg>
                                <span className="hidemobile">Text</span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-file-image"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z" />
                                </svg>{" "}
                                <span className="hidemobile">
                                  Upload Template Images
                                </span>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col className="col-lg col-6">
                          <Nav className=" justify-content-end">
                            <Nav.Item>
                              <DashboardSaveModalComponents />
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                      <div className="clearfix">&nbsp;</div>
                      <Row>
                        <Col sm={12}>
                          <Row>
                            <Col sm={3} className="tabsidebar">
                              <Tab.Content>
                                <Tab.Pane eventKey="first">
                                  <DashboardTextFieldComponent
                                    state={this.state}
                                    onChange={this.handleTextChange}
                                    handleClick={this.changebold}
                                  />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                  <DashboardImageComponent />
                                </Tab.Pane>

                                <Tab.Pane eventKey="third">
                                  <DashboardTemplatesComponent />
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                            <Col sm={9} className="cardcontainer">
                              <Tab.Container
                                id="bottom-tabs-example"
                                defaultActiveKey="first"
                              >
                                <Row>
                                  <Col sm={12}>
                                    <Row>
                                      <Col>
                                        <Nav
                                          variant="pills"
                                          className="text-center justify-content-center"
                                        >
                                          <Nav.Item>
                                            <Nav.Link eventKey="first">
                                              Front Side
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                            <Nav.Link eventKey="second">
                                              Back Side
                                            </Nav.Link>
                                          </Nav.Item>
                                        </Nav>
                                      </Col>
                                    </Row>
                                    <div className="clearfix he-1">&nbsp;</div>
                                  </Col>
                                  {/* Front Side of Card */}
                                  {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Landscape ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                                  <Col sm={12} className="px-5">
                                    <Tab.Content>
                                      <Tab.Pane eventKey="first">
                                        {this.props.card_details.orientation !=
                                        "portrait" ? (
                                          <div
                                            style={{
                                              display: "block",
                                              border: "solid 1px blue",
                                              borderRadius: "15px",
                                            }}
                                          >
                                            <div
                                              id="savefromhere"
                                              className="col-lg-12"
                                              style={{
                                                background: "#ccc",
                                                borderRadius: "15px",
                                              }}
                                            >
                                              <div
                                                id="backgroundimagefront"
                                                className="col-lg-12 p-4 backgroundimage"
                                                // name={this.props.baseImgPath}
                                                style={{
                                                  backgroundImage: `url(${this.props.card_details.templatedesign})`,
                                                  borderRadius: "15px",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "cover",
                                                  // crossOrigin:"anonymous",
                                                  crossOrigin: "true",
                                                }}
                                              >
                                                <div
                                                  ref={this.inputRef}
                                                  id={"cardheight"}
                                                  className="col-lg-12 cardheight justify-content-center"
                                                  style={{
                                                    height: "100%",
                                                    border: "dashed 1px #000",
                                                    overflow: "hidden",
                                                    position: "relative",
                                                    borderRadius: "15px",
                                                  }}
                                                >
                                                  <div
                                                    className="col-12"
                                                    style={{
                                                      height: "100%",
                                                      borderRadius: "15px",
                                                    }}
                                                  >
                                                    {this.props.card_details
                                                      .staffid != undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .staffid.position.x,
                                                          y: this.props
                                                            .card_details
                                                            .staffid.position.y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col className="text-center">
                                                              <FormLabel
                                                                className="m-0 p-0 col py-2 d-block"
                                                                id="staffid"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .staffid
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}

                                                    {this.props.card_details
                                                      .firstname !=
                                                      undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .firstname.position
                                                            .x,
                                                          y: this.props
                                                            .card_details
                                                            .firstname.position
                                                            .y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col className="text-center">
                                                              <FormLabel
                                                                className="m-0 p-0 col d-block text-center py-2"
                                                                id="firstname"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .firstname
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}

                                                    {this.props.card_details
                                                      .lastname !=
                                                      undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .lastname.position
                                                            .x,
                                                          y: this.props
                                                            .card_details
                                                            .lastname.position
                                                            .y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col className="text-center">
                                                              <FormLabel
                                                                className="m-0 p-0 col d-block text-center py-2"
                                                                id="lastname"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .lastname
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}
                                                    {this.props.card_details
                                                      .position !=
                                                      undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .position.position
                                                            .x,
                                                          y: this.props
                                                            .card_details
                                                            .position.position
                                                            .y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col>
                                                              <FormLabel
                                                                className="m-0 p-0 col-12 d-block text-center py-2"
                                                                id="position"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .position
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}
                                                    {this.props.card_details
                                                      .phoneno != undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .phoneno.position.x,
                                                          y: this.props
                                                            .card_details
                                                            .phoneno.position.y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col>
                                                              <FormLabel
                                                                className="m-0 p-0 col-12 d-block text-center py-2"
                                                                id="phoneno"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .phoneno
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Portrait ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                                          <div
                                            style={{
                                              display: "block",
                                              border: "solid 1px blue",
                                              borderRadius: "15px",
                                              width: "480px",
                                              height: "764px",
                                            }}
                                          >
                                            <div
                                              id="savefromhere"
                                              className="col-lg-12"
                                              style={{
                                                background: "#ccc",
                                                borderRadius: "15px",
                                                width: "478px",
                                                height: "762px",
                                              }}
                                            >
                                              <div
                                                id="backgroundimagefront"
                                                className="col-lg-12 p-4 backgroundimage"
                                                // name={this.props.baseImgPath}
                                                style={{
                                                  backgroundImage: `url(${this.props.card_details.templatedesign})`,
                                                  borderRadius: "15px",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "cover",
                                                  // crossOrigin:"anonymous",
                                                  crossOrigin: "true",
                                                  width: "478px",
                                                  height: "762px",
                                                }}
                                              >
                                                <div
                                                  ref={this.inputRef}
                                                  id={"cardheight"}
                                                  className="col-lg-12 cardheight justify-content-center"
                                                  style={{
                                                    height: "100%",
                                                    border: "dashed 1px #000",
                                                    overflow: "hidden",
                                                    position: "relative",
                                                    borderRadius: "15px",
                                                    width: "478px",
                                                    height: "762px",
                                                  }}
                                                >
                                                  <div
                                                    className="col-12"
                                                    style={{
                                                      height: "100%",
                                                      borderRadius: "15px",
                                                      width: "478px",
                                                      height: "762px",
                                                    }}
                                                  >
                                                    {this.props.card_details
                                                      .staffid != undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .staffid.position.x,
                                                          y: this.props
                                                            .card_details
                                                            .staffid.position.y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col className="text-center">
                                                              <FormLabel
                                                                className="m-0 p-0 col py-2 d-block"
                                                                id="staffid"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .staffid
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .staffid
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}

                                                    {this.props.card_details
                                                      .firstname !=
                                                      undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .firstname.position
                                                            .x,
                                                          y: this.props
                                                            .card_details
                                                            .firstname.position
                                                            .y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col className="text-center">
                                                              <FormLabel
                                                                className="m-0 p-0 col d-block text-center py-2"
                                                                id="firstname"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .firstname
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .firstname
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}

                                                    {this.props.card_details
                                                      .lastname !=
                                                      undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .lastname.position
                                                            .x,
                                                          y: this.props
                                                            .card_details
                                                            .lastname.position
                                                            .y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col className="text-center">
                                                              <FormLabel
                                                                className="m-0 p-0 col d-block text-center py-2"
                                                                id="lastname"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .lastname
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .lastname
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}
                                                    {this.props.card_details
                                                      .position !=
                                                      undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .position.position
                                                            .x,
                                                          y: this.props
                                                            .card_details
                                                            .position.position
                                                            .y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col>
                                                              <FormLabel
                                                                className="m-0 p-0 col-12 d-block text-center py-2"
                                                                id="position"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .position
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .position
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}
                                                    {this.props.card_details
                                                      .phoneno != undefined && (
                                                      <Draggable
                                                        axis="both"
                                                        handle=".handle"
                                                        position={{
                                                          x: this.props
                                                            .card_details
                                                            .phoneno.position.x,
                                                          y: this.props
                                                            .card_details
                                                            .phoneno.position.y,
                                                        }}
                                                        {...dragHandlers}
                                                        onDrag={
                                                          this.onControlledDrag
                                                        }
                                                        onStop={
                                                          this.onChangePosition
                                                        }
                                                      >
                                                        <div>
                                                          <Row className="handle">
                                                            <Col>
                                                              <FormLabel
                                                                className="m-0 p-0 col-12 d-block text-center py-2"
                                                                id="phoneno"
                                                                style={{
                                                                  color: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .color ||
                                                                    "#000"
                                                                  }`,
                                                                  fontWeight: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontBold
                                                                      ? "bold"
                                                                      : "normal"
                                                                  }`,
                                                                  fontSize: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontSize ||
                                                                    "14px"
                                                                  }`,
                                                                  fontStyle: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontItalic
                                                                      ? "italic"
                                                                      : "normal"
                                                                  }`,
                                                                  textDecoration: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontUnderline
                                                                      ? "underline"
                                                                      : "none"
                                                                  }`,
                                                                  textAlign: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .alignText ||
                                                                    "left"
                                                                  }`,
                                                                  fontFamily: `${
                                                                    this.props
                                                                      .card_details
                                                                      .phoneno
                                                                      .fontFamily ||
                                                                    "arial"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  this.props
                                                                    .card_details
                                                                    .phoneno
                                                                    .text
                                                                }
                                                              </FormLabel>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Draggable>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Tab.Pane>
                                      <Tab.Pane eventKey="second">
                                        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Landscape ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                                        {this.props.card_details.orientation !=
                                        "portrait" ? (
                                          <div
                                            style={{
                                              display: "block",
                                              border: "solid 1px blue",
                                              borderRadius: "15px",
                                            }}
                                          >
                                            <div
                                              className="col-lg-12"
                                              style={{
                                                background: "#ccc",
                                                borderRadius: "15px",
                                              }}
                                            >
                                              <div
                                                className="col-lg-12 p-4 backgroundimage"
                                                style={{
                                                  background: `url(${this.props.card_details.templatedesignB})`,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "cover",
                                                  crossOrigin: "anonymous",
                                                  borderRadius: "15px",
                                                }}
                                                id="savefromhereback"
                                              >
                                                <div
                                                  className="col-lg-12 cardheight justify-content-center"
                                                  style={{
                                                    height: "100%",
                                                    border: "dashed 1px #000",
                                                    overflow: "hidden",
                                                    position: "relative",
                                                    borderRadius: "15px",
                                                  }}
                                                >
                                                  <div className="col-12 align-middle justify-content-center"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ portrait ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                                          <div
                                            style={{
                                              display: "block",
                                              border: "solid 1px blue",
                                              borderRadius: "15px",
                                              width: "478px",
                                              height: "762px",
                                            }}
                                          >
                                            <div
                                              className="col-lg-12"
                                              style={{
                                                background: "#ccc",
                                                borderRadius: "15px",
                                                width: "478px",
                                                height: "762px",
                                              }}
                                            >
                                              <div
                                                className="col-lg-12 p-4 backgroundimage"
                                                style={{
                                                  background: `url(${this.props.card_details.templatedesignB})`,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "cover",
                                                  crossOrigin: "anonymous",
                                                  borderRadius: "15px",
                                                  width: "478px",
                                                  height: "762px",
                                                }}
                                                id="savefromhereback"
                                              >
                                                <div
                                                  className="col-lg-12 cardheight justify-content-center"
                                                  style={{
                                                    height: "100%",
                                                    border: "dashed 1px #000",
                                                    overflow: "hidden",
                                                    position: "relative",
                                                    borderRadius: "15px",
                                                    width: "478px",
                                                    height: "762px",
                                                  }}
                                                >
                                                  <div className="col-12 align-middle justify-content-center"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Tab.Pane>
                                    </Tab.Content>
                                  </Col>
                                  <Col sm={12}>
                                    <Row>
                                      <div className="clearfix he-1">
                                        &nbsp;
                                      </div>
                                      <Col className="justify-content-center text-center"></Col>
                                    </Row>
                                    <div className="clearfix he-1">&nbsp;</div>
                                  </Col>
                                </Row>
                              </Tab.Container>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Col>{" "}
                </Row>
              </Container>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
DashboardCardTemplateCreateComponents.defaultProps = {
  card_details: {
    templatedesign: "default.jpg",
    staffid: {
      text: "id",
      color: "#000",
      fontBold: false,
      fontItalic: false,
      fontUnderline: false,
      alignText: "left",
      fontSize: "14px",
      fontFamily: "arial",
      position: { x: 0, y: 0 },
    },
    firstname: {
      text: "First Name",
      color: "#000",
      fontBold: false,
      fontItalic: false,
      fontUnderline: false,
      alignText: "left",
      fontSize: "14px",
      fontFamily: "arial",
      position: { x: 0, y: 0 },
    },
    lastname: {
      text: "Last Name",
      color: "#000",
      fontBold: false,
      fontItalic: false,
      fontUnderline: false,
      alignText: "left",
      fontSize: "14px",
      fontFamily: "arial",
      position: { x: 0, y: 0 },
    },
    position: {
      text: "Position",
      color: "#000",
      fontBold: false,
      fontItalic: false,
      fontUnderline: false,
      alignText: "left",
      fontSize: "14px",
      fontFamily: "arial",
      position: { x: 0, y: 0 },
    },
    phoneno: {
      text: "Phone",
      color: "#000",
      fontBold: false,
      fontItalic: false,
      fontUnderline: false,
      alignText: "left",
      fontSize: "14px",
      fontFamily: "arial",
      position: { x: 0, y: 0 },
    },
    extrafields: [],
    myuploadedimage: { file: "", position: { x: 0, y: 0 }, frontback: "front" },
  },
};
function mapStateToProps(state) {
  return {
    card_details: state.card_data,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { UpdateCardState, onChangePosition, addCardDetails },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardCardTemplateCreateComponents);
