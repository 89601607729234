import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import { getorderlines, getuserordersdetails } from "../apiserve/ApiComponent";
import TopBarComponent from "./TopBarComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import "./dashboard.css";
import DataTable from "react-data-table-component";
class UserOrderDetailsComponents extends Component {
  constructor(props) {
    super(props);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.state = {
      allorders: [],
      response: "",
      orderdetails: [],
      singleorder: [],
      deliveryA: [],
      columnsValue: [
        {
          name: "Card Preview",
          // selector: row=> row.fname +" "+ row.lname,
          // sortable: true,
          cell: (row) => (
            <img
              src={
                "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                row.card_preview
              }
              style={{ width: "150px" }}
            />
          ),
        },
        {
          name: "Card Quantity",
          selector: (row) => row.qty,
          // sortable: true,
        },
        {
          name: "Card Amount",
          selector: (row) => row.amount,
          // sortable: true,
        },
        {
          name: "Total Amount",
          selector: (row) => row.total_amount,
          sortable: true,
        },
        {
          name: "Order Status",
          selector: (row) => row.order_status,
          sortable: true,
        },
      ],
      responsetype: "",
      baseImgPath:
        "https://office.monitorwa.com.au/tccardorderapi/public/templates/",
      trx_id: "",
      show: false,
      isLoading: false,
    };
    // this.showuserdetails = this.showuserdetails.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.updateuserorderstatus = this.updateuserorderstatus.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleUserInput(e) {
    const value = e.target.value;
    let updatedata = value;
    this.setState({ categoryname: updatedata });
  }

  getuserordersdetails(id) {
    const formData = new FormData();
    formData.append("txn_id", id);
    getuserordersdetails(
      id,
      (res) => {
        this.setState({ orderdetails: res.data.data[0][0] });
        this.setState({ singleorder: res.data.data[0][0] });
        this.state.deliveryA.Street1 = res.data.data[0][0].Street1;
        this.state.deliveryA.Street2 = res.data.data[0][0].Street2;
        this.state.deliveryA.PostalCode = res.data.data[0][0].PostalCode;
        getorderlines(id);
      },
      (err) => {
        this.setState({ response: "Error while getting order details" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  getorderlines(id) {
    getorderlines(
      id,
      (res) => {
        var emptyobj = [];
        var orderlines = res.data.data[0];

        for (var i = 0; i < orderlines.length; i++) {
          const obj = {
            id: "card:- " + orderlines[i].id,
            card_preview: orderlines[i].card_preview,
            qty: orderlines[i].qty,
            amount: orderlines[i].amount,
            total_amount: orderlines[i].total_amount,
            order_status:
              orderlines[i].order_status == 0
                ? "Created"
                : orderlines[i].order_status == 1
                ? "Success"
                : orderlines[i].order_status == 2
                ? "Failed"
                : "Delivered",
            print: true,
          };
          emptyobj.push(obj);
        }
        this.setState({ tableTitle: emptyobj });
      },
      (err) => {
        this.setState({ response: "Error while getting order details" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  handleOnSubmit(e) {
    e.preventDefault();
    let catname = { name: "" };
    console.log(catname);
    catname.name = this.state.categoryname;
    console.log(catname);
    if (catname == "" || undefined) {
      this.setState({ response: "Please enter category name" });
      this.setState({ responsetype: "error" });
    } else {
      this.setState({ isLoading: true });
    }
  }
  componentDidMount() {
    console.log(localStorage.getItem("vcardurole"));
    if (localStorage.getItem("vcardurole") == 2) {
      let cardid = "";
      let queries = queryString.parse(this.props.location.search);
      if (queries.id != undefined) {
        cardid = queries.id;
      }
      this.setState({ trx_id: cardid });
      this.getuserordersdetails(cardid);
      this.getorderlines(cardid);
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Status Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center mb-4">
              Are you sure, You want change status to delivered.
            </h5>
            {/* <p className="text-center">
				  Once you delete your card, Card data will be deleted permanently.
				</p> */}
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.updateuserorderstatus}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <TopBarComponent imagePath={localStorage.getItem("profileimagepath")} />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5>Transaction ID: {this.state.trx_id}</h5>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2"></div>
                </div>
              </div>
              <div className="container rounded bg-white m-1">
                <h4>User Details</h4>
                <div className="table-responsive">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone No.</th>
                        <th scope="col">Current Address</th>
                        <th scope="col">Shipping Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {this.state.singleorder.fname}{" "}
                          {this.state.singleorder.lname}
                        </td>
                        <td>{this.state.singleorder.email}</td>
                        <td>{this.state.singleorder.phoneno}</td>
                        <td>
                          {this.state.singleorder.address1}, <br />{" "}
                          {this.state.singleorder.area},{" "}
                          {this.state.singleorder.address2 +
                            " " +
                            this.state.singleorder.state}{" "}
                          - {this.state.singleorder.postcode}
                        </td>
                        <td>
                          {this.state.deliveryA.Street1},<br />{" "}
                          {this.state.deliveryA.Street2} -{" "}
                          {this.state.deliveryA.PostalCode}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="mt-4">Order Card Details</h4>
                <DataTable
                  columns={this.state.columnsValue}
                  data={this.state.tableTitle}
                  pagination
                ></DataTable>
                <div className="table-responsive"></div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default UserOrderDetailsComponents;
