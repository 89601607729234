import React, { Component, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import { updateTransaction } from "./apiserve/ApiComponent";
// import { forgotpassword } from './apiserve/ApiComponent';
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
class PaymentConfirmationPage extends Component {
  constructor() {
    super();
    this.state = {
      confirmation: "",
      txn_id: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/",
      response: "",
      responsetype: "",
      accesscode: "",
    };
    // this.handleChange = this.handleChange.bind(this);
    // this.contuneedting = this.contuneedting.bind(this);
  }

  //   contuneedting(){
  //     window.location.href="user/order_history";
  //   }

  componentDidMount() {
    let queries = queryString.parse(this.props.location.search);
    if (queries.AccessCode != undefined) {
      this.state.confirmation = true;
      this.setState({ confirmation: true });
      this.state.accesscode = queries.AccessCode;
      this.setState({ accesscode: queries.AccessCode });
      var formdata = new FormData();
      formdata.append("accesscode", this.state.accesscode);
      updateTransaction(formdata);
    } else {
      this.state.confirmation = false;
      this.setState({ confirmation: false });
    }
  }

  render() {
    return (
      <div>
        <HeaderComponent />
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form className="login100-form" style={{ paddingTop: "100px" }}>
                <span className="login100-form-title p-b-43 mb-1">
                  {this.state.confirmation == true
                    ? "Payment Completed"
                    : "Payment Failed"}
                </span>
                <p className="text-muted fw-slim text-center fs-5 border-bottom mb-5">
                  {this.state.confirmation == true
                    ? "Thank you, Your payment has been successful."
                    : "For further details please contact Monitor WA (support@monitorwa.com.au)"}
                </p>
                {this.state.confirmation == true ? (
                  <div>
                    <p className="text-center fs-5">The Process From Here -</p>
                    <li>Confirmation of Your Order</li>
                    <li>The Ordered Card(s) are Printed</li>
                    <li>
                      Your Order will be Posted to Your Nominated Delivery
                      Address
                    </li>
                    <li>
                      You will Receive an Email Confirmation When Your Order is
                      Dispatched
                    </li>
                    <br></br>
                    <br></br>

                    <p className="text-center fs-5">
                      Please Note : That orders are usually printed and
                      dispatched by the next business day
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}
              </form>

              <div
                className="login100-more"
                style={{
                  backgroundImage: `url(${
                    this.state.baseImgPath + "Order.png"
                  })`,
                  //backgroundPosition: "center",
                  //backgroundSize: "cover",
                  //backgroundRepeat: "no-repeat",
                  //width: "500px",
                  //height: "50%",
                }}
              ></div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}
export default PaymentConfirmationPage;
