import React, { Component, useState } from "react";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
  withRouter,
} from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import Toast from "react-bootstrap/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get_templatedata } from "./apiserve/ApiComponent";
import SaveModalComponents from "./SaveModalComponents";
import { addCardDetails, depositMoney, withdrawMoney } from "../state/index";
import { UpdateCardState } from "../state/index";

class CardDetailsTemplatesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: "",
      responsetype: "",
      staffid: "",
      firstname: "",
      lastname: "",
      position: "",
      phoneno: "",
      fromtemplate: [],
      baseImgPath: "../public/images/templates/",
      tid: "",
      cardOrientation: "",
      formErrors: {
        staffid: "",
        firstname: "",
        lastname: "",
        position: "",
      },
      staffidValid: false,
      firstnameValid: false,
      lastnameValid: false,
      positionValid: false,
      formValid: false,
      valuess: [],
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }
  createUI() {
    return this.state.valuess.map((el, i) => (
      <div key={i}>
        <div className="d-grid gap-2">
          <InputGroup className={`mb-0 form-group`}>
            <FormControl
              name={i}
              className="form-control"
              onChange={this.handleChange.bind(this, i)}
            />
            <InputGroup.Text id="basic-addon2" className="p-0">
              <Button
                style={{
                  borderRadius: "0",
                  background: "#e9ecef",
                  border: "none",
                  color: "#666",
                }}
                onClick={this.removeClick.bind(this, i)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="red"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </Button>
            </InputGroup.Text>
          </InputGroup>
          <div className="clearfix he-1">&nbsp;</div>
        </div>
      </div>
    ));
  }

  get_templatedata(id) {
    get_templatedata(
      id,
      (res) => {
        //success
        let tempslider = [];
        let card_templatedata = res.data.data[0][0].cardtemplatedata;
        this.setState({ templatedata: res.data.data[0][0] });

        // this.setState(templatedata);
        // console.log(this.state.templatedata);
        // console.log(tempslider);
        // console.log(this.state.templatedata.cardbgimage);
        tempslider[0] = this.state.templatedata.cardbgimage;
        tempslider[1] = this.state.templatedata.card_back_image;
        this.setState({ fromtemplate: tempslider });
        this.setState({
          cardOrientation: this.state.templatedata.card_orientation,
        });
        UpdateCardState(card_templatedata);
        //  console.log(card_templatedata.staffid.color);
      },
      (err) => {
        console.log(err);
      }
    );
    // let card_templatedata=
  }

  handleChange(i, event) {
    let valuess = [...this.state.valuess];
    valuess[i] = {
      text: event.target.value,
      showHide: false,
      color: "#000",
      fontBold: false,
      fontItalic: false,
      fontUnderline: false,
      alignText: "left",
      fontSize: "14px",
      fontFamily: "arial",
      position: { x: 0, y: 0 },
      frontback: "front",
    };

    this.setState({ valuess });
  }

  handleOnSubmit = (e) => {
    e.preventDefault();
    // const { history } = this.props;
    let formdata = {
      templatedesign: this.state.fromtemplate,
      staffid: {
        text: this.state.staffid,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "50px",
        fontFamily: "arial",
        position: { x: 0, y: 0 },
      },
      firstname: {
        text: this.state.firstname,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "50px",
        fontFamily: "arial",
        position: { x: 0, y: 50 },
      },
      lastname: {
        text: this.state.lastname,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "50px",
        fontFamily: "arial",
        position: { x: 0, y: 100 },
      },
      position: {
        text: this.state.position,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "50px",
        fontFamily: "arial",
        position: { x: 0, y: 150 },
      },
      phoneno: {
        text: this.state.phoneno,
        color: "#000",
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
        alignText: "left",
        fontSize: "50px",
        fontFamily: "arial",
        position: { x: 0, y: 200 },
      },
      extrafields: this.state.valuess,
      myuploadedimage: {
        file: "",
        position: { x: 0, y: 0 },
        frontback: "front",
      },
    };
    //  console.log("Click " , formdata);

    if (this.state.formValid == true) {
      this.props.addCardDetails(formdata);
      // this.props.addCardDetails(formdata);
      const { history } = this.props;
      // localStorage.setItem('designId', this.state.tid);
      if (history) history.push(`/card-design?id=${this.state.tid}`);
    } else {
      this.setState({ response: "Please enter valid data." });
      this.setState({ responsetype: "error" });
      this.setState({ isLoading: false });
    }
  };

  addClick() {
    this.setState((prevState) => ({ valuess: [...prevState.valuess, ""] }));
  }

  removeClick(i) {
    let valuess = [...this.state.valuess];
    valuess.splice(i, 1);
    this.setState({ valuess });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let staffidValid = this.state.staffidValid;
    let firstnameValid = this.state.firstnameValid;
    let lastnameValid = this.state.lastnameValid;
    let positionValid = this.state.positionValid;

    switch (fieldName) {
      case "firstname":
        firstnameValid = value.length >= 2;
        fieldValidationErrors.firstname = firstnameValid ? "" : " is invalid";
        break;

      case "lastname":
        lastnameValid = value.length >= 2;
        fieldValidationErrors.lastname = lastnameValid ? "" : " is invalid";
        break;

      case "position":
        positionValid = value.length >= 2;
        fieldValidationErrors.position = positionValid ? "" : " is invalid";
        break;

      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        //staffidValid: staffidValid,
        firstnameValid: firstnameValid,
        lastnameValid: lastnameValid,
        positionValid: positionValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        //this.state.staffidValid &&
        this.state.firstnameValid &&
        this.state.lastnameValid &&
        this.state.positionValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  componentDidMount = async () => {
    // if(localStorage.getItem('vcardurole')==2){
    let queries = queryString.parse(this.props.location.search);
    let tempid = queries.id;
    this.get_templatedata(tempid);
    this.setState({ tid: queries.id });
    // }
    // else{
    //   window.location.href="/";
    // }
  };

  render() {
    return (
      <div>
        <HeaderComponent />
        <Container>
          <div className="clearfix">&nbsp;</div>

          <Row>
            <Col className="col-lg-6 col-12">
              <Carousel>
                <Carousel.Item>
                  {console.log(this.state.fromtemplate[0])}
                  <img
                    className="d-block"
                    style={
                      this.state.cardOrientation != "portrait"
                        ? { width: "100%" }
                        : { width: "300px" }
                    }
                    src={
                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                      this.state.fromtemplate[0]
                    }
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block"
                    style={
                      this.state.cardOrientation != "portrait"
                        ? { width: "100%" }
                        : { width: "300px" }
                    }
                    src={
                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                      this.state.fromtemplate[1]
                    }
                    alt="Second slide"
                  />
                </Carousel.Item>
                {/* <Carousel.Item><img className="d-block w-100" src={`${this.state.fromtemplate}`} alt="First slide" /></Carousel.Item> */}
              </Carousel>
            </Col>
            <Col className="col-lg-6 col-12 mt-lg-0 mt-3">
              <h1 className="title-block-title text-size-1 strong generic-header-title-container ">
                Standard ID Cards
              </h1>
              <div className="clearfix">&nbsp;</div>
              <div className="col-12">
                {/* inputlist */}

                {/* {this.props.amount.amount} */}

                {/* done check */}
                {/* forms */}

                <Form className="register-form" onSubmit={this.handleOnSubmit}>
                  <Row>
                    <Col>
                      <div className="d-grid gap-2">
                        <Row>
                          <Col className="col-lg-9">
                            <FormLabel className="label1 py-2">
                              Standard Details
                            </FormLabel>
                          </Col>
                        </Row>
                      </div>

                      {/* 0 */}
                      <div className="d-grid gap-1">
                        {/* <input type="hidden" name="templateimage" className="form-control" value={this.props.location.state.fromtemplate+'.jpg'||this.state.fromtemplate+'.jpg'||''} onChange={this.handleUserInput} /> */}
                        {/* <select className="form-1" name="" value={this.state.templateimage || templateimage || "s2.jpg" }  onChange={this.handleUserInput}>
  <option value="d1.jpg">Select Design</option>
  <option value="d2.jpg">Design 1</option>
  <option value="d3.jpg">Design 2</option>
  <option value="b1.jpg">Design 3</option>
  <option value="b2.jpg">Design 4</option>
  <option value="b3.jpg">Design 5</option>
</select> */}
                        <InputGroup
                          className={`mb-0 form-group ${this.errorClass(
                            this.state.formErrors.staffid
                          )} || '' `}
                        >
                          <FormControl
                            placeholder="Staff ID"
                            aria-label="Staff ID"
                            aria-describedby="basic-addon2"
                            name="staffid"
                            //required
                            className="form-control"
                            value={this.state.staffid || ""}
                            onChange={this.handleUserInput}
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            className="p-0"
                          ></InputGroup.Text>
                        </InputGroup>
                        <div className="clearfix he-1">&nbsp;</div>
                      </div>
                      {/* 0 */}

                      {/* 1 */}
                      <div className="d-grid gap-1">
                        <InputGroup
                          className={`mb-0 form-group ${this.errorClass(
                            this.state.formErrors.firstname
                          )} || '' `}
                        >
                          <FormControl
                            placeholder="First Name"
                            name="firstname"
                            required
                            className="form-control"
                            value={this.state.firstname || ""}
                            onChange={this.handleUserInput}
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            className="p-0"
                          ></InputGroup.Text>
                        </InputGroup>
                        <div className="clearfix he-1">&nbsp;</div>
                      </div>
                      {/* 1 */}

                      {/* 2 */}

                      <div className="d-grid gap-1">
                        <InputGroup
                          className={`mb-0 form-group ${this.errorClass(
                            this.state.formErrors.lastname
                          )}`}
                        >
                          <FormControl
                            // placeholder="Middle Name"
                            aria-label="Middle Name"
                            aria-describedby="basic-addon2"
                            name="lastname"
                            required
                            className="form-control"
                            placeholder="Last Name"
                            value={this.state.lastname || ""}
                            onChange={this.handleUserInput}
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            className="p-0"
                          ></InputGroup.Text>
                        </InputGroup>
                        <div className="clearfix he-1">&nbsp;</div>
                      </div>
                      {/* 2 */}

                      {/* 3 */}
                      <div className="d-grid gap-1">
                        <InputGroup
                          className={`mb-0 form-group ${this.errorClass(
                            this.state.formErrors.position
                          )}`}
                        >
                          <FormControl
                            // placeholder="position"
                            aria-label="position"
                            aria-describedby="basic-addon2"
                            name="position"
                            required
                            className="form-control"
                            placeholder="Position"
                            value={this.state.position || ""}
                            onChange={this.handleUserInput}
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            className="p-0"
                          ></InputGroup.Text>
                        </InputGroup>
                        <div className="clearfix he-1">&nbsp;</div>
                      </div>
                      {/* 3 */}
                    </Col>
                    <Col>
                      <div className="d-grid gap-2">
                        <Row>
                          <Col className="col-lg-9">
                            <FormLabel className="label1 py-2">
                              Personal Details
                            </FormLabel>
                          </Col>
                          <Col className="d-lg-flex text-end">
                            <Button
                              variant="outline-dark"
                              size="md"
                              type="button"
                              value="add more"
                              onClick={this.addClick.bind(this)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-plus-lg"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z" />
                              </svg>
                            </Button>
                          </Col>
                        </Row>

                        <div className="clearfix he-1">&nbsp;</div>
                      </div>

                      {/* 5 */}

                      <div className="d-grid gap-2">
                        <InputGroup className={`mb-0 form-group`}>
                          <FormControl
                            type="number"
                            pattern="[6-9]{1}[0-9]{9}"
                            // placeholder="Phone"
                            aria-label="Phone"
                            aria-describedby="basic-addon2"
                            name="phoneno"
                            className="form-control"
                            placeholder="Phone Number"
                            value={this.state.phoneno || ""}
                            onChange={this.handleUserInput}
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            className="p-0"
                          ></InputGroup.Text>
                        </InputGroup>

                        <div className="clearfix he-1">&nbsp;</div>
                      </div>
                      {/* 5 */}
                      <div className="variablebox">{this.createUI()}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Link
                        to="/select-card-templates"
                        variant="outline-dark"
                        size="md"
                        type="button"
                        className="btn text-white col-lg-4 col-12 m-lg-2 mt-2"
                        style={{
                          backgroundColor: "#1f2744",
                          border: "solid 1px #1f2744",
                          display: "inline-block",
                          float: "left",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-collection"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z" />
                        </svg>{" "}
                        &nbsp;&nbsp; Card Templates
                      </Link>
                      {/* <SaveModalComponents /> */}

                      <button
                        variant="outline-dark"
                        size="md"
                        type="submit"
                        className="btn text-white col-12 col-lg-4 m-lg-2 mt-2"
                        style={{
                          backgroundColor: "#1f2744",
                          border: "solid 1px #1f2744",
                          display: "inline-block",
                          float: "left",
                        }}
                      >
                        Continue Editing &nbsp;&nbsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          />
                        </svg>
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
          <div className="clearfix">&nbsp;</div>
          <Row>
            <Col>
              <div className="text-">
                <h2>
                  <strong>
                    Your business is one of a kind. Now your Visiting Card can
                    be, too.
                  </strong>
                </h2>
                <p>
                  With a fresh box of professional visiting cards comes
                  confidence – the knowledge that you’re prepared for every
                  opportunity that comes your way. Whether you’re making first
                  impressions, rewarding regulars with a loyalty card or giving
                  satisfied clients your contact info for next time, we’re here
                  to help you look and feel ready to impress.
                </p>
                <p>
                  <strong>Papers &amp; details – you’ve got options</strong>
                </p>
                <p>
                  To begin creating your card, select your preferred paper
                  thickness (<strong>standard </strong>or{" "}
                  <strong>premium</strong>), paper stock (
                  <strong>matte </strong>or <strong>glossy</strong>) and corner
                  style (<strong>standard </strong>or <strong>rounded</strong>)
                  from the options above.
                </p>

                <p>
                  As you design, we’ll offer you a wide range of personalisation
                  options – including paper stocks and finish options – that
                  could work for your style, business and budget.
                </p>
              </div>
            </Col>
          </Row>

          <div className="clearfix">&nbsp;</div>
        </Container>
        <FooterComponent />
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
      </div>
    );
  }
}
// amount
function mapStateToProps(state) {
  return {
    card_details: state.card_data,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addCardDetails }, dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardDetailsTemplatesComponent);
