import React, { Component, useState,useRef  } from "react";
import { Tabs, Tab, Modal, Row, Button, Col, Form, Card, Container, Nav, Image, FormLabel, InputGroup, FormControl, } from "react-bootstrap-v5";
import DashboardTextFieldComponent from "./DashboardTextFieldComponent";
import DashboardImageComponent from "./DashboardImageComponent";
import DashboardTemplatesComponent from "./DashboardTemplatesComponent";
import queryString from 'query-string';
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import { useEffect } from "react";
// import HeaderComponent from "./HeaderComponent";
// import FooterComponent from "./FooterComponent";
import DashboardSaveModalComponents from "./DashboardSaveModalComponents";

import { Resizable } from "re-resizable";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {UpdateCardState, onChangePosition, addCardDetails} from '../../state/index';

import Draggable, { DraggableCore } from 'react-draggable';
import { get_Utemplatedata,savemycard,getcategorylist } from '../apiserve/ApiComponent';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
// import {onChangePosition} from '../state/index'

import { saveAs } from 'file-saver';

class DashboardCardTemplateUpdateComponents extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      card_data:[],
    }
    this.adjustXPos.state = {
      activeDrags: 0,
      categorylist:[],

      deltaPosition: {
        x: 0, y: 0
      },
      controlledPosition: {
        x: -400, y: 200
      }
    };
    this.onChangePosition = this.onChangePosition.bind(this);
    this.get_Utemplatedata=this.get_Utemplatedata.bind(this);
    this.inputRef = React.createRef();
    this.state = {
      response: "",
      responsetype: "",
      allcarddata: [],
      loading: false,
      setLoading: false,
      basicImagedata: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      frontbaseimg: "",
    };
  }
  get_Utemplatedata(id){
    // this.state.basicImagedata = this.state.baseImgPath + this.props.card_details.templatedesign;
    // this.setState(basicImagedata);
  //   let formdata = {
  //     templatedesign:this.state.fromtemplate,
  //     staffid:{text:this.state.staffid,color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
  //     firstname:{text:this.state.firstname,color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
  //     lastname:{text:this.state.lastname,color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
  //     position:{text:this.state.position,color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
  //     phoneno:{text:this.state.phoneno,color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
  //     extrafields:this.state.valuess,
  //     myuploadedimage:{file:'',position: { x:0, y:0 },frontback:'front'}
  // };
    // this.props.UpdateCardState(card_data);
    // console.log(formdata);
    
    get_Utemplatedata(id, (res) => {
      //success
      let card_templatedata=JSON.parse(res.data.data[0].cardtemplatedata);
      this.setState({card_data:card_templatedata})
      this.setState({allcarddata:res.data.data[0]})
    this.props.addCardDetails(card_templatedata);
      console.log(Object.getPrototypeOf(this.props.card_details.cardbgimage)===Object.prototype)
      console.log(this.props.card_details.cardbgimage)
      console.log(Object.keys(this.props.card_details.cardbgimage).length)
      this.props.UpdateCardState(card_templatedata);   
    },(err) => {
      console.log(err);
    });  

  }



  handleCustomDrag = (e,ui) => {
    // const {x, y} = this.state.deltaPosition;

    // this.setState({
    //   deltaPosition: {
    //     x: x + ui.deltaX,
    //     y: y + ui.deltaY,
    //   }
    // });
    console.log(e);
  };

  onStart = () => {
    this.setState({activeDrags: ++this.state.activeDrags});
  };

  onStop = () => {
    // console.log(this.state.activeDrags)
    this.setState({activeDrags: --this.state.activeDrags});
  };

  onChangePosition(e,position){
    // handleDrag = (e, ui) => {
      console.log(position);
      // console.log(ui.deltaY);
      // this.setState({
      //   deltaPosition: {
      //     x: x + ui.deltaX,
      //     y: y + ui.deltaY,
      //   }
      // });
    // };
    console.log(e);
    // let newx = e.layerX-e.offsetX;
    let newx = position.x;
    // let newy = e.layerY-e.offsetY;
    let newy = position.y;
    let targetname = e.target.id
    console.log(newx);
    console.log(newy);
    console.log(targetname);
    // console.log(this.props);
    this.props.onChangePosition({targetname, newy, newx});
    // console.log(this.props.card_details);
  }
  onDrop = (e) => {
    this.setState({activeDrags: --this.state.activeDrags});
    if (e.target.classList.contains("drop-target")) {
      alert("Dropped!");
      e.target.classList.remove('hovered');
    }
  };
  onTouchEnd(ev) {
    var rect = ev.target.getBoundingClientRect();
    console.log(rect.top, rect.right, rect.bottom, rect.left);
 }
 checkupdateposition(e){
  var rect = e.target.getBoundingClientRect();
  console.log(rect.top, rect.right, rect.bottom, rect.left);
 }
  onDropAreaMouseEnter = (e) => {
    if (this.state.activeDrags) {
      e.target.classList.add('hovered');
    }
  }
  onDropAreaMouseLeave = (e) => {
    e.target.classList.remove('hovered');
  }

  // For controlled component
  adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {x, y} = this.state.controlledPosition;
    this.setState({controlledPosition: {x: x - 10, y}});
  };

  adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {controlledPosition} = this.state;
    const {x, y} = controlledPosition;
    this.setState({controlledPosition: {x, y: y - 10}});
  };

  onControlledDrag = (e, position) => {
    const {x, y} = position;
    this.setState({controlledPosition: {x, y}});
  };

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  };


// checkforactionsaveandloggedinuser(){
  
//   	  let queries = queryString.parse(this.props.location.search);
// 		if (queries.action != undefined) {
// 		if(queries.action=='save' && localStorage.getItem("token") && localStorage.getItem("vcardurole")==2){
//        setTimeout(function() {
//         this.setState({ response: "Please save your card." }); 
//         this.setState({ responsetype: "success"}); 
//      }.bind(this), 5000); 
      
//     }  
// 	  } 
// }

  componentDidMount() {
    let id=''
    let queries = queryString.parse(this.props.location.search);
if (queries.id != undefined) {id=queries.id;}  
console.log(id);
localStorage.setItem('updatetempid', id);
  // let formdata = {
  //   templatedesign:'default.jpg',
  //   templatedesignB:'default.jpg',
  //   staffid:{text:"ID",color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:300, y:50 }} ,
  //   firstname:{text:"First Name",color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:300, y:100 }} ,
  //   lastname:{text:"Last Name",color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:300, y:150 }} ,
  //   position:{text:"Position",color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:300, y:200 }} ,
  //   phoneno:{text:"Phone",color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:300, y:250 }} ,
  //   extrafields:[],
  //   myuploadedimage:{file:'',position: { x:0, y:0 },frontback:'front'}
  // };
  // useEffect(() => {
    // this.setState({card_data:formdata})
    // this.props.addCardDetails(formdata);
  // }, [this.state.loading])
  // this.setState({setLoading:true});
// this.props.UpdateCardState(card_data);
// console.log(formdata);

this.get_Utemplatedata(id);
// this.getcategorylist();
// this.checkforactionsaveandloggedinuser();

  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps.card_details)
  }
  handleDelete(index) {
    this.props.delEmp(index);
  }
  handleEdit(index) {
    console.log("Edit:", index);
  }
  localStorageUpdated() {
    if (!localStorage.getItem('selectedFile')) {
      alert("not found");
    }
    else if (!this.state.status) {
      alert("found");
    }
  }

  
savetopng(){

  const input = document.getElementById('savefromhere');
  console.log(input);
  html2canvas(input, {
    allowTaint : false,
    useCORS: true,
  })
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      saveAs(imgData,'card-design.png');
    });
}
savetojpg(){
  const input = document.getElementById('savefromhere');
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/jpg');
      saveAs(imgData,'card-design.jpg');
    });
}
savetopdf(){
  const input = document.getElementById('savefromhere');
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("card-design.pdf");
    });
}
  render() {
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
    const { ResizableBox } = require('react-resizable');
  const htmlToImage = require('html-to-image');
  const FileSaver = require('file-saver');
  const {deltaPosition, controlledPosition} = this.state;


    return (
      <div>
        <TopBarComponent/>
        
        <div className="container-fluid">
        <div className="row">
        <SideBarComponent/> 
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <Container>
            <Row>
              <Col>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row style={{ border: "solid 1px #1f2744", borderRadius: "3px" }} className="mobiletabs mt-4">
                    <Col className="col-lg col-6">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-fonts"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.258 3h-8.51l-.083 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062 2.434.301 2.693 1.846h.479L12.258 3z" />
                            </svg>
                            <span className="hidemobile">Text</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-file-image"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                              <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12V2z" />
                            </svg>{" "}
                            <span className="hidemobile">Upload Template</span>
                          </Nav.Link>
                        </Nav.Item>

                        {/* <Nav.Item>
                          <Nav.Link eventKey="third">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-three-dots-vertical"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>{" "}
                            <span className="hidemobile">Templates Gallery</span>
                          </Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                    </Col>
                    <Col className="col-lg col-6">
                      <Nav className=" justify-content-end">
                        {/* <Nav.Item>
                          <Nav.Link className="cred">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-counterclockwise"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                              />
                              <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                            </svg>{" "}
                            <span className="hidemobile">Undo</span>
                          </Nav.Link>
                        </Nav.Item> */}
                        {/* <Nav.Item>
                          <Nav.Link className="cred">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-clockwise"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                              />
                              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                            </svg>{" "}
                            <span className="hidemobile">Redo</span>
                          </Nav.Link>
                        </Nav.Item> */}

                        {/* <Nav.Item>
          <Nav.Link className="cblue"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
</svg> Proof</Nav.Link>
        </Nav.Item> */}
                        <Nav.Item>
                          {/* <Nav.Link className="cblue" onClick={this.handleShow}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-save" viewBox="0 0 16 16">
  <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
</svg> Save</Nav.Link> */}
                          <DashboardSaveModalComponents />
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <div className="clearfix">&nbsp;</div>
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col sm={3} className="tabsidebar">
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              
                              <DashboardTextFieldComponent
                                state={this.state}
                                onChange={this.handleTextChange}
                                handleClick={this.changebold} />

                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              {/* {this.state.data} */}
                              <DashboardImageComponent  />
                            </Tab.Pane>

                            <Tab.Pane eventKey="third">
                              <DashboardTemplatesComponent/>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                        <Col sm={9} className="cardcontainer">
                          <Tab.Container id="bottom-tabs-example" defaultActiveKey="first">
                            <Row>
                              <Col sm={12}>
                                <Row>
                                  <Col>
                                    <Nav variant="pills" className="text-center justify-content-center">
                                      <Nav.Item>
                                        <Nav.Link eventKey="first">Front Side</Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="second">Back Side</Nav.Link>
                                      </Nav.Item>
                                      {/* <Nav.Item>
                                        <Nav.Link eventKey="third1">Third Side</Nav.Link>
                                      </Nav.Item> */}
                                    </Nav>
                                  </Col>
                                </Row>
                                <div className="clearfix he-1">&nbsp;</div>


                              </Col>
                              <Col sm={12} className="px-5">
                                <Tab.Content>
                                  <Tab.Pane eventKey="first">

                                    <div style={{ display: "block", border: "solid 1px blue" }}>

                                      <div id="savefromhere" className="col-lg-12" style={{ background: "#ccc" }}>
                                        {this.props.card_details.cardbgimage && (
                                          <div id="backgroundimagefront"
                                          className="col-lg-12 p-4 backgroundimage"
                                          // name={this.props.baseImgPath}
                                          style={{
                                            backgroundImage: `url(${(Object.getPrototypeOf(this.props.card_details.cardbgimage)===Object.prototype)?this.state.baseImgPath+this.state.allcarddata.cardbgimage:this.props.card_details.templatedesign})`,
                                            
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                            // crossOrigin:"anonymous",
                                            crossOrigin:"true"
                                          }}
                                          >
                                          {/* <div
                                          className="col-lg-12 p-4 backgroundimage"
                                          style={{
                                            background: `url(images/design/default.jpg})`,
                                            
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                          }}
                                          id="savefromhere"> */}

                                          <div  ref={this.inputRef} id={"cardheight"}
                                            className="col-lg-12 cardheight justify-content-center"
                                            style={{ height: "100%", border: "dashed 1px #000", overflow: "hidden",position:"relative" }}
                                          >

                                            <div className="col-12" style={{height:"100%"}}>
                                            {/* <Draggable position={this.state.controlledPosition} {...dragHandlers} onDrag={this.onControlledDrag}>
                                              <div className="box">
                                                My position can be changed programmatically. <br />
                                                I have a drag handler to sync state.
                                                <div>
                                                  <a href="#" onClick={this.adjustXPos}>Adjust x ({this.props.card_details.staffid.position.x})</a>
                                                </div>
                                                <div>
                                                  <a href="#" onClick={this.adjustYPos}>Adjust y ({this.props.card_details.staffid.position.x})</a>
                                                </div>
                                              </div>
                                            </Draggable> */}
                                            {this.props.card_details.staffid!=undefined && (
                                              <Draggable
                                              axis="both"
                                              handle=".handle"
                                               position={{ x:this.props.card_details.staffid.position.x, y:this.props.card_details.staffid.position.y}}
                                            
                                               {...dragHandlers}
                                               onDrag={this.onControlledDrag}
                                               onStop={this.onChangePosition}
                                              >
                                              <div>

                                                <Row className="handle">
                                                  <Col className="text-center">


                                                    <FormLabel className="m-0 p-0 col py-2 d-block" id="staffid"  style={{color:`${this.props.card_details.staffid.color || '#000'}`,fontWeight:`${this.props.card_details.staffid.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.staffid.fontSize || '14px'}`,fontStyle:`${this.props.card_details.staffid.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.staffid.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.staffid.alignText||"left"}`,fontFamily:`${this.props.card_details.staffid.fontFamily||"arial"}`}} >
                                                      {this.props.card_details.staffid.text}
                                                    </FormLabel>

                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                            )}

                                              {this.props.card_details.firstname!=undefined && (
                                              <Draggable
                                                axis="both"
                                                handle=".handle"
                                                position={{ x:this.props.card_details.firstname.position.x, y: this.props.card_details.firstname.position.y }}
                                                
                                                {...dragHandlers}
                                                onDrag={this.onControlledDrag}
                                                onStop={this.onChangePosition}>
                                                  
                                                <div>

                                                  <Row className="handle">
                                                    <Col className="text-center">


                                                      <FormLabel className="m-0 p-0 col d-block text-center py-2" id="firstname" style={{color:`${this.props.card_details.firstname.color || '#000'}`,fontWeight:`${this.props.card_details.firstname.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.firstname.fontSize || '14px'}`,fontStyle:`${this.props.card_details.firstname.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.firstname.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.firstname.alignText||"left"}`,fontFamily:`${this.props.card_details.firstname.fontFamily||"arial"}`}} >
                                                        {this.props.card_details.firstname.text}
                                                      </FormLabel>
                                                      
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Draggable>
                                              )}

                                              {this.props.card_details.lastname!=undefined && (
                                              <Draggable
                                                axis="both"
                                                handle=".handle"
                                                position={{ x: this.props.card_details.lastname.position.x, y: this.props.card_details.lastname.position.y}}
                                              
                                                {...dragHandlers}
                                                onDrag={this.onControlledDrag}
                                                onStop={this.onChangePosition}>
                                                <div>

                                                  <Row className="handle">
                                                    <Col className="text-center">


                                                   
                                                      <FormLabel className="m-0 p-0 col d-block text-center py-2" id="lastname" style={{color:`${this.props.card_details.lastname.color || '#000'}`,fontWeight:`${this.props.card_details.lastname.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.lastname.fontSize || '14px'}`,fontStyle:`${this.props.card_details.lastname.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.lastname.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.lastname.alignText||"left"}`,fontFamily:`${this.props.card_details.lastname.fontFamily||"arial"}`}} >
                                                        {this.props.card_details.lastname.text}
                                                      </FormLabel>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Draggable>
                                              )}
                                              {this.props.card_details.position!=undefined && (
                                              <Draggable
                                                axis="both"
                                                handle=".handle"
                                                position={{ x: this.props.card_details.position.position.x, y: this.props.card_details.position.position.y }}
                                               
                                                {...dragHandlers}
                                                onDrag={this.onControlledDrag}
                                                onStop={this.onChangePosition}>
                                                <div>

                                                  <Row className="handle">

                                                    <Col>
                                                      <FormLabel className="m-0 p-0 col-12 d-block text-center py-2" id="position" style={{color:`${this.props.card_details.position.color || '#000'}`,fontWeight:`${this.props.card_details.position.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.position.fontSize || '14px'}`,fontStyle:`${this.props.card_details.position.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.position.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.position.alignText||"left"}`,fontFamily:`${this.props.card_details.position.fontFamily||"arial"}`}} >
                                                        {this.props.card_details.position.text}
                                                      </FormLabel>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Draggable>
                                              )}
                                              {this.props.card_details.phoneno!=undefined && (
                                              <Draggable
                                                axis="both"
                                                handle=".handle"
                                                position={{ x: this.props.card_details.phoneno.position.x, y: this.props.card_details.phoneno.position.y }}
                                              
                                                {...dragHandlers}
                                                onDrag={this.onControlledDrag}
                                                onStop={this.onChangePosition}>
                                                <div>

                                                  <Row className="handle">
                                                    <Col>


                                                      <FormLabel className="m-0 p-0 col-12 d-block text-center py-2" id="phoneno" style={{color:`${this.props.card_details.phoneno.color || '#000'}`,fontWeight:`${this.props.card_details.phoneno.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.phoneno.fontSize || '14px'}`,fontStyle:`${this.props.card_details.phoneno.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.phoneno.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.phoneno.alignText||"left"}`,fontFamily:`${this.props.card_details.phoneno.fontFamily||"arial"}`}} >
                                                        {this.props.card_details.phoneno.text}
                                                      </FormLabel>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Draggable>
                                              )}


                                              {/* {this.props.card_details.extrafields.map(function (item, i) {
                                                return <div key={i}>
                                                  <Draggable
                                                    axis="both"
                                                    handle=".handle"
                                                    positionOffset={{ x: 60, y: -224 }}
                                                    position={null}
                                                    grid={[1, 1]}
                                                    scale={1}
                                                    onStart={this.handleStart}
                                                    onDrag={this.handleDrag}
                                                    onStop={this.handleStop}>
                                                    <div>

                                                      <Row className="handle">
                                                        <Col>
                                                          <FormLabel className="col-12 text-center py-2 fw-bold" >
                                                            {item}
                                                          </FormLabel>
                                                        </Col>
                                                      </Row>

                                                    </div>
                                                  </Draggable>
                                                </div>
                                              })} */}

{/* {this.props.card_details.extrafields && this.props.card_details.extrafields.map((item,i) =>(

  item.frontback=="front" && <Draggable
        // axis="both"
        // handle=".handle" 
        position={{x: 200, y: 290}}        
        // position={null}
        // grid={[1, 1]}
        // scale={1}
        {...dragHandlers}
        // onStart={this.handleStart}
        onDrag={this.onControlledDrag}
        // onDrag={this.handleDrag}
        onStop={this.onChangePosition}
        >
<div>
          
          <Row className="handle">
          <div style={{fontSize:`${item.fontSize ||'14px'}`,display:"inline-block",color:`${item.color ||'#000'}`,fontFamily:`${item.fontFamily ||'arial'}`,fontWeight:`${item.fontBold?'bold':'normal'}`,fontStyle:`${item.fontItalic?'italic':'normal'}`,textDecoration:`${item.fontUnderline?'underline':'none'}`}} key={i}>{item.text}</div>
</Row>
</div>
</Draggable>

))} */}
                                              {/* { this.props.card_details.myuploadedimage.frontback=='front' && this.props.card_details.myuploadedimage.file && (
    <div>
<Draggable
                                                axis="both"
                                                handle=".handle"
                                                 positionOffset={{ x: 27, y: 176 }}
                                                position={null}
                                                positionOffset={{ x: 27, y: 176 }}
                                                grid={[1, 1]}
                                                scale={1}
                                                onStart={this.handleStart}
                                                onDrag={this.handleDrag}                                                
                                                onStop={this.handleStop}
                                                
                                                
                                              >
                                                <div>

                                                  <Row className="handle">
                                                    <Resizable
                                                      
                                                      defaultSize={{
                                                        width: "auto",
                                                        height: "auto"
                                                      }}
                                                    >
                                                      <img src={ this.props.card_details.myuploadedimage.file?this.props.card_details.myuploadedimage.file : null ||'' } style={{ width: "100%", height: "100%" }} />
                                                    </Resizable>
                                                  </Row>
                                                </div>
                                              </Draggable>
    </div>
  )} */}


                                            </div>
                                          </div>
                                        </div>
                                        )}
                                        
                                      </div>
                                    </div>





                                  </Tab.Pane>
                                  <Tab.Pane eventKey="second">
                                    <div style={{ display: "block", border: "solid 1px blue" }}>

                                      <div className="col-lg-12" style={{ background: "#ccc" }}>
                                        {this.props.card_details.templatedesignB && (
                                          <div
                                          className="col-lg-12 p-4 backgroundimage"
                                          style={{
                                            background: `url(${this.state.allcarddata.card_back_image==undefined||null? this.state.baseImgPath + this.state.allcarddata.cardbgimage:this.state.baseImgPath + this.state.allcarddata.card_back_image})`,
                                            
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                            crossOrigin:"anonymous"
                                          }}
                                          id="savefromhereback"
                                          >

                                          <div
                                            className="col-lg-12 cardheight justify-content-center"
                                            style={{ height: "100%", border: "dashed 1px #000",overflow:"hidden",position:"relative" }}
                                          >

                                            <div className="col-12 align-middle justify-content-center" >

                                              {/* <Draggable
                                                axis="both"
                                                handle=".handle"
                                                positionOffset={{ x: 24, y: 2 }}
                                                position={null}
                                                grid={[1, 1]}
                                                scale={1}
                                                onStart={this.handleStart}
                                                onDrag={this.handleDrag}
                                                onStop={this.handleStop}>
                                                <div>

                                                  <Row className="handle">
                                                    <Col className="text-center">


                                                      <FormLabel className="m-0 p-0 col d-block text-center py-2" id="valuw1" style={{color:`${this.props.card_details.staffid.color || '#000'}`,fontWeight:`${this.props.card_details.staffid.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.staffid.fontSize || '14px'}`,fontStyle:`${this.props.card_details.staffid.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.staffid.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.staffid.alignText||"left"}`,fontFamily:`${this.props.card_details.staffid.fontFamily||"arial"}`}} >

                                                        {this.props.card_details.staffid.text}
                                                      </FormLabel>

                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Draggable> */}
                                              {/* <Draggable
                                                axis="both"
                                                handle=".handle"
                                                positionOffset={{ x: 240, y: 80 }}
                                                position={null}
                                                grid={[1, 1]}
                                                scale={1}
                                                onStart={this.handleStart}
                                                onDrag={this.handleDrag}
                                                onStop={this.handleStop}>
                                                <div>

                                                  <Row className="handle">
                                                    <Col className="text-center">


                                                      <FormLabel className="m-0 p-0 col d-block text-center py-2" id="valuw1" style={{color:`${this.props.card_details.firstname.color || '#000'}`,fontWeight:`${this.props.card_details.firstname.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.firstname.fontSize || '14px'}`,fontStyle:`${this.props.card_details.firstname.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.firstname.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.firstname.alignText||"left"}`,fontFamily:`${this.props.card_details.firstname.fontFamily||"arial"}`}} >
                                                        {this.props.card_details.firstname.text}
                                                      </FormLabel>
                                                      
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Draggable> */}
                                              {/* <Draggable
                                                axis="both"
                                                handle=".handle"
                                                positionOffset={{ x: 240, y: 80 }}
                                                position={null}
                                                grid={[1, 1]}
                                                scale={1}
                                                onStart={this.handleStart}
                                                onDrag={this.handleDrag}
                                                onStop={this.handleStop}>
                                                <div>

                                                  <Row className="handle">
                                                    <Col className="text-center">

                                                      <FormLabel className="m-0 p-0 col d-block text-center py-2" style={{color:`${this.props.card_details.lastname.color || '#000'}`,fontWeight:`${this.props.card_details.lastname.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.lastname.fontSize || '14px'}`,fontStyle:`${this.props.card_details.lastname.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.lastname.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.lastname.alignText||"left"}`,fontFamily:`${this.props.card_details.lastname.fontFamily||"arial"}`}} >
                                                        {this.props.card_details.lastname.text}
                                                      </FormLabel>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Draggable> */}
                                              {/* <Draggable
                                                axis="both"
                                                handle=".handle"
                                                positionOffset={{ x:400, y: 10 }}
                                                position={null}
                                                grid={[1, 1]}
                                                scale={1}
                                                onStart={this.handleStart}
                                                onDrag={this.handleDrag}
                                                onStop={this.handleStop}>
                                                <div>

                                                  <Row className="handle">

                                                    <Col>
                                                      <FormLabel className="m-0 p-0 col-12 d-block text-center py-2" style={{color:`${this.props.card_details.position.color || '#000'}`,fontWeight:`${this.props.card_details.position.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.position.fontSize || '14px'}`,fontStyle:`${this.props.card_details.position.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.position.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.position.alignText||"left"}`,fontFamily:`${this.props.card_details.position.fontFamily||"arial"}`}} >
                                                        {this.props.card_details.position.text}
                                                      </FormLabel>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Draggable> */}
                                              {/* <Draggable
                                                axis="both"
                                                handle=".handle"
                                                positionOffset={{ x: 240, y: 100 }}
                                                position={null}
                                                grid={[1, 1]}
                                                scale={1}
                                                onStart={this.handleStart}
                                                onDrag={this.handleDrag}
                                                onStop={this.handleStop}>
                                                <div>

                                                  <Row className="handle">
                                                    <Col>


                                                      <FormLabel className="m-0 p-0 col-12 d-block text-center py-2" id="valuw0" style={{color:`${this.props.card_details.phoneno.color || '#000'}`,fontWeight:`${this.props.card_details.phoneno.fontBold?"bold":"normal"}`,fontSize:`${this.props.card_details.phoneno.fontSize || '14px'}`,fontStyle:`${this.props.card_details.phoneno.fontItalic?"italic":"normal"}`,textDecoration:`${this.props.card_details.phoneno.fontUnderline?"underline":"none"}`,textAlign:`${this.props.card_details.phoneno.alignText||"left"}`,fontFamily:`${this.props.card_details.phoneno.fontFamily||"arial"}`}} >
                                                        {this.props.card_details.phoneno.text}
                                                      </FormLabel>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Draggable> */}
                                              {/* {this.props.card_details.extrafields && this.props.card_details.extrafields.map((item,i) =>(

  item.frontback=="back" && <Draggable
        axis="both"
        handle=".handle" 
        positionOffset={{x: 200, y: 290}}        
        position={null}
        grid={[1, 1]}
        scale={1}
        onStart={this.handleStart}
        onDrag={this.handleDrag}
        onStop={this.handleStop}
        >
<div>
          
          <Row className="handle">
          <div style={{fontSize:`${item.fontSize ||'14px'}`,display:"inline-block",color:`${item.color ||'#000'}`,fontFamily:`${item.fontFamily ||'arial'}`,fontWeight:`${item.fontBold?'bold':'normal'}`,fontStyle:`${item.fontItalic?'italic':'normal'}`,textDecoration:`${item.fontUnderline?'underline':'none'}`}} key={i}>{item.text}</div>
</Row>
</div>
</Draggable>

))} */}
{/* 
{ this.props.card_details.myuploadedimage.frontback=='back' && this.props.card_details.myuploadedimage.file && (
    <div>
<Draggable
                                                axis="both"
                                                handle=".handle"
                                                positionOffset={{ x: 27, y: 176 }}
                                                position={null}
                                                grid={[1, 1]}
                                                scale={1}
                                                onStart={this.handleStart}
                                                onDrag={this.handleDrag}
                                                onStop={e=>this.handleStop(e)}
                                           
                                              >
                                                <div>

                                                  <Row className="handle">
                                                    <Resizable
                                                      
                                                      defaultSize={{
                                                        width: "auto",
                                                        height: "auto"
                                                      }}
                                                    >
                                                      <img src={ this.props.card_details.myuploadedimage.file?this.props.card_details.myuploadedimage.file : null ||'' } style={{ width: "100%", height: "100%" }} />
                                                    </Resizable>
                                                  </Row>
                                                </div>
                                              </Draggable>
    </div>
  )} */}
                                              


                                            </div>
                                          </div>
                                        </div>
                                        )}
                                        
                                      </div>
                                    </div>
                                  </Tab.Pane>

                                </Tab.Content>
                              </Col>
                              <Col sm={12}>
                                <Row>
                                  <div className="clearfix he-1">&nbsp;</div>
                                  <Col className="justify-content-center text-center">
                                  {/* <button onClick={this.savetopng} type="button" class="m-1 btn-sm btn btn-primary" >Save to PNG</button> */}
                                  {/* <button onClick={this.savetojpg} type="button" class="m-1 btn-sm btn btn-primary" >Save to JPG</button> */}
                                  {/* <button onClick={this.savetopdf} type="button" class="m-1 btn-sm btn btn-primary" >Save to PDF</button> */}
                                  </Col>
                                  
                                  {/* <Col>
                                    <div className="justify-content-center nav" role="tablist">
                                      <div className="nav-item">
                                        <a
                                          href="#"
                                          role="tab"
                                          id="left-tabs-example-tab-null"
                                          aria-controls="left-tabs-example-tabpane-null"
                                          tabIndex="-1"
                                          className="cred nav-link"
                                        >
                                          {" "}
                                          Preview
                                        </a>
                                      </div>
                                      <div className="nav-item">
                                        <a
                                          href="#"
                                          role="tab"
                                          id="left-tabs-example-tab-null"
                                          aria-controls="left-tabs-example-tabpane-null"
                                          tabIndex="-1"
                                          className="cred nav-link"
                                        >
                                          {" "}
                                          Next
                                        </a>
                                      </div>
                                    </div>
                                  </Col> */}
                                </Row>
                                <div className="clearfix he-1">&nbsp;</div>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>{" "}
            </Row>
          </Container>
          </main>
        </div>
        </div>
        {/* <FooterComponent /> */}
      </div>
    );
  }
}
// DashboardCardTemplateCreateComponents.defaultProps = {    
//   card_details: {
//     templatedesign:'default.jpg',
//     staffid:{text:'id',color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
//     firstname:{text:'First Name',color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
//     lastname:{text:'Last Name',color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
//     position:{text:'Position',color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
//     phoneno:{text:'Phone',color:"#000",fontBold:false,fontItalic:false,fontUnderline:false,alignText:"left",fontSize:"14px",fontFamily:"arial",position: { x:0, y:0 }} ,
//     extrafields:[],
//     myuploadedimage:{file:'',position: { x:0, y:0 },frontback:'front'}
//   }
// }
function mapStateToProps(state) {
  return {
    card_details: state.card_data,
  };
}
function mapDispatchToProps(dispatch){  
  return bindActionCreators({ UpdateCardState, onChangePosition, addCardDetails},dispatch )
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardCardTemplateUpdateComponents);
