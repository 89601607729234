import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import Toast from "react-bootstrap/Toast";
import {
  Tabs,
  Tab,
  Table,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Pagination,
} from "react-bootstrap-v5";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import "./dashboard.css";
import { getmycard, buyusercards } from "../apiserve/ApiComponent";
class DashboardComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mycarddata: [],
      carddata: [],
      response: "",
      responsetype: "",
      isChecked: false,
      baseImgPath:
        "https://office.monitorwa.com.au/tccardorderapi/public/templates/",
      // items:[],
      // itemsdata:[],
      // items:{'items':[]},
      checkedState: [],
      total_amount: "",
      // qantity:"",
    };
    // this.qtyminus = this.qtyminus.bind(this)
    this.buyusercards = this.buyusercards.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.singlePurchas = this.singlePurchas.bind(this);
    this.handlePlus = this.handlePlus.bind(this);
  }
  getmycard() {
    getmycard(
      (res) => {
        let DcheckedState = [];
        console.log(res.data.data[0]);
        this.setState({ mycarddata: res.data.data[0] });
        for (let i = 0; i < this.state.mycarddata.length; i++) {
          this.state.mycarddata[i].qty = parseInt(this.state.mycarddata[i].qty);
          // let amount = parseInt(this.state.total_amount + (this.state.mycarddata[i].qty * this.state.mycarddata[i].amount));
          DcheckedState.push(false);
          // this.setState({total_amount:amount});
        }
        // this.setState({qantity:0});
        this.setState({ checkedState: DcheckedState });
        this.setState({
          carddata: JSON.parse(this.state.mycarddata.card_data),
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // Purchased Cards;
  buyusercards() {
    let items = [];
    for (let i = 0; i < this.state.mycarddata.length; i++) {
      if (
        parseInt(this.state.mycarddata[i].qty) > 0 &&
        this.state.checkedState[i] == true
      ) {
        items.push({
          id: this.state.mycarddata[i].id,
          qty: this.state.mycarddata[i].qty,
          userid: this.state.mycarddata[i].userid,
          carddescription: this.state.mycarddata[i].cardname,
          itemcost: this.state.mycarddata[i].amount,
          total: this.state.mycarddata[i].amount * this.state.mycarddata[i].qty,
        });
      }
    }
    if (items.length == 0) {
      this.setState({ response: "Please select card(s) to purchase." });
      this.setState({ responsetype: "error" });
    } else {
      //Add Postage if Cards Ordered
      items.push({
        id: "0",
        qty: 1,
        userid: "",
        carddescription: "Flat Postage Fee",
        itemcost: "10",
        total: "10",
      });

      const formData = new FormData();
      formData.append("items", JSON.stringify(items));
      formData.append("userid", localStorage.getItem("mwauid"));

      buyusercards(
        formData,
        (res) => {
          if (res.status == 200) {
            this.setState({
              response:
                "Payment link is generated, Redirecting to payment page.",
            });
            this.setState({ responsetype: "success" });
            let paymentlink = res.data.data.PaymentURL;
            //Payment Link
            localStorage.setItem("accesscode", res.data.data.AccessCode);
            setTimeout(function () {
              window.location.href = paymentlink;
              //window.open(paymentlink);
            }, 1000);
          }
        },
        (err) => {
          console.log(err);
          this.setState({
            response: "Error while generating payment link, Try again.",
          });
          this.setState({ responsetype: "error" });
        }
      );
    }
  }

  singlePurchas(id, template_id) {
    console.log("here");
    let items = [];
    for (let i = 0; i < this.state.mycarddata.length; i++) {
      if (
        parseInt(this.state.mycarddata[i].qty) > 0 &&
        this.state.mycarddata[i].id == id
      ) {
        items.push({
          id: this.state.mycarddata[i].id,
          qty: this.state.mycarddata[i].qty,
          userid: this.state.mycarddata[i].userid,
        });
      }
    }
    items.push({
      id: "0",
      qty: 1,
      userid: "",
      carddescription: "Flat Postage Fee",
      itemcost: "10",
      total: "10",
    });

    const formData = new FormData();
    formData.append("items", JSON.stringify(items));
    formData.append("userid", localStorage.getItem("mwauid"));

    buyusercards(
      formData,
      (res) => {
        if (res.status == 200) {
          this.setState({
            response: "Payment link is generated, Redirecting to payment page.",
          });
          this.setState({ responsetype: "success" });
          let paymentlink = res.data.data.PaymentURL;
          //Payment Link
          //localStorage.setItem("accesscode", res.data.data.AccessCode);
          setTimeout(function () {
            window.location.href = paymentlink;
            //window.open(paymentlink);
          }, 1000);
        }
      },
      (err) => {
        this.setState({
          response: "Error while generating payment link, Try again.",
        });
        this.setState({ responsetype: "error" });
      }
    );
  }

  handleChange(e, uid, templateid) {
    console.log(e);
    console.log(templateid);
    let checkedarray = [];
    let amount = "";
    if (e.target.checked == true) {
      for (let i = 0; i < this.state.mycarddata.length; i++) {
        if (this.state.mycarddata[i].id == uid) {
          checkedarray = this.state.checkedState;
          checkedarray[i] = true;
          console.log(checkedarray);
          this.state.mycarddata[i].qty = 1;
          amount = parseInt(
            this.state.total_amount +
              this.state.mycarddata[i].amount * this.state.mycarddata[i].qty
          );
          this.setState({ total_amount: amount });
        }
      }
      this.setState({ checkedState: checkedarray });
    } else {
      for (let i = 0; i < this.state.mycarddata.length; i++) {
        if (this.state.mycarddata[i].id == uid) {
          checkedarray = this.state.checkedState;
          checkedarray[i] = false;
          console.log(checkedarray);
          amount = parseInt(
            this.state.total_amount -
              this.state.mycarddata[i].amount * this.state.mycarddata[i].qty
          );
          this.setState({ total_amount: amount });
          this.state.mycarddata[i].qty = 0;
        }
      }
      this.setState({ checkedState: checkedarray });
    }
  }

  // quantity minus
  handleMinus(uid) {
    // console.log(uid)
    for (let i = 0; i < this.state.mycarddata.length; i++) {
      if (this.state.mycarddata[i].id == uid) {
        if (this.state.mycarddata[i].qty == 0) {
          this.setState({ response: "Add some cards first" });
          this.setState({ responsetype: "error" });
        } else {
          this.state.mycarddata[i].qty = this.state.mycarddata[i].qty - 1;
          this.state.total_amount = parseInt(
            this.state.total_amount - parseInt(this.state.mycarddata[i].amount)
          );
        }
      }
    }
  }

  // quantity plus
  handlePlus(uid, i) {
    // $(checkbox'#id).prop('checked', true);
    let flag = true;
    // for(let x=0; x<(this.state.checkedState).length; x++){
    if (this.state.checkedState[i] == false) {
      flag = false;
    }
    // }
    if (flag == true) {
      for (let i = 0; i < this.state.mycarddata.length; i++) {
        if (this.state.mycarddata[i].id == uid) {
          this.state.mycarddata[i].qty = this.state.mycarddata[i].qty + 1;
          this.state.total_amount = parseInt(
            this.state.total_amount + parseInt(this.state.mycarddata[i].amount)
          );
        }
      }
    } else {
      this.setState({ response: "Please select card first" });
      this.setState({ responsetype: "error" });
    }
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") == 2) {
      this.getmycard();
    } else {
      window.location.href = "/";
    }
  }
  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <TopBarComponent imagePath={localStorage.getItem("profileimagepath")} />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="container rounded bg-white m-1 mt-3 p-0">
                <Tabs
                  defaultActiveKey="saved"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="saved" title="My Stored Cards">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>NO</th>
                          <th>Card Name</th>
                          <th>Card Front</th>
                          <th>Card Back</th>
                          <th>Created at</th>
                          <th>Status</th>
                          <th>Cost</th>
                          <th>Action</th>
                          <th>Purchase Quantity</th>
                          <th>Buy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.mycarddata.map(function (item, i) {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.cardname}</td>
                              <td>
                                {item.card_orientation != "portrait" ? (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.card_preview
                                    }
                                    style={{ width: "100px", height: "60px" }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.card_preview
                                    }
                                    style={{ width: "60px", height: "100px" }}
                                  />
                                )}
                              </td>
                              <td>
                                {item.card_orientation != "portrait" ? (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.card_preview_back
                                    }
                                    style={{ width: "100px", height: "60px" }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.card_preview_back
                                    }
                                    style={{ width: "60px", height: "100px" }}
                                  />
                                )}
                              </td>
                              <td>
                                {
                                  new Date(item.created_at)
                                    .toString()
                                    .split("GMT")[0]
                                }
                              </td>
                              <td>
                                {item.card_status == 0 ? "Draft" : "Saved"}
                              </td>
                              <td>
                                {item.amount == 0 ? "Free" : "$" + item.amount}
                              </td>
                              <td>
                                <Link to={`/user/mycarddetails?id=${item.id}`}>
                                  View Card
                                </Link>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  name={item.id}
                                  value={item.id}
                                  defaultChecked={this.state.isChecked}
                                  onChange={(e) =>
                                    this.handleChange(
                                      e,
                                      item.id,
                                      item.template_id
                                    )
                                  }
                                />{" "}
                                &nbsp;
                                <a
                                  href="#"
                                  className="qty minumqty"
                                  onClick={(e) => this.handleMinus(item.id)}
                                >
                                  -
                                </a>{" "}
                                {item.qty}{" "}
                                <a
                                  href="#"
                                  className="qty"
                                  onClick={(e) => this.handlePlus(item.id, i)}
                                >
                                  +
                                </a>
                              </td>
                              <td>
                                <Button
                                  className="btn btn-success"
                                  onClick={(e) =>
                                    this.singlePurchas(
                                      item.id,
                                      item.template_id
                                    )
                                  }
                                >
                                  Buy Now
                                </Button>
                              </td>
                            </tr>
                          );
                        }, this)}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </div>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  Billing Amount: $
                  {this.state.total_amount == ""
                    ? "0"
                    : this.state.total_amount}
                </h1>
                <div className="btn-toolbar mb-2 mb-md-0 text-right">
                  <button
                    type="button"
                    onClick={this.buyusercards}
                    className="btn btn-sm btn-success btn-outline-secondary text-white"
                  >
                    Buy Cards
                  </button>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardComponents;
