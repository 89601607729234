import React, { Component, useState } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink, Switch } from 'react-router-dom'
import {Tabs, Tab, Modal, Row, Button, Col, Form, Card, Container,Nav,Image,FormLabel,Carousel,Navbar,NavDropdown} from "react-bootstrap-v5";   
class TopBarComponent extends Component{
 
  constructor(props) {
    super(props);
   if(!localStorage.getItem('token') && (localStorage.getItem('vcardurole')!=2))
   {
     window.location.href="/";
   }
  //  console.log(localStorage.getItem('token'));
  //  console.log("roel:",localStorage.getItem('vcardurole'))
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('vcardurole');
    localStorage.removeItem('username');
    localStorage.removeItem('mwauid');
    window.location.href="/login";
      }
    render() {

        return (
          <div>
            <Navbar expand="lg" className="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow" style={{background:"#1f2744 "}}>
  <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/">MONITOR WA</a>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="col-12 col-lg-auto ms-lg-auto mb-2 justify-content-end mb-md-0 visibleMO text-white">
        <li><Link to="/admin/dashboard" className="nav-link px-2" style={{color:"#fff",fontSize:"16px"}}>Dashboard</Link></li>
        <li><Link to="/admin/users" className="nav-link px-2" style={{color:"#fff",fontSize:"16px"}}>Users</Link></li>
        <li><Link to="/admin/all-cards" className="nav-link px-2" style={{color:"#fff",fontSize:"16px"}}>User Cards</Link></li>
        <li><Link to="/admin/cards-templates" className="nav-link px-2" style={{color:"#fff",fontSize:"16px"}}>Cards Templates</Link></li>
        <li><Link to="/admin/cards-category" className="nav-link px-2" style={{color:"#fff",fontSize:"16px"}}>Categories</Link></li>
        <li><Link to="/admin/card-orders" className="nav-link px-2" style={{color:"#fff",fontSize:"16px"}}>Card Orders</Link></li>
        <li><Link to="/admin/slider-setting" className="nav-link px-2" style={{color:"#fff",fontSize:"16px"}}>Slider Setting</Link></li>
        <li><Link to="/admin/website-setting" className="nav-link px-2" style={{color:"#fff",fontSize:"16px"}}>Website Setting</Link></li>
      </Nav>
      <div className="nav-item text-nowrap">
      <a href="#" className="btn text-white nav-link p-3" aria-current="page" onClick={this.logout}>Sign out</a>     
    </div>
    </Navbar.Collapse>
</Navbar>
          {/* <header className="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow" style={{background:"#1f2744 "}}>
  <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">Company name</a>
  <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="navbar-nav">
    <div className="nav-item text-nowrap">
      <Link className="nav-link p-3" aria-current="page" onClick={this.logout}>Sign out</Link>     
    </div>
  </div>
</header> */}
          </div>
       

        )
    }
}
export default TopBarComponent;
