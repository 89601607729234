import React, { Component, useState } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  FormLabel,
  Row,
  Col,
  Form,
} from "react-bootstrap-v5";
import * as Icon from "react-bootstrap-icons";
import Popover from "react-bootstrap/Popover";
import Modal from "react-bootstrap/Modal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import Spinner from "react-bootstrap/Spinner";
import {
  savetempdesign,
  updatetempdesign,
  uploadtemplatefile,
} from "../apiserve/ApiComponent";
import Toast from "react-bootstrap/Toast";
import html2canvas from "html2canvas";

class DashboardSaveModalComponents extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSaveMessage = this.handleSaveMessage.bind(this);
    this.handlesavedClose = this.handlesavedClose.bind(this);

    this.state = {
      show: false,
      saved: false,
      response: "",
      responsetype: "",
    };
  }

  componentDidMount() {
    //   console.log(JSON.stringify(JSON.stringify(this.props.card_details)));
  }

  handleClose() {
    this.setState({ show: false });
  }

  handlesavedClose() {
    this.setState({ saved: false });
  }

  handleShow() {
    let carddata = JSON.stringify(this.props.card_details);

    if (JSON.parse(carddata).Tname == undefined) {
      this.setState({ response: "Enter Template Design Name" });
      this.setState({ responsetype: "error" });
    } else if (JSON.parse(carddata).amount == undefined) {
      this.setState({ response: "Enter Template Price" });
      this.setState({ responsetype: "error" });
    } else if (!Number.isInteger(parseInt(JSON.parse(carddata).amount))) {
      this.setState({ response: "Enter Valid Price or Enter Number" });
      this.setState({ responsetype: "error" });
    } else if (JSON.parse(carddata).status == undefined) {
      this.setState({ response: "Select Template Status" });
      this.setState({ responsetype: "error" });
    } else if (JSON.parse(carddata).category_id == undefined) {
      this.setState({ response: "Select Template Category" });
      this.setState({ responsetype: "error" });
    } else {
      this.setState({ show: true });
    }
  }

  async savetempdesign() {
    const inputf = document.getElementById("savefromhere");
    const inputb = document.getElementById("savefromhereback");
    console.log(inputf);
    let card_preview_back;
    let card_preview;
    let card_preview_Update;
    this.setState({ isLoading: true });

    const formData = new FormData();
    let carddata = JSON.stringify(this.props.card_details);
    html2canvas(inputb, {
      onclone: function (clonedDoc) {
        clonedDoc.getElementById(
          "bottom-tabs-example-tabpane-second"
        ).style.display = "block";
      },
    }).then((canvas) => {
      var file;
      file = canvas.toDataURL("image/png");
      var blobBin = atob(file.split(",")[1]);
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      file = new Blob([new Uint8Array(array)], { type: "image/png" });
      card_preview_back = file;
      html2canvas(inputf).then((canvas) => {
        var file;
        file = canvas.toDataURL("image/png");
        var blobBin = atob(file.split(",")[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        file = new Blob([new Uint8Array(array)], { type: "image/png" });
        card_preview = this.props.card_details.cardbgimage;
        card_preview_Update = file;

        const template_id = parseInt(localStorage.getItem("designId"));

        let uid = localStorage.getItem("mwauid");
        let statuscheck = JSON.parse(carddata).status;
        if (JSON.parse(carddata).status == "active") {
          statuscheck = 1;
        } else {
          statuscheck = 0;
        }

        //formData.append("image_back_preview", card_preview_back);
        formData.append("name", JSON.parse(carddata).Tname);
        formData.append("data", carddata);
        formData.append("status", statuscheck);
        formData.append("amount", parseInt(JSON.parse(carddata).amount));
        formData.append(
          "category_id",
          parseInt(JSON.parse(carddata).category_id)
        );
        formData.append("card_orientation", JSON.parse(carddata).orientation);
        // console.log(formData);
        let updatetempid = "";
        updatetempid = localStorage.getItem("updatetempid");
        if (updatetempid != undefined) {
          console.log("update template");

          if (
            (this.props.card_details.cardbgimage != undefined || null) &&
            !(
              Object.getPrototypeOf(this.props.card_details.cardbgimage) ===
              Object.prototype
            )
          ) {
            formData.append("image_front", card_preview);
          }
          if (
            (this.props.card_details.cardbgimageback != undefined || null) &&
            !(
              Object.getPrototypeOf(this.props.card_details.cardbgimageback) ===
              Object.prototype
            )
          ) {
            formData.append("image_back", card_preview_back);
          }
          formData.append("image_front_preview", card_preview_Update);
          formData.append("id", updatetempid);

          //save template images to public folder

          updatetempdesign(
            formData,
            (res) => {
              //success
              this.setState({ show: false });
              this.setState({ isLoading: false });
              this.setState({
                response: "Your card template has been updated.",
              });
              this.setState({ responsetype: "success" });
              // setTimeout(function () {
              //   window.location.href = "/admin/cards-templates";
              // }, 1000);
            },
            (err) => {
              //error
              console.log(err);
              this.setState({ show: false });
              this.setState({
                response:
                  "Error while updateting design." + JSON.stringify(err),
              });
              this.setState({ responsetype: "error" });
            }
          );
        } else {
          formData.append("image_front_preview", card_preview);
          formData.append(
            "image_back_preview",
            this.props.card_details.cardbgimageback
          );
          //formData.append("image_front", card_preview);
          //formData.append("preview_front", card_preview);
          console.log(formData);

          uploadtemplatefile(formData);
          savetempdesign(
            formData,
            (res) => {
              //success
              this.setState({ show: false });
              this.setState({ isLoading: false });
              this.setState({
                response: "Your card template has been created.",
              });
              // this.setState({ responsetype: "success" });
              // setTimeout(function () {
              //   window.location.href = "/admin/cards-templates";
              // }, 1000);
            },
            (err) => {
              console.log(err);
              this.setState({ show: false });
              this.setState({
                response: "Error while creating design." + JSON.stringify(err),
              });
              this.setState({ responsetype: "error" });
            }
          );
        }
      });
    });

    // }
  }

  closetoast() {
    this.setState({ response: "" });
  }

  handleSaveMessage() {
    this.setState({ show: false });
    //   check for logged in users
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("vcardurole") == 1) {
        this.setState({ saved: true });
        this.savetempdesign();
      } else {
        this.setState({
          response: "Your token is expired, Please login. Redirecting to login",
        });
        this.setState({ responsetype: "error" });
        setTimeout(function () {
          window.location.href = "/login?q=savecard";
        }, 1000); //will call the function after 2 secs.
      }
    } else {
      setTimeout(function () {
        window.location.href = "/login?q=savecard";
      }); //will call the function after 2 secs.
    }
  }

  render() {
    return (
      <div>
        <Button className="cbluesave" onClick={this.handleShow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-save"
            viewBox="0 0 16 16"
          >
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
          </svg>{" "}
          <span className="hidemobile">Save Template</span>
        </Button>
        {/* <button type="button" className="btn btn-primary"  onClick={this.handleShow}>Login</button> */}

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Save Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure, You want to save this design.
            </h5>
            <p className="text-center">Before saving please confirm design.</p>
            <Row>
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.handleSaveMessage}>
                  Save
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.saved} onHide={this.handlesavedClose}>
          <Modal.Header closeButton>
            <Modal.Title>Template Saved</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <h5 className="text-center">Template is being saved.</h5>
            <p className="text-center">Please wait till process complete.</p>
            <Row>
              {/* <Col><Button size="sm" onClick={this.handlesavedClose}>OK</Button></Col> */}
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    card_details: state.card_data,
  };
}
export default connect(mapStateToProps, null)(DashboardSaveModalComponents);
