import "./App.css";

import React, {
  useEffect,
  useState,
  Component,
  Suspense,
  lazy,
  useReducer,
} from "react";
//Import immutability-helper
import update from "immutability-helper";

import HeaderComponent from "./components/HeaderComponent";
import DesignerComponent from "./components/DesignerComponent";
import TextFieldComponent from "./components/TextFieldComponent";
import FormssComponent from "./components/FormssComponent";
import HomePageComponent from "./components/HomePageComponent";
import LoginComponent from "./components/LoginComponent";
import SignupComponent from "./components/SignupComponent";
import { browserHistory } from "react-router"; // notice I'm importing from 'react-router'

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FooterComponent from "./components/FooterComponent";
import ForgotPasswordComponent from "./components/ForgotPasswordComponent";
import ResetPasswordComponent from "./components/ResetPasswordComponent";
import PaymentConfirmationPage from "./components/PaymentConfirmationPage";
import MailVerificationPage from "./components/MailVerificationPage";
import CardTemplatesComponent from "./components/CardTemplatesComponent";
import CardDetailsTemplatesComponent from "./components/CardDetailsTemplatesComponent";
import ColorComponent from "./components/ColorComponent";
import FileUploadComponent from "./components/FileUploadComponent";
import ImageComponent from "./components/ImageComponent";

import DashboardComponents from "./components/admin/DashboardComponents";
import DashboardUserComponents from "./components/admin/DashboardUserComponents";
import DashboardCardOrddersComponents from "./components/admin/DashboardCardOrddersComponents";
import DashboardPendingCardOrdersComponents from "./components/admin/DashboardPendingCardOrdersComponents";
import DashboardCompletedCardOrdersComponents from "./components/admin/DashboardCompletedCardOrdersComponents";
import DashboardCancelledCardOrdersComponents from "./components/admin/DashboardCancelledCardOrdersComponents";
import DashboardAllCardsComponents from "./components/admin/DashboardAllCardsComponents";
import DashboardAllCardsTemplatesComponents from "./components/admin/DashboardAllCardsTemplatesComponents";
import DashboardGetUserProfileComponents from "./components/admin/DashboardGetUserProfileComponents";
import DashboardCreateUserComponents from "./components/admin/DashboardCreateUserComponents";
import DashboardCardTemplateDetailedViewComponent from "./components/admin/DashboardCardTemplateDetailedViewComponent";
import DashboardCardTemplateCreateComponents from "./components/admin/DashboardCardTemplateCreateComponents";
import DashboardCardTemplateUpdateComponents from "./components/admin/DashboardCardTemplateUpdateComponents";
import DashboardAllCategoryComponent from "./components/admin/DashboardAllCategoryComponent";
import DashboardCardCategoryCreateComponents from "./components/admin/DashboardCardCategoryCreateComponents";
import DashboardCardCategoryDetailsComponents from "./components/admin/DashboardCardCategoryDetailsComponents";
import DashboardWebsiteSettingComponents from "./components/admin/DashboardWebsiteSettingComponents";
import DashboardOrderDetailsComponents from "./components/admin/DashboardOrderDetailsComponents";
import UserOrderDetailsComponents from "./components/user/UserOrderDetailsComponents";
import DesignerComponentUpdate from "./components/DesignerComponentUpdate";

import PricingComponents from "./components/PricingComponents";
import ContactComponent from "./components/ContactComponent";
import PrivacyComponent from "./components/PrivacyComponent";
import TermsComponent from "./components/TermsComponent";
import CheckOutComponent from "./components/CheckOutComponent";
import CartComponent from "./components/CartComponent";

import userDashboardComponents from "./components/user/DashboardComponents";
import userDashboardUserComponents from "./components/user/DashboardUserComponents";
import userDashboardCardOrddersComponents from "./components/user/DashboardCardOrddersComponents";
import userDashboardPendingCardOrdersComponents from "./components/user/DashboardPendingCardOrdersComponents";
import userDashboardCompletedCardOrdersComponents from "./components/user/DashboardCompletedCardOrdersComponents";
import userDashboardCancelledCardOrdersComponents from "./components/user/DashboardCancelledCardOrdersComponents";
import DashboardMyUserProfileComponents from "./components/user/DashboardMyUserProfileComponents";
import DashboardMyPlansComponents from "./components/user/DashboardMyPlansComponents";
import DashboardMyCardsComponents from "./components/user/DashboardMyCardsComponents";
import DashboardChangePasswordComponents from "./components/user/DashboardChangePasswordComponents";
import DashboardOrderHistoryComponents from "./components/user/DashboardOrderHistoryComponents";
import DashboardCardDetailedViewComponent from "./components/admin/DashboardCardDetailedViewComponent";
import DashboardMyCardDetailedViewComponent from "./components/user/DashboardMyCardDetailedViewComponent";
import DashboardAllSliderComponent from "./components/admin/DashboardAllSliderComponent";
import DashboardSliderCreateComponents from "./components/admin/DashboardSliderCreateComponents";
import DashboardSliderUpdateComponents from "./components/admin/DashboardSliderUpdateComponents";
import userDashboardSavedTemplates from "./components/user/DashboardSavedTemplates";
import userDashboardSavedTemplateDetails from "./components/user/DashboardSavedTemplateDetails";
import userDashboardQueuedCards from "./components/user/DashboardQueuedCards";
import DashboardPrintCard from "./components/admin/DashboardPrintCard";
import CreateTemplateComponent from "./components/CreateCardTemplate";

function App(props) {
  var BrowserHistory = require("react-router").default;

  return (
    <Router>
      <div className="App"></div>
      <Switch>
        <Route exact path="/" component={HomePageComponent} />
        <Route path="/login" component={LoginComponent} />
        <Route path="/signup" component={SignupComponent} />
        {/* <Route path="/logout" component={LoginComponent} /> */}
        <Route path="/forgot-password" component={ForgotPasswordComponent} />
        <Route path="/reset-password" component={ResetPasswordComponent} />
        <Route path="/payment_status" component={PaymentConfirmationPage} />
        <Route path="/email-verify" component={MailVerificationPage} />
        <Route path="/card-design" component={DesignerComponent} />
        <Route path="/card-design-update" component={DesignerComponentUpdate} />
        <Route path="/select-card-templates">
          <CardTemplatesComponent />
        </Route>
        <Route
          path="/create-template"
          component={CreateTemplateComponent}
        ></Route>
        <Route path="/pricing" component={PricingComponents}></Route>
        <Route path="/contact-us" component={ContactComponent}></Route>
        <Route path="/privacy" component={PrivacyComponent}></Route>
        <Route path="/terms" component={TermsComponent}></Route>
        <Route path="/card" component={CardDetailsTemplatesComponent}></Route>
        <Route path="/color" component={ColorComponent}></Route>
        <Route path="/fileupload" component={FileUploadComponent}></Route>
        <Route path="/image" component={ImageComponent}></Route>
        <Route exact path="/admin/" component={DashboardComponents}></Route>
        <Route path="/admin/dashboard" component={DashboardComponents}></Route>
        <Route path="/admin/users" component={DashboardUserComponents}></Route>
        <Route
          path="/admin/userdetails"
          component={DashboardGetUserProfileComponents}
        ></Route>
        <Route
          path="/admin/createuser"
          component={DashboardCreateUserComponents}
        ></Route>
        <Route
          path="/admin/all-cards"
          component={DashboardAllCardsComponents}
        ></Route>
        <Route
          path="/admin/carddetails"
          component={DashboardCardDetailedViewComponent}
        ></Route>
        <Route
          path="/admin/templatedetails"
          component={DashboardCardTemplateDetailedViewComponent}
        ></Route>
        <Route
          path="/admin/cards-templates"
          component={DashboardAllCardsTemplatesComponents}
        ></Route>
        <Route
          path="/admin/createtemplate"
          component={DashboardCardTemplateCreateComponents}
        ></Route>
        <Route
          path="/admin/updatetemplate"
          component={DashboardCardTemplateUpdateComponents}
        ></Route>
        <Route
          path="/admin/orders"
          component={DashboardOrderDetailsComponents}
        ></Route>
        <Route path="/admin/printcard" component={DashboardPrintCard}></Route>
        <Route
          path="/user/orders"
          component={UserOrderDetailsComponents}
        ></Route>
        <Route
          path="/admin/cards-category"
          component={DashboardAllCategoryComponent}
        ></Route>
        <Route
          path="/admin/createcategory"
          component={DashboardCardCategoryCreateComponents}
        ></Route>
        <Route
          path="/admin/cardcategory"
          component={DashboardCardCategoryDetailsComponents}
        ></Route>
        <Route
          path="/admin/slider-setting"
          component={DashboardAllSliderComponent}
        ></Route>
        <Route
          path="/admin/create-slider"
          component={DashboardSliderCreateComponents}
        ></Route>
        <Route
          path="/admin/update-slider"
          component={DashboardSliderUpdateComponents}
        ></Route>
        <Route
          path="/admin/website-setting"
          component={DashboardWebsiteSettingComponents}
        ></Route>
        <Route
          path="/admin/card-orders"
          component={DashboardCardOrddersComponents}
        ></Route>
        <Route
          path="/admin/pending-orders"
          component={DashboardPendingCardOrdersComponents}
        ></Route>
        <Route
          path="/admin/completed-orders"
          component={DashboardCompletedCardOrdersComponents}
        ></Route>
        <Route
          path="/admin/cancelled-orders"
          component={DashboardCancelledCardOrdersComponents}
        ></Route>
        <Route path="/checkout">
          <CheckOutComponent />
        </Route>
        <Route path="/cart">
          <CartComponent />
        </Route>
        <Route
          exact
          path="/user/"
          component={DashboardMyUserProfileComponents}
        ></Route>
        <Route
          path="/user/dashboard"
          component={DashboardMyUserProfileComponents}
        ></Route>
        <Route
          path="/user/myprofile"
          component={DashboardMyUserProfileComponents}
        ></Route>
        <Route
          path="/user/myplan"
          component={DashboardMyPlansComponents}
        ></Route>
        <Route
          path="/user/change_password"
          component={DashboardChangePasswordComponents}
        ></Route>
        <Route
          path="/user/order_history"
          component={DashboardOrderHistoryComponents}
        ></Route>
        {/* <Route path="/user/users" component={userDashboardUserComponents}></Route>       */}
        <Route
          path="/user/mycards"
          component={DashboardMyCardsComponents}
        ></Route>
        <Route
          path="/user/mycarddetails"
          component={DashboardMyCardDetailedViewComponent}
        ></Route>
        <Route
          path="/user/pending-orders"
          component={userDashboardPendingCardOrdersComponents}
        ></Route>
        <Route
          path="/user/completed-orders"
          component={userDashboardCompletedCardOrdersComponents}
        ></Route>
        <Route
          path="/user/cancelled-orders"
          component={userDashboardCancelledCardOrdersComponents}
        ></Route>
        <Route
          path="/user/saved_templates"
          component={userDashboardSavedTemplates}
        ></Route>
        <Route
          path="/user/saved_templatedetails"
          component={userDashboardSavedTemplateDetails}
        ></Route>
        <Route
          path="/user/queuedCards"
          component={userDashboardQueuedCards}
        ></Route>
      </Switch>
    </Router>
  );
}

export default App;
