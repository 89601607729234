import React, { Component, useState } from 'react';
import {Button,InputGroup,FormControl,FormLabel, Row,Col,Form} from "react-bootstrap-v5";
import * as Icon from 'react-bootstrap-icons';
import { FormErrors } from '../FormErrors';
import FieldFontsDropdownComponent from './FieldFontsDropdownComponent';
import ColorComponent from './ColorComponent';
class FieldOptionsComponent extends Component{
  constructor(props) {
    super(props);
    this.state = props.state;
    this.state = {
      // email: '',
      // password: '',
      staffid:'',
      studentname: "",
      middlename: "",
      surname: "",
      dob: "",
      phoneno: "",
      email: "",
      parentguardian: "",
      school: "",
      street: "",
      postcode: "",
      suburb: "",
      statename: "",
      stylestudentname:"",
      studentnamecolorpicker:"",
      formErrors: {
        studentname: "",
        middlename: "",
        surname: "",
        dob: "",
        phoneno: "",
        email: "",
        parentguardian: "",
        school: "",
        street: "",
        postcode: "",
        suburb: "",
        statename: "",
      },
      // Valid: false,
      studentnameValid: false,
      middlenameValid: false,
      surnameValid: false,
      surnameValid: false,
      dobValid: false,
      phonenoValid: false,
      emailValid: false,
      parentguardianValid: false,
      schoolValid: false,
      streetValid: false,
      postcodeValid: false,
      suburbValid: false,
      statenameValid: false,
      formValid: false,
      showHidestudentname: false,
      showHidemiddlename: false,
      showHidesurname: false,
      showHidedob: false,
      showHidephoneno: false,
      showHideemail: false,
      showHideparentguardian: false,
      showHideschool: false,
      showHidestreet: false,
      showHidepostcode: false,
      showHidesuburb: false,
      showHidestatename: false,
      values: [],
      // template url
      templateUrl:
        "https://img.freepik.com/free-photo/hand-painted-watercolor-background-with-sky-clouds-shape_24972-1095.jpg",
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleChange(i, event) {
  //   let values = [...this.state.values];
  //   values[i] = event.target.value;
  //   this.setState({ values });
  // }

  addClick() {
    this.setState((prevState) => ({ values: [...prevState.values, ""] }));
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }
  // handleSubmit(event) {
  //   alert("A name was submitted: " + this.state.values.join(", "));
  //   event.preventDefault();
  // }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let studentnamecolorpicker = this.state.studentnamecolorpicker;    
    this.setState(
      {
        studentnamecolorpicker: studentnamecolorpicker,        
      },
    );
  }
    render() {
      const { staffid,studentname,middlename,surname,phoneno,studentnamecolorpicker } = (this.props.location && this.props.location.state) || {};
        return (
          <div>
            <div >
            {/* {this.state.studentnamecolorpicker} */}
              <FieldFontsDropdownComponent/>
              {/* <ColorComponent /> */}
            </div>            
          </div>

        )
    }
}
export default FieldOptionsComponent;
