import React, { Component, useState } from "react";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
} from "react-bootstrap-v5";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
import { getallcardstemplatelist } from "./apiserve/ApiComponent";
class FeaturedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allcardstemp: [],
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
    };
  }
  getallcardstemplatelist() {
    getallcardstemplatelist(
      (res) => {
        this.setState({ allcardstemp: res.data.data[0] });
        console.log(this.state.allcardstemp);
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading cards templates." });
        this.setState({ responsetype: "error" });
      }
    );
  }
  componentDidMount() {
    this.getallcardstemplatelist();
  }
  render() {
    const { from } = "";
    return (
      <div>
        <Container>
          <div className="clearfix">&nbsp;</div>
          <Row xs={1} md={3} className="g-4">
            {this.state.allcardstemp.slice(0, 3).map(function (item, i) {
              return (
                <Col sm={12} md={4} key={item.id}>
                  <Link
                    to={"/card?template=design" + item.id + "&id=" + item.id}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <Card>
                      <Card.Img
                        variant="top"
                        src={
                          item.cardbgimage != ""
                            ? `https://office.monitorwa.com.au/tccardorderapi/templates/${item.cardbgimage}`
                            : "images/design/design1.jpg"
                        }
                        style={{ height: "270px" }}
                      />
                      <Card.Body>
                        <Card.Title style={{ color: "#000" }}>
                          {item.cardtemplatename}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            }, this)}
          </Row>
          <div className="clearfix">&nbsp;</div>

          <div className="clearfix">&nbsp;</div>
        </Container>
      </div>
    );
  }
}
export default FeaturedComponent;
