import React, { Component, useState } from "react";
import "../App.css";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
} from "react-bootstrap-v5";
class ContactComponent extends Component {
  render() {
    return (
      <div>
        <HeaderComponent />

        <div className="clearfix">&nbsp;</div>
        <div className="col bgblue" style={{ paddingTop: "30px" }}>
          <Container style={{ maxWidth: "1140px" }}>
            <div className="row">
              <div
                className="col-md-6 col-sm-12"
                style={{ padding: "6rem 10px" }}
              >
                <h1 className="text-white">Get in touch</h1>
                <p className="text-white" style={{ fontSize: "18px" }}>
                  Want to get in touch? We'd love to hear from you. Here's how
                  you can reach us...
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <img
                  src="images/Berry%20Contact%20Us.png"
                  width="100%"
                  style={{ Width: "100%" }}
                />
              </div>
            </div>
          </Container>
          <div
            className="col"
            style={{
              height: "64px",
              backgroundImage: "url(images/below--swoop--white--v3.svg)",
            }}
          >
            &nbsp;
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col citem1 text-center">
              <div className="col my-4">
                <img
                  className="contact-box-item logo"
                  style={{ height: "50px" }}
                  src="https://www.hubspot.com/hubfs/Contact%20Us/phone.svg"
                />
              </div>
              <div className="col">
                <h3 className="contact-box-title">Talk to Sales</h3>
              </div>
              <div className="col">
                <p className="contactp">
                  Are you looking for a custom card solution then please call
                  our sales team.
                </p>
              </div>
              <p
                style={{
                  marginTop: "50px",
                  fontSize: "20px",
                  fontWeight: "500",
                  letterSpacing: "1px",
                }}
              >
                +61 8 9240 4777
              </p>
            </div>
            <div className="col citem1 text-center">
              <div className="col my-4">
                <img
                  className="contact-box-item logo"
                  style={{ height: "50px" }}
                  src="https://www.hubspot.com/hubfs/Contact%20Us/chat.svg"
                />
              </div>
              <div className="col">
                <h3 className="contact-box-title">Talk to Support</h3>
              </div>
              <div className="col">
                <p className="contactp">
                  Having trouble then please feel free to contact our support
                  department.
                </p>
              </div>
              <div className="col" style={{ margin: "30px" }}>
                <a
                  className="btn1"
                  style={{ display: "inline-block" }}
                  href="mailto:support@monitorwa.com.au"
                >
                  Contact Support
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div
              className="col m-2"
              style={{
                border: "solid 1px #ddd",
                borderRadius: "10px",
                padding: "50px",
              }}
            >
              <div className="row">
                <div className="col-md-6 col-sm-12 text-center">
                  <img src="images/img-01.png" alt="IMG" width="50%" />
                </div>
                <div className="col-md-6 col-sm-12">
                  <form className="contact100-form validate-form">
                    <div className="col text-center">
                      <h3 className="contact-box-title">Contact Form </h3>
                    </div>

                    <div
                      className="wrap-input100 validate-input"
                      data-validate="Name is required"
                    >
                      <input
                        className="input100"
                        type="text"
                        name="name"
                        placeholder="Name"
                      />
                      <span className="focus-input100"></span>
                    </div>

                    <div
                      className="wrap-input100 validate-input"
                      data-validate="Valid email is required: ex@abc.xyz"
                    >
                      <input
                        className="input100"
                        type="text"
                        name="email"
                        placeholder="Email"
                      />
                      <span className="focus-input100"></span>
                    </div>

                    <div
                      className="wrap-input100 validate-input"
                      data-validate="Message is required"
                    >
                      <textarea
                        className="input100"
                        name="message"
                        placeholder="Message"
                      ></textarea>
                      <span className="focus-input100"></span>
                    </div>
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <div className="container-contact100-form-btn">
                          <br></br>
                          <button className="contact-form-btn">Send</button>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div clasName="clearfix">&nbsp;</div>
        <FooterComponent />
      </div>
    );
  }
}
export default ContactComponent;
