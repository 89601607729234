import React, { Component } from "react";
import HomePageComponent from "./HomePageComponent";
import LoginComponent from "./LoginComponent";
import SignupComponent from "./SignupComponent";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  Navbar,
  NavDropdown,
} from "react-bootstrap-v5";

import { connect } from "react-redux";
class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //baseImgPath:'http://vcard.nirmalvermicompost.com/API/vcardapi/public/images/',
      baseImgPath: "../../public/images",
    };
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("vcardurole");
    localStorage.removeItem("username");
    localStorage.removeItem("mwauid");
    window.location.href = "/login";
  }
  render() {
    return (
      <div>
        <Navbar expand="lg">
          <Container>
            <Link
              to="/"
              className="d-flex align-items-center mb-2 col-5 mb-lg-0 text-decoration-none"
            >
              <Image
                src={require("../public/images/logo.png")}
                className="img-responsive"
                id="monitorMainLogo"
              />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="col-12 col-lg-auto ms-lg-auto mb-2 justify-content-end mb-md-0">
                <li>
                  <Link
                    to="/"
                    className="nav-link px-2"
                    style={{ color: "#494c4f", fontSize: "16px" }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/select-card-templates"
                    className="nav-link px-2"
                    style={{ color: "#494c4f", fontSize: "16px" }}
                  >
                    Templates
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname:
                        "https://monitorwa.com.au/contact-us-apac-support-solution-sales/",
                    }}
                    target="_blank"
                    className="nav-link px-2"
                    style={{ color: "#494c4f", fontSize: "16px" }}
                  >
                    Contact Us
                  </Link>
                </li>
              </Nav>

              {localStorage.getItem("token") &&
                localStorage.getItem("vcardurole") == 2 && (
                  <div>
                    <Nav>
                      <li>
                        <Link
                          to="/user/myprofile"
                          type="button"
                          className="btn btn-outline-light me-2"
                          style={{ color: "#494c4f" }}
                        >
                          {localStorage.getItem("username")}
                        </Link>
                      </li>
                      <a
                        href="#"
                        type="button"
                        className="btn text-white"
                        style={{
                          backgroundColor: "#1f2744",
                          border: "solid 1px #1f2744",
                        }}
                        onClick={this.logout}
                      >
                        Log Out
                      </a>
                    </Nav>
                  </div>
                )}

              {localStorage.getItem("token") &&
                localStorage.getItem("vcardurole") == 1 && (
                  <div>
                    <Nav>
                      <li>
                        <Link
                          to="/admin/dashboard"
                          type="button"
                          className="btn btn-outline-light me-2"
                          style={{ color: "#494c4f" }}
                        >
                          {localStorage.getItem("username")}
                        </Link>
                      </li>
                      <a
                        href="#"
                        type="button"
                        className="btn text-white"
                        style={{
                          backgroundColor: "#1f2744",
                          border: "solid 1px #1f2744",
                        }}
                        onClick={this.logout}
                      >
                        Log Out
                      </a>
                    </Nav>
                  </div>
                )}
              {!localStorage.getItem("token") &&
                !localStorage.getItem("vcardurole") && (
                  <div>
                    <Nav>
                      <li>
                        <Link
                          to="/login"
                          type="button"
                          className="btn btn-outline-light me-2"
                          style={{ color: "#494c4f" }}
                        >
                          Login
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/signup"
                          type="button"
                          className="btn text-white"
                          style={{
                            backgroundColor: "#1f2744",
                            border: "solid 1px #1f2744",
                          }}
                        >
                          Sign-up
                          {this.props.balance}
                        </Link>
                      </li>
                    </Nav>
                  </div>
                )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default HeaderComponent;
