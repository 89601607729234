import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import {
  getcategorydata,
  deletecategory,
  updatecategory,
} from "../apiserve/ApiComponent";
import TopBarComponent from "./TopBarComponent";
import queryString from "query-string";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import "./dashboard.css";
class DashboardCardCategoryDetailsComponents extends Component {
  constructor(props) {
    super(props);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.state = {
      allorders: [],
      response: "",
      categoryname: "",
      catid: "",
      show: false,
      showD: false,
      responsetype: "",
      isLoading: false,
    };
    // this.showuserdetails = this.showuserdetails.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.deletecategory = this.deletecategory.bind(this);
    this.updatecategory = this.updatecategory.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(type) {
    if (type == "delete") {
      this.setState({ showD: true });
    } else if (this.state.categoryname == "" || undefined) {
      this.setState({ response: "Enter category name." });
      this.setState({ responsetype: "danger" });
    } else {
      this.setState({ show: true });
    }
  }
  deletecategory() {
    let cardid = {};
    // cardid= localStorage.getItem('currentcard');
    cardid = { id: this.state.catid };
    console.log(cardid);
    this.setState({ isLoading: true });
    deletecategory(
      cardid,
      (res) => {
        if ((res.status = 200)) {
          this.setState({ isLoading: false });
          this.setState({ showD: false });
          this.setState({ response: "Category deleted successfully." });
          this.setState({ responsetype: "success" });
          setTimeout(function () {
            window.location.href = "/admin/cards-category";
          }, 1000);
        }
      },
      (err) => {
        console.log(err);
        this.setState({ showD: false });
        this.setState({ response: "Error in deleting category." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  updatecategory() {
    let cardid = {};
    cardid = this.state.catid;
    const formData = new FormData();
    formData.append("id", this.state.catid);
    formData.append("name", this.state.categoryname);
    // cardid= localStorage.getItem('currentcard');
    this.setState({ isLoading: true });
    updatecategory(
      formData,
      (res) => {
        console.log(res);
        if ((res.status = 200)) {
          this.setState({ isLoading: false });
          this.setState({ show: false });
          this.setState({ response: "Category Updated successfully." });
          this.setState({ responsetype: "success" });
          setTimeout(function () {
            window.location.href = "/admin/cards-category";
          }, 1000);
        }
      },
      (err) => {
        console.log(err);
        this.setState({ show: false });
        this.setState({ response: "Error in updating category." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  handleUserInput(e) {
    const value = e.target.value;
    let updatedata = value;
    this.setState({ categoryname: updatedata });
    // console.log(this.state.categoryname);
  }
  getcategorydata(id) {
    getcategorydata(
      id,
      (res) => {
        this.setState({ categoryname: res.data.data });
      },
      (err) => {
        this.setState({ isLoading: false });
        this.setState({ response: "Error while getting category data" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  handleOnSubmit(e) {
    e.preventDefault();
    let catname = { name: "" };
    console.log(catname);
    catname.name = this.state.categoryname;
    console.log(catname);
    if (catname == "" || undefined) {
      this.setState({ response: "Please enter category name" });
      this.setState({ responsetype: "error" });
    } else {
      this.setState({ isLoading: true });
      // createcategory(catname, (res) => {
      //     if(res.success == false){
      //         this.setState({isLoading:false});
      //         this.setState({ response: "Category alredy exist" });
      //       this.setState({ responsetype: "error"});
      //     }
      //     else{
      //         this.setState({isLoading:false});
      //         this.setState({ response: "Category Created successfully" });
      //       this.setState({ responsetype: "success"});
      //     }
      //   },(err) => {
      //     this.setState({isLoading:false});
      //     this.setState({ response: "Error while creating category" });
      //       this.setState({ responsetype: "error"});
      //   });
    }
  }
  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      let queries = queryString.parse(this.props.location.search);
      let tempid = queries.id;
      this.setState({ catid: tempid });
      this.setState({ categoryname: queries.name });
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure, You want to update Category.
            </h5>
            {/* <p className="text-center">
				  Once you delete your template, template will no longer accessible for user.
				</p> */}
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.updatecategory}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>

            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showD} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure, You want to Delete Category.
            </h5>
            {/* <p className="text-center">
				  Once you delete your template, template will no longer accessible for user.
				</p> */}
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.deletecategory}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>

            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Update Category</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      onClick={(e) => this.handleShow("update")}
                      className="btn btn-sm btn-success text-white btn-outline-secondary"
                    >
                      Update
                    </button>
                  </div>
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      onClick={(e) => this.handleShow("delete")}
                      className="btn btn-sm btn-danger text-white btn-outline-secondary"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div className="container rounded bg-white m-1">
                <form onSubmit={this.handleOnSubmit}>
                  <div className="row">
                    <div className="col-lg-12 col-md-6 border-right">
                      <div className="p-1">
                        <div className="row mt-3">
                          <div className="col-lg-6 col-md-6">
                            <label>Category</label>
                            <InputGroup>
                              <FormControl
                                name="fname"
                                placeholder="Enter Category Name"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.categoryname || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>
                          {/* <div className="col-lg-3 col-md-3 mt-3">                    
                        <button type="submit" className="contact-form-btn btn btn-success text-white">
                            <span>Create Category</span>
                        </button>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardCardCategoryDetailsComponents;
