import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  getmy_profiledetails,
  updateuserprofile,
  emailaddresscheck,
} from "../apiserve/ApiComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import { onFileUpload } from "../apiserve/ApiComponent";
import axios from "axios";
import "./dashboard.css";
class DashboardComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myprofiledata: [],
      response: "",
      responsetype: "",
      isLoading: false,
      selectedFile: "",
      selectedFileBase64: "",
      defaultimg:
        "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/profile/",
      fname: "",
      lname: "",
      email: "",
      country: [
        { name: "Afghanistan", code: "AF" },
        { name: "Åland Islands", code: "AX" },
        { name: "Albania", code: "AL" },
        { name: "Algeria", code: "DZ" },
        { name: "American Samoa", code: "AS" },
        { name: "AndorrA", code: "AD" },
        { name: "Angola", code: "AO" },
        { name: "Anguilla", code: "AI" },
        { name: "Antarctica", code: "AQ" },
        { name: "Antigua and Barbuda", code: "AG" },
        { name: "Argentina", code: "AR" },
        { name: "Armenia", code: "AM" },
        { name: "Aruba", code: "AW" },
        { name: "Australia", code: "AU" },
        { name: "Austria", code: "AT" },
        { name: "Azerbaijan", code: "AZ" },
        { name: "Bahamas", code: "BS" },
        { name: "Bahrain", code: "BH" },
        { name: "Bangladesh", code: "BD" },
        { name: "Barbados", code: "BB" },
        { name: "Belarus", code: "BY" },
        { name: "Belgium", code: "BE" },
        { name: "Belize", code: "BZ" },
        { name: "Benin", code: "BJ" },
        { name: "Bermuda", code: "BM" },
        { name: "Bhutan", code: "BT" },
        { name: "Bolivia", code: "BO" },
        { name: "Bosnia and Herzegovina", code: "BA" },
        { name: "Botswana", code: "BW" },
        { name: "Bouvet Island", code: "BV" },
        { name: "Brazil", code: "BR" },
        { name: "British Indian Ocean Territory", code: "IO" },
        { name: "Brunei Darussalam", code: "BN" },
        { name: "Bulgaria", code: "BG" },
        { name: "Burkina Faso", code: "BF" },
        { name: "Burundi", code: "BI" },
        { name: "Cambodia", code: "KH" },
        { name: "Cameroon", code: "CM" },
        { name: "Canada", code: "CA" },
        { name: "Cape Verde", code: "CV" },
        { name: "Cayman Islands", code: "KY" },
        { name: "Central African Republic", code: "CF" },
        { name: "Chad", code: "TD" },
        { name: "Chile", code: "CL" },
        { name: "China", code: "CN" },
        { name: "Christmas Island", code: "CX" },
        { name: "Cocos (Keeling) Islands", code: "CC" },
        { name: "Colombia", code: "CO" },
        { name: "Comoros", code: "KM" },
        { name: "Congo", code: "CG" },
        { name: "Congo, The Democratic Republic of the", code: "CD" },
        { name: "Cook Islands", code: "CK" },
        { name: "Costa Rica", code: "CR" },
        { name: "Cote D'Ivoire", code: "CI" },
        { name: "Croatia", code: "HR" },
        { name: "Cuba", code: "CU" },
        { name: "Cyprus", code: "CY" },
        { name: "Czech Republic", code: "CZ" },
        { name: "Denmark", code: "DK" },
        { name: "Djibouti", code: "DJ" },
        { name: "Dominica", code: "DM" },
        { name: "Dominican Republic", code: "DO" },
        { name: "Ecuador", code: "EC" },
        { name: "Egypt", code: "EG" },
        { name: "El Salvador", code: "SV" },
        { name: "Equatorial Guinea", code: "GQ" },
        { name: "Eritrea", code: "ER" },
        { name: "Estonia", code: "EE" },
        { name: "Ethiopia", code: "ET" },
        { name: "Falkland Islands (Malvinas)", code: "FK" },
        { name: "Faroe Islands", code: "FO" },
        { name: "Fiji", code: "FJ" },
        { name: "Finland", code: "FI" },
        { name: "France", code: "FR" },
        { name: "French Guiana", code: "GF" },
        { name: "French Polynesia", code: "PF" },
        { name: "French Southern Territories", code: "TF" },
        { name: "Gabon", code: "GA" },
        { name: "Gambia", code: "GM" },
        { name: "Georgia", code: "GE" },
        { name: "Germany", code: "DE" },
        { name: "Ghana", code: "GH" },
        { name: "Gibraltar", code: "GI" },
        { name: "Greece", code: "GR" },
        { name: "Greenland", code: "GL" },
        { name: "Grenada", code: "GD" },
        { name: "Guadeloupe", code: "GP" },
        { name: "Guam", code: "GU" },
        { name: "Guatemala", code: "GT" },
        { name: "Guernsey", code: "GG" },
        { name: "Guinea", code: "GN" },
        { name: "Guinea-Bissau", code: "GW" },
        { name: "Guyana", code: "GY" },
        { name: "Haiti", code: "HT" },
        { name: "Heard Island and Mcdonald Islands", code: "HM" },
        { name: "Holy See (Vatican City State)", code: "VA" },
        { name: "Honduras", code: "HN" },
        { name: "Hong Kong", code: "HK" },
        { name: "Hungary", code: "HU" },
        { name: "Iceland", code: "IS" },
        { name: "India", code: "IN" },
        { name: "Indonesia", code: "ID" },
        { name: "Iran, Islamic Republic Of", code: "IR" },
        { name: "Iraq", code: "IQ" },
        { name: "Ireland", code: "IE" },
        { name: "Isle of Man", code: "IM" },
        { name: "Israel", code: "IL" },
        { name: "Italy", code: "IT" },
        { name: "Jamaica", code: "JM" },
        { name: "Japan", code: "JP" },
        { name: "Jersey", code: "JE" },
        { name: "Jordan", code: "JO" },
        { name: "Kazakhstan", code: "KZ" },
        { name: "Kenya", code: "KE" },
        { name: "Kiribati", code: "KI" },
        { name: "Korea, Democratic People'S Republic of", code: "KP" },
        { name: "Korea, Republic of", code: "KR" },
        { name: "Kuwait", code: "KW" },
        { name: "Kyrgyzstan", code: "KG" },
        { name: "Lao People'S Democratic Republic", code: "LA" },
        { name: "Latvia", code: "LV" },
        { name: "Lebanon", code: "LB" },
        { name: "Lesotho", code: "LS" },
        { name: "Liberia", code: "LR" },
        { name: "Libyan Arab Jamahiriya", code: "LY" },
        { name: "Liechtenstein", code: "LI" },
        { name: "Lithuania", code: "LT" },
        { name: "Luxembourg", code: "LU" },
        { name: "Macao", code: "MO" },
        { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
        { name: "Madagascar", code: "MG" },
        { name: "Malawi", code: "MW" },
        { name: "Malaysia", code: "MY" },
        { name: "Maldives", code: "MV" },
        { name: "Mali", code: "ML" },
        { name: "Malta", code: "MT" },
        { name: "Marshall Islands", code: "MH" },
        { name: "Martinique", code: "MQ" },
        { name: "Mauritania", code: "MR" },
        { name: "Mauritius", code: "MU" },
        { name: "Mayotte", code: "YT" },
        { name: "Mexico", code: "MX" },
        { name: "Micronesia, Federated States of", code: "FM" },
        { name: "Moldova, Republic of", code: "MD" },
        { name: "Monaco", code: "MC" },
        { name: "Mongolia", code: "MN" },
        { name: "Montserrat", code: "MS" },
        { name: "Morocco", code: "MA" },
        { name: "Mozambique", code: "MZ" },
        { name: "Myanmar", code: "MM" },
        { name: "Namibia", code: "NA" },
        { name: "Nauru", code: "NR" },
        { name: "Nepal", code: "NP" },
        { name: "Netherlands", code: "NL" },
        { name: "Netherlands Antilles", code: "AN" },
        { name: "New Caledonia", code: "NC" },
        { name: "New Zealand", code: "NZ" },
        { name: "Nicaragua", code: "NI" },
        { name: "Niger", code: "NE" },
        { name: "Nigeria", code: "NG" },
        { name: "Niue", code: "NU" },
        { name: "Norfolk Island", code: "NF" },
        { name: "Northern Mariana Islands", code: "MP" },
        { name: "Norway", code: "NO" },
        { name: "Oman", code: "OM" },
        { name: "Pakistan", code: "PK" },
        { name: "Palau", code: "PW" },
        { name: "Palestinian Territory, Occupied", code: "PS" },
        { name: "Panama", code: "PA" },
        { name: "Papua New Guinea", code: "PG" },
        { name: "Paraguay", code: "PY" },
        { name: "Peru", code: "PE" },
        { name: "Philippines", code: "PH" },
        { name: "Pitcairn", code: "PN" },
        { name: "Poland", code: "PL" },
        { name: "Portugal", code: "PT" },
        { name: "Puerto Rico", code: "PR" },
        { name: "Qatar", code: "QA" },
        { name: "Reunion", code: "RE" },
        { name: "Romania", code: "RO" },
        { name: "Russian Federation", code: "RU" },
        { name: "RWANDA", code: "RW" },
        { name: "Saint Helena", code: "SH" },
        { name: "Saint Kitts and Nevis", code: "KN" },
        { name: "Saint Lucia", code: "LC" },
        { name: "Saint Pierre and Miquelon", code: "PM" },
        { name: "Saint Vincent and the Grenadines", code: "VC" },
        { name: "Samoa", code: "WS" },
        { name: "San Marino", code: "SM" },
        { name: "Sao Tome and Principe", code: "ST" },
        { name: "Saudi Arabia", code: "SA" },
        { name: "Senegal", code: "SN" },
        { name: "Serbia and Montenegro", code: "CS" },
        { name: "Seychelles", code: "SC" },
        { name: "Sierra Leone", code: "SL" },
        { name: "Singapore", code: "SG" },
        { name: "Slovakia", code: "SK" },
        { name: "Slovenia", code: "SI" },
        { name: "Solomon Islands", code: "SB" },
        { name: "Somalia", code: "SO" },
        { name: "South Africa", code: "ZA" },
        { name: "South Georgia and the South Sandwich Islands", code: "GS" },
        { name: "Spain", code: "ES" },
        { name: "Sri Lanka", code: "LK" },
        { name: "Sudan", code: "SD" },
        { name: "Suriname", code: "SR" },
        { name: "Svalbard and Jan Mayen", code: "SJ" },
        { name: "Swaziland", code: "SZ" },
        { name: "Sweden", code: "SE" },
        { name: "Switzerland", code: "CH" },
        { name: "Syrian Arab Republic", code: "SY" },
        { name: "Taiwan, Province of China", code: "TW" },
        { name: "Tajikistan", code: "TJ" },
        { name: "Tanzania, United Republic of", code: "TZ" },
        { name: "Thailand", code: "TH" },
        { name: "Timor-Leste", code: "TL" },
        { name: "Togo", code: "TG" },
        { name: "Tokelau", code: "TK" },
        { name: "Tonga", code: "TO" },
        { name: "Trinidad and Tobago", code: "TT" },
        { name: "Tunisia", code: "TN" },
        { name: "Turkey", code: "TR" },
        { name: "Turkmenistan", code: "TM" },
        { name: "Turks and Caicos Islands", code: "TC" },
        { name: "Tuvalu", code: "TV" },
        { name: "Uganda", code: "UG" },
        { name: "Ukraine", code: "UA" },
        { name: "United Arab Emirates", code: "AE" },
        { name: "United Kingdom", code: "GB" },
        { name: "United States", code: "US" },
        { name: "United States Minor Outlying Islands", code: "UM" },
        { name: "Uruguay", code: "UY" },
        { name: "Uzbekistan", code: "UZ" },
        { name: "Vanuatu", code: "VU" },
        { name: "Venezuela", code: "VE" },
        { name: "Viet Nam", code: "VN" },
        { name: "Virgin Islands, British", code: "VG" },
        { name: "Virgin Islands, U.S.", code: "VI" },
        { name: "Wallis and Futuna", code: "WF" },
        { name: "Western Sahara", code: "EH" },
        { name: "Yemen", code: "YE" },
        { name: "Zambia", code: "ZM" },
        { name: "Zimbabwe", code: "ZW" },
      ],
      phoneno: "",
      address1: "",
      address2: "",
      postcode: "",
      state: "",
      // area:'',
      // education:'',
      country: "",
      uprofileimg: "",
      response: "",
      responsetype: "",
      resp: "",
      isLoading: false,
      formErrors: {
        fname: "",
        lname: "",
        email: "",
        phoneno: "",
        address1: "",
        address2: "",
        postcode: "",
        state: "",
        area: "",
        // education:'',
        country: "",
      },
      fnameValid: false,
      lnameValid: false,
      emailValid: false,
      phonenoValid: false,
      address1Valid: false,
      address2Valid: false,
      postcodeValid: false,
      stateValid: false,
      areaValid: false,
      // educationValid:false,
      countryValid: false,
      formValid: true,
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
  }
  getprofiledetails() {
    getmy_profiledetails(
      (res) => {
        this.setState({ myprofiledata: res.data.data[0][0] });
        this.setState({ fname: res.data.data[0][0].fname });
        this.setState({ lname: res.data.data[0][0].lname });
        this.setState({ email: res.data.data[0][0].email });
        this.setState({ phoneno: res.data.data[0][0].phoneno });
        this.setState({ address1: res.data.data[0][0].address1 });
        this.setState({ address2: res.data.data[0][0].address2 });
        this.setState({ state: res.data.data[0][0].state });
        this.setState({ area: res.data.data[0][0].area });
        this.setState({ postcode: res.data.data[0][0].postcode });
        // this.setState({education:res.data.user.education});
        this.setState({ country: res.data.data[0][0].country });
        this.setState({ uprofileimg: res.data.data[0][0].uprofileimg });
        localStorage.setItem(
          "profileimagepath",
          this.state.baseImgPath + res.data.data[0][0].uprofileimg
        );
      },
      (err) => {
        // console.log(err);
        this.setState({ response: "Error in loading profile data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  checkvaliddata() {
    console.log(this.state);
    if (this.state.fname) {
      this.state.fnameValid = true;
    }
    if (this.state.lname) {
      this.state.lnameValid = true;
    }
    if (this.state.email) {
      this.state.emailValid = true;
    }
    if (this.state.phoneno) {
      this.state.phonenoValid = true;
    }
  }

  emailaddresscheck() {
    emailaddresscheck(
      this.state.email,
      this.state.myprofiledata.id,
      (res) => {
        console.log(res);
        if (res.data.success == false) {
          this.setState({ response: res.data.message });
          this.setState({ responsetype: "error" });
        } else {
          this.setState({ response: "Email Address Valid" });
          this.setState({ responsetype: "success" });
        }
        this.setState({ emailValid: false }, this.validateForm);
      },
      (err) => {
        // console.log(err);
        this.setState({ response: "Error in loading profile data." });
        this.setState({ responsetype: "error" });
      }
    );
  }
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    let updatedata = this.state.myprofiledata;
    updatedata[name] = value;
    this.setState({ myprofiledata: updatedata }, () => {
      this.validateField(name, value);
    });
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let vpasswordValid = this.state.vpasswordValid;
    let fnameValid = this.state.fnameValid;
    let lnameValid = this.state.lnameValid;
    let phonenoValid = this.state.phonenoValid;
    let emailValid = this.state.emailValid;
    let address1Valid = this.state.address1Valid;
    let address2Valid = this.state.address2Valid;
    let postcodeValid = this.state.postcodeValid;
    let stateValid = this.state.stateValid;
    let countryValid = this.state.countryValid;
    switch (fieldName) {
      case "fname":
        fnameValid = value.length >= 3;
        fieldValidationErrors.fname = fnameValid ? "" : " is too short";
        break;

      case "lname":
        lnameValid = value.length >= 3;
        fieldValidationErrors.lname = lnameValid ? "" : " is too short";
        break;

      case "phoneno":
        phonenoValid = value.length == 10;
        fieldValidationErrors.phoneno = phonenoValid ? "" : " is too short";
        break;

      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;

      case "address1":
        address1Valid = value.length >= 3;
        fieldValidationErrors.address1 = address1Valid ? "" : " is too short";
        break;

      case "postcode":
        postcodeValid = value.length >= 3;
        fieldValidationErrors.postcode = postcodeValid ? "" : " is too short";
        break;

      case "state":
        stateValid = value.length >= 2;
        fieldValidationErrors.state = stateValid ? "" : " is too short";
        break;

      case "country":
        countryValid = value.length >= 3;
        fieldValidationErrors.country = countryValid ? "" : " is too short";
        break;

      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        fnameValid: fnameValid,
        lnameValid: lnameValid,
        phonenoValid: phonenoValid,
        emailValid: emailValid,
        address1Valid: address1Valid,
        address2Valid: address2Valid,
        postcodeValid: postcodeValid,
        stateValid: stateValid,
        countryValid: countryValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    if (
      this.state.fnameValid &&
      this.state.lnameValid &&
      this.state.phonenoValid &&
      this.state.emailValid &&
      this.state.address1Valid &&
      this.state.postcodeValid &&
      this.state.stateValid &&
      this.state.countryValid
    ) {
      this.state.formValid = true;
    }
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }
  handleOnSubmit = (e) => {
    this.checkvaliddata();
    this.validateForm();
    e.preventDefault();
    let updatedata = this.state.myprofiledata;
    updatedata["token"] = localStorage.getItem("token");
    this.setState({ myprofiledata: updatedata });
    const { myprofiledata } = this.state;
    this.setState({ isLoading: true });
    if (this.state.formValid == true) {
      myprofiledata.password = "";
      updateuserprofile(
        myprofiledata,
        (res) => {
          if (res.data.success == true || res.success == true) {
            this.setState({
              response: res.data.message || "Profile updated successfully.",
            });
            this.setState({ responsetype: "success" });
            this.setState({ isLoading: false });
          } else {
            this.setState({
              response:
                "Profile update failed due to" + JSON.stringify(res.data.error),
            });
            this.setState({ responsetype: "error" });
            this.setState({ isLoading: false });
          }
        },
        (err) => {
          this.setState({ response: +JSON.stringify(err) });
          this.setState({ isLoading: false });
        }
      );
    } else {
      this.setState({
        response:
          "All fileds are mandatory. Please fill all the required fields.",
      });
      this.setState({ responsetype: "error" });
      this.setState({ isLoading: false });
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({
      selectedFileBase64: URL.createObjectURL(event.target.files[0]),
    });
    this.setState({ selectedFile: event.target.files[0] });
    console.log(this.state.selectedFile);
  };

  onImageUpload() {
    //console.log(this.state.selectedFile);
    // let profileimgbase64 = this.state.selectedFileBase64;
    if (this.state.selectedFile != "") {
      this.setState({ response: "Profile photo being uploded" });
      this.setState({ responsetype: "success" });
      const formData = new FormData();
      formData.append("image", this.state.selectedFile);
      formData.append("patronID", localStorage.getItem("mwauid"));
      formData.append("fileName", "");
      onFileUpload(
        formData,
        (res) => {
          this.setState({ response: "Profile image updated" });
          this.setState({ responsetype: "success" });
        },
        (err) => {
          console.log(err);
          this.setState({ response: "Error while uploading profile photo." });
          this.setState({ responsetype: "error" });
        }
      );
    } else {
      this.setState({ response: "Select profile photo." });
      this.setState({ responsetype: "error" });
    }
  }
  closetoast() {
    this.setState({ response: "" });
  }
  componentDidMount() {
    if (localStorage.getItem("vcardurole") == 2) {
      this.getprofiledetails();
    } else {
      window.location.href = "/";
    }
  }
  render() {
    return (
      <div>
        <TopBarComponent
          imagePath={
            this.state.baseImgPath + this.state.myprofiledata.uprofileimg
          }
        />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="container rounded bg-white m-1">
                <div className="row">
                  <div className="col-lg-3 col-md-3 border-right">
                    <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                      {/* <img className="rounded-circle mt-5" style={{width:"150px",height:"150px"}} src={this.state.myprofiledata.uprofileimg!=null? this.state.baseImgPath+this.state.myprofiledata.uprofileimg : this.state.selectedFileBase64 } /> */}
                      {this.state.myprofiledata.uprofileimg == null && (
                        <img
                          className="rounded-circle mt-5"
                          style={{ width: "150px", height: "150px" }}
                          src={
                            this.state.myprofiledata.uprofileimg
                              ? this.state.baseImgPath +
                                this.state.myprofiledata.uprofileimg
                              : "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                          }
                        />
                      )}
                      {this.state.myprofiledata.uprofileimg != null && (
                        <img
                          className="rounded-circle mt-5"
                          style={{ width: "150px", height: "150px" }}
                          src={
                            this.state.myprofiledata.uprofileimg
                              ? this.state.baseImgPath +
                                this.state.myprofiledata.uprofileimg
                              : this.state.baseImgPath +
                                "loading-slow-internet.gif"
                          }
                        />
                      )}
                      <span className="font-weight-bold">
                        {this.state.myprofiledata.fname || ""}&nbsp;
                        {this.state.myprofiledata.lname || ""}
                      </span>
                      <span className="text-black-50">
                        {this.state.myprofiledata.email || ""}
                      </span>
                      <br />
                      <div style={{ textAlign: "center" }}>
                        <input
                          type="file"
                          onChange={this.onFileChange}
                          style={{ border: "none" }}
                        />{" "}
                        <br />
                        {/* 
                        <div className="col-lg-6 col-md-6 mt-3">
                            <button
                              type="submit"
                              className="contact-form-btn signup-btn1"
                              style={{
                                display: `${
                                  !this.state.isLoading ? "block" : "none"
                                }`,
                              }} */}
                        <div className="col-lg-2 col-md-6 mt-3">
                          <button
                            className="btn btn-md btn-success btn-outline-secondary text-white"
                            onClick={this.onImageUpload}
                            style={{ width: "220px" }}
                          >
                            Upload!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-5 border-right">
                    <form onSubmit={this.handleOnSubmit}>
                      <div className="p-1">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h4 className="text-right">Profile Settings</h4>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-6 col-md-6">
                            <label>First Name</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.fname
                              )} || '' `}
                            >
                              <FormControl
                                name="fname"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.fname || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Last Name</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.lname
                              )} || '' `}
                            >
                              <FormControl
                                placeholder="Last Name"
                                name="lname"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.lname || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Email Address</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.email
                              )} || '' `}
                            >
                              <FormControl
                                placeholder="Email Address"
                                name="email"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.email || ""}
                                readOnly
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Phone Number</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.phoneno
                              )} || '' `}
                            >
                              <FormControl
                                placeholder="Phone Number"
                                name="phoneno"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.phoneno || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Address 1</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.address1
                              )} || '' `}
                            >
                              <FormControl
                                placeholder="Address 1"
                                name="address1"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.address1 || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Address 2</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.address2
                              )} || '' `}
                            >
                              <FormControl
                                placeholder="Address 2"
                                name="address2"
                                className="form-control wrap-input100 validate-input"
                                value={this.state.address2 || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Suburb</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.area
                              )} || '' `}
                            >
                              <FormControl
                                placeholder="Suburb"
                                name="area"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.area || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Post Code</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.postcode
                              )} || '' `}
                            >
                              <FormControl
                                placeholder="Post Code"
                                name="postcode"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.postcode || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>State</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.state
                              )} || '' `}
                            >
                              <FormControl
                                placeholder="State"
                                name="state"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.state || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Country</label>
                            <InputGroup
                              className={`mb-0 form-group ${this.errorClass(
                                this.state.formErrors.country
                              )} || '' `}
                            >
                              <FormControl
                                placeholder="Country"
                                name="country"
                                required
                                className="form-control wrap-input100 validate-input"
                                value={this.state.country || ""}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </InputGroup>
                          </div>

                          <div className="col-lg-6 col-md-6 mt-3">
                            <button
                              type="submit"
                              className="contact-form-btn signup-btn1"
                              style={{
                                display: `${
                                  !this.state.isLoading ? "block" : "none"
                                }`,
                              }}
                            >
                              Update My Profile
                            </button>
                            <span
                              className="login100-form-btn"
                              style={{
                                display: `${
                                  this.state.isLoading ? "block" : "none"
                                }`,
                                textAlign: "center",
                                padding: "8px 15px",
                              }}
                            >
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
      </div>
    );
  }
}
export default DashboardComponents;
