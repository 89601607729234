import React, { Component, useState } from "react";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Pagination,
} from "react-bootstrap-v5";
import * as Icon from "react-bootstrap-icons";
import { FormErrors } from "../FormErrors";
// import DashboardFieldFontsDropdownComponent from "./DashboardFieldFontsDropdownComponent";
// import DashboardColorComponent from "./DashboardColorComponent";
import { getallcardstemplatelist } from "../apiserve/ApiComponent";

class DashboardCardsTemplatesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allcardstemp: [],
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
    };
  }
  getallcardstemplatelist() {
    getallcardstemplatelist(
      (res) => {
        this.setState({ allcardstemp: res.data.data });
        console.log(this.state.allcardstemp);
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading cards templates." });
        this.setState({ responsetype: "error" });
      }
    );
  }
  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      this.getallcardstemplatelist();
    } else {
      window.location.href = "/";
    }
  }
  renderCols(start, end) {
    const range = [];

    for (let n = 0; n < this.state.allcardstemp.length; n++) {
      const url = `${this.state.baseImgPath}${this.state.allcardstemp[n].cardbgimage}`;

      const col = (
        <Col key={n} className="m-2 p-4" style={{ background: "#c5c5c5" }}>
          <a href={"/card-design?id=" + this.state.allcardstemp[n].id}>
            <Image src={url} fluid />{" "}
          </a>
        </Col>
      );

      range.push(col);
    }

    return range;
  }

  render() {
    return (
      <div>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Paid">
            {this.renderCols(1, 10)}
            {/* <Row>
              <Pagination size="sm" className="justify-content-center">
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item active>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
              </Pagination>
            </Row> */}
          </Tab>

          <Tab eventKey="profile" title="Free">
            {/* {this.renderCols(11, 20)}
            <Row>
              <Pagination size="sm" className="justify-content-center">
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item active>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
              </Pagination>
            </Row> */}
          </Tab>
        </Tabs>
      </div>
    );
  }
}
export default DashboardCardsTemplatesComponent;
