import React, { Component, useState } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  FormLabel,
  Row,
  Col,
  Form,
} from "react-bootstrap-v5";
import * as Icon from "react-bootstrap-icons";
import Popover from "react-bootstrap/Popover";
import Modal from "react-bootstrap/Modal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import { savemycard, updatemycard } from "./apiserve/ApiComponent";
import Toast from "react-bootstrap/Toast";
import html2canvas from "html2canvas";
class SaveModalComponents extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSaveMessage = this.handleSaveMessage.bind(this);
    this.handlesavedClose = this.handlesavedClose.bind(this);

    this.state = {
      show: false,
      saved: false,
      isLoading: false,
      response: "",
      responsetype: "",
    };
  }

  componentDidMount() {
    //   console.log(JSON.stringify(JSON.stringify(this.props.card_details)));
  }

  handleClose() {
    this.setState({ show: false });
  }

  handlesavedClose() {
    this.setState({ saved: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  //   _getFetchData = async () => {
  // 	let allKeys = await AsyncStorage.getAllKeys(); // 1
  // 	if (allKeys.length) { // 2

  // 	   let filteredKeys = allKeys.filter(key => !key.includes('not')); // 3
  // 	   let items = await Promise.all(filteredKeys.map(async key => { // 4
  // 		  let value = await AsyncStorage.getItem(key);
  // 		  return {
  // 			 key,
  // 			 value
  // 		  }; // 5
  // 	   }))

  // 	   let filteredTrueItems = items.filter(item => items.value === 'true'); // 6
  // 	   let filteredFalseItems = items.filter(item => items.value !== 'true'); // 7
  // 	   // now you have two arrays one with the items that have the true values
  // 	   // and one with the items that have the false values
  // 	   // at this points you can decide what to return as it is not
  // 	   // that clear from your question

  // 	   // return the value that your want                                       // 8
  // 	} else {
  // 	   // return your default value if there are no keys                        // 8
  // 	}
  //  }

  async savemycard() {
    this.setState({ isLoading: true });
    const inputf = document.getElementById("savefromhere");
    const inputb = document.getElementById("savefromhereback");
    let card_preview_back;
    let card_preview;
    // inputf.toBlob(function(blob) {
    const formData = new FormData();
    // formData.append('my-file', blob, 'filename.png');
    // });
    let carddata = JSON.stringify(this.props.card_details);
    html2canvas(inputb, {
      useCORS: true,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById(
          "bottom-tabs-example-tabpane-second"
        ).style.display = "block";
      },
    }).then((canvas) => {
      var file;
      file = canvas.toDataURL("image/png");
      var blobBin = atob(file.split(",")[1]);
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      file = new Blob([new Uint8Array(array)], { type: "image/png" });
      card_preview_back = file;
      html2canvas(inputf, {
        useCORS: true,
      }).then((canvas) => {
        var file;
        file = canvas.toDataURL("image/png");
        console.log(file);
        var blobBin = atob(file.split(",")[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        file = new Blob([new Uint8Array(array)], { type: "image/png" });
        card_preview = file;
        const template_id = parseInt(localStorage.getItem("designId"));
        let uid = localStorage.getItem("mwauid");
        let cardname = "card Design" + uid;
        // let formData;
        formData.append("card_preview_back", card_preview_back);
        formData.append("card_preview", card_preview);
        formData.append("template_id", template_id);
        formData.append("carddata", carddata);
        formData.append("cardname", cardname);
        console.log(this.props.card_details);
        if (this.props.card_details.Lcardbgimage != undefined) {
          formData.append("card_logo", this.props.card_details.Lcardbgimage);
        }

        // formData={
        // 	'card_preview':card_preview,
        // 	'card_preview_back':card_preview_back,
        // 	'template_id':template_id,
        // 	'carddata':carddata,
        // 	'cardname':cardname,
        // 	'uid':uid,
        // }
        // formData.append(card_preview,card_preview_back,template_id,carddata,cardname,uid);
        // if(card_preview!=undefined && card_preview_back!=undefined){
        let cid = "";
        let action = "";
        cid = localStorage.getItem("cardDesignId");
        action = localStorage.getItem("action");
        if (action == "update") {
          formData.append("id", cid);
          formData.append("uid", uid);
          formData.append("status", 1);
          updatemycard(
            formData,
            (res) => {
              console.log(res);
              if (res.status == false) {
                this.setState({ saved: false });
                this.setState({ isLoading: false });
                this.setState({
                  response: "Error while saving your please try again.",
                });
                this.setState({ responsetype: "Error" });
              } else {
                this.setState({ saved: false });
                this.setState({ isLoading: false });
                this.setState({
                  response: "Your card has been saved to your account.",
                });
                this.setState({ responsetype: "success" });
              }
            },
            (err) => {
              console.log(err);
              this.setState({ saved: false });
              this.setState({
                response: "Error saving data." + JSON.stringify(err),
              });
              this.setState({ responsetype: "error" });
            }
          );
        } else {
          formData.append("uid", uid);
          savemycard(
            formData,
            (res) => {
              console.log(res);
              if (res.status == false) {
                this.setState({ saved: false });
                this.setState({ isLoading: false });
                this.setState({
                  response: "Error while saving your please try again.",
                });
                this.setState({ responsetype: "Error" });
              } else {
                this.setState({ saved: false });
                this.setState({ isLoading: false });
                this.setState({
                  response: "Your card has been saved to your account.",
                });
                this.setState({ responsetype: "success" });
              }
            },
            (err) => {
              console.log(err);
              this.setState({ saved: false });
              this.setState({
                response: "Error saving data." + JSON.stringify(err),
              });
              this.setState({ responsetype: "error" });
            }
          );
        }
      });
    });

    // }
  }

  closetoast() {
    this.setState({ response: "" });
  }

  handleSaveMessage() {
    this.setState({ show: false });
    //   check for logged in users
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("vcardurole") == 2) {
        //  console.log(this.props.card_details);
        //  this.setState({ show: false });
        this.setState({ saved: true });
        this.savemycard();
      } else {
        this.setState({
          response: "You are not logged in, Please login. Redirecting to login",
        });
        this.setState({ responsetype: "error" });
        setTimeout(function () {
          // localStorage.setItem('designId', this.props.match.params.id);
          window.location.href = "/login?q=savecard";
        }, 2000); //will call the function after 2 secs.
      }
    } else {
      setTimeout(function () {
        // localStorage.setItem('designId', this.props.location.search.id);
        window.location.href = "/login?q=savecard";
      }); //will call the function after 2 secs.
    }

    //   check for logged in users
  }

  render() {
    return (
      <div>
        <Button className="cbluesave" onClick={this.handleShow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-save"
            viewBox="0 0 16 16"
          >
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
          </svg>{" "}
          <span className="hidemobile">Save</span>
        </Button>
        {/* <button type="button" className="btn btn-primary"  onClick={this.handleShow}>Login</button> */}

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Save Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure, You want to save your card.
            </h5>
            <p className="text-center">
              You need to login to save your card and it will be saved in your
              account. If you are not logged in, You will be redirected to login
              page.
            </p>
            <Row>
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.handleSaveMessage}>
                  Save
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.saved} onHide={this.handlesavedClose}>
          <Modal.Header closeButton>
            <Modal.Title>Card Saved</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <h5 className="text-center">
              Your card is being save in your account.
            </h5>
            <p className="text-center">Please wait till process complete.</p>
            <Row>
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
              {/* <Col><Button size="sm" onClick={this.handlesavedClose}>OK</Button></Col> */}
            </Row>
          </Modal.Body>
        </Modal>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    card_details: state.card_data,
  };
}
export default connect(mapStateToProps, null)(SaveModalComponents);
