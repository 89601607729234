import React, { Component, useState } from "react";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
} from "react-bootstrap-v5";
import { getallslider } from "./apiserve/ApiComponent";
class SliderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allslideretails: [],
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/sliders/",
    };
  }

  getallslider() {
    getallslider(
      (res) => {
        this.setState({ allslideretails: res.data.data[0] });
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading slider." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    this.getallslider();
  }

  render() {
    return (
      <div>
        <Carousel>
          {this.state.allslideretails.map(function (item, i) {
            return (
              <Carousel.Item key={item.id}>
                <img
                  className="d-block w-100"
                  src={
                    "https://office.monitorwa.com.au/tccardorderapi/sliders/" +
                    item.image
                  }
                  alt="slide"
                />
              </Carousel.Item>
            );
          }, this)}
        </Carousel>
      </div>
    );
  }
}
export default SliderComponent;
