import React, { Component, useState } from 'react';
import {Button,InputGroup,FormControl,FormLabel, Row,Col,Form} from "react-bootstrap-v5";
import * as Icon from 'react-bootstrap-icons';
import { FormErrors } from '../FormErrors';
import FieldFontsDropdownComponent from './FieldFontsDropdownComponent';
import ColorComponent from './ColorComponent';


class PreviousUploads extends Component{
  constructor (props) {
    super(props);
    }
   

    render() {
      
        return (
          <div>
            <div style={{border:'solid 1px #666',padding:'10px'}}>
                <p>You’ll find your recent uploads below. Please sign in to view all of your uploads.</p>

                <Button variant="light" size="sm" className="" style={{border:"1px solid #c1c4c5"}} >Sign In</Button>
             
            </div>            
          </div>

        )
    }
}
export default PreviousUploads;
