export const depositMoney = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "deposit",
      payload,
    });
  };
};

export const withdrawMoney = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "withdraw",
      payload,
    });
  };
};

export function addCardDetails(payload) {
  return {
    type: "ADD_CARD_DETAILS",
    payload,
  };
}

export function UpdateCardState(payload) {
  return {
    type: "UPDATE_CARD_STATE",
    payload,
  };
}

export function UpdateFieldValue(payload) {
  return {
    type: "UPDATE_FIELD_VALUE",
    payload,
  };
}

export function UpdateFieldValueData(payload) {
  return {
    type: "UPDATE_FIELD_VALUE_DATA",
    payload,
  };
}

export function ColorChange(payload) {
  return {
    type: "CHANGE_COLOR_VALUE",
    payload,
  };
}

export function FontSizeChange(payload) {
  return {
    type: "CHANGE_FONT_VALUE",
    payload,
  };
}

export function BoldChange(payload) {
  return {
    type: "CHANGE_BOLD_VALUE",
    payload,
  };
}

export function AlignChange(payload) {
  return {
    type: "CHANGE_ALIGN_VALUE",
    payload,
  };
}

export function ItalicChange(payload) {
  return {
    type: "CHANGE_ITALIC_VALUE",
    payload,
  };
}

export function UnderlineChange(payload) {
  return {
    type: "CHANGE_UNDERLINE_VALUE",
    payload,
  };
}

export function ParaChange(payload) {
  return {
    type: "CHANGE_PARA_VALUE",
    payload,
  };
}

export function ChangeFontFamily(payload) {
  return {
    type: "CHANGE_FONT_FAMILY",
    payload,
  };
}

export function UploadMyImage(payload) {
  return {
    type: "UPLOAD_MY_IMAGE",
    payload,
  };
}

export function UploadMyImgLogo(payload) {
  return {
    type: "UPLOAD_MY_IMAGE_LOGO",
    payload,
  };
}

export function UploadMyFTemp(payload) {
  return {
    type: "UPLOAD_MY_F_TEMPLATE",
    payload,
  };
}

export function UploadMyBTemp(payload) {
  return {
    type: "UPLOAD_MY_B_TEMPLATE",
    payload,
  };
}

export function ChangeFrontBackMyImage(payload) {
  return {
    type: "CHANGE_FRONTBACK_IMAGE",
    payload,
  };
}

export function ChangeFrontBackExtraField(payload) {
  return {
    type: "CHANGE_FRONTBACK_EXTRAFIELD",
    payload,
  };
}

export function RemoveExtraField(payload) {
  return {
    type: "REMOVE_EXTRA_FIELD",
    payload,
  };
}

export function AddExtraField(payload) {
  return {
    type: "ADD_EXTRA_FIELD",
    payload,
  };
}

export function showHideExtra(payload) {
  return {
    type: "SHOWHIDE_EXTRA_FIELD",
    payload,
  };
}

export function ChangeExtraFieldColor(payload) {
  return {
    type: "CHANGE_EXTRA_FIELD_COLOR",
    payload,
  };
}

export function ChangeExtraFieldFontSize(payload) {
  return {
    type: "CHANGE_EXTRA_FIELD_FONTSIZE",
    payload,
  };
}

export function ChangeExtraFieldFontFamily(payload) {
  return {
    type: "CHANGE_EXTRA_FIELD_FONTFAMILY",
    payload,
  };
}

export function ChangeExtraFieldBold(payload) {
  return {
    type: "CHANGE_EXTRA_FIELD_BOLD",
    payload,
  };
}

export function ChangeExtraFieldItalic(payload) {
  return {
    type: "CHANGE_EXTRA_FIELD_ITALIC",
    payload,
  };
}

export function ChangeExtraFieldUnderline(payload) {
  return {
    type: "CHANGE_EXTRA_FIELD_UNDERLINE",
    payload,
  };
}

export function RemoveImage(payload) {
  return {
    type: "REMOVE_IMAGE",
    payload,
  };
}

export function ChangeExtraField(payload) {
  return {
    type: "CHANGE_EXTRA_FIELD",
    payload,
  };
}

export function onChangePosition(payload) {
  return {
    type: "UPDATE_NEW_POSITION",
    payload,
  };
}

export function onChangePositionExtra(payload) {
  return {
    type: "UPDATE_NEW_EXTRA_POSITION",
    payload,
  };
}

export function checkUndoStatus(payload) {
  return {
    type: "UPDATE_UNDO_STATUS",
    payload,
  };
}

export function checkUndoStatusE(payload) {
  return {
    type: "UPDATE_UNDO_STATUS_EXTRA",
    payload,
  };
}

export function checkRedoStatus(payload) {
  return {
    type: "UPDATE_REDO_STATUS",
    payload,
  };
}

export function checkRedoStatusE(payload) {
  return {
    type: "UPDATE_REDO_EXTRA_STATUS",
    payload,
  };
}

export function updateUndoStatus(payload) {
  return {
    type: "UPDATE_NEW_UNDO_STATUS",
    payload,
  };
}

export function updateRedoStatus(payload) {
  return {
    type: "UPDATE_NEW_REDO_STATUS",
    payload,
  };
}

export function checkUndoPoistoin(payload) {
  return {
    type: "UPDATE_NEW_UNDO_POSITION",
    payload,
  };
}

export function checkUndoPoistoinExtra(payload) {
  return {
    type: "UPDATE_NEW_UNDO_EXTRA_POSITION",
    payload,
  };
}

export function checkRedoPoistoin(payload) {
  return {
    type: "UPDATE_NEW_REDO_POSITION",
    payload,
  };
}

export function echeckRedoPoistoin(payload) {
  return {
    type: "UPDATE_NEW_REDO_EXTRA_POSITION",
    payload,
  };
}

export function undoSingleValue(payload) {
  return {
    type: "UPDATE_NEW_SINGLE_VALUE",
    payload,
  };
}

export function undoSingleValueE(payload) {
  return {
    type: "UPDATE_NEW_SINGLE_EXTRA_VALUE",
    payload,
  };
}

export function redoSingleValue(payload) {
  return {
    type: "UPDATE_NEW_SINGLE_VALUE_REDO",
    payload,
  };
}

export function redoSingleValueE(payload) {
  return {
    type: "UPDATE_NEW_SINGLE_VALUE_EXTRA_REDO",
    payload,
  };
}
