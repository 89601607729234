import React, { Component, useState, useRef } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import Draggable, { DraggableCore } from "react-draggable";
import { Row, Col, FormLabel, Button } from "react-bootstrap-v5";
import { getcarddetails, cardprinted } from "../apiserve/ApiComponent";
import Toast from "react-bootstrap/Toast";
import queryString from "query-string";
import "./dashboard.css";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { saveAs } from "file-saver";

class DashboardPrintCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carddetails: [],
      carddata: [],
      numberRequired: "1",
      xfactor: 0,
      yfactor: 0,
      orderID: "",
      response: "",
      responsetype: "",
      card_orientation: "",
    };
  }

  savetopng(element, cardname) {
    const input = document.getElementById(element);
    html2canvas(input, {
      scale: 1.0,
      watermark: "",
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      saveAs(imgData, `${cardname}.png`);
    });
  }

  savetojpg(element, cardname) {
    const input = document.getElementById(element);
    html2canvas(input, {
      scale: 1.0,
      watermark: "",
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpg", 1.0);
      saveAs(imgData, `${cardname}.jpg`);
      this.setState({ showID: false });
    });
  }

  savetopdf(element, cardname) {
    const inputpdf = document.getElementById(element);
    html2canvas(inputpdf, {
      scale: 0.7,
      watermark: "",
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      console.log(imgData);
      const pdf = new jsPDF("p", "mm", "A4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save(`${cardname}.pdf`);
    });
  }

  cardPrinted() {
    var id = this.state.orderID;
    cardprinted(this.state.carddetails.id, id, (res) => {
      if (res.data.success === true) {
        this.setState({ response: "Card Marked as Printed." });
        this.setState({ responsetype: "success" });
        setTimeout(function () {
          window.location.href = "/admin/orders?id=" + id;
        }, 1000);
      }
    });
  }

  printCard(element, cardname) {
    this.savetojpg(element, cardname);
  }

  getcarddetails = async (id) => {
    await getcarddetails(
      id,
      (res) => {
        this.setState({ carddetails: res.data.data[0][0] });
        this.state.carddetails.created_at = this.state.carddetails.createdat;
        this.setState({
          usercardbg: res.data.data[0][0].card_preview,
        });
        this.setState({
          carduserdetails: res.data.data[0][0].userdetails,
        });
        this.setState({
          carddata: JSON.parse(res.data.data[0][0].card_data),
        });

        this.setState({ usercardback: res.data.data[0][0].card_back_image });
        this.setState({
          card_orientation: res.data.data[0][0].card_orientation,
        });

        if (
          this.state.carddata.firstname == undefined &&
          this.state.carddata.lastname == undefined
        ) {
          //Data from Queued card is in different format
          var sID = this.state.carddata[1].staffid;
          var fname = this.state.carddata[2].firstname;
          var lname = this.state.carddata[3].lastname;
          var pos = this.state.carddata[4].position;

          this.state.carddata.staffid = sID;
          this.state.carddata.firstname = fname;
          this.state.carddata.lastname = lname;
          this.state.carddata.position = pos;
        }
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading cards data." });
        this.setState({ responsetype: "error" });
      }
    );

    if (this.state.card_orientation != "portrait") {
      this.setState({ xfactor: 128 });
      this.setState({ yfactor: 110 });
    } else {
      //this.setState({ xfactor: 90 });
      //this.setState({ yfactor: 200 });
      this.setState({ xfactor: 0 });
      this.setState({ yfactor: 0 });
    }
  };

  componentDidMount = async () => {
    let cardid = "";
    let queries = queryString.parse(this.props.location.search);
    if (queries.CardID != undefined) {
      cardid = queries.CardID;
      this.state.numberRequired = queries.Amount;
      this.state.orderID = queries.TransID;
      await this.getcarddetails(cardid);
    }
  };

  render() {
    const boarderstyles = {
      border: "1px solid rgba(0, 0, 0, 0.05)",
    };
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  Print Card - {this.state.numberRequired} Copy(s) Required
                </h1>
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-success btn-outline-secondary text-white"
                    onClick={() => {
                      this.printCard(
                        "frontofcard",
                        `${this.state.carddetails.cardname} front`
                      );
                    }}
                  >
                    Print Card Front
                  </button>
                  {"   "}
                  <button
                    type="button"
                    className="btn btn-sm btn-success btn-outline-secondary text-white"
                    onClick={() => {
                      this.printCard(
                        "backofcard",
                        `${this.state.carddetails.cardname} back`
                      );
                    }}
                  >
                    Print Card Back
                  </button>
                  {"   "}
                  <button
                    type="button"
                    className="btn btn-sm btn-primary btn-outline-secondary text-white"
                    onClick={() => {
                      this.cardPrinted();
                    }}
                  >
                    Mark Card as Printed
                  </button>
                </div>
              </div>
              {/* Front of Card Display */}

              <Row>
                <div className="col">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 mb-4">
                      {/* <div className="col-md-4 col-sm-12 mb-4"></div> */}
                      <div>
                        <div className="row">
                          <div className="col-12">
                            {/* <div height="1013px" width="638px"> */}
                            <div className="col">
                              <div className="col">
                                {/* Landscape Card */}

                                {this.state.card_orientation != "portrait" ? (
                                  <div
                                    id="frontofcard"
                                    className="col-lg-12"
                                    style={{
                                      background: "#ccc",
                                      height: "638px",
                                      width: "1013px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <div
                                      className="col-lg-12 p-4 backgroundimage"
                                      style={{
                                        background: `url(https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.usercardbg})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        height: "638px",
                                        width: "1013px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <div
                                        ref={this.inputRef}
                                        //id={"cardheight"}
                                        className="col-lg-12 cardheight justify-content-center"
                                        style={{
                                          height: "100%",
                                          border: "solid 1px #000",
                                          overflow: "hidden",
                                          position: "relative",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <div
                                          className="col-12"
                                          style={{
                                            height: "638px",
                                            width: "1013px",
                                          }}
                                        >
                                          {this.state.carddata.staffid && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.staffid
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.staffid
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col
                                                    className="text-center"
                                                    width="100%"
                                                  >
                                                    <FormLabel
                                                      className="m-0 p-0 col py-2 d-block"
                                                      id="valuw1"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .staffid.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .staffid.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .staffid.fontSize ||
                                                          "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .staffid.fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .staffid
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .staffid
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .staffid
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                      }}
                                                    >
                                                      {
                                                        this.state.carddata
                                                          .staffid.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.firstname && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.firstname
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.firstname
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col
                                                    className="text-center"
                                                    width="100%"
                                                    style={boarderstyles}
                                                  >
                                                    <FormLabel
                                                      className="m-0 p-0 col d-block text-center py-2"
                                                      id="firstname"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .firstname.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .firstname.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .fontSize || "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                      }}
                                                    >
                                                      {console.log(
                                                        this.state.carddata
                                                          .firstname.alignText
                                                      )}
                                                      {
                                                        this.state.carddata
                                                          .firstname.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.lastname && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.lastname
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.lastname
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col
                                                    className="text-center"
                                                    style={boarderstyles}
                                                  >
                                                    <FormLabel
                                                      className="m-0 p-0 col d-block text-center py-2"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .lastname.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .lastname.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .lastname
                                                            .fontSize || "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .lastname.fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .lastname
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .lastname
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .lastname
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                      }}
                                                    >
                                                      {
                                                        this.state.carddata
                                                          .lastname.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.position && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.position
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.position
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col>
                                                    <FormLabel
                                                      className="m-0 p-0 col-12 d-block text-center py-2"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .position.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .position.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .position
                                                            .fontSize || "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .position.fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .position
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .position
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .position
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                      }}
                                                    >
                                                      {
                                                        this.state.carddata
                                                          .position.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.phoneno && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.phoneno
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.phoneno
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col>
                                                    <FormLabel
                                                      className="m-0 p-0 col-12 d-block text-center py-2"
                                                      id="valuw0"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .phoneno.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .phoneno.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .phoneno.fontSize ||
                                                          "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .phoneno.fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .phoneno
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .phoneno
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .phoneno
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                      }}
                                                    >
                                                      {
                                                        this.state.carddata
                                                          .phoneno.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.extrafields &&
                                            this.state.carddata.extrafields.map(
                                              (item, i) => (
                                                <Draggable
                                                  key={i}
                                                  position={{
                                                    x:
                                                      item.position.x +
                                                      this.state.xfactor,
                                                    y:
                                                      item.position.y +
                                                      this.state.yfactor,
                                                  }}
                                                >
                                                  <div>
                                                    <Row className="handle">
                                                      <div
                                                        id="extrafields"
                                                        className={i}
                                                        style={{
                                                          fontSize: `${
                                                            item.fontSize ||
                                                            "14px"
                                                          }`,
                                                          display:
                                                            "inline-block",
                                                          color: `${
                                                            item.color || "#000"
                                                          }`,
                                                          fontFamily: `${
                                                            item.fontFamily ||
                                                            "arial"
                                                          }`,
                                                          fontWeight: `${
                                                            item.fontBold
                                                              ? "bold"
                                                              : "normal"
                                                          }`,
                                                          fontStyle: `${
                                                            item.fontItalic
                                                              ? "italic"
                                                              : "normal"
                                                          }`,
                                                          textDecoration: `${
                                                            item.fontUnderline
                                                              ? "underline"
                                                              : "none"
                                                          }`,
                                                        }}
                                                      >
                                                        {item.text}
                                                      </div>
                                                    </Row>
                                                  </div>
                                                </Draggable>
                                              )
                                            )}
                                          {this.state.carddata
                                            .myuploadedimage && (
                                            <div>
                                              <Draggable
                                                position={{
                                                  x:
                                                    this.state.carddata
                                                      .myuploadedimage.position
                                                      .x + this.state.xfactor,
                                                  y:
                                                    this.state.carddata
                                                      .myuploadedimage.position
                                                      .y + this.state.yfactor,
                                                }}
                                              >
                                                <div>
                                                  <Row className="handle">
                                                    <img
                                                      id="myuploadedimage"
                                                      src={
                                                        this.state.carddata
                                                          .myuploadedimage
                                                          ? this.state.carddata
                                                              .myuploadedimage
                                                              .file
                                                          : "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                                      }
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                      }}
                                                    />
                                                    {/* </Resizable> */}
                                                  </Row>
                                                </div>
                                              </Draggable>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  // ~~~~ Portrait ~~~~
                                  <div
                                    id="frontofcard"
                                    className="col-lg-12"
                                    style={{
                                      background: "#ccc",
                                      // height: "1013px",
                                      // width: "633px",
                                      height: "762px",
                                      width: "478px",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <div
                                      className="col-lg-12 p-4 backgroundimage"
                                      style={{
                                        background: `url(https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.usercardbg})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        height: "762px",
                                        width: "478px",
                                        borderRadius: "15px",
                                      }}
                                    >
                                      <div
                                        ref={this.inputRef}
                                        //id={"cardheight"}
                                        className="col-lg-12 cardheight justify-content-center"
                                        style={{
                                          height: "100%",
                                          border: "solid 1px #000",
                                          overflow: "hidden",
                                          position: "relative",
                                          borderRadius: "15px",
                                        }}
                                      >
                                        <div
                                          className="col-12"
                                          style={{
                                            height: "762px",
                                            width: "478px",
                                            borderRadius: "15px",
                                          }}
                                        >
                                          {this.state.carddata.staffid && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.staffid
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.staffid
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col
                                                    className="text-center"
                                                    md={12}
                                                    lg={12}
                                                  >
                                                    <FormLabel
                                                      className="m-0 p-0 col py-2 d-block"
                                                      id="staffid"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .staffid.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .staffid.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .staffid.fontSize ||
                                                          "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .staffid.fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .staffid
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .staffid
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .staffid
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                      }}
                                                    >
                                                      {
                                                        this.state.carddata
                                                          .staffid.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.firstname && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.firstname
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.firstname
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col
                                                    className="text-center"
                                                    md={12}
                                                    lg={12}
                                                  >
                                                    <FormLabel
                                                      className="m-0 p-0 col d-block text-center py-2"
                                                      id="firstname"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .firstname.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .firstname.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .fontSize || "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .firstname
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                        width: "350px",
                                                        display: "flex",
                                                      }}
                                                    >
                                                      {
                                                        this.state.carddata
                                                          .firstname.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.lastname && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.lastname
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.lastname
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col
                                                    className="text-center"
                                                    md={12}
                                                    lg={12}
                                                  >
                                                    <FormLabel
                                                      className="m-0 p-0 col d-block text-center py-2"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .lastname.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .lastname.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .lastname
                                                            .fontSize || "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .lastname.fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .lastname
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .lastname
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .lastname
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                      }}
                                                    >
                                                      {
                                                        this.state.carddata
                                                          .lastname.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.position && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.position
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.position
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col
                                                    className="text-center"
                                                    md={12}
                                                    lg={12}
                                                  >
                                                    <FormLabel
                                                      className="m-0 p-0 col-12 d-block text-center py-2"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .position.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .position.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .position
                                                            .fontSize || "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .position.fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .position
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .position
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .position
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                      }}
                                                    >
                                                      {
                                                        this.state.carddata
                                                          .position.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.phoneno && (
                                            <Draggable
                                              position={{
                                                x:
                                                  this.state.carddata.phoneno
                                                    .position.x +
                                                  this.state.xfactor,
                                                y:
                                                  this.state.carddata.phoneno
                                                    .position.y +
                                                  this.state.yfactor,
                                              }}
                                            >
                                              <div>
                                                <Row className="handle">
                                                  <Col
                                                    className="text-center"
                                                    md={12}
                                                    lg={12}
                                                  >
                                                    <FormLabel
                                                      className="m-0 p-0 col-12 d-block text-center py-2"
                                                      id="valuw0"
                                                      style={{
                                                        color: `${
                                                          this.state.carddata
                                                            .phoneno.color ||
                                                          "#000"
                                                        }`,
                                                        fontWeight: `${
                                                          this.state.carddata
                                                            .phoneno.fontBold
                                                            ? "bold"
                                                            : "normal"
                                                        }`,
                                                        fontSize: `${
                                                          this.state.carddata
                                                            .phoneno.fontSize ||
                                                          "14px"
                                                        }`,
                                                        fontStyle: `${
                                                          this.state.carddata
                                                            .phoneno.fontItalic
                                                            ? "italic"
                                                            : "normal"
                                                        }`,
                                                        textDecoration: `${
                                                          this.state.carddata
                                                            .phoneno
                                                            .fontUnderline
                                                            ? "underline"
                                                            : "none"
                                                        }`,
                                                        textAlign: `${
                                                          this.state.carddata
                                                            .phoneno
                                                            .alignText || "left"
                                                        }`,
                                                        fontFamily: `${
                                                          this.state.carddata
                                                            .phoneno
                                                            .fontFamily ||
                                                          "arial"
                                                        }`,
                                                      }}
                                                    >
                                                      {
                                                        this.state.carddata
                                                          .phoneno.text
                                                      }
                                                    </FormLabel>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Draggable>
                                          )}

                                          {this.state.carddata.extrafields &&
                                            this.state.carddata.extrafields.map(
                                              (item, i) => (
                                                <Draggable
                                                  key={i}
                                                  position={{
                                                    x:
                                                      item.position.x +
                                                      this.state.xfactor,
                                                    y:
                                                      item.position.y +
                                                      this.state.yfactor,
                                                  }}
                                                >
                                                  <div>
                                                    <Row className="handle">
                                                      <div
                                                        id="extrafields"
                                                        className={i}
                                                        style={{
                                                          fontSize: `${
                                                            item.fontSize ||
                                                            "14px"
                                                          }`,
                                                          display:
                                                            "inline-block",
                                                          color: `${
                                                            item.color || "#000"
                                                          }`,
                                                          fontFamily: `${
                                                            item.fontFamily ||
                                                            "arial"
                                                          }`,
                                                          fontWeight: `${
                                                            item.fontBold
                                                              ? "bold"
                                                              : "normal"
                                                          }`,
                                                          fontStyle: `${
                                                            item.fontItalic
                                                              ? "italic"
                                                              : "normal"
                                                          }`,
                                                          textDecoration: `${
                                                            item.fontUnderline
                                                              ? "underline"
                                                              : "none"
                                                          }`,
                                                        }}
                                                      >
                                                        {item.text}
                                                      </div>
                                                    </Row>
                                                  </div>
                                                </Draggable>
                                              )
                                            )}
                                          {this.state.carddata
                                            .myuploadedimage && (
                                            <div>
                                              <Draggable
                                                position={{
                                                  x:
                                                    this.state.carddata
                                                      .myuploadedimage.position
                                                      .x + this.state.xfactor,
                                                  y:
                                                    this.state.carddata
                                                      .myuploadedimage.position
                                                      .y + this.state.yfactor,
                                                }}
                                              >
                                                <div>
                                                  <Row className="handle">
                                                    <img
                                                      id="myuploadedimage"
                                                      src={
                                                        this.state.carddata
                                                          .myuploadedimage
                                                          ? this.state.carddata
                                                              .myuploadedimage
                                                              .file
                                                          : "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                                      }
                                                      style={{
                                                        width: "78%",
                                                        height: "78%",
                                                      }}
                                                    />
                                                    {/* </Resizable> */}
                                                  </Row>
                                                </div>
                                              </Draggable>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <Row>
                {/* Back of Card Display */}

                <div className="col">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 mb-4">
                      <div className="row">
                        <div className="col-12">
                          <div className="col">
                            <div className="col">
                              <div>
                                {/* Landscape */}
                                {this.state.card_orientation != "portrait" ? (
                                  <div
                                    id="backofcard"
                                    className="col-lg-12"
                                    style={{
                                      background: "#ccc",
                                      height: "638px",
                                      width: "1013px",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <div
                                      className="col-lg-12 p-4 backgroundimage"
                                      style={{
                                        background: `url(https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.usercardback})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        height: "638px",
                                        width: "1013px",
                                        borderRadius: "15px",
                                      }}
                                    >
                                      <div
                                        ref={this.inputRef}
                                        id={"cardheight"}
                                        className="col-lg-12 cardheight justify-content-center"
                                        style={{
                                          height: "100%",
                                          border: "solid 1px #000",
                                          overflow: "hidden",
                                          position: "relative",
                                          borderRadius: "15px",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    id="backofcard"
                                    className="col-lg-12"
                                    style={{
                                      background: "#ccc",
                                      height: "762px",
                                      width: "478px",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <div
                                      className="col-lg-12 p-4 backgroundimage"
                                      style={{
                                        background: `url(https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.usercardback})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        height: "762px",
                                        width: "478px",
                                        borderRadius: "15px",
                                      }}
                                    >
                                      <div
                                        ref={this.inputRef}
                                        id={"cardheight"}
                                        className="col-lg-12 cardheight justify-content-center"
                                        style={{
                                          height: "100%",
                                          border: "solid 1px #000",
                                          overflow: "hidden",
                                          position: "relative",
                                          borderRadius: "15px",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardPrintCard;
