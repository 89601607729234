import React, { Component, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
import { FormErrors } from "./FormErrors";
import { login_user } from "./apiserve/ApiComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uemail: "",
      upassword: "",
      response: "",
      responsetype: "",
      isLoading: false,
      showPassword: false,
      formErrors: { uemail: "", upassword: "" },
      uemailValid: false,
      upasswordValid: false,
      formValid: false,
      previousurl: "",
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleClickShowPassword = (e) => {
    e.preventDefault();
    //this.setState({ showPassword: !this.state.showPassword });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const { uemail, upassword } = this.state;
    let resp = "";
    if (upassword.length < 6) {
      this.setState({
        response: "Login Failed, Password must be minimum 6 characters.",
      });
      this.setState({ responsetype: "error" });
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: true });
      login_user(
        uemail,
        upassword,
        (res) => {
          //success
          if (res.data.data[0][0].success == "true") {
            localStorage.setItem("token", res.data.data[0][0].token);
            localStorage.setItem("vcardurole", res.data.data[0][0].role);
            localStorage.setItem("mwauid", res.data.data[0][0].uid);
            localStorage.setItem("username", res.data.data[0][0].username);

            this.setState({
              response: res.data.message || "Login Successful !!!",
            });
            this.setState({ responsetype: "success" });
            this.setState({ isLoading: false });

            if (res.data.data[0][0].role == 2) {
              // debugger;
              if (localStorage.getItem("savesignup") == "save") {
                setTimeout(function () {
                  let tempId = localStorage.getItem("designId");
                  window.location.href =
                    "/card-design?id=" + tempId + "&action=signup";
                });
              } else if (
                this.state.previousurl == "savecard" ||
                this.state.previousurl
              ) {
                // this.props.history.goBack();
                setTimeout(function () {
                  let tempId = localStorage.getItem("designId");
                  window.location.href =
                    "/card-design?id=" + tempId + "&action=save";
                }); //will call the function after 2 secs.
              } else {
                setTimeout(function () {
                  window.location.href = "/user/myprofile";
                }, 2000); //will call the function after 2 secs.
              }
            } else if (res.data.data[0][0].role == 1) {
              setTimeout(function () {
                window.location.href = "/admin/dashboard";
              }, 2000); //will call the function after 2 secs.
            }
          } else if (res.data.data[0][0].success == "false") {
            // alert("Registration Failed due to"+JSON.stringify(res.data.error));
            this.setState({
              response:
                "Login Failed, " + JSON.stringify(res.data.data[0][0].data),
            });
            this.setState({ responsetype: "error" });
            this.setState({ isLoading: false });
          } else if (res.data.error) {
            this.setState({
              response:
                "Login Failed, " + JSON.stringify(res.data.data[0][0].data),
            });
            this.setState({ responsetype: "error" });
            this.setState({ isLoading: false });
          }
        },
        (err) => {
          //error
          // alert(err);
          this.setState({ response: "Invalid Credentials. Please try again." });
          this.setState({ responsetype: "error" });
          this.setState({ isLoading: false });
        }
      );
    }
  };
  closetoast() {
    this.setState({ response: "" });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let uemailValid = this.state.uemailValid;
    let upasswordValid = this.state.upasswordValid;
    switch (fieldName) {
      case "uemail":
        uemailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.uemail = uemailValid ? "" : " is invalid";
        break;

      case "upassword":
        upasswordValid = value.length >= 6;
        fieldValidationErrors.upassword = upasswordValid ? "" : " is too short";
        break;

      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        uemailValid: uemailValid,
        upasswordValid: upasswordValid,
      },
      this.validateForm
    );
  }
  validateForm() {
    this.setState({
      formValid: this.state.uemailValid && this.state.upasswordValid,
    });
  }
  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  componentDidMount() {
    let queries = queryString.parse(this.props.location.search);
    if (queries.q != undefined) {
      this.setState({ previousurl: queries.q });
      localStorage.setItem("savecard", queries.q);
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("vcardurole") == 2
    ) {
      window.location.href = "/user/myprofile";
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("vcardurole") == 1
    ) {
      window.location.href = "/admin/dashboard";
    }
  }

  render() {
    return (
      <div>
        <HeaderComponent />
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form validate-form"
                style={{ paddingTop: "60px" }}
                onSubmit={this.handleOnSubmit}
              >
                <span className="login100-form-title p-b-43 mb-2">
                  Please enter your login details
                </span>
                <Toast
                  onClose={() => this.setState({ response: "" })}
                  show={this.state.response != "" ? true : false}
                  className={
                    this.state.responsetype == "success"
                      ? "bg-success"
                      : "bg-danger"
                  }
                  delay={3000}
                  autohide
                >
                  <Toast.Body>{this.state.response}</Toast.Body>
                </Toast>

                <InputGroup
                  className={`mb-0 form-group ${this.errorClass(
                    this.state.formErrors.uemail
                  )} || '' `}
                >
                  <FormControl
                    placeholder="Email Address"
                    name="uemail"
                    required
                    className="form-control wrap-input100 validate-input"
                    value={this.state.uemail || ""}
                    onChange={(e) => this.handleUserInput(e)}
                  />
                  <InputGroup.Text
                    id="basic-addon2"
                    className="p-0"
                  ></InputGroup.Text>
                </InputGroup>

                <InputGroup
                  className={`mb-0 form-group ${this.errorClass(
                    this.state.formErrors.upassword
                  )} || '' `}
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  <FormControl
                    type={this.state.showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="upassword"
                    required
                    className="form-control wrap-input100 validate-input"
                    value={this.state.upassword || ""}
                    onChange={(e) => this.handleUserInput(e)}
                  />
                  <button
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "5px",
                      border: "0",
                      bottom: "0",
                      height: "40px",
                      "z-index": "999",
                    }}
                  >
                    {this.state.showPassword ? (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                      </div>
                    ) : (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye-slash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                          <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                          <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                        </svg>
                      </div>
                    )}
                  </button>
                  <InputGroup.Text
                    id="basic-addon2"
                    className="p-0"
                  ></InputGroup.Text>
                </InputGroup>

                <div className="flex-sb-m w-full p-t-3 p-b-32 my-3">
                  <div
                    className="contact100-form-checkbox"
                    style={{ display: "inline-block" }}
                  >
                    <input
                      className="input-checkbox100"
                      id="ckb1"
                      type="checkbox"
                      name="remember-me"
                    />
                    <label className="label-checkbox100" htmlFor="ckb1">
                      Remember me
                    </label>
                  </div>

                  <div
                    style={{
                      display: "inline-block",
                      textAlign: "right",
                      float: "right",
                    }}
                  >
                    <a href="/forgot-password" className="txt1">
                      Forgot Password?
                    </a>
                  </div>
                </div>

                <div className="container-login100-form-btn">
                  <button
                    className="login100-form-btn"
                    type="submit"
                    style={{
                      display: `${!this.state.isLoading ? "block" : "none"}`,
                    }}
                  >
                    Login
                  </button>
                  <span
                    className="login100-form-btn"
                    style={{
                      display: `${this.state.isLoading ? "block" : "none"}`,
                      textAlign: "center",
                      padding: "8px 15px",
                    }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </span>
                </div>

                <div className="text-center p-t-46 p-b-20 my-3">
                  <span className="txt2">
                    <Link to="/signup" className="text-primary ml-2">
                      Signup
                    </Link>
                  </span>
                </div>
              </form>

              <div
                className="login100-more"
                style={{
                  backgroundImage: "url('images/login.jpg')",
                }}
              ></div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}
export default LoginComponent;
