import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import { deleteslider, updateslider } from "../apiserve/ApiComponent";
import TopBarComponent from "./TopBarComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import "./dashboard.css";
class DashboardSliderUpdateComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allorders: [],
      response: "",
      sliderdetails: "",
      sliderid: "",
      slidername: "",
      sliderdisplayOrder: "",
      selectedFile: "",
      show: false,
      selectedFileBase64: "",
      responsetype: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/sliders/",
      isLoading: false,
      isLoadingD: false,
    };
    // this.showuserdetails = this.showuserdetails.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
    this.deleteslider = this.deleteslider.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleUserInput(e) {
    const value = e.target.value;
    let updatedata = value;
    this.setState({ sliderdetails: updatedata });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({
      selectedFileBase64: URL.createObjectURL(event.target.files[0]),
    });
    this.setState({ selectedFile: event.target.files[0] });
    // this.setState({show:'new'})
  };

  handleChange(e) {
    this.setState({ displayOrder: e.target.value });
    this.state.sliderdisplayOrder = e.target.value;
  }

  onImageUpload() {
    if (this.state.selectedFile != "") {
      this.setState({ response: "Slider image is being uploded" });
      this.setState({ responsetype: "success" });
      const formData = new FormData();
      formData.append("image", this.state.selectedFile);
      formData.append("id", this.state.sliderid);
      formData.append("displayOrder", this.state.sliderdisplayOrder);
      this.setState({ isLoading: true });
      updateslider(
        formData,
        (res) => {
          this.setState({ isLoading: false });
          this.setState({ response: "Slider image updated" });
          this.setState({ responsetype: "success" });
          setTimeout(function () {
            window.location.href = "/admin/slider-setting";
          }, 1000);
        },
        (err) => {
          console.log(err);
          this.setState({ isLoading: false });
          this.setState({ response: "Error while updating slider." });
          this.setState({ responsetype: "error" });
        }
      );
    } else {
      this.setState({ response: "Select new slider image." });
      this.setState({ responsetype: "error" });
    }
  }

  deleteslider() {
    let id = this.state.sliderid;
    const formData = new FormData();
    formData.append("id", id);
    this.setState({ isLoadingD: true });

    deleteslider(
      id,
      (res) => {
        this.setState({ isLoadingD: false });
        this.setState({ response: "Slider deleted" });
        this.setState({ responsetype: "success" });
        setTimeout(function () {
          window.location.href = "/admin/slider-setting";
        }, 100);
      },
      (err) => {
        console.log(err);
        this.setState({ isLoadingD: false });
        this.setState({ response: "Error while deleting slider." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      let cardname = "";
      let cardid = "";
      let displayOrder = "";
      let queries = queryString.parse(this.props.location.search);
      if (queries.name != undefined) {
        cardname = queries.name;
        cardid = queries.id;
        displayOrder = queries.displayorder;
      }

      this.setState({ sliderdetails: cardname });
      this.setState({ slidername: cardname });
      this.setState({ sliderid: cardid });
      this.setState({ sliderdisplayOrder: displayOrder });
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Slider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center">
              Are you sure, You want to delete silder.
            </h5>
            <p className="text-center">
              After delete slider will be remove from website.
            </p>
            <Row
              style={{ display: `${!this.state.isLoadingD ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.deleteslider}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row
              style={{ display: `${this.state.isLoadingD ? "flex" : "none"}` }}
            >
              <span className="" style={{ textAlign: "center" }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </span>
            </Row>
          </Modal.Body>
        </Modal>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Update Slider</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-success btn-outline-secondary text-white"
                      onClick={this.onImageUpload}
                    >
                      <span
                        style={{
                          display: `${
                            !this.state.isLoading ? "block" : "none"
                          }`,
                        }}
                      >
                        Update Slider
                      </span>
                      <span
                        className=""
                        style={{
                          display: `${this.state.isLoading ? "block" : "none"}`,
                          textAlign: "center",
                        }}
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </span>
                    </button>
                  </div>
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-danger btn-outline-secondary text-white"
                      onClick={this.handleShow}
                    >
                      <span>Delete Slider</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="container rounded bg-white m-1">
                <form onSubmit={this.handleOnSubmit}>
                  <div className="row">
                    <div className="col-lg-12 col-md-6 border-right">
                      <div className="p-1">
                        <div className="row mt-3">
                          <div className="col-lg-3 col-md-3 col-sm-12">
                            <label>Select Image</label>
                            <InputGroup>
                              <input
                                type="file"
                                onChange={this.onFileChange}
                                style={{ border: "none" }}
                              />{" "}
                              <br />
                            </InputGroup>
                            <br />
                            <label>Display Order</label>{" "}
                            <input
                              value={this.state.sliderdisplayOrder}
                              onChange={this.handleChange}
                            ></input>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <label>Old Image</label>
                            <img
                              src={
                                this.state.baseImgPath +
                                this.state.sliderdetails
                              }
                              width="100%"
                            />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <label>Selected Image</label>
                            <img
                              src={this.state.selectedFileBase64}
                              width="100%"
                            />
                          </div>
                          {/* <div className="col-lg-3 col-md-3 mt-3">                    
                        
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardSliderUpdateComponents;
