import React, { Component, useState, useRef } from "react";
import "../App.css";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
} from "react-bootstrap-v5";
import Toast from "react-bootstrap/Toast";

import {
  uploadtemporarytemplatefile,
  savetempdesign,
  movetempTemplateFiles,
} from "../components/apiserve/ApiComponent";

class CreateTemplateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseImgPath:
        "https://office.monitorwa.com.au/tccardorderapi/temp/templates/",
      selectedFrontFile: "",
      selectedFrontFileBase64: "",
      selectedBackFile: "",
      selectedBackFileBase64: "",
      uploaded: false,
      uploadedBack: false,
      response: "",
      responsetype: "",
      templateName: "",
      cardOrientation: "landscape",
      cardOrientationLandscape: false,
      cardOrientationPortrait: false,
      cleanedSelectedFrontFile: "",
      cleanedSelectedBackFile: "",
    };
    //this.handleClick = this.handleClick.bind(this);
    this.uploadFrontImage = this.uploadFrontImage.bind(this);
    this.uploadBackImage = this.uploadBackImage.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.handleOrientation = this.handleOrientation.bind(this);
  }

  handleOrientation = (e) => {
    if (e.target.id == "landscape") {
      this.setState({ cardOrientationLandscape: true });
      this.setState({ cardOrientationPortrait: false });
      this.setState({ cardOrientation: "landscape" });
    } else {
      this.setState({ cardOrientationPortrait: true });
      this.setState({ cardOrientationLandscape: false });
      this.setState({ cardOrientation: "portrait" });
    }
  };

  handleOnChange = (e) => {
    this.setState({ templateName: e.target.value });
  };

  createTemplate = async () => {
    if (
      this.state.cleanedSelectedFrontFile != undefined &&
      this.state.cleanedSelectedBackFile != undefined &&
      this.state.templateName != ""
    ) {
      let formdata = {
        templatedesign: this.state.cleanedSelectedFrontFile,
        templatedesignB: this.state.cleanedSelectedBackFile,
        staffid: {
          text: "ID",
          color: "#000",
          fontBold: false,
          fontItalic: false,
          fontUnderline: false,
          alignText: "left",
          fontSize: "50px",
          fontFamily: "arial",
          position: { x: 300, y: 50 },
        },
        firstname: {
          text: "First Name",
          color: "#000",
          fontBold: false,
          fontItalic: false,
          fontUnderline: false,
          alignText: "left",
          fontSize: "50px",
          fontFamily: "arial",
          position: { x: 300, y: 100 },
        },
        lastname: {
          text: "Last Name",
          color: "#000",
          fontBold: false,
          fontItalic: false,
          fontUnderline: false,
          alignText: "left",
          fontSize: "50px",
          fontFamily: "arial",
          position: { x: 300, y: 150 },
        },
        position: {
          text: "Position",
          color: "#000",
          fontBold: false,
          fontItalic: false,
          fontUnderline: false,
          alignText: "left",
          fontSize: "50px",
          fontFamily: "arial",
          position: { x: 300, y: 200 },
        },
        phoneno: {
          text: "Phone",
          color: "#000",
          fontBold: false,
          fontItalic: false,
          fontUnderline: false,
          alignText: "left",
          fontSize: "50px",
          fontFamily: "arial",
          position: { x: 300, y: 250 },
        },
        extrafields: [],
        myuploadedimage: {
          file: "",
          position: { x: 0, y: 0 },
          frontback: "front",
        },
        Tname: "",
        amount: "6.00",
        status: "active",
        category_id: "0",
        cardbgimage: "",
        cardbgimageback: "",
      };

      const obj = {
        name: this.state.templateName,
        data: JSON.stringify(formdata),
        status: "1",
        amount: "6",
        category_id: "0",
        preview_back: this.state.cleanedSelectedBackFile,
        preview_front: this.state.cleanedSelectedFrontFile,
        card_orientation: this.state.cardOrientation,
      };

      movetempTemplateFiles(
        this.state.cleanedSelectedFrontFile,
        this.state.cleanedSelectedBackFile,
        (res) => {
          if (res.data.success) {
            savetempdesign(
              obj,
              (res) => {
                if (res.data.success) {
                  this.setState({ response: "Template Created" });
                  this.setState({ responsetype: "success" });
                } else {
                  this.setState({
                    response: "Error while creating template.",
                  });
                  this.setState({ responsetype: "error" });
                }
              },
              (err) => {
                this.setState({ response: "Unable to create template" });
                this.setState({ responsetype: "error" });
              }
            );
          }
        }
      );
    } else {
      if (this.state.cleanedSelectedFrontFile == undefined) {
        this.setState({
          response: "A front image is required to create a template",
        });
        this.setState({ responsetype: "error" });
      }
      if (this.state.templateName == "") {
        this.setState({
          response: "Please give your template a name",
        });
        this.setState({ responsetype: "error" });
      }
      if (this.state.cleanedSelectedBackFile == undefined) {
        this.setState({
          response: "A Rear image is required to create a template",
        });
        this.setState({ responsetype: "error" });
      }
    }
  };

  onFrontFileChange = async (event) => {
    // Update the state
    await this.uploadFrontImage(event.target.files[0]);
    this.setState({
      selectedFrontFileBase64: URL.createObjectURL(event.target.files[0]),
    });
    this.setState({ selectedFrontFile: event.target.files[0] });
  };

  onBackFileChange = async (event) => {
    // Update the state
    await this.uploadBackImage(event.target.files[0]);
    this.setState({
      selectedBackFileBase64: URL.createObjectURL(event.target.files[0]),
    });
    this.setState({ selectedBackFile: event.target.files[0] });
  };

  uploadBackImage = async (file) => {
    if (!file) {
      file = this.state.selectedFrontFile;
    }
    //console.log(this.state.selectedFile);
    // let profileimgbase64 = this.state.selectedFileBase64;
    if (file != "") {
      this.setState({ selectedBackFile: file });
      this.setState({ response: "Template Rear Image Uploading" });
      this.setState({ responsetype: "success" });
      const formData = new FormData();
      formData.append("image", file);
      formData.append("patronID", localStorage.getItem("mwauid"));
      formData.append("fileName", file);
      await uploadtemporarytemplatefile(
        formData,
        (res) => {
          if (res.data.success) {
            this.setState({ cleanedSelectedBackFile: res.data.data });

            this.setState({ response: "Template Rear Image Uploaded" });
            this.setState({ responsetype: "success" });
          } else {
            this.setState({
              response: "Error while uploading template image.",
            });
            this.setState({ responsetype: "error" });
          }
        },
        (err) => {
          console.log(err);
          this.setState({ response: "Error while uploading template image." });
          this.setState({ responsetype: "error" });
        }
      );
      this.setState({ uploadedBacked: true });
    } else {
      this.setState({ response: "Select Template Rear Image" });
      this.setState({ responsetype: "error" });
    }
  };

  uploadFrontImage = async (file) => {
    if (!file) {
      file = this.state.selectedFrontFile;
    }
    //console.log(this.state.selectedFile);
    // let profileimgbase64 = this.state.selectedFileBase64;
    if (file != "") {
      this.setState({ selectedFrontFile: file });
      this.setState({ response: "Template Front Image Uploading" });
      this.setState({ responsetype: "success" });
      const formData = new FormData();
      formData.append("image", file);
      formData.append("patronID", localStorage.getItem("mwauid"));
      formData.append("fileName", file);
      await uploadtemporarytemplatefile(
        formData,
        (res) => {
          if (res.data.success) {
            this.setState({ cleanedSelectedFrontFile: res.data.data });
            this.setState({ response: "Template Front Image Uploaded" });
            this.setState({ responsetype: "success" });
          } else {
            this.setState({
              response: "Error while uploading template image.",
            });
            this.setState({ responsetype: "error" });
          }
        },
        (err) => {
          console.log(err);
          this.setState({ response: "Error while uploading template image." });
          this.setState({ responsetype: "error" });
        }
      );
      this.setState({ uploaded: true });
    } else {
      this.setState({ response: "Select Template Front Image" });
      this.setState({ responsetype: "error" });
    }
  };

  componentDidMount = async () => {
    this.setState({ cardOrientation: "landscape" });
    this.setState({ cardOrientationLandscape: true });
    this.setState({ cardOrientationPortrait: false });
  };

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <HeaderComponent />
        <Container>
          <div className="clearfix">&nbsp;</div>
          <Row>
            <div className="col-lg-6 col-md-6">
              <h2 id="productName" className="bold basic text-shadow">
                Create Your Own Template
              </h2>
            </div>

            <div className="col-lg-6 col-md-6">
              <button
                style={{
                  paddingLeft: "35px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-start",
                  float: "right",
                  backgroundColor: "teal",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  height: "40px",
                  width: "200px",
                  fontSize: "18px",
                }}
                onClick={(e) => this.createTemplate(e)}
              >
                Create Template
              </button>
            </div>
          </Row>
          <Row>
            <h5 className="bold basic text-shadow">
              Please Note: <br />
              <br />
              Please read and understand the instruction(s) on the right side of
              the screen, this will ensure your template <br />
              is either for only you to use or available to everyone
            </h5>
          </Row>
          <br />
          <Row>
            <div>
              <h6>Card Orientation</h6>
              <input
                type="radio"
                name="cardorientation"
                id="landscape"
                value={this.state.cardOrientationLandscape}
                onChange={(e) => this.handleOrientation(e)}
                checked={this.state.cardOrientationLandscape}
              ></input>
              <label
                for="landscape"
                style={{ paddingLeft: "20px", paddingRight: "30px" }}
              >
                Landscape
              </label>
              <input
                type="radio"
                name="cardorientation"
                id="portrait"
                value={this.state.cardOrientationPortrait}
                onChange={(e) => this.handleOrientation(e)}
                checked={this.state.cardOrientationPortrait}
              ></input>
              <label for="portrait" style={{ paddingLeft: "20px" }}>
                Portrait
              </label>
            </div>
          </Row>
          <br />
          <Row>
            <div>
              <h6>Template Name</h6>
              <input
                type="text"
                className="form-control"
                name="cardname"
                required
                placeholder="Your Template Name"
                style={{ width: "59%" }}
                value={this.state.templateName}
                onChange={(e) => this.handleOnChange(e)}
              />
            </div>
          </Row>
          <br />
          <h6>Front Side of Card</h6>
          <Row>
            <Col>
              <div>
                {this.state.cardOrientation == "landscape" ? (
                  //Landscape Orientation
                  <div
                    style={{
                      display: "block",
                      border: "solid 1px blue",
                      width: "762px",
                      height: "478px",
                      borderRadius: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "block",
                        border: "dashed 1px blue",
                        width: "726px",
                        height: "443px",
                        position: "relative",
                        top: "16px",
                        left: "16px",
                        borderRadius: "15px",
                      }}
                    ></div>
                    {this.state.uploaded && (
                      <div
                        className=" col-lg-12 p-4 backgroundimage"
                        style={{
                          background:
                            this.state.cleanedSelectedFrontFile != ""
                              ? `url("${
                                  this.state.baseImgPath +
                                  this.state.cleanedSelectedFrontFile
                                }")`
                              : "",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          //position: "absolute",
                          marginTop: "-443px",
                          marginLeft: "0px",
                          resize: "both",
                          width: "759px",
                          height: "476px",
                          borderRadius: "15px",
                        }}
                      ></div>
                    )}
                  </div>
                ) : (
                  //Portrait Orientation
                  <div
                    style={{
                      display: "block",
                      border: "solid 1px blue",
                      width: "478px",
                      height: "762px",
                      borderRadius: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "block",
                        border: "dashed 1px blue",
                        width: "443px",
                        height: "726px",
                        position: "relative",
                        top: "16px",
                        left: "16px",
                        borderRadius: "15px",
                      }}
                    ></div>
                    {this.state.uploaded && (
                      <div
                        className=" col-lg-12 p-4 backgroundimage"
                        style={{
                          background:
                            this.state.cleanedSelectedFrontFile != ""
                              ? `url("${
                                  this.state.baseImgPath +
                                  this.state.cleanedSelectedFrontFile
                                }")`
                              : "",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          //position: "absolute",
                          marginTop: "-725px",
                          marginLeft: "0px",
                          resize: "both",
                          width: "476px",
                          height: "759px",
                          borderRadius: "15px",
                        }}
                      ></div>
                    )}
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <h6>
                Your image/design should be: <br />
                <br />
                <ul>
                  {this.state.cardOrientation == "landscape" ? (
                    <li>
                      86mm wide x 54mm high <br />
                      (1016 pixels wide x 638 pixels high)
                    </li>
                  ) : (
                    <li>
                      54mm wide x 86mm high <br />
                      (638 pixels wide x 1016 pixels high)
                    </li>
                  )}
                  {this.state.cardOrientation == "landscape" ? (
                    <li>orientation should be Landscape Only</li>
                  ) : (
                    <li>orientation should be Portrait Only</li>
                  )}
                  <li>images are not resized or scaled to fit</li>
                  <li>dotted line indicates a bleed edge of 2mm</li>
                  <li>
                    it is suggested that you should keep images to 3mb is size
                    or less
                  </li>
                  <li>ensure that you give your template a name</li>
                  <li>
                    to add your image/s click on the "Choose File" button <br />
                    (one for the front image and one for the back image)
                    <br />
                    this will upload the image for you
                  </li>
                  <li>
                    if you are not logged in when the "Create Template" is
                    clicked then your template will be available for everyone
                    using this site
                  </li>
                </ul>
              </h6>
              <Row>
                <input
                  type="file"
                  id="frontfileInput"
                  onChange={this.onFrontFileChange}
                  style={{ border: "none" }}
                />
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <h6>Rear Side of Card</h6>
          <Row>
            <Col>
              {this.state.cardOrientation == "landscape" ? (
                //Landscape Orientation
                <div
                  style={{
                    display: "block",
                    border: "solid 1px blue",
                    width: "762px",
                    height: "478px",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      border: "dashed 1px blue",
                      width: "726px",
                      height: "443px",
                      position: "relative",
                      top: "16px",
                      left: "16px",
                      borderRadius: "15px",
                    }}
                  ></div>
                  {this.state.uploadedBacked && (
                    <div
                      className="col-lg-12 p-4 backgroundimage"
                      style={{
                        background:
                          this.state.cleanedSelectedBackFile != ""
                            ? `url("${
                                this.state.baseImgPath +
                                this.state.cleanedSelectedBackFile
                              }")`
                            : "",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        //position: "absolute",
                        marginTop: "-443px",
                        marginLeft: "0px",
                        resize: "both",
                        width: "759px",
                        height: "476px",
                        borderRadius: "15px",
                      }}
                    ></div>
                  )}
                </div>
              ) : (
                //Portrait Orientation
                <div
                  style={{
                    display: "block",
                    border: "solid 1px blue",
                    width: "478px",
                    height: "762px",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      border: "dashed 1px blue",
                      width: "443px",
                      height: "726px",
                      position: "relative",
                      top: "16px",
                      left: "16px",
                      borderRadius: "15px",
                    }}
                  ></div>
                  {this.state.uploadedBacked && (
                    <div
                      className="col-lg-12 p-4 backgroundimage"
                      style={{
                        background:
                          this.state.cleanedSelectedBackFile != ""
                            ? `url("${
                                this.state.baseImgPath +
                                this.state.cleanedSelectedBackFile
                              }")`
                            : "",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        //position: "absolute",
                        marginTop: "-725px",
                        marginLeft: "0px",
                        resize: "both",
                        width: "476px",
                        height: "759px",
                        borderRadius: "15px",
                      }}
                    ></div>
                  )}
                </div>
              )}
            </Col>
            <Col>
              <Row>
                <input
                  type="file"
                  onChange={this.onBackFileChange}
                  style={{ border: "none" }}
                />
              </Row>
            </Col>
          </Row>
        </Container>
        <div clasName="clearfix">&nbsp;</div>
        <FooterComponent />
      </div>
    );
  }
}
export default CreateTemplateComponent;
