import React, { Component, useState } from 'react';
import {Button,InputGroup,FormControl,FormLabel, Row,Col,Form} from "react-bootstrap-v5";

import * as Icon from 'react-bootstrap-icons';
import { FormErrors } from '../FormErrors';
import FieldFontsDropdownComponent from './FieldFontsDropdownComponent';
import ColorComponent from './ColorComponent';
import { BrowserRouter, Route,withRouter,Redirect,useHistory  } from 'react-router-dom';
class FileUploadComponent extends Component{
  constructor (props) {
    super(props);
    this.state={fileUploadState:"",selectedFile:'',}
    this.inputReference = React.createRef();

    }

    fileUploadAction = () =>this.inputReference.current.click();
    fileUploadInputChange = (e) =>this.setState({fileUploadState:e.target.value});
    
onFileChange = event => {    
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });	  
  };

  // On file upload (click the upload button)
  onFileUpload = (e) => {
    let errors = {};
    const {userID,FileUpload} =this.state;
  e.preventDefault();
  // Create an object of formData
  const formData = new FormData();
  
  // Update the formData object
  // formData.append(
  //   "FileUpload ",
  //   this.state.selectedFile,
  //   this.state.selectedFile.name
  // );

  };
  
  // File content to be displayed after
  // file upload is complete
  fileData = () => {
  
// 		if (this.state.selectedFile) {
     
// 		  return (
// 			<div>
// 			  <h2>File Details:</h2>
       
//   <p>File Name: {this.state.selectedFile.name}</p>
 
       
//   <p>File Type: {this.state.selectedFile.type}</p>
 
       
//   <p>
// 				Last Modified:{" "}
// 				{this.state.selectedFile.lastModifiedDate.toDateString()}
// 			  </p>
 
// 			</div>
// 		  );
// 		} else {
// 		  return (
// 			<div>
// 			  <br />
// 			  <h4>Choose before Pressing the Upload button</h4>
// 			</div>
// 		  );
// 		}
  };
  

    render() {
      
        return (
          <div>
        
					
                <div className="d-grid gap-2">
                <input type="file" hidden ref={this.inputReference} onChange={this.onFileChange} />
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}} onClick={this.fileUploadAction}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>My Computer</FormLabel>
</div>
        <div>
        
        {/* <img src={this.state.fileUploadState} alt={this.state.fileUploadState? this.state.fileUploadState : null} style={{maxHeight:"100px",width:"100px",border:"solid 1px #ccc",borderRadius:"100px",minHeight:"100px"}} /> */}
        <img src={this.state.selectedFile? URL.createObjectURL(this.state.selectedFile) : null ||'' } alt={this.state.selectedFile? this.state.selectedFile.name : null} style={{maxHeight:"100px",width:"100px",border:"solid 1px #ccc",borderRadius:"100px",minHeight:"100px"}} />

</div>           
          </div>

        )
    }
}
export default withRouter(FileUploadComponent);
