import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import {
  Table,
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import axios from "axios";
import "./dashboard.css";
import { Resizable } from "re-resizable";
import Draggable, { DraggableCore } from "react-draggable";
import { AlignEnd } from "react-bootstrap-icons";

import { getmytemplates } from "../apiserve/ApiComponent";

class DashboardSavedTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carddetails: [],
      carduserdetails: [],
      carddata: [],
      templateData: [],
      card_logodata: [],
      cardbgimage: "",
      show: false,
      saved: false,
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      response: "",
      responsetype: "",
      isLoading: false,
    };
    this.loadTemplate = this.loadTemplate.bind(this);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handleOnChange = this.handleOnChange.bind(this);
    // this.handleSave = this.handleSave.bind(this);
  }
  loadmytemplates() {
    getmytemplates(localStorage.getItem("mwauid"), (res) => {
      this.setState({ templateData: res.data.data[0] });
    });
  }

  loadTemplate(templateid) {
    console.log(templateid);
    window.location.href = `/user/saved_templatedetails?id=${templateid}`;
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") == 2) {
      this.loadmytemplates();
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <TopBarComponent imagePath={localStorage.getItem("profileimagepath")} />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="container rounded bg-white m-1 mt-3 p-0">
                <Tabs
                  defaultActiveKey="saved"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="saved" title="My Templates">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Template Name</th>
                          <th>Template Front</th>
                          <th>Template Back</th>
                          <th>Created Date/Time</th>
                          <th>Status</th>
                          <th>Cost</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.templateData.map(function (item, i) {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.cardtemplatename}</td>
                              <td>
                                {item.card_orientation != "portrait" ? (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.cardbgimage
                                    }
                                    style={{ width: "100px", height: "60px" }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.cardbgimage
                                    }
                                    style={{ width: "60px", height: "100px" }}
                                  />
                                )}
                              </td>
                              <td>
                                {item.card_orientation != "portrait" ? (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.card_back_image
                                    }
                                    style={{ width: "100px", height: "60px" }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      "https://office.monitorwa.com.au/tccardorderapi/templates/" +
                                      item.card_back_image
                                    }
                                    style={{ width: "60px", height: "100px" }}
                                  />
                                )}
                              </td>
                              <td>
                                {
                                  new Date(item.created_at)
                                    .toString()
                                    .split("GMT")[0]
                                }
                              </td>
                              <td>
                                {item.card_status == 0 ? "Draft" : "Saved"}
                              </td>
                              <td>
                                {item.amount == 0 ? "Free" : "$" + item.amount}
                              </td>
                              {/* <td>
                                <Link to={`/user/mycarddetails?id=${item.id}`}>
                                  View Card
                                </Link>
                              </td> */}
                              {/* <td>
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  name={item.id}
                                  value={item.id}
                                  defaultChecked={this.state.isChecked}
                                  onChange={(e) =>
                                    this.handleChange(
                                      e,
                                      item.id,
                                      item.template_id
                                    )
                                  }
                                />{" "}
                                &nbsp;
                                <a
                                  href="#"
                                  className="qty minumqty"
                                  onClick={(e) => this.handleMinus(item.id)}
                                >
                                  -
                                </a>{" "}
                                {item.qty}{" "}
                                <a
                                  href="#"
                                  className="qty"
                                  onClick={(e) => this.handlePlus(item.id, i)}
                                >
                                  +
                                </a>
                              </td> */}
                              <td>
                                <Button
                                  className="btn btn-success"
                                  onClick={(e) => this.loadTemplate(item.id)}
                                >
                                  Order Card
                                </Button>
                              </td>
                            </tr>
                          );
                        }, this)}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </div>
              {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  Billing Amount: $
                  {this.state.total_amount == ""
                    ? "0"
                    : this.state.total_amount}
                </h1>
                <div className="btn-toolbar mb-2 mb-md-0 text-right">
                  <button
                    type="button"
                    onClick={this.buyusercards}
                    className="btn btn-sm btn-success btn-outline-secondary text-white"
                  >
                    Buy Cards
                  </button>
                </div>
              </div> */}
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardSavedTemplates;
