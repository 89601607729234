import React, { Component, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
class SideBarComponent extends Component {
  render() {
    return (
      <div className="p-0">
        <nav
          id="sidebarMenu"
          className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
        >
          <div className="position-sticky pt-3">
            <ul className="nav flex-column">
              <li>
                <a href="/">
                  <img
                    src="../images/logo.png"
                    className="img-responsive px-2"
                  />
                </a>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/user/myprofile"
                >
                  <span data-feather="file"></span>
                  My Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/user/mycards"
                >
                  <span data-feather="file"></span>
                  My Cards
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/user/saved_templates"
                >
                  <span data-feather="file"></span>
                  My Templates
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/user/queuedCards"
                >
                  <span data-feather="file"></span>
                  Queued Cards
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/user/order_history"
                >
                  <span data-feather="file"></span>
                  Order History
                </Link>
              </li>
            </ul>

            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Settings</span>
              <a
                className="link-secondary"
                href="#"
                aria-label="Add a new report"
              >
                <span data-feather="plus-circle"></span>
              </a>
            </h6>

            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <a className="nav-link" href="/user/change_password">
                  <span data-feather="file-text"></span>
                  Change Password
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default SideBarComponent;
