import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import { getallcards } from "../apiserve/ApiComponent";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import "./dashboard.css";
import DataTable from "react-data-table-component";
class CorporateComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allcardsdata: [],
      columnsValue: [
        {
          name: "Card Name",
          selector: (row) => row.cardname,
          sortable: true,
        },
        {
          name: "User",
          selector: (row) => row.fname + " " + row.lname,
          sortable: true,
        },
        {
          name: "Card Front",
          // selector: row=> row.card_preview,
          // sortable: true,.
          cell: (row) =>
            row.card_orientation != "portrait" ? (
              <img
                src={this.state.baseImgPath + row.card_preview}
                style={{ width: "100px", height: "60px" }}
              />
            ) : (
              <img
                src={this.state.baseImgPath + row.card_preview}
                style={{ width: "60px", height: "100px" }}
              />
            ),
        },
        {
          name: "Card Back",
          // selector: row=> row.card_preview_back,
          // sortable: true,
          cell: (row) =>
            row.card_orientation != "portrait" ? (
              <img
                src={this.state.baseImgPath + row.card_preview}
                style={{ width: "100px", height: "60px" }}
              />
            ) : (
              <img
                src={this.state.baseImgPath + row.card_preview_back}
                style={{ width: "60px", height: "100px" }}
              />
            ),
        },
        {
          name: "Created Date",
          selector: (row) => row.created_at,
          sortable: true,
        },
        {
          name: "Last Update",
          selector: (row) => row.updated_at,
          sortable: true,
        },
        {
          name: "Card Orientation",
          selector: (row) =>
            row.card_orientation != null
              ? row.card_orientation.charAt(0).toUpperCase() +
                row.card_orientation.slice(1)
              : "Landscape",
          sortable: true,
        },
        {
          name: "Card Status",
          selector: (row) => {
            switch (row.card_status) {
              case 0:
                return "Inactive";
                break;
              case 1:
                return "Active";
                break;
              case 2:
                return "Deleted";
                break;

              default:
                return "Unknown";
                break;
            }
          },
          sortable: true,
        },
        {
          name: "View Card",
          button: true,
          cell: (row) => (
            <Link to={`/admin/carddetails?id=${row.action}`}>View</Link>
          ),
        },
      ],
      response: "",
      responsetype: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      isLoading: false,
    };
  }

  getallcards() {
    getallcards(
      (res) => {
        var created_date;
        var updated_date;
        if (res.data.success == true) {
          this.setState({ allcardsdata: res.data.data[0] });
          var emptyobj = [];
          for (let i = 0; i < this.state.allcardsdata.length; i++) {
            let cdate = new Date(this.state.allcardsdata[i].created_at);
            let udate = new Date(this.state.allcardsdata[i].updated_at);
            this.state.allcardsdata[i].created_at = cdate
              .toString()
              .split("GMT")[0];
            created_date = cdate.toString().split("GMT")[0];
            this.state.allcardsdata[i].updated_at = udate
              .toString()
              .split("GMT")[0];
            updated_date = udate.toString().split("GMT")[0];
            const obj = {
              id: i + 1,
              created_at: created_date,
              updated_at: updated_date,
              cardname: this.state.allcardsdata[i].cardname,
              fname: this.state.allcardsdata[i].fname,
              lname: this.state.allcardsdata[i].lname,
              card_preview: this.state.allcardsdata[i].card_preview,
              card_preview_back: this.state.allcardsdata[i].card_preview_back,
              card_orientation: this.state.allcardsdata[i].card_orientation,
              action: this.state.allcardsdata[i].id,
              card_status: this.state.allcardsdata[i].card_status,
            };
            emptyobj.push(obj);
            //this.getorderlines(this.state.allcardsdata[i].userid);
          }
          this.setState({ tableTitle: emptyobj });
          this.setState(this.state.allcardsdata);
          // var emptyobj = [];
          // //   for (var key of res.data.data.entries()) {
          // //     console.log(key[0] + ", " + key[1]);
          // //     const obj = {
          // //       id: key[0] + 1,
          // //       cardname: key[1]["cardname"],
          // //       fname: key[1]["fname"],
          // //       lname: key[1]["lname"],
          // //       card_preview: key[1]["card_preview"],
          // //       card_preview_back: key[1]["card_preview_back"],
          // //       created_at: created_date,
          // //       updated_at: updated_date,
          // //       action: key[1]["id"],
          // //     };
          // //     emptyobj.push(obj);
          // //   }
          // //   this.setState({ tableTitle: emptyobj });
        } else {
          this.setState({
            response: "Login token is rexpired please login again",
          });
          this.setState({ responsetype: "success" });
          localStorage.removeItem("token");
          localStorage.removeItem("vcardurole");
          localStorage.removeItem("username");
          localStorage.removeItem("mwauid");
          setTimeout(function () {
            window.location.href = "/login";
          }, 1000);
        }
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading cards data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      this.getallcards();
    } else {
      window.location.href = "/";
    }
  }
  render() {
    return (
      <div>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">User Cards</h1>
              </div>
              <DataTable
                columns={this.state.columnsValue}
                data={this.state.tableTitle}
                pagination
                striped
              ></DataTable>
              <div className="table-responsive">
                {/* <table className="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Card Name</th>
                            <th scope="col">User</th>
                            <th scope="col">Cards Front</th>
                            <th scope="col">Cards Back</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Last Update</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.allcardsdata.map(function(item, i){    
                              return (
                              <tr key={i}>
                              <td>{i+1}</td>
                              <td>{item.cardname}</td>
                              <td>{item.fname+" "+item.lname}</td>
                              <td><img src={this.state.baseImgPath + item.card_preview} style={{width:"100px",height:"60px"}} /></td>
                              <td><img src={this.state.baseImgPath + item.card_preview_back} style={{width:"100px",height:"60px"}} /></td>
                              <td>{item.created_at}</td>
                              <td>{item.updated_at}</td>
                              <td>
                                <Link to={`/admin/carddetails?id=${item.id}`}>View</Link>
                              </td>
                            </tr>);
                            },this)}
                        </tbody>
                      </table> */}
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default CorporateComponents;
