import React, { Component, useState, PropTypes } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  Navbar,
  NavDropdown,
} from "react-bootstrap-v5";

class TopBarComponent extends Component {
  constructor(props) {
    super(props);

    if (!localStorage.getItem("token")) {
      window.location.href = "/";
    }
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("vcardurole");
    localStorage.removeItem("username");
    localStorage.removeItem("mwauid");
    localStorage.removeItem("profileimagepath");
    window.location.href = "/login";
  }

  render() {
    return (
      <div>
        <Navbar
          expand="lg"
          className="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow"
          style={{ background: "#1f2744 " }}
        >
          <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/">
            IDCARDS - MONITOR WA
          </a>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="col-12 col-lg-auto ms-lg-auto mb-2 justify-content-end mb-md-0 visibleMO text-white">
              <li>
                <Link
                  to="/user/myprofile"
                  className="nav-link px-2"
                  style={{ color: "#fff", fontSize: "16px" }}
                >
                  My Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/user/mycards"
                  className="nav-link px-2"
                  style={{ color: "#fff", fontSize: "16px" }}
                >
                  My Cards
                </Link>
              </li>
              <li>
                <Link
                  to="/user/order_history"
                  className="nav-link px-2"
                  style={{ color: "#fff", fontSize: "16px" }}
                >
                  Order History
                </Link>
              </li>
              <li>
                <Link
                  to="/user/change_password"
                  className="nav-link px-2"
                  style={{ color: "#fff", fontSize: "16px" }}
                >
                  Change Password
                </Link>
              </li>
            </Nav>
            <div className="nav-item text-nowrap">
              <a
                href="#"
                className="btn text-white nav-link p-3"
                aria-current="page"
                onClick={this.logout}
              >
                <img
                  // className="rounded-circle mt-5"
                  style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "70%",
                  }}
                  src={
                    this.props.imagePath != null
                      ? this.props.imagePath
                      : "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                  }
                  alt="Profile Image"
                />{" "}
                Sign Out
              </a>
            </div>
          </Navbar.Collapse>
        </Navbar>
        {/* <header className="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow" style={{background:"#1f2744 "}}> */}
        {/* <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/">MONITOR WA</a>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="sidebarMenu">
            <div className="navbar-nav">
            <a class="nav-item nav-link" href="#">Features</a>
            <a class="nav-item nav-link" href="#">Features</a>
            <a class="nav-item nav-link" href="#">Features</a>
              <div className="nav-item text-nowrap">
      <Link className="nav-link p-3" aria-current="page" onClick={this.logout}>Sign out</Link>     
    </div>
    </div>
  </div> */}
        {/* </header> */}
      </div>
    );
  }
}
export default TopBarComponent;
