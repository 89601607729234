import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import { passwordChange } from "../apiserve/ApiComponent";
import { InputGroup, FormControl } from "react-bootstrap-v5";
import Toast from "react-bootstrap/Toast";
import "./dashboard.css";
class DashboardComponents extends Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      response: "",
      responsetype: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      let input = {};
      input["password"] = "";
      input["confirm_password"] = "";
      input["user"] = "";
      this.state.input.user = localStorage.getItem("mwauid");

      this.setState({ input: input });
      let pass = { password: "", user: "" };
      pass.password = this.state.input.password;
      pass.user = this.state.input.user;

      passwordChange(
        pass,
        (res) => {
          if (res.data.success == true) {
            this.setState({ response: "Password updated successfully" });
            this.setState({ responsetype: "success" });
          }
        },
        (err) => {
          console.log(err);
          this.setState({ response: "Error while updating password." });
          this.setState({ responsetype: "error" });
        }
      );
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["password"]) {
      isValid = false;
      this.setState({ response: "Please enter your password." });
      this.setState({ responsetype: "error" });
    } else if (input["password"].length <= 5) {
      isValid = false;
      this.setState({ response: "Please enter minimum 6 character password" });
      this.setState({ responsetype: "error" });
    } else if (!input["confirm_password"]) {
      isValid = false;
      this.setState({ response: "Please enter your confirm password." });
      this.setState({ responsetype: "error" });
    } else if (input["confirm_password"].length <= 5) {
      isValid = false;
      this.setState({ response: "Please enter minimum 6 character password" });
      this.setState({ responsetype: "error" });
    } else if (
      typeof input["password"] !== "undefined" &&
      typeof input["confirm_password"] !== "undefined"
    ) {
      if (input["password"] != input["confirm_password"]) {
        isValid = false;
        this.setState({ response: "Passwords don't match." });
        this.setState({ responsetype: "error" });
      }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    return (
      <div>
        <TopBarComponent imagePath={localStorage.getItem("profileimagepath")} />
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="container rounded bg-white m-1 mt-3 p-0">
                <div className="card login-form">
                  <div className="card-body">
                    <h3 className="card-title">Change Password</h3>

                    <div className="card-text">
                      <form onSubmit={this.handleSubmit}>
                        <div
                          style={{
                            width: "100%",
                            height: "30%",
                            padding: "2%",
                            backgroundColor: "#68B5DF",
                            borderRadius: "10px",
                            border: "1px solid black",
                            display: "inline-block",
                          }}
                        >
                          <div className="row">
                            <div className="form-group col-md-6 col-sm-12 mb-2">
                              <label
                                for="password"
                                style={{ paddingBottom: "6px" }}
                              >
                                Your new password
                              </label>
                              <input
                                type="password"
                                name="password"
                                value={this.state.input.password}
                                onChange={this.handleChange}
                                class="form-control"
                                placeholder="Enter password"
                                id="password"
                                min="6"
                              />
                            </div>
                            <div className="form-group col-md-6 col-sm-12 mb-2">
                              <label
                                for="Cpassword"
                                style={{ paddingBottom: "6px" }}
                              >
                                Repeat password
                              </label>
                              <input
                                type="password"
                                name="confirm_password"
                                value={this.state.input.confirm_password}
                                onChange={this.handleChange}
                                class="form-control"
                                placeholder="Enter confirm password"
                                id="confirm_password"
                                min="6"
                              />
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-block submit-btn mt-2"
                          >
                            Change Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardComponents;
