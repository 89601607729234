import React, { Component } from 'react';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Card, Container,Nav,Image,FormLabel} from "react-bootstrap-v5";
import TextFieldComponent from "./TextFieldComponent";
import ImageComponent from "./ImageComponent";
import MoreComponent from "./MoreComponent";
import { FormErrors } from '../FormErrors';

 import TemplatesComponent from "./TemplatesComponent";
import { DragDropContext } from 'react-beautiful-dnd';


class DesignerComponent extends Component{
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      formErrors: {email: '', password: ''},
      emailValid: false,
      passwordValid: false,
      formValid: false,
      showHideFName: true,
      showHideLName: true
    }
    this.hideComponent = this.hideComponent.bind(this);
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '': ' is too short';
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    emailValid : emailValid ,
                    passwordValid: passwordValid
                  }, this.validateForm);
  }

  validateForm ( )  {
    this.setState({formValid: this.state.emailValid && this.state.passwordValid});
  }

  errorClass(error) {
    return(error.length === 0 ? 'has-valid' : 'has-error');
  }

  hideComponent(name) {
    switch (name) {
      case "showHideFName":
        this.setState({ showHideFName: !this.state.showHideFName });
        break;
      case "showHideLName":
        this.setState({ showHideLName: !this.state.showHideLName });
        break;

    }
  }

    render() {
      const { showHideFName, showHideLName } = this.state;
        return (
          <div>
             {showHideFName && (
            <tr>
              <td>First Name :</td>
              <td>
                <input type="text" id="fName" />
              </td>
            </tr>
          )}
          {showHideLName && (
            <tr>
              <td>Last Name :</td>
              <td>
                <input type="text" id="lName" />
              </td>
            </tr>
          )}
          {showHideFName && showHideLName && (
            <tr>
              <td>
                <button>Submit</button>
              </td>
            </tr>
          )}
   <form className="demoForm">
        <h2>Sign up</h2>
        <div className="panel panel-default">
          <FormErrors formErrors={this.state.formErrors} />
        </div>
        <div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
          <label htmlFor="email">Email address</label>
          <input type="email" required className="form-control" name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleUserInput}  />
        </div>
        <div className={`form-group ${this.errorClass(this.state.formErrors.password)}`}>
          <label htmlFor="password">Password</label>
          <input type="password" className="form-control" name="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleUserInput}  />
        </div>
        <button type="submit" className="btn btn-primary" disabled={!this.state.formValid}>Sign up</button>
      </form>

      <button onClick={() => this.hideComponent("showHideFName")}>
                Hide First Name
              </button>
     
              <button onClick={() => this.hideComponent("showHideLName")}>
                Hide Last Name
              </button>
        </div>

        
        )
    }
}
export default DesignerComponent;