import React, { Component, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import { verifymailfunction } from "./apiserve/ApiComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  Carousel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
class MailVerificationPage extends Component {
  constructor() {
    super();
    this.state = {
      confrimation: "",
      token: "",
      email: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      response: "",
      responsetype: "",
    };
    // this.handleChange = this.handleChange.bind(this);
    // this.contuneedting = this.contuneedting.bind(this);
  }

  //   verifymailfunction(){
  //       if(this.state.token != undefined){
  //         let token=this.state.token;
  //       let email=this.state.email;
  //       console.log(this.state.token);
  //       console.log(this.state.email);
  //       verifymailfunction(token,email,(res) => {
  //         let data = res.data.data;
  //         this.setState({termsdetails:data});
  //         console.log(this.state.termsdetails);
  //       },(err) => {
  //           console.log(err);
  //           this.setState({ response:"Error in loading Terms and Conditions" });
  //       this.setState({ responsetype: "error"});
  //       });
  //       }
  //   }

  componentDidMount() {
    let queries = queryString.parse(this.props.location.search);
    console.log(queries);
    let token = "";
    let mail = "";
    if (queries.token != undefined) {
      token = queries.token;
      mail = queries.email;
      this.setState({ token: token });
      this.setState({ email: mail });
      verifymailfunction(
        token,
        mail,
        (res) => {
          let data = res.data.data;
          this.setState({ termsdetails: data });
          console.log(this.state.termsdetails);
        },
        (err) => {
          console.log(err);
          this.setState({ response: "Error in verifing email" });
          this.setState({ responsetype: "error" });
        }
      );
    }
  }

  render() {
    return (
      <div>
        <HeaderComponent />
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100 text-center">
              <form className="login100-form" style={{ paddingTop: "100px" }}>
                <span className="login100-form-title p-b-43 mb-3 border-bottom">
                  {this.state.token != undefined
                    ? "Email Verification"
                    : "Verification Failed"}
                </span>
                <p className="text-muted fw-slim text-center fs-5 mb-3">
                  {this.state.token != undefined
                    ? "Thank you, Your email has been verify successfully."
                    : ""}
                </p>
                <Link to="/">
                  <span class="btn btn-success">Go Home</span>
                </Link>
                {/* <div className="text-center">
                            <p className="fw-bold">Order Details</p>
                    </div> */}
                {/* <span className="fw-bold">Order Id: {this.state.txn_id} </span><br /> */}
                {/* <span className="fw-bold">Payment Type: </span>Online */}
                {/* <br /> <br /> */}
                {/* <div className="text-center"><button className="btn btn-success text-white" onClick={this.contuneedting}>Continue Editing</button></div> */}
              </form>

              <div
                className="login100-more"
                style={{
                  backgroundImage:
                    "url('" + this.state.baseImgPath + "bg-02.jpg')",
                }}
              ></div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    );
  }
}
export default MailVerificationPage;
