import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import { getallorders } from "../apiserve/ApiComponent";
import TopBarComponent from "./TopBarComponent";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import "./dashboard.css";
import DataTable from "react-data-table-component";
class DashboardCardOrddersComponents extends Component {
  constructor(props) {
    super(props);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.state = {
      allorders: [],
      columnsValue: [
        {
          name: "Client Name",
          selector: (row) => row.cardname,
          sortable: true,
          width: "350px",
        },
        {
          name: "Card Ordered",
          selector: (row) => row.qty,
          sortable: true,
          width: "120px",
        },
        {
          name: "Order Amount",
          selector: (row) => row.amount,
          sortable: true,
          width: "150px",
        },
        {
          name: "Transaction Id",
          selector: (row) => row.txn_id,
          sortable: true,
          width: "150px",
        },
        {
          name: "Date Ordered",
          selector: (row) => row.order_date,
          sortable: true,
        },
        {
          name: "Date Updated",
          selector: (row) => row.updated_date,
          sortable: true,
        },
        {
          name: "Order Status",
          selector: (row) => row.order_status,
          sortable: true,
          width: "120px",
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <Link to={`/admin/orders?id=${row.action}`}>View</Link>
          ),
        },
      ],
      response: "",
      responsetype: "",
      isLoading: false,
    };
    // this.showuserdetails = this.showuserdetails.bind(this);
  }

  getallorders() {
    getallorders(
      (res) => {
        this.setState({ allorders: res.data.data[0] });
        var emptyobj = [];
        for (var i = 0; i < this.state.allorders.length; i++) {
          const obj = {
            id: i + 1,
            fname: this.state.allorders[i].fname,
            lname: this.state.allorders[i].lname,
            cardname: this.state.allorders[i].cardname,
            qty: this.state.allorders[i].qty,
            amount: this.state.allorders[i].amount,
            txn_id: this.state.allorders[i].txn_id,
            order_date: this.state.allorders[i].createddate,
            updated_date: this.state.allorders[i].updateddate,
            order_status:
              this.state.allorders[i].order_status == 0
                ? "Created"
                : this.state.allorders[i].order_status === 1
                ? "Dispatched"
                : this.state.allorders[i].order_status === 2
                ? "Failed"
                : "Delivered",
            action: this.state.allorders[i].txn_id,
          };
          emptyobj.push(obj);
        }
        this.setState({ tableTitle: emptyobj });
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading order list." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      this.getallorders();
    } else {
      window.location.href = "/";
    }
    // this.getdashboarddata();
  }

  render() {
    return (
      <div>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Card Orders</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    {/* <button type="button" className="btn btn-sm btn-outline-secondary">Export</button> */}
                  </div>
                </div>
              </div>

              <DataTable
                columns={this.state.columnsValue}
                data={this.state.tableTitle}
                pagination
              ></DataTable>
              <div className="table-responsive"></div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardCardOrddersComponents;
