import React, { Component } from "react";
import { Button, Form, Image } from "react-bootstrap-v5";
import { getallfooterdetails } from "./apiserve/ApiComponent";
import { fbImage } from "../public/images/facebook.png";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
class FooterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allsitedetails: [{ facebook_url: "https://facebook.com" }],
      //baseImgPath:'http://vcard.nirmalvermicompost.com/API/vcardapi/public/images/',
      baseImgPath: "../public/images/",
    };
  }

  getallfooterdetails() {
    getallfooterdetails(
      (res) => {
        let data = res.data.data[0][0];

        if (data.facebook_url.includes("//")) {
          // console.log(data.facebook_url);
          data.facebook_url = data.facebook_url.substr(
            data.facebook_url.indexOf("//") + 2,
            data.facebook_url.length
          );
        }
        if (data.linkedin_url.includes("//")) {
          // console.log(data.linkedin_url);
          data.linkedin_url = data.linkedin_url.substr(
            data.linkedin_url.indexOf("//") + 2,
            data.linkedin_url.length
          );
          // console.log(data.linkedin_url);
        }
        if (data.twitter_url.includes("//")) {
          // console.log(data.twitter_url);
          data.twitter_url = data.twitter_url.substr(
            data.twitter_url.indexOf("//") + 2,
            data.twitter_url.length
          );
          // console.log(data.twitter_url);
        }
        if (data.instagram_url.includes("//")) {
          // console.log(data.instagram_url);
          data.instagram_url = data.instagram_url.substr(
            data.instagram_url.indexOf("//") + 2,
            data.instagram_url.length
          );
          // console.log(data.instagram_url);
        }
        this.setState({ allsitedetails: data });
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading website data" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    this.getallfooterdetails();
  }

  render() {
    return (
      <div style={{ backgroundColor: "#cacaca" }}>
        {/* // style={{backgroundColor:"#2a2e32"}} */}
        <div className="d-flex flex-column h-100">
          {/* <!-- FOR DEMO PURPOSE --> */}
          {/* <section className="hero text-white py-5 flex-grow-1">
        <div className="container py-4">
            <div className="row">
                <div className="col-lg-6">
                    <h1 className="display-4">Bootstrap footer bottom</h1>
                    <p className="fst-italic text-muted">Using Bootstrap 5 flexbox utilities, create a footer that always sticks to the bottom of your viewport. Snippet by <a className="text-primary" href="#">Card Builder</a></p>
                </div>
            </div>
        </div>
    </section> */}

          {this.props.formdata}
          {/* <!-- FOOTER --> */}
          <footer className="w-100 py-4 flex-shrink-0">
            <div className="container py-4">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  {/* <h5 className="h1 text-white">FB.</h5> */}
                  <Image
                    src={require("../public/images/logo.png")}
                    className="img-responsive"
                  />
                  <div className="clearfix">&nbsp;</div>
                  <p className="small text-muted">
                    Monitor WA Card Ordering Portal
                  </p>
                  <p className="small text-muted mb-0">
                    &copy; Copyright. All Rights Reserved.{" "}
                    <a className="text-primary copy" href="#">
                      <br />
                      <br />
                      Monitor WA Pty Ltd
                    </a>
                  </p>
                </div>
                <div className="col-lg-2 col-md-6">
                  <h5 className=" mb-3 cblue">Quick Links</h5>
                  <ul className="list-unstyled text-muted">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/select-card-templates">Templates</Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://monitorwa.com.au/contact-us-apac-support-solution-sales/",
                        }}
                        target="_blank"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6">
                  <h5 className=" mb-3 cblue">Social Links</h5>
                  <ul className="list-unstyled text-muted">
                    <div className="row">
                      <div className="col-3">
                        <a
                          href={`https://${this.state.allsitedetails.facebook_url}`}
                          className="align-items-center mb-2 col-5 mb-lg-0 text-decoration-none text-center m-0"
                        >
                          <Image
                            src={require("../public/images/facebook.png")}
                            //src={fbImage}
                            width="65%"
                          />
                        </a>
                      </div>
                      <div className="col-3">
                        <a
                          href={`https://${this.state.allsitedetails.linkedin_url}`}
                          className="align-items-center mb-2 col-5 mb-lg-0 text-decoration-none text-center m-0"
                        >
                          <Image
                            src={require("../public/images/linkedin.png")}
                            width="65%"
                          />
                        </a>
                      </div>
                      <div className="col-3">
                        <a
                          href={`https://${this.state.allsitedetails.twitter_url}`}
                          className="align-items-center mb-2 col-5 mb-lg-0 text-decoration-none text-center m-0"
                        >
                          <Image
                            src={require("../public/images/twitter.png")}
                            width="65%"
                          />
                        </a>
                      </div>
                      <div className="col-3">
                        <a
                          href={`https://${this.state.allsitedetails.instagram_url}`}
                          className="align-items-center mb-2 col-5 mb-lg-0 text-decoration-none text-center m-0"
                        >
                          <Image
                            src={require("../public/images/instagram.png")}
                            width="65%"
                          />
                        </a>
                      </div>
                    </div>

                    {/* <li><Link to="/login">Login</Link></li> */}
                    {/* <li><Link to="/signup">Sign Up</Link></li> */}
                    {/* <li><Link to="/checkout">Checkout</Link></li> */}
                    {/* <li><Link to="/cart">Shopping Cart</Link></li> */}
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6">
                  <h5 className=" mb-3 cblue">Address</h5>
                  <p className="small text-muted">
                    {this.state.allsitedetails.address}.
                  </p>
                  <p className="small text-muted">
                    Phone:{" "}
                    <a
                      href={`tel:${this.state.allsitedetails.phone}`}
                      className="text-decoration-none"
                    >
                      {this.state.allsitedetails.phone}
                    </a>
                  </p>
                  <p className="small text-muted">
                    Mail:{" "}
                    <a
                      href={`mailto:${this.state.allsitedetails.mail}`}
                      className="text-decoration-none"
                    >
                      {this.state.allsitedetails.mail}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
        {/* <footer className="page-footer font-small blue pt-4" style={{borderTop:'solid 1px #ccc'}}>
            <div className="container text-center text-md-left">
                <div className="row">
                    <div className="col-md-3 mt-md-0 mt-3">
                        <h5 className="text-uppercase">Footer Content</h5>
                        <p>Here you can use rows and columns to organize your footer content.</p>
                    </div>
        
                 
                    <div className="col-md-3 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="#!">Link 1</a></li>
                            <li><a href="#!">Link 2</a></li>
                            <li><a href="#!">Link 3</a></li>
                            <li><a href="#!">Link 4</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="#!">Link 1</a></li>
                            <li><a href="#!">Link 2</a></li>
                            <li><a href="#!">Link 3</a></li>
                            <li><a href="#!">Link 4</a></li>
                        </ul>
                    </div>
        
                    <div className="col-md-3 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="#!">Link 1</a></li>
                            <li><a href="#!">Link 2</a></li>
                            <li><a href="#!">Link 3</a></li>
                            <li><a href="#!">Link 4</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        
            <div className="footer-copyright text-center py-3">© 2020 Copyright:
                <a href=""> Monitorwa</a>
            </div>
        
        </footer> */}
      </div>
    );
  }
}

export default FooterComponent;
