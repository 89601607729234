import React, { Component, useState } from 'react';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Card, Container,Nav,Image,FormLabel,Carousel} from "react-bootstrap-v5";
class ServicesComponent extends Component{
 
    render() {

        return (
          <div>
     
       
        <Container>
  
        <Row xs={1} md={3} className="g-4">
  {Array.from({ length: 6 }).map((_, idx) => (
    <Col>
      <Card>
        <Card.Img variant="top" src="https://via.placeholder.com/300x150/FFCa0008/ffffff" />
        <Card.Body>
          <Card.Title>Card title</Card.Title>
          <Card.Text>
            This is a longer card with supporting text below as a natural
            lead-in to additional content. This content is a little bit longer.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  ))}
</Row>
<div className="clearfix">&nbsp;</div>
        </Container>
        
          </div>
       

        )
    }
}
export default ServicesComponent;
