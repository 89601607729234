import React, { Component, useState } from 'react';
import SliderComponent from "./SliderComponent";
import HeaderComponent from "./HeaderComponent";
import ServicesComponent from "./ServicesComponent";
import FooterComponent from "./FooterComponent";
import FeaturedComponent from './FeaturedComponent';
class HomePageComponent extends Component{
    componentDidMount() {
        if(localStorage.getItem('savesignup')!=undefined){
            localStorage.removeItem('savesignup');
            localStorage.removeItem('action');
        }
    }
    render() {

        return (
          <div>
              <HeaderComponent/>
              <SliderComponent/>
              <FeaturedComponent/>
              <FooterComponent/>
          </div>
       

        )
    }
}
export default HomePageComponent;
