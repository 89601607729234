import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import {
  getcarddetails,
  usercardAccessControl,
} from "../apiserve/ApiComponent";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav,
  Image,
  FormLabel,
  InputGroup,
  FormControl,
} from "react-bootstrap-v5";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import axios from "axios";
import "./dashboard.css";
import { Resizable } from "re-resizable";
import Draggable, { DraggableCore } from "react-draggable";
class DashboardCardDetailedViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carddetails: [],
      carduserdetails: [],
      usercardbg: [],
      carddata: [],
      card_orientation: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      response: "",
      responsetype: "",
      show: false,
      isLoading: false,
    };
    this.usercardAccessControl = this.usercardAccessControl.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  getcarddetails(id) {
    getcarddetails(
      id,
      (res) => {
        this.setState({ carddetails: res.data.data[0][0] });
        this.state.carddetails.created_at = this.state.carddetails.createdat;
        this.setState({
          usercardbg: res.data.data[0][0].card_preview,
        });
        this.setState({
          carduserdetails: res.data.data[0][0].userdetails,
        });
        this.setState({
          carddata: JSON.parse(res.data.data[0][0].card_data),
        });
        this.setState({
          card_orientation: this.state.carddetails.card_orientation,
        });
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading cards data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  // Accerss control user profile;
  usercardAccessControl() {
    let userfid = { id: "", isActive: "" };
    userfid.id = this.state.carddetails.id;
    if (this.state.carddetails.card_status == 0) {
      userfid.isActive = 1;
    } else {
      userfid.isActive = 0;
    }
    this.setState({ isLoading: true });
    usercardAccessControl(
      userfid,
      (res) => {
        this.setState({ response: "User data updated successfully." });
        this.setState({ responsetype: "success" });
        this.setState({ slow: false });
        setTimeout(function () {
          window.location.href = "?id=" + userfid.id;
        }, 1000);
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading profile data." });
        this.setState({ responsetype: "error" });
      }
    );
  }

  componentDidMount() {
    //if (localStorage.getItem("vcardurole") == 2) {
    let cardid = "";
    let queries = queryString.parse(this.props.location.search);
    if (queries.id != undefined) {
      cardid = queries.id;
    }
    this.getcarddetails(cardid);
    //} else {
    //  window.location.href = "/";
    //}
  }

  render() {
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Card Access</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center mb-4">
              Are you sure you want to change
              <br /> the this cards active status?
            </h5>
            {/* <p className="text-center">
				  Once you delete your card, Card data will be deleted permanently.
				</p> */}
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button size="sm" onClick={this.usercardAccessControl}>
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Cards</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  {/* <div className="btn-group me-2">
            <button type="button" className="btn btn-sm btn-primary btn-outline-secondary text-white">Buy Card</button>
          </div> */}
                  <button
                    type="button"
                    onClick={this.handleShow}
                    className="btn btn-sm btn-success btn-outline-secondary text-white"
                  >
                    {/* <span data-feather="calendar"></span> */}
                    {this.state.carddetails.card_status == 0
                      ? "Inactive"
                      : "Active"}
                  </button>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-md-4 col-sm-12 mb-4">
                    <div className="row">
                      <div className="col-12">
                        <label>Card name:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.carddetails.cardname}
                          readOnly
                        />
                      </div>

                      <div className="col-12">
                        <label>Card created by user:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.carduserdetails}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="clearfix he-1"></div>
                    <div className="row">
                      <div className="col-12">
                        <label>Card created on date:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.carddetails.createdat}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="clearfix he-1"></div>
                    <div className="row">
                      <div className="col-12">
                        <label>Card Design:</label>
                        <input
                          className="form-control"
                          value={this.state.carddetails.cardtemplatename}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col">
                      {this.state.card_orientation != "portrait" ? (
                        <div
                          style={{
                            display: "block",
                            border: "solid 1px blue",
                            borderRadius: "15px",
                          }}
                        >
                          <div
                            id="savefromhere"
                            className="col-lg-12"
                            style={{ background: "#ccc", borderRadius: "15px" }}
                          >
                            <div
                              className="col-lg-12 p-4 backgroundimage"
                              style={{
                                background: `url(https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.usercardbg})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                borderRadius: "15px",
                              }}
                            >
                              <div
                                ref={this.inputRef}
                                id={"cardheight"}
                                className="col-lg-12 cardheight justify-content-center"
                                style={{
                                  height: "100%",
                                  border: "dashed 1px #000",
                                  overflow: "hidden",
                                  position: "relative",
                                  borderRadius: "15px",
                                }}
                              >
                                <div
                                  className="col-12"
                                  style={{ height: "100%" }}
                                >
                                  {this.state.carddata.staffid && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.staffid.position
                                          .x,
                                        y: this.state.carddata.staffid.position
                                          .y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col className="text-center">
                                            <FormLabel
                                              className="m-0 p-0 col py-2 d-block"
                                              id="valuw1"
                                              style={{
                                                color: `${
                                                  this.state.carddata.staffid
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.staffid
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.staffid
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.staffid
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.staffid
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.staffid
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.staffid
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {this.state.carddata.staffid.text}
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.firstname && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.firstname
                                          .position.x,
                                        y: this.state.carddata.firstname
                                          .position.y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col className="text-center">
                                            <FormLabel
                                              className="m-0 p-0 col d-block text-center py-2"
                                              id="valuw1"
                                              style={{
                                                color: `${
                                                  this.state.carddata.firstname
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.firstname
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.firstname
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.firstname
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.firstname
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.firstname
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.firstname
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {
                                                this.state.carddata.firstname
                                                  .text
                                              }
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.lastname && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.lastname.position
                                          .x,
                                        y: this.state.carddata.lastname.position
                                          .y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col className="text-center">
                                            <FormLabel
                                              className="m-0 p-0 col d-block text-center py-2"
                                              style={{
                                                color: `${
                                                  this.state.carddata.lastname
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.lastname
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.lastname
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.lastname
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.lastname
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.lastname
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.lastname
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {
                                                this.state.carddata.lastname
                                                  .text
                                              }
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.position && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.position.position
                                          .x,
                                        y: this.state.carddata.position.position
                                          .y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col>
                                            <FormLabel
                                              className="m-0 p-0 col-12 d-block text-center py-2"
                                              style={{
                                                color: `${
                                                  this.state.carddata.position
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.position
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.position
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.position
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.position
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.position
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.position
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {
                                                this.state.carddata.position
                                                  .text
                                              }
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.phoneno && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.phoneno.position
                                          .x,
                                        y: this.state.carddata.phoneno.position
                                          .y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col>
                                            <FormLabel
                                              className="m-0 p-0 col-12 d-block text-center py-2"
                                              id="valuw0"
                                              style={{
                                                color: `${
                                                  this.state.carddata.phoneno
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.phoneno
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.phoneno
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.phoneno
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.phoneno
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.phoneno
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.phoneno
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {this.state.carddata.phoneno.text}
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.extrafields &&
                                    this.state.carddata.extrafields.map(
                                      (item, i) => (
                                        <Draggable
                                          key={i}
                                          position={{
                                            x: item.position.x,
                                            y: item.position.y,
                                          }}
                                        >
                                          <div>
                                            <Row className="handle">
                                              <div
                                                id="extrafields"
                                                className={i}
                                                style={{
                                                  fontSize: `${
                                                    item.fontSize || "14px"
                                                  }`,
                                                  display: "inline-block",
                                                  color: `${
                                                    item.color || "#000"
                                                  }`,
                                                  fontFamily: `${
                                                    item.fontFamily || "arial"
                                                  }`,
                                                  fontWeight: `${
                                                    item.fontBold
                                                      ? "bold"
                                                      : "normal"
                                                  }`,
                                                  fontStyle: `${
                                                    item.fontItalic
                                                      ? "italic"
                                                      : "normal"
                                                  }`,
                                                  textDecoration: `${
                                                    item.fontUnderline
                                                      ? "underline"
                                                      : "none"
                                                  }`,
                                                }}
                                              >
                                                {item.text}
                                              </div>
                                            </Row>
                                          </div>
                                        </Draggable>
                                      )
                                    )}
                                  {this.state.carddetails.card_logo && (
                                    <div>
                                      <Draggable
                                        position={{
                                          x: this.state.carddata.myuploadedimage
                                            .position.x,
                                          y: this.state.carddata.myuploadedimage
                                            .position.y,
                                        }}
                                      >
                                        <div>
                                          <Row className="handle">
                                            <img
                                              id="myuploadedimage"
                                              src={
                                                this.state.carddetails.card_logo
                                                  ? this.state.carddetails
                                                      .card_logo.file
                                                  : null || ""
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            />
                                            {/* </Resizable> */}
                                          </Row>
                                        </div>
                                      </Draggable>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "block",
                            border: "solid 1px blue",
                            borderRadius: "15px",
                            width: "478px",
                            height: "762px",
                          }}
                        >
                          <div
                            id="savefromhere"
                            className="col-lg-12"
                            style={{
                              background: "#ccc",
                              borderRadius: "15px",
                              width: "478px",
                              height: "762px",
                            }}
                          >
                            <div
                              className="col-lg-12 p-4 backgroundimage"
                              style={{
                                background: `url(https://office.monitorwa.com.au/tccardorderapi/templates/${this.state.usercardbg})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                borderRadius: "15px",
                                width: "478px",
                                height: "762px",
                              }}
                            >
                              <div
                                ref={this.inputRef}
                                id={"cardheight"}
                                className="col-lg-12 cardheight justify-content-center"
                                style={{
                                  height: "100%",
                                  border: "dashed 1px #000",
                                  overflow: "hidden",
                                  position: "relative",
                                  borderRadius: "15px",
                                  width: "478px",
                                  height: "762px",
                                }}
                              >
                                <div
                                  className="col-12"
                                  style={{ height: "100%" }}
                                >
                                  {this.state.carddata.staffid && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.staffid.position
                                          .x,
                                        y: this.state.carddata.staffid.position
                                          .y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col className="text-center">
                                            <FormLabel
                                              className="m-0 p-0 col py-2 d-block"
                                              id="valuw1"
                                              style={{
                                                color: `${
                                                  this.state.carddata.staffid
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.staffid
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.staffid
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.staffid
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.staffid
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.staffid
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.staffid
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {this.state.carddata.staffid.text}
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.firstname && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.firstname
                                          .position.x,
                                        y: this.state.carddata.firstname
                                          .position.y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col className="text-center">
                                            <FormLabel
                                              className="m-0 p-0 col d-block text-center py-2"
                                              id="valuw1"
                                              style={{
                                                color: `${
                                                  this.state.carddata.firstname
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.firstname
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.firstname
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.firstname
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.firstname
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.firstname
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.firstname
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {
                                                this.state.carddata.firstname
                                                  .text
                                              }
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.lastname && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.lastname.position
                                          .x,
                                        y: this.state.carddata.lastname.position
                                          .y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col className="text-center">
                                            <FormLabel
                                              className="m-0 p-0 col d-block text-center py-2"
                                              style={{
                                                color: `${
                                                  this.state.carddata.lastname
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.lastname
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.lastname
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.lastname
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.lastname
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.lastname
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.lastname
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {
                                                this.state.carddata.lastname
                                                  .text
                                              }
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.position && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.position.position
                                          .x,
                                        y: this.state.carddata.position.position
                                          .y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col>
                                            <FormLabel
                                              className="m-0 p-0 col-12 d-block text-center py-2"
                                              style={{
                                                color: `${
                                                  this.state.carddata.position
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.position
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.position
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.position
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.position
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.position
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.position
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {
                                                this.state.carddata.position
                                                  .text
                                              }
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.phoneno && (
                                    <Draggable
                                      position={{
                                        x: this.state.carddata.phoneno.position
                                          .x,
                                        y: this.state.carddata.phoneno.position
                                          .y,
                                      }}
                                    >
                                      <div>
                                        <Row className="handle">
                                          <Col>
                                            <FormLabel
                                              className="m-0 p-0 col-12 d-block text-center py-2"
                                              id="valuw0"
                                              style={{
                                                color: `${
                                                  this.state.carddata.phoneno
                                                    .color || "#000"
                                                }`,
                                                fontWeight: `${
                                                  this.state.carddata.phoneno
                                                    .fontBold
                                                    ? "bold"
                                                    : "normal"
                                                }`,
                                                fontSize: `${
                                                  this.state.carddata.phoneno
                                                    .fontSize || "14px"
                                                }`,
                                                fontStyle: `${
                                                  this.state.carddata.phoneno
                                                    .fontItalic
                                                    ? "italic"
                                                    : "normal"
                                                }`,
                                                textDecoration: `${
                                                  this.state.carddata.phoneno
                                                    .fontUnderline
                                                    ? "underline"
                                                    : "none"
                                                }`,
                                                textAlign: `${
                                                  this.state.carddata.phoneno
                                                    .alignText || "left"
                                                }`,
                                                fontFamily: `${
                                                  this.state.carddata.phoneno
                                                    .fontFamily || "arial"
                                                }`,
                                              }}
                                            >
                                              {this.state.carddata.phoneno.text}
                                            </FormLabel>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Draggable>
                                  )}

                                  {this.state.carddata.extrafields &&
                                    this.state.carddata.extrafields.map(
                                      (item, i) => (
                                        <Draggable
                                          key={i}
                                          position={{
                                            x: item.position.x,
                                            y: item.position.y,
                                          }}
                                        >
                                          <div>
                                            <Row className="handle">
                                              <div
                                                id="extrafields"
                                                className={i}
                                                style={{
                                                  fontSize: `${
                                                    item.fontSize || "14px"
                                                  }`,
                                                  display: "inline-block",
                                                  color: `${
                                                    item.color || "#000"
                                                  }`,
                                                  fontFamily: `${
                                                    item.fontFamily || "arial"
                                                  }`,
                                                  fontWeight: `${
                                                    item.fontBold
                                                      ? "bold"
                                                      : "normal"
                                                  }`,
                                                  fontStyle: `${
                                                    item.fontItalic
                                                      ? "italic"
                                                      : "normal"
                                                  }`,
                                                  textDecoration: `${
                                                    item.fontUnderline
                                                      ? "underline"
                                                      : "none"
                                                  }`,
                                                }}
                                              >
                                                {item.text}
                                              </div>
                                            </Row>
                                          </div>
                                        </Draggable>
                                      )
                                    )}
                                  {this.state.carddetails.card_logo && (
                                    <div>
                                      <Draggable
                                        position={{
                                          x: this.state.carddata.myuploadedimage
                                            .position.x,
                                          y: this.state.carddata.myuploadedimage
                                            .position.y,
                                        }}
                                      >
                                        <div>
                                          <Row className="handle">
                                            <img
                                              id="myuploadedimage"
                                              src={
                                                this.state.carddetails.card_logo
                                                  ? this.state.carddetails
                                                      .card_logo.file
                                                  : null || ""
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            />
                                            {/* </Resizable> */}
                                          </Row>
                                        </div>
                                      </Draggable>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardCardDetailedViewComponent;
