import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import {
  getordersdetails,
  updateorderstatus,
  getorderlines,
  getcarddetails,
} from "../apiserve/ApiComponent";
import TopBarComponent from "./TopBarComponent";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";
import { Modal, Row, Button, Col } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import "./dashboard.css";
import DataTable from "react-data-table-component";
import { jsPDF } from "jspdf";
import { Image } from "react-bootstrap";

class DashboardOrderDetailsComponents extends Component {
  constructor(props) {
    super(props);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.state = {
      allorders: [],
      response: "",
      orderdetails: [],
      singleorder: [],
      deliveryA: [],
      columnsValue: [
        {
          name: "Card Preview",
          // selector: row=> row.fname +" "+ row.lname,
          // sortable: true,
          cell: (row) =>
            row.card_orientation != "portrait" ? (
              <img
                src={this.state.baseImgPath + row.card_preview}
                style={{ width: "150px" }}
              />
            ) : (
              <img
                src={this.state.baseImgPath + row.card_preview}
                style={{ width: "100px" }}
              />
            ),
        },
        {
          name: "Card Qantity",
          selector: (row) => row.qty,
          // sortable: true,
        },
        {
          name: "Card Amount",
          selector: (row) => row.amount,
          // sortable: true,
        },
        {
          name: "Total Amount",
          selector: (row) => row.total_amount,
          sortable: true,
        },
        {
          name: "Order Status",
          selector: (row) => row.order_status,
          sortable: true,
        },
        {
          name: "",
          button: true,
          cell: (row) => (
            <Button
              className="btn btn-success"
              value={row.id}
              onClick={(e) => this.loadPrintCard(row.card_id, row.qty)}
            >
              Print
            </Button>
            //<Link to={`/admin/orders?id=${row.action}`}>Print</Link>
          ),
        },
      ],
      responsetype: "",
      trx_id: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      show: false,
      isLoading: false,
    };
    // this.showuserdetails = this.showuserdetails.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updateorderstatus = this.updateorderstatus.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleUserInput(e) {
    const value = e.target.value;
    let updatedata = value;
    this.setState({ categoryname: updatedata });
  }

  loadPrintCard(cardID, quantity) {
    window.location.href =
      "/admin/printcard?CardID=" +
      cardID +
      "&Amount=" +
      quantity +
      "&TransID=" +
      this.state.trx_id;
  }

  getordersdetails(id) {
    getordersdetails(
      id,
      (res) => {
        this.setState({ orderdetails: res.data.data[0][0] });
        this.setState({ singleorder: res.data.data[0][0] });
        this.state.deliveryA.Street1 = res.data.data[0][0].Street1;
        this.state.deliveryA.Street2 = res.data.data[0][0].Street2;
        this.state.deliveryA.PostalCode = res.data.data[0][0].PostalCode;
        this.state.deliveryA.State = res.data.data[0][0].state;
        getorderlines(id);
      },
      (err) => {
        this.setState({ response: "Error while getting order details" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  getorderlines(id) {
    getorderlines(
      id,
      (res) => {
        var emptyobj = [];
        var orderlines = res.data.data[0];
        for (var i = 0; i < orderlines.length; i++) {
          const obj = {
            id: orderlines[i].id,
            card_preview: orderlines[i].card_preview,
            qty: orderlines[i].qty,
            amount: orderlines[i].amount,
            total_amount: orderlines[i].total_amount,
            order_status:
              orderlines[i].order_status == 0
                ? "Created"
                : orderlines[i].order_status == 1
                ? "Success"
                : orderlines[i].order_status == 2
                ? "Printed"
                : "Delivered",
            card_id: orderlines[i].cardid,
            cardname: orderlines[i].cardname,
          };
          emptyobj.push(obj);
        }
        this.setState({ tableTitle: emptyobj });
      },
      (err) => {
        this.setState({ response: "Error while getting order details" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  dispatchOrder() {
    var imgData = this.getBase64Image(document.getElementById("monitorLogo"));
    //   "data:image/jpeg;base64," +
    //   base64.encode(this.state.baseImgPath + "MWA.jpeg");
    const doc = new jsPDF("p", "mm", "a4");
    doc.setProperties({
      title: `Dispatch for ${this.state.orderdetails.fname} ${this.state.orderdetails.lname} Transaction ID:${this.state.orderdetails.txn_id}`,
      subject: `Dispatch document for card order transaction number - ${this.state.orderdetails.txn_id}`,
      author: "Monitor WA - IDCards",
      keywords: "generated, javascript, web 2.0, ajax",
      creator: "Monitor WA",
    });
    doc.setFontSize(16);
    doc.addImage(imgData, "JPEG", 10, 20, 125, 20);
    doc.text(`Dispatch Advice`, 160, 45);
    doc.line(10, 50, 202, 50, "F");
    doc.setFontSize(12);
    doc.text(`Customer Details`, 110, 58);
    doc.setFontSize(12);
    doc.text(
      `${this.state.orderdetails.fname} ${this.state.orderdetails.lname}`,
      125,
      65
    );
    doc.text(`Address`, 125, 70);
    doc.text(`${this.state.orderdetails.address1}`, 130, 75);
    console.log(this.state.orderdetails.address2);
    if (
      this.state.orderdetails.address2 != "" &&
      this.state.orderdetails.address2 !== null
    ) {
      doc.text(`${this.state.orderdetails.address2}`, 130, 80);
      doc.text(
        `${this.state.orderdetails.area}, ${this.state.orderdetails.state} ${this.state.orderdetails.postcode}`,
        130,
        85
      );
    } else {
      doc.text(
        `${this.state.orderdetails.area}, ${this.state.orderdetails.state} ${this.state.orderdetails.postcode}`,
        130,
        80
      );
    }

    doc.text(`Phone/Mobile  ${this.state.orderdetails.phoneno}`, 15, 90);
    doc.text(`Email  ${this.state.orderdetails.email}`, 15, 95);

    //Shipping Details
    doc.setFontSize(12);
    doc.text(`Shipping Details`, 10, 58);
    doc.setFontSize(12);
    doc.text(
      `${this.state.orderdetails.fname} ${this.state.orderdetails.lname}`,
      27,
      65
    );
    doc.text(`Address`, 27, 70);
    doc.text(`${this.state.orderdetails.address1}`, 32, 75);
    if (
      this.state.orderdetails.Street2 != "" &&
      this.state.orderdetails.Street2 != null
    ) {
      doc.text(
        `${this.state.orderdetails.Street2} ${this.state.orderdetails.postcode}`,
        32,
        80
      );
    } else {
      doc.text(
        `${this.state.orderdetails.area}, ${this.state.orderdetails.state} ${this.state.orderdetails.postcode}`,
        32,
        80
      );
    }

    doc.setFontSize(10);
    doc.text(`Transaction #  ${this.state.orderdetails.txn_id}`, 160, 95);

    doc.line(10, 102, 202, 102, "F");
    var header = [
      {
        name: "cardName",
        prompt: "Card Name",
        width: 105,
        align: "left",
        padding: 0,
      },
      {
        name: "quantity",
        prompt: "Quantity",
        width: 50,
        align: "center",
        padding: 0,
      },
      {
        name: "amount",
        prompt: "Card Amount",
        width: 50,
        align: "right",
        padding: 0,
      },
      {
        name: "totalAmount",
        prompt: "Total Amount",
        width: 50,
        align: "right",
        padding: 0,
      },
    ];

    let aData = [];
    var oTotal = 0;
    for (var i = 0; i < this.state.tableTitle.length; i++) {
      const dataObj = {
        cardName: this.state.tableTitle[i].cardname,
        quantity: this.state.tableTitle[i].qty.toString(),
        amount: this.state.tableTitle[i].amount,
        totalAmount: this.state.tableTitle[i].total_amount,
      };
      oTotal =
        oTotal +
        Number(this.state.tableTitle[i].total_amount.replace(/[^0-9.-]+/g, ""));
      aData.push(dataObj);
    }

    const dataObj = {
      cardName: " ",
      quantity: " ",
      amount: "Total",
      totalAmount: this.numberFormat(oTotal),
    };
    aData.push(dataObj);
    doc.table(10, 110, aData, header);

    //Footer
    doc.line(10, 285, 202, 285, "F");
    doc.setFontSize(10);
    doc.text(
      "Monitor WA  -  Unit 2/17 Macadam Pl Balcatta WA 6021  -  08 9240 4777  -  support@monitorwa.com.au",
      20,
      290
    );

    //Card Lines
    doc.save(
      `Dispatch for ${this.state.orderdetails.fname} ${this.state.orderdetails.lname} Transaction ID:${this.state.orderdetails.txn_id}.pdf`
    );
    this.updateorderstatus(1);
  }

  numberFormat = (value) =>
    new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
    }).format(value);

  updateorderstatus(orderStatus) {
    const formData = new FormData();
    formData.append("txn_id", this.state.trx_id);
    formData.append("order_status", orderStatus);
    this.setState({ isLoading: true });
    updateorderstatus(
      formData,
      (res) => {
        this.setState({ isLoading: false });
        this.setState({ show: false });
        this.setState({ response: "Order status updated." });
        this.setState({ responsetype: "success" });
        setTimeout(function () {
          window.location.href = "/admin/card-orders";
        }, 1000);
      },
      (err) => {
        this.setState({ isLoading: false });
        this.setState({ show: false });
        this.setState({ response: "Error while updating order status" });
        this.setState({ responsetype: "error" });
      }
    );
  }

  handleOnSubmit(e) {
    e.preventDefault();
    let catname = { name: "" };
    console.log(catname);
    catname.name = this.state.categoryname;
    if (catname == "" || undefined) {
      this.setState({ response: "Please enter category name" });
      this.setState({ responsetype: "error" });
    } else {
      this.setState({ isLoading: true });
    }
  }
  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      let cardid = "";
      let queries = queryString.parse(this.props.location.search);
      if (queries.id != undefined) {
        cardid = queries.id;
      }
      this.setState({ trx_id: queries.id });
      this.getordersdetails(cardid);
      this.getorderlines(cardid);
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <Toast
          onClose={() => this.setState({ response: "" })}
          show={this.state.response != "" ? true : false}
          className={
            this.state.responsetype == "success" ? "bg-success" : "bg-danger"
          }
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.response}</Toast.Body>
        </Toast>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Status Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-center mb-4">
              Are you sure, You want change status to delivered.
            </h5>
            {/* <p className="text-center">
				  Once you delete your card, Card data will be deleted permanently.
				</p> */}
            <Row
              style={{ display: `${!this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col style={{ textAlign: "right" }}>
                <Button
                  size="sm"
                  onClick={() => {
                    this.updateorderstatus(4);
                  }}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button size="sm" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row
              style={{ display: `${this.state.isLoading ? "flex" : "none"}` }}
            >
              <Col></Col>
              <Col>
                <span
                  className="login100-form-btn"
                  style={{
                    display: `${this.state.isLoading ? "block" : "none"}`,
                    textAlign: "center",
                    padding: "8px 15px",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </Modal>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5>Transaction ID: {this.state.trx_id}</h5>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-success btn-outline-secondary text-white"
                      onClick={() => {
                        this.dispatchOrder();
                      }}
                    >
                      Dispatch Order
                    </button>
                    {/* <p><span className="fw-b">Transaction ID: </span>{this.state.trx_id}</p> */}
                  </div>
                  {this.state.singleorder && (
                    <div
                      className="btn-group me-2"
                      style={{
                        display: `${
                          this.state.singleorder.order_status == 1
                            ? "block"
                            : "none"
                        }`,
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-success btn-outline-secondary text-white"
                        onClick={this.handleShow}
                      >
                        Update Status
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="container rounded bg-white m-1">
                <h4>User Details</h4>
                <div className="table-responsive">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone No.</th>
                        <th scope="col">Current Address</th>
                        <th scope="col">Shipping Address</th>
                      </tr>
                    </thead>
                    {this.state.singleorder && (
                      <tbody>
                        <tr>
                          <td>
                            {this.state.singleorder.fname}{" "}
                            {this.state.singleorder.lname}
                          </td>
                          <td>{this.state.singleorder.email}</td>
                          <td>{this.state.singleorder.phoneno}</td>
                          <td>
                            {this.state.singleorder.address1}, <br />{" "}
                            {this.state.singleorder.address2}
                            {this.state.singleorder.area},{" "}
                            {this.state.singleorder.state}-
                            {this.state.singleorder.postcode}
                          </td>
                          <td>
                            {this.state.deliveryA.Street1},<br />{" "}
                            {this.state.deliveryA.Street2}-
                            {this.state.deliveryA.PostalCode}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
                <h4 className="mt-4">Order Card Details</h4>
                <DataTable
                  columns={this.state.columnsValue}
                  data={this.state.tableTitle}
                  pagination
                ></DataTable>
                <div className="table-responsive"></div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardOrderDetailsComponents;
