import React, { Component } from 'react';
import {Button,InputGroup,FormControl,FormLabel,Row,Col,Form } from "react-bootstrap-v5";
import * as Icon from 'react-bootstrap-icons';

class MoreComponent extends Component{
    render() {
        return (
       
        <div>
        <div className="clearfix">&nbsp;</div>
        <FormLabel className="label1">More Options</FormLabel>
        <div className="clearfix"></div>
        <a href="#" style={{fontSize:"12px"}}  className="cblue">View upload specs and templates</a>
        <div className="clearfix">&nbsp;</div>
        <Row>
          <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-border-all" viewBox="0 0 16 16">
  <path d="M0 0h16v16H0V0zm1 1v6.5h6.5V1H1zm7.5 0v6.5H15V1H8.5zM15 8.5H8.5V15H15V8.5zM7.5 15V8.5H1V15h6.5z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>Table</FormLabel>
</div>
          </Col>

          <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upc-scan" viewBox="0 0 16 16">
  <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>QR Code</FormLabel>
</div>
          </Col>

          <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>Arrow</FormLabel>
</div>
          </Col>
        </Row>

        <Row>
          <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-square" viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>Rectangle</FormLabel>
</div>
          </Col>

          <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>Oval</FormLabel>
</div>
          </Col>

          <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-distribute-horizontal" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M14.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5zm-13 0a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z"/>
  <path d="M6 13a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v10z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>Line</FormLabel>
</div>
          </Col>
        </Row>

        <Row>
          <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>My Computer</FormLabel>
    <Form.Group controlId="formFile" className="mb-3">
    <Form.Control type="file"/>
  </Form.Group>

</div>
          </Col>

          <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
  <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z"/>
  <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>Previous Uploads</FormLabel>
</div>
          </Col>

          <Col>
          <div className="d-grid gap-2">
  <Button variant="light" size="md" className="p-4" style={{border:"1px solid #c1c4c5"}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
  </Button>
  <FormLabel style={{fontSize:"10px"}}>Image Library</FormLabel>
</div>
          </Col>
        </Row>

 
<div className="clearfix">&nbsp;</div>


<hr></hr>
<FormLabel className="label1">Design Colours</FormLabel>
<div className="clearfix">&nbsp;</div>
<Row>
          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#4B4A4B",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#384557",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#AAA8AC",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#D3AB0D",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#021F45",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>


          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#999B9B",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

        
        </Row>
        <Row>
          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#044385",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#2F4154",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#8B0B1A",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#9F9F9F",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#022F45",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>


          <Col className="p-1">
            <div className="d-grid gap-2">
              <Button variant="light" size="sm" className="p-1" style={{border:"1px solid #c1c4c5",background:"#993B9B",borderRadius:"30px",height:"30px",width:"30px"}}>&nbsp;</Button>
            </div>
          </Col>

        
        </Row>

        
        

</div>
       

        )
    }
}
export default MoreComponent;