import React, { Component, useState } from 'react';
import {Button,InputGroup,FormControl,FormLabel, Row,Col,Form} from "react-bootstrap-v5";
import * as Icon from 'react-bootstrap-icons';
import { FormErrors } from '../FormErrors';

class FieldFontsDropdownComponent extends Component{
  constructor (props) {
    super(props);
    }
   

    render() {
      
        return (
          <div>
           
    <Row>
      <Col className="font-weight-bold"><FormLabel className="label1 py-2">Font:</FormLabel></Col>
      <Col>
      <Form.Select>
      <option value="Arial">Arial</option>
<option value="Arial Italic">Arial Italic</option>
<option value="Arial Bold">Arial Bold</option>
<option value="Arial Bold Italic">Arial Bold Italic</option>
<option value="Arial Black">Arial Black</option>
<option value="Bahnschrift ">Bahnschrift </option>
<option value="Calibri Light">Calibri Light</option>
<option value="Calibri Light Italic">Calibri Light Italic</option>
<option value="Calibri">Calibri</option>
<option value="Calibri Italic">Calibri Italic</option>
<option value="Calibri Bold">Calibri Bold</option>
<option value="Calibri Bold Italic">Calibri Bold Italic</option>
<option value="Cambria">Cambria</option>
<option value="Cambria Italic">Cambria Italic</option>
<option value="Cambria Bold">Cambria Bold</option>
<option value="Cambria Bold Italic">Cambria Bold Italic</option>
<option value="Cambria Math">Cambria Math</option>
<option value="Candara Light ">Candara Light </option>
<option value="Candara Light Italic ">Candara Light Italic </option>
<option value="Candara">Candara</option>
<option value="Candara Italic">Candara Italic</option>
<option value="Candara Bold">Candara Bold</option>
<option value="Candara Bold Italic">Candara Bold Italic</option>
<option value="Comic Sans MS">Comic Sans MS</option>
<option value="Comic Sans MS Italic">Comic Sans MS Italic</option>
<option value="Comic Sans MS Bold">Comic Sans MS Bold</option>
<option value="Comic Sans MS Bold Italic">Comic Sans MS Bold Italic</option>
<option value="Consolas">Consolas</option>
<option value="Consolas Italic">Consolas Italic</option>
<option value="Consolas Bold">Consolas Bold</option>
<option value="Consolas Bold Italic">Consolas Bold Italic</option>
<option value="Constantia">Constantia</option>
<option value="Constantia Italic">Constantia Italic</option>
<option value="Constantia Bold">Constantia Bold</option>
<option value="Constantia Bold Italic">Constantia Bold Italic</option>
<option value="Corbel Light ">Corbel Light </option>
<option value="Corbel Light Italic ">Corbel Light Italic </option>
<option value="Corbel">Corbel</option>
<option value="Corbel Italic">Corbel Italic</option>
<option value="Corbel Bold">Corbel Bold</option>
<option value="Corbel Bold Italic">Corbel Bold Italic</option>
<option value="Courier New">Courier New</option>
<option value="Courier New Italic">Courier New Italic</option>
<option value="Courier New Bold">Courier New Bold</option>
<option value="Courier New Bold Italic">Courier New Bold Italic</option>
<option value="Ebrima">Ebrima</option>
<option value="Ebrima Bold">Ebrima Bold</option>
<option value="Franklin Gothic Medium">Franklin Gothic Medium</option>
<option value="Franklin Gothic Medium Italic">Franklin Gothic Medium Italic</option>
<option value="Gabriola">Gabriola</option>
<option value="Gadugi">Gadugi</option>
<option value="Gadugi Bold">Gadugi Bold</option>
<option value="Georgia">Georgia</option>
<option value="Georgia Italic">Georgia Italic</option>
<option value="Georgia Bold">Georgia Bold</option>
<option value="Georgia Bold Italic">Georgia Bold Italic</option>
<option value="HoloLens MDL2 Assets ">HoloLens MDL2 Assets </option>
<option value="Impact">Impact</option>
<option value="Ink Free ">Ink Free </option>
<option value="Javanese Text">Javanese Text</option>
<option value="Leelawadee UI">Leelawadee UI</option>
<option value="Leelawadee UI Semilight">Leelawadee UI Semilight</option>
<option value="Leelawadee UI Bold">Leelawadee UI Bold</option>
<option value="Lucida Console">Lucida Console</option>
<option value="Lucida Sans Unicode">Lucida Sans Unicode</option>
<option value="Malgun Gothic">Malgun Gothic</option>
<option value="Malgun Gothic Bold">Malgun Gothic Bold</option>
<option value="Malgun Gothic Semilight ">Malgun Gothic Semilight </option>
<option value="Marlett">Marlett</option>
<option value="Microsoft Himalaya">Microsoft Himalaya</option>
<option value="Microsoft JhengHei Light">Microsoft JhengHei Light</option>
<option value="Microsoft JhengHei">Microsoft JhengHei</option>
<option value="Microsoft JhengHei Bold">Microsoft JhengHei Bold</option>
<option value="Microsoft JhengHei UI Light">Microsoft JhengHei UI Light</option>
<option value="Microsoft JhengHei UI">Microsoft JhengHei UI</option>
<option value="Microsoft JhengHei UI Bold">Microsoft JhengHei UI Bold</option>
<option value="Microsoft New Tai Lue">Microsoft New Tai Lue</option>
<option value="Microsoft New Tai Lue Bold">Microsoft New Tai Lue Bold</option>
<option value="Microsoft PhagsPa">Microsoft PhagsPa</option>
<option value="Microsoft PhagsPa Bold">Microsoft PhagsPa Bold</option>
<option value="Microsoft Sans Serif">Microsoft Sans Serif</option>
<option value="Microsoft Tai Le">Microsoft Tai Le</option>
<option value="Microsoft Tai Le Bold">Microsoft Tai Le Bold</option>
<option value="Microsoft YaHei Light">Microsoft YaHei Light</option>
<option value="Microsoft YaHei">Microsoft YaHei</option>
<option value="Microsoft YaHei Bold">Microsoft YaHei Bold</option>
<option value="Microsoft YaHei UI Light">Microsoft YaHei UI Light</option>
<option value="Microsoft YaHei UI">Microsoft YaHei UI</option>
<option value="Microsoft YaHei UI Bold">Microsoft YaHei UI Bold</option>
<option value="Microsoft Yi Baiti">Microsoft Yi Baiti</option>
<option value="MingLiU-ExtB">MingLiU-ExtB</option>
<option value="PMingLiU-ExtB">PMingLiU-ExtB</option>
<option value="MingLiU_HKSCS-ExtB">MingLiU_HKSCS-ExtB</option>
<option value="Mongolian Baiti">Mongolian Baiti</option>
<option value="MS Gothic">MS Gothic</option>
<option value="MS PGothic">MS PGothic</option>
<option value="MS UI Gothic">MS UI Gothic</option>
<option value="MV Boli">MV Boli</option>
<option value="Myanmar Text">Myanmar Text</option>
<option value="Myanmar Text Bold">Myanmar Text Bold</option>
<option value="Nirmala UI Semilight">Nirmala UI Semilight</option>
<option value="Nirmala UI">Nirmala UI</option>
<option value="Nirmala UI Bold">Nirmala UI Bold</option>
<option value="Palatino Linotype">Palatino Linotype</option>
<option value="Palatino Linotype Italic">Palatino Linotype Italic</option>
<option value="Palatino Linotype Bold">Palatino Linotype Bold</option>
<option value="Palatino Linotype Bold Italic">Palatino Linotype Bold Italic</option>
<option value="Segoe MDL2 Assets ">Segoe MDL2 Assets </option>
<option value="Segoe Print">Segoe Print</option>
<option value="Segoe Print Bold">Segoe Print Bold</option>
<option value="Segoe Script">Segoe Script</option>
<option value="Segoe Script Bold">Segoe Script Bold</option>
<option value="Segoe UI Light">Segoe UI Light</option>
<option value="Segoe UI Light Italic">Segoe UI Light Italic</option>
<option value="Segoe UI Semilight">Segoe UI Semilight</option>
<option value="Segoe UI Semilight Italic">Segoe UI Semilight Italic</option>
<option value="Segoe UI">Segoe UI</option>
<option value="Segoe UI Italic">Segoe UI Italic</option>
<option value="Segoe UI Semibold">Segoe UI Semibold</option>
<option value="Segoe UI Semibold Italic">Segoe UI Semibold Italic</option>
<option value="Segoe UI Bold">Segoe UI Bold</option>
<option value="Segoe UI Bold Italic">Segoe UI Bold Italic</option>
<option value="Segoe UI Black">Segoe UI Black</option>
<option value="Segoe UI Black Italic">Segoe UI Black Italic</option>
<option value="Segoe UI Historic ">Segoe UI Historic </option>
<option value="Segoe UI Emoji">Segoe UI Emoji</option>
<option value="Segoe UI Symbol">Segoe UI Symbol</option>
<option value="SimSun">SimSun</option>
<option value="NSimSun">NSimSun</option>
<option value="SimSun-ExtB">SimSun-ExtB</option>
<option value="Sitka Small">Sitka Small</option>
<option value="Sitka Small Italic">Sitka Small Italic</option>
<option value="Sitka Small Bold">Sitka Small Bold</option>
<option value="Sitka Small Bold Italic">Sitka Small Bold Italic</option>
<option value="Sitka Text">Sitka Text</option>
<option value="Sitka Text Italic">Sitka Text Italic</option>
<option value="Sitka Text Bold">Sitka Text Bold</option>
<option value="Sitka Text Bold Italic">Sitka Text Bold Italic</option>
<option value="Sitka Subheading">Sitka Subheading</option>
<option value="Sitka Subheading Italic">Sitka Subheading Italic</option>
<option value="Sitka Subheading Bold">Sitka Subheading Bold</option>
<option value="Sitka Subheading Bold Italic">Sitka Subheading Bold Italic</option>
<option value="Sitka Heading">Sitka Heading</option>
<option value="Sitka Heading Italic">Sitka Heading Italic</option>
<option value="Sitka Heading Bold">Sitka Heading Bold</option>
<option value="Sitka Heading Bold Italic">Sitka Heading Bold Italic</option>
<option value="Sitka Display">Sitka Display</option>
<option value="Sitka Display Italic">Sitka Display Italic</option>
<option value="Sitka Display Bold">Sitka Display Bold</option>
<option value="Sitka Display Bold Italic">Sitka Display Bold Italic</option>
<option value="Sitka Banner">Sitka Banner</option>
<option value="Sitka Banner Italic">Sitka Banner Italic</option>
<option value="Sitka Banner Bold">Sitka Banner Bold</option>
<option value="Sitka Banner Bold Italic">Sitka Banner Bold Italic</option>
<option value="Sylfaen">Sylfaen</option>
<option value="Symbol">Symbol</option>
<option value="Tahoma">Tahoma</option>
<option value="Tahoma Bold">Tahoma Bold</option>
<option value="Times New Roman">Times New Roman</option>
<option value="Times New Roman Italic">Times New Roman Italic</option>
<option value="Times New Roman Bold">Times New Roman Bold</option>
<option value="Times New Roman Bold Italic">Times New Roman Bold Italic</option>
<option value="Trebuchet MS">Trebuchet MS</option>
<option value="Trebuchet MS Italic">Trebuchet MS Italic</option>
<option value="Trebuchet MS Bold">Trebuchet MS Bold</option>
<option value="Trebuchet MS Bold Italic">Trebuchet MS Bold Italic</option>
<option value="Verdana">Verdana</option>
<option value="Verdana Italic">Verdana Italic</option>
<option value="Verdana Bold">Verdana Bold</option>
<option value="Verdana Bold Italic">Verdana Bold Italic</option>
<option value="Webdings">Webdings</option>
<option value="Wingdings">Wingdings</option>
<option value="Yu Gothic Light">Yu Gothic Light</option>
<option value="Yu Gothic Regular">Yu Gothic Regular</option>
<option value="Yu Gothic Medium ">Yu Gothic Medium </option>
<option value="Yu Gothic Bold">Yu Gothic Bold</option>
<option value="Yu Gothic UI Light ">Yu Gothic UI Light </option>
<option value="Yu Gothic UI Semilight ">Yu Gothic UI Semilight </option>
<option value="Yu Gothic UI Regular ">Yu Gothic UI Regular </option>
<option value="Yu Gothic UI Semibold ">Yu Gothic UI Semibold </option>
<option value="Yu Gothic UI Bold ">Yu Gothic UI Bold </option>
  </Form.Select>
      </Col>
    </Row>
    <div className="clearfix he-1">&nbsp;</div>
    {/* <Row>
      <Col className="font-weight-bold"><FormLabel className="label1 py-2">Size:</FormLabel></Col>
      <Col>
      <div className="d-grid gap-1">
<InputGroup className= {`mb-0 form-group`}>
    <FormControl
      
      aria-label="Size"
      aria-describedby="basic-addon2"
      name="size" className="form-control" placeholder="Size" />
  </InputGroup>
  <div className="clearfix he-1">&nbsp;</div>
</div>
      </Col>
    </Row> */}

          </div>

        )
    }
}
export default FieldFontsDropdownComponent;
