import React, { Component, useState } from "react";
import SideBarComponent from "./SideBarComponent";
import TopBarComponent from "./TopBarComponent";
import { getallcardsteamplate } from "../apiserve/ApiComponent";
import {
  BrowserRouter,
  Route,
  withRouter,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import DataTable from "react-data-table-component";
import "./dashboard.css";
class DashboardComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allcardsteamplate: [],
      columnsValue: [
        {
          name: "Design Name",
          selector: (row) => row.cardtemplatename,
          sortable: true,
        },
        {
          name: "Card Front",
          // selector: row=> row.card_preview,
          // sortable: true,.
          cell: (row) =>
            row.card_orientation != "portrait" ? (
              <img
                src={this.state.baseImgPath + row.cardbgimage}
                style={{ width: "100px", height: "60px" }}
              />
            ) : (
              <img
                src={this.state.baseImgPath + row.cardbgimage}
                style={{ width: "60px", height: "100px" }}
              />
            ),
        },
        {
          name: "Card Back",
          // selector: row=> row.card_preview_back,
          // sortable: true,
          cell: (row) =>
            row.card_orientation != "portrait" ? (
              <img
                src={this.state.baseImgPath + row.card_back_image}
                style={{ width: "100px", height: "60px" }}
              />
            ) : (
              <img
                src={this.state.baseImgPath + row.card_back_image}
                style={{ width: "60px", height: "100px" }}
              />
            ),
        },
        {
          name: "Created Date",
          selector: (row) => row.created_at,
          sortable: true,
        },
        {
          name: "Last Update",
          selector: (row) => row.updated_at,
          sortable: true,
        },
        {
          name: "Cost",
          selector: (row) => row.amount,
          sortable: true,
        },
        {
          name: "Card Status",
          selector: (row) => row.cardstatus,
          sortable: true,
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <Link to={`/admin/templatedetails?id=${row.action}`}>View</Link>
          ),
        },
      ],
      response: "",
      responsetype: "",
      baseImgPath: "https://office.monitorwa.com.au/tccardorderapi/templates/",
      isLoading: false,
    };
  }
  getallcardsteamplate() {
    getallcardsteamplate(
      (res) => {
        this.setState({ allcardsteamplate: res.data.data[0] });
        var created_date;
        var updated_date;
        var emptyobj = [];
        for (let i = 0; i < this.state.allcardsteamplate.length; i++) {
          const obj = {
            id: i + 1,
            cardtemplatename: this.state.allcardsteamplate[i].cardtemplatename,
            cardbgimage: this.state.allcardsteamplate[i].cardbgimage,
            card_back_image: this.state.allcardsteamplate[i].card_back_image,
            created_at: this.state.allcardsteamplate[i].createddisplay,
            updated_at: this.state.allcardsteamplate[i].updateddisplay,
            amount: this.state.allcardsteamplate[i].amount,
            card_orientation: this.state.allcardsteamplate[i].card_orientation,
            cardstatus:
              this.state.allcardsteamplate[i].cardstatus == 1
                ? "Active"
                : "Inactive",
            action: this.state.allcardsteamplate[i].id,
          };
          emptyobj.push(obj);
        }
        this.setState(this.state.allcardsteamplate);
        this.setState({ tableTitle: emptyobj });
      },
      (err) => {
        console.log(err);
        this.setState({ response: "Error in loading cards data." });
        this.setState({ responsetype: "error" });
      }
    );
  }
  componentDidMount() {
    if (localStorage.getItem("vcardurole") != 2) {
      this.getallcardsteamplate();
    } else {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <div>
        <TopBarComponent />
        <div className="container-fluid">
          <div className="row">
            <SideBarComponent />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Card Templates</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  {/* <div className="btn-group me-2">
            <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
            <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
          </div> */}
                  <Link to={`/admin/createtemplate`}>
                    <button
                      type="button"
                      className="btn btn-primary text-white btn-sm btn-outline-secondary"
                    >
                      {/* <span data-feather="calendar"></span> */}
                      Create Template
                    </button>
                  </Link>
                </div>
              </div>
              <DataTable
                columns={this.state.columnsValue}
                data={this.state.tableTitle}
                pagination
              ></DataTable>
              <div className="table-responsive">
                {/* <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Design Name</th>
              <th scope="col">Cards Front</th>
              <th scope="col">Cards Back</th>              
              <th scope="col">Created At</th>
              <th scope="col">Updated At</th>
              <th scope="col">Cost</th>
              <th scope="col">Card Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
          {
   this.state.allcardsteamplate.map(function(item, i){    
    return (
    <tr key={i}>
    <td>{i+1}</td>
    <td>{item.cardtemplatename}</td>
    <td><img src={this.state.baseImgPath + item.cardbgimage} style={{width:"100px",height:"60px"}} /></td>
    <td><img src={this.state.baseImgPath + item.card_back_image} style={{width:"100px",height:"60px"}} /></td>
    <td>{item.created_at}</td>
    <td>{item.updated_at}</td>
    <td>{item.amount>0?item.amount:'Free'}</td>
    <td>{(item.cardstatus=1)? 'Active':'Inactive'}</td>
    <td>
      <Link to={`/admin/templatedetails?id=${item.id}`}>View Template</Link>
    </td>
  </tr>);
  },this)}
          </tbody>
        </table> */}
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardComponents;
